import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getBuildingAddress, putBuildingAddress } from '~/api/building';
import { IBuildingAddress } from '~/types/building';

export default (buildingId: string) => {
  const history = useHistory();
  const [loader, setLoader] = useState(false);
  const [address, setAddress] = useState<null | IBuildingAddress>(null);

  const getAddress = async () => {
    try {
      setLoader(true);
      const res = await getBuildingAddress(buildingId);
      setAddress(res.data);
      setLoader(false);
    } catch (e) {
      setLoader(false);
    }
  };

  useEffect(() => {
    getAddress();
  }, []);

  const onPutAddress = async (address: IBuildingAddress) => {
    try {
      setLoader(true);
      await putBuildingAddress(buildingId, address);
      toast('Added address successfully!');

      setLoader(false);
      history.goBack();
    } catch (e) {
      setLoader(false);
    }
  };

  return { loader, address, onPutAddress };
};
