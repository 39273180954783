import {
  closestCenter,
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors
} from '@dnd-kit/core';
import React, { FC } from 'react';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';

interface IProps {
  isDraggable: boolean;
  setItems: (activeId: string, overId: string) => void;
}

export const DragContext: FC<IProps> = ({
  isDraggable,
  children,
  setItems
}) => {
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor)
  );

  if (isDraggable) {
    const handleDragEnd = (event: DragEndEvent) => {
      const { active, over } = event;

      if (over && active.id !== over.id) {
        setItems(active.id as string, over.id as string);
      }
    };

    return (
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
        modifiers={[restrictToVerticalAxis]}
      >
        {children}
      </DndContext>
    );
  }

  return <div>{children}</div>;
};
