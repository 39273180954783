import React, { useEffect, useState } from "react"
import Switch from "react-ios-switch"
import style from './CobuSwitchList.module.css'
import Spacer from "../Spacer/Spacer"

type IProps = {
    data: {
        key: string,
        id: string,
        value: boolean
    }[],
    title: string,
}

const CobuSwitchList = ({ title, data }: IProps) => {

    const [switchDatas, setData] = useState(data)
    useEffect(() => { setData(data) }, [data])
    return (

        <div className={style.container}>
            <Spacer height={10} />
            <p className="bold">{title}</p>
            <Spacer height={10} />
            {
                switchDatas.map((switchData) => {
                    return (
                        <div key={switchData.id} className={style.list}>
                            <p className={style.rowTextStyle}>{switchData.key}</p>
                            <Switch
                                className={style.switchStyle}
                                checked={switchData.value}
                                key={switchData.id}
                                onChange={(newSwitchState: boolean) => { }}
                            />
                        </div>
                    )
                })
            }
        </div>
    )
}

export default CobuSwitchList