import React from 'react';
import classes from './MerchantForm.module.css';
import { MerchantCategories } from '~/enums/MerchantCategories';
import { MerchantPatch } from '~/types/merchant';

type Props = {
  merchant: MerchantPatch;
  onChangeName: (name: string) => void;
  onChangeCategory: (category: string) => void;
  isEdit: boolean;
};
const MerchantForm = (props: Props) => {
  const { merchant, onChangeName, onChangeCategory, isEdit } = props;

  return (
    <div>
      <div className={classes.createMerchant}>
        <div className={classes.createMerchantForm}>
          <label className='margin-top-16' htmlFor='title'>
            Add merchant name
          </label>
          <input
            type='text'
            id='merchantName'
            name='merchantName'
            placeholder={'Merchant name'}
            defaultValue={merchant.name}
            onChange={(e) => onChangeName(e.target.value)}
          />
        </div>
        <div className={classes.addCategory}>
          <label>Add Category</label>
          <select
            name='merchantcategory'
            defaultValue={merchant.category}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
              onChangeCategory(e.target.value)
            }
            className='height-50'
          >
            <option value='' disabled>
              Choose category
            </option>
            {MerchantCategories.map((MerchantCategory: string) => (
              <option value={MerchantCategory} key={MerchantCategory}>
                {MerchantCategory}
              </option>
            ))}
          </select>
        </div>
      </div>
      {!isEdit && (
        <p className='modal-small-text'>
          Please remember you will need to activate the Offer after the service
          provider enters the details.
        </p>
      )}
    </div>
  );
};

export default MerchantForm;
