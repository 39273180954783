import { emperor } from "~/services"

export const getAllActiveQueues = async () => {
    return await emperor.get('/queues')
}

export const getJobsByQueue = async (queueId: string) => {
    return await emperor.get(`/jobs/${queueId}`);
};

export const retryJobAPI = async (jobId: string) => {
    return await emperor.post(`/jobs/retry/${jobId}`);
};

export const retryQueueAPI = async (queueId: string) => {
    return await emperor.post(`/queue/retry/${queueId}`);
};