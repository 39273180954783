import React, { useState } from 'react';
import { connect, ResolveThunks } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { getUserRoleRequest, loginRequest } from '~/api/auth';
import LoginForm from '~/components/LoginForm/LoginForm';
import Logo from '~/utils/images/logo.svg';
import './LoginContainer.css';
import { saveUser, saveUserRole } from '~/reducers/auth';
import { RootState } from '~/redux/store';
import InvalidBlock from '~/components/InvalidBlock/InvalidBlock';
import { Mixpanel } from '~/services/MixpanelService';
import Footer from '../../components/Footer/Footer';
import { useFullScreenLoader } from '~/hooks/useFullScreenLoader';
import { withFullScreenLoader } from '~/hocs/withFullScreenLoader';
import { AxiosError } from 'axios';
import { toast } from 'react-toastify';

interface DispatchProps {
  saveUser: typeof saveUser;
  saveUserRole: typeof saveUserRole;
}

interface IProps {
  isRSVPLogin: boolean;
}

type Props = IProps &
  ResolveThunks<DispatchProps> &
  ReturnType<typeof mapStateToProps>;

const LoginContainer = (props: Props) => {
  const [invalidCredentials, setInvalidCredentials] = useState(false);
  const history = useHistory();
  const { gatheringId } = useParams<{ gatheringId: string }>();
  const { isRSVPLogin, saveUser, saveUserRole } = props;
  const { loader } = useFullScreenLoader();

  const login = (data: { email: string; password: string }) => {
    loader.show();
    loginRequest({ email: data.email, password: data.password })
      .then((res) => {
        if (res.data.user) {
          const {
            uuid,
            email,
            phone,
            profileImage,
            verified,
            verifiedPhone,
            building,
            firstName,
            lastName
          } = res.data.user;

          const userData = {
            uuid,
            email,
            phone,
            profileImage,
            verified,
            verifiedPhone,
            building: building.uuid
          };
          saveUser(userData);

          // set up Mixpanel
          try {
            Mixpanel.identify(res.data.user.uuid);
            Mixpanel.people.set({
              $email: email,
              $first_name: firstName,
              $last_name: lastName,
              building_name: building.name
            });

            Mixpanel.track('did_login');
          } catch (e) {
            console.log(e);
          }
        }
        getUserRoleRequest(res.data.user.uuid).then((userRoleRes) => {
          saveUserRole(userRoleRes.data);
          if (isRSVPLogin) {
            history.push(`/rsvp/gathering/${gatheringId}`);
          } else {
            history.push('/building');
          }
        });
      })
      .catch((e) => {
        const error = e as AxiosError;
        if (e.status != 403) {
          setInvalidCredentials(true);
        }
        toast.error(error?.message ?? 'Something went wrong');
      })
      .finally(loader.hide);
  };
  const hideInvalidBlock = () => {
    setInvalidCredentials(false);
  };
  return (
    <div>
      <div className='login-container'>
        <div className='left-container'>
          <div className='cobu-container'>
            <img className='cobu-logo' src={Logo} alt='cobu logo' />
            <div className='cobu-text login-text'>
              Helps you meet your neighbors and build community where you live.
            </div>
          </div>
        </div>
        <div className='right-container'>
          {invalidCredentials ? (
            <InvalidBlock text='Incorrect email or password! Try again.' />
          ) : (
            <div className='empty-invalid-block-container' />
          )}
          {isRSVPLogin && !invalidCredentials && (
            <div className='cobu-text forgotten-password-container'>
              Please login to RSVP for your event
            </div>
          )}
          <LoginForm
            onLogin={login}
            invalidCredentials={invalidCredentials}
            onChange={hideInvalidBlock}
          />
          <div
            className='cobu-text forgotten-password-container pointer'
            onClick={() => history.push('/forgottenPassword')}
          >
            Forgotten password?
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

function mapStateToProps(state: RootState) {
  return {
    userId: state.auth.user
  };
}

function mapDispatchToProps(): DispatchProps {
  return {
    saveUser,
    saveUserRole
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps()
)(withFullScreenLoader(LoginContainer));
