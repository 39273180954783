import React from 'react';
import ReactTooltip from 'react-tooltip';
import classes from './CobuTooltip.module.css';

interface IProps {
  disable?: boolean;
}
const CobuTooltip = (props: IProps) => {
  const { disable } = props;
  return (
    <ReactTooltip
      place={'bottom'}
      effect={'solid'}
      className={classes.tooltip}
      disable={disable}
    />
  );
};

export default CobuTooltip;
