import { useEffect, useMemo, useState } from 'react';
import { getBuildingKeys, postBuildingKeys } from '~/api/building';
import { getDirtyValues } from '~/helpers/helpers';
import { IBuildingKey } from '~/types/building';
import { FormInputConfig } from '~/types/residentManagement';

export default () => {
  const [loader, setLoader] = useState(false);
  const [buildingKeys, setBuildingKeys] = useState<null | IBuildingKey[]>(null);

  const fetchBuildingKeys = async () => {
    try {
      setLoader(true);
      const { data } = await getBuildingKeys();

      setLoader(false);
      setBuildingKeys(data);
    } catch (e) {
      setLoader(false);
    }
  };

  const postNewBuildingKeys = async (buildingKeys: IBuildingKey[]) => {
    try {
      setLoader(true);
      await postBuildingKeys(buildingKeys);

      fetchBuildingKeys();

      setLoader(false);
    } catch (e) {
      setLoader(false);
    }
  };

  useEffect(() => {
    fetchBuildingKeys();
  }, []);

  const onSubmit = (formValue: { buildingKeys: IBuildingKey[] }) => {
    const { buildingKeys } = formValue;

    postNewBuildingKeys(buildingKeys);
  };

  const buildingKeyFormFields: FormInputConfig[] = useMemo(
    () => [
      {
        id: 'uuid',
        isRequired: false,
        displayNone: true
      },
      {
        id: 'name',
        isRequired: true,
        inputType: 'text'
      }
    ],
    []
  );

  return { buildingKeys, loader, onSubmit, buildingKeyFormFields };
};
