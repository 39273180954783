import _ from "lodash";
import { useEffect, useReducer } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { fetchResidentsMinData } from "~/api/resident";
import { attributeGoogleReview, getGoogleReviewDetails } from "~/api/review";
import CobuConstants from "~/helpers/cobuConstants";
import googleReviewReducer, { initialState, loader, hideLoader, setActiveResident, setGoogleReviewDetails } from "~/reducers/googleReview";
import { AttributeReviewType, GoogleReview } from "~/types/reviewReceived";
import { AttributionUser, UserMin } from "~/types/user";

// Custom hook to manage Google review data and attribution
export default (reviewId: string, buildingId: string) => {
    const [googleReviewState, dispatch] = useReducer(googleReviewReducer, initialState);
    const history = useHistory();

    // Fetch Google review data and building residents on component mount
    useEffect(() => {
        fetchGoogleReviewData();
    }, []);

    // Select a user for attributing the review
    const selectUser = (user: AttributionUser) => {
        dispatch(setActiveResident({ activeResident: user }));
    };

    // Attribute a review to a selected user
    const attributeReview = async (meta: AttributeReviewType) => {
        dispatch(loader({ loadingString: meta.userId ? CobuConstants.googleReview.attributingReviewforUser(meta.userName) : CobuConstants.googleReview.attributingReview }));
        try {
            const attributeGoogleReviewResp = await attributeGoogleReview(meta);
            dispatch(hideLoader());
            toast(CobuConstants.googleReview.attributeSuccess);
            history.go(-1);
        } catch (error) {
            dispatch(hideLoader());
            if (error) {
                toast(error?.message);
            }
        }
    };

    // Fetch Google review details and building residents
    const fetchGoogleReviewData = async () => {
        try {
            dispatch(loader({ loadingString: "" }));
            const [googleReviewResp, buildingResidentsResp] = await Promise.all([
                getGoogleReviewDetails(reviewId),
                fetchResidentsMinData(buildingId)
            ]);

            // Refactor and categorize building residents
            const refactoredUsers: AttributionUser[] = buildingResidentsResp.data.residents.map((user: UserMin) => ({
                ...user,
                fullName: `${user.firstName} ${user.lastName}`
            }));

            const [deactivated, activated] = _.partition(refactoredUsers, (o) => o.deactivated);
            const [activeInvited, activatedNonInvited] = _.partition(activated, (o) => o.reviewInvitationDate !== null && o.reviewInvitationDate !== "");
            const [deactiveInvited, deactiveNonInvited] = _.partition(deactivated, (o) => o.reviewInvitationDate !== null && o.reviewInvitationDate !== "");

            // Combine and set the categorized residents into the state
            const users = [...activeInvited, ...deactiveInvited, ...activatedNonInvited, ...deactiveNonInvited];
            dispatch(setGoogleReviewDetails({ review: googleReviewResp.data, residents: users.reverse() }));
        } catch (error) {
            dispatch(hideLoader());
            if (error) {
                toast(error?.message);
            }
        }
    };

    return { googleReviewState, attributeReview, selectUser };
};

