import { createSlice } from '@reduxjs/toolkit';
import { IOwnerGroupAsset } from '~/types/assets';

interface IInitialState {
  selectedOwnerGroupProperties: null | IOwnerGroupAsset[];
}

const initialState: IInitialState = {
  selectedOwnerGroupProperties: null
};

const assetSlice = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers: {
    setOwnerGroupProperties: (state, action) => {
      state.selectedOwnerGroupProperties = action.payload;
    }
  }
});

export const { setOwnerGroupProperties } = assetSlice.actions;

export default assetSlice.reducer;
