import React, { useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { AutoGrowFormField } from '~/components/AutoGrowFormField/AutoGrowFormFiled';
import { buildingEmailsFormFiled } from '~/components/Header/CreateMenu/constants';
import Spinner from '~/components/Spinner/Spinner';
import useBuildingEmails from '~/hooks/building/useBuildingEmails';
import { IBuildingEmailDetails } from '~/types/building';
import './EditBuildingEmails.css';
import { FormInputConfig } from '~/types/residentManagement';
import { FormField } from '~/components/FormField';
import Breadcrumbs from '~/components/Breadcrumbs/Breadcrumbs';
import Spacer from '~/components/Spacer/Spacer';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

export const EditBuildingEmails = () => {
  const { buildingId } = useParams<{ buildingId: string }>();

  const {
    loader,
    buildingEmailDetails,
    bucketRolesFormFields,
    roleWiseEmailsFormFields,
    onSubmitBuildingEmails
  } = useBuildingEmails(buildingId);

  const {
    register,
    handleSubmit,
    errors,
    control,
    reset,
    formState: { isDirty, dirtyFields }
  } = useForm<IBuildingEmailDetails>({
    defaultValues: {}
  });

  useEffect(() => {
    if (buildingEmailDetails) {
      reset({
        propertyEmailGroups: buildingEmailDetails.propertyEmailGroups,
        propertyEmails: buildingEmailDetails.propertyEmails,
        reportEmails: buildingEmailDetails.reportEmails
      });
    }
  }, [buildingEmailDetails]);

  const onSubmit: SubmitHandler<IBuildingEmailDetails> = (buildingDetails) => {
    if (!isDirty) {
      toast('Please edit the form');

      return;
    }

    if (buildingDetails.hasOwnProperty('reportEmails')) {
      const commaSeperatedEmails = buildingDetails.reportEmails
        ?.split('\n')
        .join(',');

      buildingDetails.reportEmails = `{${commaSeperatedEmails}}`;
    }

    onSubmitBuildingEmails(buildingDetails);
  };

  const generateField = (fieldConfig: FormInputConfig) => {
    return (
      <div key={fieldConfig.id}>
        <div className='item'>
          {!!fieldConfig?.label && (
            <label
              style={fieldConfig?.styles?.lableStyles}
              className={`${fieldConfig?.styles?.lableClassName}`}
              htmlFor='title'
            >
              {fieldConfig.label}
            </label>
          )}
          <FormField
            fieldConfig={fieldConfig}
            errors={errors}
            register={register}
            control={control}
          />
        </div>
      </div>
    );
  };

  return (
    <div className='float-spinner-parent container'>
      {loader && <Spinner float />}
      <Breadcrumbs />
      <form id='form-container' onSubmit={handleSubmit(onSubmit)}>
        {generateField(buildingEmailsFormFiled)}
        <div className='additional-url-container'>
          <p className='review-description'>{'Role wise Report Emails:'}</p>
          <AutoGrowFormField
            addNewRowText={'Add New Email Role'}
            errors={errors}
            control={control}
            register={register}
            formFieldName={'propertyEmails' as keyof IBuildingEmailDetails}
            rowConfig={roleWiseEmailsFormFields}
          />
        </div>
        <Spacer height={20} />
        <p className='review-description'>{'Group wise role assignment:'}</p>
        <AutoGrowFormField
          addNewRowText={'Add New Email Role'}
          errors={errors}
          control={control}
          register={register}
          formFieldName={'propertyEmailGroups' as keyof IBuildingEmailDetails}
          rowConfig={bucketRolesFormFields}
        />
        <input
          className='btn-standard btn-primary margin-top-32'
          type='submit'
        />
      </form>
    </div>
  );
};
