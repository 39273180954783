import React from 'react';
import { Link } from 'react-router-dom';
import { YardiBuildingIntegration } from '~/api/yardiIntegration';
import Spinner from '~/components/Spinner/Spinner';

export const yardiBuildingIntegrationTableColumns = ({
  downloadYardiCsv,
  onEditClicked,
  onDeleteClicked,
  onSyncClicked,
  buildingIntegrationsBeingSynced
}: {
  downloadYardiCsv: (buildingId: string) => void;
  onEditClicked: (integration: YardiBuildingIntegration) => void;
  onSyncClicked: (integration: YardiBuildingIntegration) => void;
  onDeleteClicked: (integrationId: string) => void;
  buildingIntegrationsBeingSynced: any;
}) => [
  {
    Header: 'PROPERTY',
    disableSortBy: true,
    accessor: (row: YardiBuildingIntegration) => (
      <Link to={`/building/${row.buildingId}`}>{row.buildingName}</Link>
    )
  },
  {
    Header: 'PROPERTY ID',
    disableSortBy: true,
    accessor: (row: YardiBuildingIntegration) => <div>{row.propertyId}</div>
  },
  {
    Header: 'OWNER',
    disableSortBy: true,
    accessor: (row: YardiBuildingIntegration) => (
      <div>{row.ownerGroup ?? 'N/A'}</div>
    )
  },
  {
    Header: 'PROPERTY MGMT GROUP',
    disableSortBy: true,
    accessor: (row: YardiBuildingIntegration) => (
      <div>{row.pmOwnerGroup ?? 'N/A'}</div>
    )
  },
  {
    Header: '# OF RECORDS',
    disableSortBy: true,
    accessor: (row: YardiBuildingIntegration) => (
      <div>{row.totalRecordsSynced ?? 0}</div>
    )
  },
  {
    Header: 'LAST SYNCED',
    disableSortBy: true,
    accessor: (row: YardiBuildingIntegration) => (
      <div>
        {row.lastSyncTime
          ? new Date(row.lastSyncTime).toLocaleDateString()
          : 'N/A'}
      </div>
    )
  },
  {
    Header: 'STATUS',
    disableSortBy: true,
    accessor: (row: YardiBuildingIntegration) => (
      <div className={`last-sync-status-${row.lastSyncStatus}`}>
        {row.lastSyncStatus}
      </div>
    )
  },
  {
    Header: 'ACTIONS',
    disableSortBy: true,
    accessor: (row: YardiBuildingIntegration) => {
      if (buildingIntegrationsBeingSynced[row.buildingId]) {
        return (
          <div
            style={{
              display: 'flex',
              left: 0,
              top: 0,
              height: '100%',
              width: '100%',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: 10
            }}
          >
            <Spinner />
          </div>
        );
      }

      return (
        <div>
          <button
            className='btn btn-primary btn-small'
            onClick={() => downloadYardiCsv(row.buildingId)}
          >
            DOWNLOAD CSV
          </button>
          <button
            className='btn btn-secondary btn-small margin-left-16'
            onClick={() => onEditClicked(row)}
          >
            Edit
          </button>
          <button
            className='btn btn-secondary btn-small margin-left-16'
            onClick={() => onSyncClicked(row)}
          >
            Sync Now{' '}
          </button>
          <button
            className='btn btn-danger btn-small margin-left-16'
            onClick={() => onDeleteClicked(row.uuid)}
          >
            Delete
          </button>
        </div>
      );
    }
  }
];
