import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { connect, ResolveThunks } from 'react-redux';
import { useParams } from 'react-router-dom';
import Breadcrumbs from '~/components/Breadcrumbs/Breadcrumbs';
import GenericModal from '~/components/GenericModal/GenericModal';
import GenericModalWithOutButtons from '~/components/GenericModalWithOutButtons/GenericModalWithOutButtons';
import CheckboxList from '~/components/CheckboxList/CheckboxList';
import ResidentsTable from '~/components/ResidentsTable/ResidentsTable';
import Spinner from '~/components/Spinner/Spinner';
import { ReviewTabs } from '~/enums/Reviews';
import {
  removalReasonsForResidentScoreList,
  limitPerPage,
  removalReasonsForResidentsWithRIList
} from '~/helpers/constants';
import { getBuildingFromStore } from '~/helpers/reduxStoreHelpers';
import { saveUserSettings } from '~/reducers/auth';
import {
  fetchPositiveResidentsSuccess,
  fetchMorePositiveResidentsSuccess,
  removeResidentFormResidentsTabSuccess,
  fetchResidentsWithReviewInvitationSuccess,
  fetchMoreResidentsWithReviewInvitationSuccess,
  removeResidentsFromInvitationSentTabSuccess,
  fetchReviewsReceivedSuccess,
  updateReviewReceivedSuccess,
  fetchMoreReviewsReceivedSuccess
} from '~/reducers/building';
import { RootState } from '~/redux/store';
import { ResidentWithReviewInvitationType } from '~/types/resident';
import {
  fetchPositiveResidentsRequest,
  fetchResidentsWithReviewInvitationRequest,
  removeResidentFormResidentsTabRequest,
  sendInviteRequest
} from '../../api/building';
import {
  createReviewReceived,
  editReviewReceived,
  fetchReviewsReceivedRequest
} from '../../api/review';
import ResidentsWithReviewInvitationTable from '~/components/ResidentsWithReviewInvitation/ResidentsWithReviewInvitationTable';
import ServerErrorPage from '~/components/ServerErrorPage/ServerErrorPage';
import { toast } from 'react-toastify';
import ReviewReceivedForm from '~/components/ReviewReceivedForm/ReviewReceivedForm';
import ReviewReceivedTable from '~/components/ReviewReceivedTable/ReviewReceivedTable';
import ReviewReceivedDetails from '~/components/ReviewReceivedDetails/ReviewReceivedDetails';
import { ReviewReceivedType } from '~/types/reviewReceived';
import { Roles } from '~/enums/Roles';
import { ReviewReceived } from '~/types/form';

interface DispatchProps {
  fetchPositiveResidentsSuccess: typeof fetchPositiveResidentsSuccess;
  saveUserSettings: typeof saveUserSettings;
  fetchMorePositiveResidentsSuccess: typeof fetchMorePositiveResidentsSuccess;
  removeResidentFormResidentsTabSuccess: typeof removeResidentFormResidentsTabSuccess;
  fetchResidentsWithReviewInvitationSuccess: typeof fetchResidentsWithReviewInvitationSuccess;
  fetchMoreResidentsWithReviewInvitationSuccess: typeof fetchMoreResidentsWithReviewInvitationSuccess;
  removeResidentsFromInvitationSentTabSuccess: typeof removeResidentsFromInvitationSentTabSuccess;
  fetchReviewsReceivedSuccess: typeof fetchReviewsReceivedSuccess;
  updateReviewReceivedSuccess: typeof updateReviewReceivedSuccess;
  fetchMoreReviewsReceivedSuccess: typeof fetchMoreReviewsReceivedSuccess;
}
type Props = ResolveThunks<DispatchProps> & ReturnType<typeof mapStateToProps>;
const Reviews = (props: Props) => {
  const {
    positiveResidents,
    residentsWithReviewInvitation,
    fetchPositiveResidentsSuccess,
    removeResidentFormResidentsTabSuccess,
    fetchResidentsWithReviewInvitationSuccess,
    fetchMoreResidentsWithReviewInvitationSuccess,
    removeResidentsFromInvitationSentTabSuccess,
    fetchReviewsReceivedSuccess,
    reviewsReceived,
    userRole,
    updateReviewReceivedSuccess
  } = props;

  const { buildingId } = useParams<{ buildingId: string }>();
  const [loading, setLoading] = useState(true);
  const [selectedTab, setSelectedTab] = useState<ReviewTabs>(
    userRole !== Roles.Pm ? ReviewTabs.RESIDENTS : ReviewTabs.REVIEWS_RECIEVED
  );
  const [tabOptions, setTabOptions] = useState<
    { tab: ReviewTabs; page: number; totalData: number; loading: boolean }[]
  >([
    { tab: ReviewTabs.RESIDENTS, page: 1, totalData: 0, loading: false },
    { tab: ReviewTabs.INVITATIONS_SENT, page: 1, totalData: 0, loading: true },
    {
      tab: ReviewTabs.REVIEWS_RECIEVED,
      page: 1,
      totalData: 0,
      loading: userRole !== Roles.Pm
    }
  ]);
  const [showResidentRemoveModal, setShowResidentRemoveModal] = useState(false);
  const [showReviewReceivedModal, setShowReviewReceivedModal] = useState(false);
  const [residentRemovedId, setResidentRemovedId] = useState('');
  const [reviewInviteId, setReviewInviteId] = useState('');
  const [selectedReason, setSelectedReason] = useState('');
  const [error, setError] = useState(false);
  const [showReviewDetails, setShowReviewDetails] = useState(false);
  const [selectedReview, setSelectedReview] = useState<ReviewReceivedType>(
    {} as ReviewReceivedType
  );

  const [isEditReview, setIsEditReview] = useState(false);
  const selectedBuilding = getBuildingFromStore(buildingId);
  const updateTabOptions = (value: any) => {
    const copyTabOptions = [...tabOptions];
    const index = tabOptions.findIndex((to) => to.tab === selectedTab);
    copyTabOptions[index] = value;
    setTabOptions(copyTabOptions);
  };

  useEffect(() => {
    fetchPositiveResidentsRequest(buildingId)
      .then((res) => {
        fetchPositiveResidentsSuccess(res.data);
        setLoading(false);
        updateTabOptions({
          ...findTabOption(),
          totalData: res.data.count
        });
      })
      .catch(() => {
        // setError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (selectedTab === ReviewTabs.INVITATIONS_SENT) {
      updateTabOptions({
        ...findTabOption(),
        loading: true
      });
      fetchResidentsWithReviewInvitationRequest(buildingId, 1)
        .then((res) => {
          setLoading(false);
          fetchResidentsWithReviewInvitationSuccess(
            res.data.residentsWithReviewInvitations
          );
          updateTabOptions({
            ...findTabOption(),
            page: 1,
            totalData: res.data.count,
            loading: false
          });
        })
        .catch(() => {
          setError(true);
        });
    } else if (selectedTab === ReviewTabs.REVIEWS_RECIEVED) {
      updateTabOptions({
        ...findTabOption(),
        loading: true
      });
      fetchReviewsReceivedRequest(buildingId, 1)
        .then((res) => {
          setLoading(false);
          fetchReviewsReceivedSuccess(res.data.reviewsReceivedPopulated);
          updateTabOptions({
            ...findTabOption(),
            page: 1,
            totalData: res.data.count,
            loading: false
          });
        })
        .catch(() => {
          setError(true);
        });
    }
  }, [selectedTab]);

  useEffect(() => {
    //When user updates review from details section
    if (selectedReview.uuid) {
      const updatedReview = reviewsReceived.filter(
        (review) => review.uuid === selectedReview.uuid
      )[0];
      setSelectedReview(updatedReview);
    }
  }, [reviewsReceived]);

  const checkForMoreData = () => {
    if (selectedTab === ReviewTabs.RESIDENTS) {
      if (findTabOption()!.totalData === positiveResidents.length) {
        return false;
      }
      return true;
    } else if (selectedTab == ReviewTabs.INVITATIONS_SENT) {
      if (findTabOption()!.totalData === residentsWithReviewInvitation.length) {
        return false;
      }
      return true;
    } else {
      if (findTabOption()!.totalData === reviewsReceived.length) {
        return false;
      }
      return true;
    }
  };
  
  const fetchMoreData = () => {
    if (selectedTab === ReviewTabs.INVITATIONS_SENT) {
      fetchResidentsWithReviewInvitationRequest(
        buildingId,
        findTabOption()!.page + 1
      ).then(
        (res: {
          data: {
            residentsWithReviewInvitations: ResidentWithReviewInvitationType[];
            count: number;
          };
        }) => {
          fetchMoreResidentsWithReviewInvitationSuccess(
            res.data.residentsWithReviewInvitations
          );
          updateTabOptions({
            ...findTabOption(),
            page: findTabOption()!.page + 1
          });
        }
      );
    } else if (selectedTab === ReviewTabs.REVIEWS_RECIEVED) {
      fetchReviewsReceivedRequest(buildingId, findTabOption()!.page + 1)
        .then((res) => {
          fetchMoreReviewsReceivedSuccess(res.data.reviewsReceivedPopulated);
          updateTabOptions({
            ...findTabOption(),
            page: findTabOption()!.page + 1
          });
        })
        .catch(() => {
          setError(true);
        });
    }
  };
  const findTabOption = () => {
    return tabOptions.find((to) => to.tab === selectedTab);
  };
  const sendInvite = async (userId: string) => {
    try {
      const res = await sendInviteRequest(userId);
      removeResidentFormResidentsTabSuccess(res.data.user);
      toast('Review invitation has been sent!');
    } catch (err: any) {
      if (err && err.message) {
        toast(err.message);
      }
    }
  };
  const closeReviewModal = () => {
    setIsEditReview(false);
    setShowReviewReceivedModal(false);
  };
  const submitReviewReceived = (data: ReviewReceived) => {
    const successMessage = 'Review saved successfully';
    const failMessage = 'Data cannot be saved. Please try again later.';
    const reviewData = {
      residentReviewInvitationId: reviewInviteId,
      reviewName: data.name,
      reviewDate: data.dateReview,
      reviewText: data.reviewText,
      reviewNotes: data.notes,
      reviewRating: data.selectedRating,
      reviewLink: data.linkToGoogle,
      buildingId: buildingId
    };

    return new Promise((resolve) => {
      if (!isEditReview) {
        createReviewReceived(reviewData)
          .then((res) => {
            if (res.status === 200) {
              toast(successMessage);
              resolve({ success: true });
              closeReviewModal();
            }
          })
          .catch((error) => {
            if (error.status === 400) {
              toast(error.message);
              resolve({ success: false });
            } else {
              toast(failMessage);
            }
          });
      } else if (selectedReview) {
        editReviewReceived(selectedReview.uuid, reviewData)
          .then((res) => {
            if (res.status === 200) {
              updateReviewReceivedSuccess(res.data);
              toast(successMessage);
              resolve({ success: true });
              closeReviewModal();
            }
          })
          .catch((error) => {
            if (error.status === 400) {
              toast(error.message);
              resolve({ success: false });
            } else {
              toast(failMessage);
            }
          });
      }
    });
  };
  return (
    <div>
      <ReviewReceivedDetails
        showModal={showReviewDetails}
        review={selectedReview}
        closeModal={() => setShowReviewDetails(false)}
        openEditMode={() => {
          setIsEditReview(true);
        }}
      />
      {!error ? (
        <div className='content-container'>
          {loading && <Spinner />}
          {!loading && (
            <div>
              <Breadcrumbs />
              <p className='h1-title'>
                Reviews for the <strong>{selectedBuilding.name}</strong>
              </p>
              <div className='menu-bar'>
                {userRole !== Roles.Pm && (
                  <div className='tab-container pointer'>
                    <div
                      className={
                        selectedTab === ReviewTabs.RESIDENTS
                          ? 'gatherings-btn-selected upcoming'
                          : 'gatherings-btn upcoming'
                      }
                      onClick={() => {
                        setSelectedTab(ReviewTabs.RESIDENTS);
                      }}
                    >
                      <p className='bold'>RESIDENTS</p>
                    </div>
                    <div
                      className={
                        selectedTab === ReviewTabs.INVITATIONS_SENT
                          ? 'gatherings-btn-selected middle'
                          : 'gatherings-btn middle'
                      }
                      onClick={() => {
                        setSelectedTab(ReviewTabs.INVITATIONS_SENT);
                      }}
                    >
                      <p className='bold'>INVITATIONS SENT</p>
                    </div>
                    <div
                      className={
                        selectedTab === ReviewTabs.REVIEWS_RECIEVED
                          ? 'gatherings-btn-selected past'
                          : 'gatherings-btn past'
                      }
                      onClick={() => {
                        setSelectedTab(ReviewTabs.REVIEWS_RECIEVED);
                      }}
                    >
                      <p className='bold'>REVIEWS RECEIVED</p>
                    </div>
                  </div>
                )}
              </div>
              {selectedTab === ReviewTabs.RESIDENTS && userRole !== Roles.Pm && (
                <InfiniteScroll
                  dataLength={positiveResidents.length}
                  next={() => {
                    fetchMoreData();
                  }}
                  hasMore={checkForMoreData()}
                  loader={''}
                >
                  <ResidentsTable
                    data={positiveResidents}
                    openResidentRemoveModal={(value) => {
                      setShowResidentRemoveModal(true);
                      setResidentRemovedId(value);
                    }}
                    sendInvite={sendInvite}
                  />
                </InfiniteScroll>
              )}
              {findTabOption()!.loading && <Spinner />}
              {selectedTab === ReviewTabs.INVITATIONS_SENT &&
                userRole !== Roles.Pm &&
                !findTabOption()!.loading && (
                  <InfiniteScroll
                    dataLength={limitPerPage}
                    next={() => {
                      fetchMoreData();
                    }}
                    hasMore={checkForMoreData()}
                    loader={''}
                  >
                    <ResidentsWithReviewInvitationTable
                      data={residentsWithReviewInvitation}
                      openResidentRemoveModal={(value) => {
                        setShowResidentRemoveModal(true);
                        setResidentRemovedId(value);
                      }}
                      openReviewReceived={(value) => {
                        toast(`This action has been deprecated. Please utilize the Google Review Attribution page to attribute reviews to users`)
                      }}
                    />
                  </InfiniteScroll>
                )}
              {selectedTab === ReviewTabs.REVIEWS_RECIEVED &&
                !findTabOption()!.loading && (
                  <InfiniteScroll
                    dataLength={limitPerPage}
                    next={() => {
                      fetchMoreData();
                    }}
                    hasMore={checkForMoreData()}
                    loader={''}
                  >
                    <ReviewReceivedTable
                      data={reviewsReceived}
                      openReviewDetails={(review) => {
                        setSelectedReview(review);
                        setShowReviewDetails(true);
                      }}
                      showEditModal={(review) => {
                        setSelectedReview(review);
                        setIsEditReview(true);
                      }}
                      userRole={userRole}
                    />
                  </InfiniteScroll>
                )}
            </div>
          )}
          <GenericModal
            showModal={!!showResidentRemoveModal}
            title={'Remove Resident from Invite List?'}
            content={
              <CheckboxList
                options={
                  selectedTab === ReviewTabs.RESIDENTS
                    ? removalReasonsForResidentScoreList
                    : removalReasonsForResidentsWithRIList
                }
                setSelectedOption={(value) => {
                  setSelectedReason(value);
                }}
                selectedOption={selectedReason}
              />
            }
            actionText='Remove resident'
            onChange={() => {
              setShowResidentRemoveModal(false);
              setSelectedReason('');
            }}
            actionButtonDisabled={!selectedReason}
            actionFunction={async () => {
              console.log({
                user: residentRemovedId,
                removalReason: selectedReason,
                removalTable:
                  selectedTab === ReviewTabs.RESIDENTS
                    ? 'RESIDENT_SCORE'
                    : 'RESIDENT_REVIEW_INVITATION'
              })
              removeResidentFormResidentsTabRequest({
                user: residentRemovedId,
                removalReason: selectedReason,
                removalTable:
                  selectedTab === ReviewTabs.RESIDENTS
                    ? 'RESIDENT_SCORE'
                    : 'RESIDENT_REVIEW_INVITATION'
              }).then((res) => {
                selectedTab === ReviewTabs.RESIDENTS
                  ? removeResidentFormResidentsTabSuccess(res.data.user)
                  : removeResidentsFromInvitationSentTabSuccess(res.data.user);
                setSelectedReason('');
                setShowResidentRemoveModal(false);
                toast('Resident has been removed!');
              });
            }}
          />

          <GenericModalWithOutButtons
            showModal={!!showReviewReceivedModal || isEditReview}
            title={'Review Received'}
            content={
              <ReviewReceivedForm
                closeModal={closeReviewModal}
                review={selectedReview}
                prefillForm={isEditReview}
                onSubmit={(data) => submitReviewReceived(data)}
              />
            }
            onChange={() => {
              closeReviewModal();
              setSelectedReason('');
            }}
          />
        </div>
      ) : (
        <ServerErrorPage />
      )}
    </div>
  );
};

function mapStateToProps(state: RootState) {
  return {
    positiveResidents: state.building.positiveResidents,
    currentUserRole: state.auth.userRole,
    userSettings: state.auth.userSettings,
    residentsWithReviewInvitation: state.building.residentsWithInvitation,
    reviewsReceived: state.building.reviewsReceived,
    showReviewsToPM: state.building.buildingDashboardConfig.showReviewsToPM,
    userRole: state.auth.userRole
  };
}

function mapDispatchToProps(): DispatchProps {
  return {
    fetchPositiveResidentsSuccess,
    fetchMorePositiveResidentsSuccess,
    saveUserSettings,
    removeResidentFormResidentsTabSuccess,
    fetchResidentsWithReviewInvitationSuccess,
    fetchMoreResidentsWithReviewInvitationSuccess,
    removeResidentsFromInvitationSentTabSuccess,
    fetchReviewsReceivedSuccess,
    updateReviewReceivedSuccess,
    fetchMoreReviewsReceivedSuccess
  };
}

export default connect(mapStateToProps, mapDispatchToProps())(Reviews);
