import { emperor } from '~/services';
export const createGroupCommentRequest = async (data: FormData) => {
  return await emperor.post(`groupcomment/`, data);
};

export const updateGroupComment = async (comment: any) => {
  return await emperor.patch(`groupcomment/${comment.uuid}`, comment);
};

export const sendEmailRequest = async (
  email: string,
  subject: string,
  contents: string
) => {
  return await emperor.post(`user/send-email`, {
    email,
    subject,
    contents
  });
};

export const createGroupEventCommentRequest = async (data: FormData) => {
  return await emperor.post('groupeventcomment', data);
};

export const updateGroupEventComment = async (comment: any) => {
  return await emperor.patch(`groupeventcomment/${comment.uuid}`, comment);
};

export const moveCommentToMarketplace = async (comment: any) => {
  let buildingId = typeof comment.building === "object" ? comment.building.uuid : comment.building;
  return await emperor.post(`groupcomment/move/${buildingId}/${comment.uuid}`, comment);
};
