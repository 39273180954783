import React from 'react';
import { Building } from '~/types/building';
import { ReactComponent as BuildingPlaceholder } from '~/utils/images/building.svg';
import CobuTooltip from '../CobuTooltip/CobuTooltip';
import classes from './BuildingCard.module.css';

interface IProps {
  onClick: (uuid: string, building: Building) => void;
  buildingCard: Building;
}

const BuildingCard = (props: IProps) => {
  const { buildingCard } = props;
  const buildingCardImgStyle = {
    backgroundImage: `url(${buildingCard.image || BuildingPlaceholder})`
  };

  return (
    <div
      className={classes.buildingCardContainer}
      onClick={() => props.onClick(buildingCard.uuid, buildingCard)}
    >
      {buildingCard.image && (
        <div
          className={`${classes.buildingCard} ${classes.buildingCardImg}`}
          style={buildingCardImgStyle}
        >
          <div className={`${classes.buildingCardOverlay} pointer`} />
        </div>
      )}

      {!buildingCard.image && (
        <div
          className={`${classes.buildingCard} ${classes.defaultImageBuilding} pointer`}
        >
          <BuildingPlaceholder />
        </div>
      )}
      <p
        className={`${classes.buildingCardTitle}`}
        data-tip={buildingCard.name}
      >
        {buildingCard.name}
      </p>
      <CobuTooltip />
    </div>
  );
};

export default BuildingCard;
