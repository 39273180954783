import { emperor } from "~/services";

export const getNotificationTypes = async () => {
    return await emperor.get(`/notification/types`)
};

export const getNotifications = async (residentId: string) => {
    return await emperor.get(`/notification`, {
        params: {
            userUuid: residentId,
            limit: 30
        }
    })
};