import { useState } from 'react';
import { patchEditBuilding, postCreateBuilding } from '~/api/building';
import {
  editOnBoardingOptions,
  onBoardingOptions
} from '~/components/CreateEditBuildingForm/constants';
import { IMultiSelectOptionList } from '~/helpers/interfaces';
import { Building } from '~/types/building';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { cloneDeep } from 'lodash';

const getBoardingOptions = (onBoardingOptions: IMultiSelectOptionList[]) => {
  onBoardingOptions[0].showSibling = false;

  return onBoardingOptions;
};

export default (buildingDetails: any) => {
  const getEditBoardingOptions = (
    onBoardingOptions: IMultiSelectOptionList[]
  ) => {
    onBoardingOptions[0].isSelected = buildingDetails?.skippable || false;
    return onBoardingOptions;
  };

  const [boardingOptions, setBoardingOptions] = useState(() =>
    getBoardingOptions(cloneDeep(onBoardingOptions))
  );

  const [editBoardingOptions, setEditBoardingOptions] = useState(() =>
    getEditBoardingOptions(cloneDeep(editOnBoardingOptions))
  );

  const history = useHistory();
  const [loader, showLoader] = useState(false);

  const createBuilding = async (building: Building, callback?: () => void) => {
    const buildingFormData = new FormData();
    buildingFormData.append('name', building.name);
    buildingFormData.append('shortName', building.shortName as string);
    buildingFormData.append('timezone', building.timezone);
    buildingFormData.append('VCM', building.VCM.toString() as string);
    buildingFormData.append(
      'onboardingSkippable',
      (building.onBoardingSkippable as boolean).toString()
    );

    if ('raffleDescription' in building) {
      buildingFormData.append(
        'raffleDescription',
        building.raffleDescription as string
      );
      buildingFormData.append(
        'raffleEndDate',
        building.raffleEndDate as string
      );
    }

    buildingFormData.append('image', building.image);

    building.propertyLogoUrl &&
      buildingFormData.append('buildingLogo', building.propertyLogoUrl);

    showLoader(true);

    try {
      const res = await postCreateBuilding(buildingFormData);
      const {
        data: { record }
      } = res;

      showLoader(false);
      toast(`Building '${building.name}' created successfully`);
      if (callback) {
        callback();
      }

      history.push(`/building/${record.uuid}/edit`);
    } catch (e) {
      showLoader(false);
      toast(e.message || 'Failed to create building. Please try again');
    }
  };

  const editBuilding = async (
    buildingId: string,
    building: Building,
    callback?: () => Promise<void>
  ) => {
    showLoader(true);

    const formData = new FormData();

    Object.entries(building).forEach(([key, value]) => {
      const type = typeof value;

      switch (type) {
        case 'string':
          formData.append(key, value as string);
          break;
        case 'boolean':
          formData.append(key, value as string);
          break;
      }
    });

    building.additional_reviewurls &&
      formData.append(
        'additional_reviewurls',
        JSON.stringify(building.additional_reviewurls)
      );

    building.google_feature_ids &&
      formData.append(
        'google_feature_ids',
        JSON.stringify(building.google_feature_ids)
      );

    building.propertyLogoUrl &&
      formData.append('buildingLogo', building.propertyLogoUrl as Blob);

    try {
      await patchEditBuilding(buildingId, formData);

      if (callback) {
        callback().then(() => {
          toast(`Building '${building.name}' updated successfully`);

          showLoader(false);
        });
      } else {
        toast(`Building '${building.name}' updated successfully`);

        showLoader(false);
      }
    } catch (e) {
      showLoader(false);
      toast(e.message || 'Failed to update building. Please try again');
    }
  };

  return {
    loader,
    boardingOptions,
    editBoardingOptions,
    setEditBoardingOptions,
    setBoardingOptions,
    createBuilding,
    editBuilding
  };
};
