import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import CobuTable from '~/components/CobuTable/CobuTable';
import Spinner from '~/components/Spinner/Spinner';
import useCommunityLeads from '~/hooks/building/referral/useCommunityLeads';
import { ICommunityLead } from '~/api/referral';
import { ReactComponent as Community } from '~/utils/images/community.svg';
import './ReferralLeads.css';
import Breadcrumbs from '~/components/Breadcrumbs/Breadcrumbs';
import { CellProps } from 'react-table';
import momentTimezone from 'moment-timezone';
import moment from 'moment';
import useGetBuildingDetails from '~/hooks/building/useGetBuildingDetails';
import { Building } from '~/types/building';
import { Tabs } from '~/components/Tabs';
import Spacer from '~/components/Spacer/Spacer';

export const ReferralLeads = () => {
  const { buildingId } = useParams<{ buildingId: string }>();
  const { loading, referralLeads } = useCommunityLeads(buildingId);
  const [offerType, setOfferType] = useState('all');

  const buildingDetails = useGetBuildingDetails(buildingId);

  const filteredLeads = useMemo(() => {
    if (!referralLeads) {
      return null;
    }

    if (offerType === 'employee') {
      return referralLeads.filter((lead: ICommunityLead) => !!lead.isEmployee);
    }

    if (offerType === 'resident') {
      return referralLeads.filter((lead: ICommunityLead) => !lead.isEmployee);
    }

    return referralLeads;
  }, [referralLeads, offerType]);

  if (loading) {
    return <Spinner />;
  }

  if (referralLeads && referralLeads.length <= 0) {
    return (
      <div className='container'>
        <Breadcrumbs />
        <div className='no-referral-container'>
          <Community />
          <h2>No Referral found!</h2>
        </div>
      </div>
    );
  }

  const buildingTimezone = (buildingDetails as unknown as Partial<Building>)
    ?.timezone;

  const referralLeadColumns = [
    {
      Header: 'LEAD NAME',
      accessor: 'leadName'
    },
    {
      Header: 'LEAD EMAIL',
      accessor: 'leadEmail'
    },
    {
      Header: 'LEAD PHONE NUMBER',
      accessor: 'leadPhoneNumber'
    },
    {
      Header: 'STATUS',
      accessor: 'status'
    },
    {
      Header: 'CREATED',
      accessor: 'createdAt',
      Cell: (cell: CellProps<ICommunityLead>) => (
        <div>
          {momentTimezone
            .tz(cell.row.original.createdAt, buildingTimezone ?? '')
            .format('MM/DD/YYYY hh:mm A')}
        </div>
      )
    },
    {
      Header: 'REFERRED BY',
      accessor: 'referredBy',
      Cell: (cell: CellProps<ICommunityLead>) => (
        <div>{cell.row.original.isEmployee ? '' : cell.value}</div>
      )
    },
    {
      Header: 'REFERRER EMAIL',
      accessor: 'referrerEmail',
      Cell: (cell: CellProps<ICommunityLead>) => (
        <div>
          {cell.row.original.isEmployee
            ? 'preferred employee program'
            : cell.value}
        </div>
      )
    },
    {
      Header: 'UNIT',
      accessor: 'roomNumber',
      Cell: (cell: CellProps<ICommunityLead>) => (
        <div>{cell.row.original.isEmployee ? '' : cell.value}</div>
      )
    }
  ];

  return (
    <div className='container'>
      <Breadcrumbs />
      <div className='margin-top-24'>
        <Tabs.Container
          isOnChangePreventDefault
          onChange={(value) => {
            setOfferType(value);
          }}
          value={offerType}
          styles={{ width: '50%' }}
        >
          <Tabs.Option selected={offerType === 'all'} key={'all'} value={'all'}>
            All
          </Tabs.Option>
          <Tabs.Option
            selected={offerType === 'resident'}
            key={'resident'}
            value={'resident'}
          >
            Resident Referrals
          </Tabs.Option>
          <Tabs.Option
            selected={offerType === 'employee'}
            key={'employee'}
            value={'employee'}
          >
            Employee preferred program
          </Tabs.Option>
        </Tabs.Container>
        <Spacer height={24} />
        {!!filteredLeads && (
          <CobuTable
            centerAlignedHeaderAndContent
            columns={referralLeadColumns}
            data={filteredLeads as ICommunityLead[]}
            sort={{
              id: 'createdAt',
              descending: true
            }}
          />
        )}
      </div>
    </div>
  );
};
