import React from "react";
import { useHistory, useParams } from "react-router-dom";
import Breadcrumbs from "~/components/Breadcrumbs/Breadcrumbs";
import Spacer from "~/components/Spacer/Spacer";
import CobuConstants from "~/helpers/cobuConstants";
import useGoogleReviewDetails from "~/hooks/googlereviews/useGoogleReviewDetails";
import style from './GoogleReview.module.css';
import moment from "moment";
import AttributionForm from "~/components/AttributionForm/AttributionForm";
import RatingDisplay from "~/components/RatingDisplay/RatingDisplay";
import Spinner from "~/components/Spinner/Spinner";

const GoogleReviewDetails = () => {
    const { buildingId, reviewId } = useParams<{ buildingId: string, reviewId: string }>();
    const { googleReviewState, attributeReview, selectUser } = useGoogleReviewDetails(reviewId, buildingId);
    const review = googleReviewState.review;
    const history = useHistory();

    // Renders details of the Google review section
    const renderReviewDetails = () => {
        return (
            <div className={`${style.flex1} ${style.margin100}`}>

                {/* Display contributor information */}
                <div className="flexRow center-align">
                    {/* Contributor's avatar */}
                    <img className='avatar margin-right-8' alt='avatar' referrerPolicy="no-referrer" src={review?.contributor_thumbnail} />
                    {/* Contributor's name and link to their Google profile */}
                    <div>
                        <p className='default-text weight-500 font-16'>{review?.contributor_name}</p>
                        <a href={review?.contributor_link} target="_blank" className='default-text font-14'>
                            {CobuConstants.googleReview.viewGoogleProfile}
                        </a>
                    </div>
                    {/* Display review rating */}
                    <div style={{ flex: 1, textAlign: 'end', marginRight: '50px' }} className='flex-end'>
                        <RatingDisplay value={review?.review_rating ?? 0} small />
                    </div>
                </div>

                <Spacer height={20} />

                {/* Display review snippet */}
                <p className='default-text font-16'>{review?.review_snippet}</p>

                <Spacer height={20} />

                {/* Display review images */}
                <div className="grid-container">
                    {review?.images?.map((img, index) => (
                        img && <img key={index} alt='avatar' referrerPolicy="no-referrer" style={{ height: 150, width: 150, marginRight: '10px' }} src={img} />
                    ))}
                </div>


                {/* Display date found */}
                <ReviewDetailItem label={CobuConstants.googleReview.dateFound} value={moment(review?.date_found).local().format('MM/DD/YYYY')} />
                {/* Display review likes */}
                <ReviewDetailItem label={CobuConstants.googleReview.likes} value={`${review?.review_likes}`} />
                {/* Display Local Guide status */}
                <ReviewDetailItem label={CobuConstants.googleReview.localGuide} value={review?.contributor_local_guide ? 'YES' : 'NO'} />

                <Spacer height={10} />

                {/* Link to view the review */}
                <a href={review?.url} target="_blank" className='default-text font-14'>
                    {CobuConstants.googleReview.viewReview}
                </a>
            </div>
        );
    };

    const ReviewDetailItem = ({ label, value }: { label: string, value: string }) => {
        return (
            <div className={`${style.row}`}>
                <p className='small-text'>{label}</p>
                <Spacer width={10} />
                <p className='small-text bold'>{value}</p>
            </div>
        );
    };

    // Renders details of the users for attribution
    const renderUsersDetails = () => {
        return (
            <div className={`${style.flex3}`}>
                <AttributionForm
                    review={review}
                    users={googleReviewState.residents}
                    attribute={(metadata) => {
                        attributeReview({
                            userId: metadata.userId,
                            userName: metadata.userName,
                            uuid: metadata.uuid,
                            isCobu: metadata.cobu,
                            notes: metadata.notes,
                            ...metadata.type && {type: metadata.type}
                        });
                    }}
                    onSelectUser={selectUser}
                />
            </div>
        );
    };

    if (googleReviewState.loading) {
        return (
            <div className={style.loadingContainer}>
                <Spinner />
                <h3 style={{ textAlign: 'center' }}>{googleReviewState.loadingString}</h3>
            </div>
        );
    }

    return (
        <div className='content-container'>
            <Breadcrumbs />
            <Spacer height={20} />
            <div className={`${style.row} ${style.spaceBtw}`}>
                <h1>{CobuConstants.googleReview.assignReviewTitle}</h1>
                <button className='btn-standard btn-secondary margin-left-16' onClick={() => history.go(-1)}>
                    {CobuConstants.googleReview.backtoList}
                </button>
            </div>
            <Spacer height={20} />
            <div className={`${style.row} ${style.flex2}`}>
                {renderReviewDetails()}
                {renderUsersDetails()}
            </div>
            <Spacer height={200} />
        </div>
    );
};

export default GoogleReviewDetails;
