import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { fetchPerk, updateMerchant, updatePerk } from '~/api/perks';
import Breadcrumbs from '~/components/Breadcrumbs/Breadcrumbs';
import PerkForm from '~/components/PerkForm/PerkForm';
import Spinner from '~/components/Spinner/Spinner';
import { PerkPatch } from '~/types/perk';

const PerkFormContainer = () => {
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const handleSubmit = (perk: PerkPatch) => {
    Promise.all([
      updatePerk(perkId, perk),
      updateMerchant(perk?.merchant!)
    ]).then(() => {
      history.push(`/building/${buildingId}/perks`);
    });
  };

  const [perk, setPerk] = useState<PerkPatch>({
    uuid: '',
    merchant: {
      name: '',
      uuid: '',
      category: ''
    },
    name: '',
    offerType: '',
    customButtonLabel: '',
    contactSMSNumber: '',
    contactPhone: '',
    contactEmail: '',
    redeemLink: '',
    status: '',
    description: ''
  });
  const { perkId, buildingId } = useParams<{
    perkId: string;
    buildingId: string;
  }>();

  useEffect(() => {
    fetchPerk(perkId).then((res) => {
      const perkPatch = {
        uuid: res.data.uuid,
        name: res.data.name,
        offerType: res.data.offerType,
        merchant: {
          uuid: res.data.merchant.uuid,
          name: res.data.merchant.name,
          category: res.data.merchant.category
        },
        customButtonLabel: res.data.customButtonLabel,
        contactSMSNumber: res.data.contactSMSNumber,
        contactPhone: res.data.contactPhone,
        contactEmail: res.data.contactEmail,
        redeemLink: res.data.redeemLink,
        status: res.data.status,
        description: res.data.description
      };
      setPerk(perkPatch);
      setLoading(false);
    });
  }, [perkId]);

  return (
    <div className='content-container'>
      <Breadcrumbs indexesToHide={[3]} />
      {!loading && <PerkForm onSubmit={handleSubmit} perk={perk} />}
      {loading && <Spinner />}
    </div>
  );
};

export default PerkFormContainer;
