import React, { FC, ReactNode } from 'react';
import { IBuildingHelloData } from '~/types/helloData';
import * as _ from 'lodash';
import moment from 'moment';
import { HelloDataView } from './HelloDataView';
import ReactJson from 'react-json-view';
import './EnableHelloData.css';

interface IProps {
  buildingHelloData: IBuildingHelloData;
}

export const HelloDataDetails: FC<IProps> = ({ buildingHelloData }) => {
  const addressDataPoints = [
    {
      Address: _.get(buildingHelloData, 'street_address'),
      City: _.get(buildingHelloData, 'city'),
      State: _.get(buildingHelloData, 'state'),
      Zipcode: _.get(buildingHelloData, 'postalcode')
    }
  ];

  const propertyDetailsDataPoints = [
    {
      Condo: _.get(buildingHelloData, 'details.is_condo'),
      Senior: _.get(buildingHelloData, 'details.is_senior'),
      Apartment: _.get(buildingHelloData, 'details.is_apartment'),
      'Single Family': _.get(buildingHelloData, 'details.is_single_family'),
      'Year Built': _.get(buildingHelloData, 'details.year_built'),
      'No. Stories': _.get(buildingHelloData, 'details.number_stories'),
      'Parking Garaged': _.get(buildingHelloData, 'details.parking_garage'),
      'Parking Covered': _.get(buildingHelloData, 'details.parking_covered'),
      'Estimated Occupancy': _.get(buildingHelloData, [
        'details.estimated_occupancy'
      ]),
      'Unit Availibility': (() => {
        const building_availability = _.get(
          buildingHelloData,
          'details.building_availability'
        );

        return building_availability?.length || 0;
      })()
    }
  ];

  const aminitiesDataPoint = [
    {
      Amenities: (() => {
        const aminities = _.get(
          buildingHelloData,
          'details.building_amenities'
        );

        if (!aminities) {
          return '';
        }

        return aminities.reduce((acc: ReactNode[], newValue: string) => {
          const isFirst = acc.length === 0;

          const valueString = newValue.split('_').join(' ');

          acc.push(<h3>{isFirst ? valueString : `, ${valueString}`}</h3>);

          return acc;
        }, []);
      })()
    }
  ];

  //
  const generalDataPoints = [
    {
      Population: _.get(buildingHelloData, 'details.demographics.total_pop'),
      Density: _.get(buildingHelloData, 'details.demographics.pop_density')
        ? `${Math.round(
            _.get(buildingHelloData, 'details.demographics.pop_density') * 100
          )} %`
        : '',
      'Median age': _.get(buildingHelloData, 'details.demographics.median_age'),
      'Median rent': _.get(
        buildingHelloData,
        'details.demographics.median_rent'
      )
        ? `$${_.get(buildingHelloData, 'details.demographics.median_rent')}`
        : '',
      'Median income': _.get(
        buildingHelloData,
        'details.demographics.median_income'
      )
        ? `$${_.get(buildingHelloData, 'details.demographics.median_income')}`
        : ''
    }
  ];

  const populationDataPoints = [
    {
      Asian: _.get(buildingHelloData, 'details.demographics.asian_pop_perc'),
      Black: _.get(buildingHelloData, 'details.demographics.black_pop_perc'),
      White: _.get(buildingHelloData, 'details.demographics.white_pop_perc'),
      Hispanic: _.get(
        buildingHelloData,
        'details.demographics.hispanic_pop_perc'
      )
    }
  ];

  const educationDataPoints = [
    {
      'High school': _.get(
        buildingHelloData,
        'details.demographics.high_school_diploma_perc'
      ),
      Undergrade: _.get(
        buildingHelloData,
        'details.demographics.in_undergrad_college_perc'
      ),
      Bachelors: _.get(
        buildingHelloData,
        'details.demographics.bachelors_degree_perc'
      ),
      Masters: _.get(
        buildingHelloData,
        'details.demographics.masters_degree_perc'
      )
    }
  ];

  const buildingQualityDataPoints = [
    {
      Overall: _.get(
        buildingHelloData,
        'details.building_quality.property_overall_quality'
      ),
      Bedroom: _.get(
        buildingHelloData,
        'details.building_quality.avg_quality_score_bedroom'
      ),
      Kitchen: _.get(
        buildingHelloData,
        'details.building_quality.avg_quality_score_kitchen'
      ),
      Bathroom: _.get(
        buildingHelloData,
        'details.building_quality.avg_quality_score_bedroom'
      ),
      'Dinning room': _.get(
        buildingHelloData,
        'details.building_quality.avg_quality_score_dining_room'
      ),
      'Living room': _.get(
        buildingHelloData,
        'details.building_quality.avg_quality_score_living_room'
      ),
      'Common areas': _.get(
        buildingHelloData,
        'details.building_quality.avg_quality_score_common_areas'
      ),
      'Laundary room': _.get(
        buildingHelloData,
        'details.building_quality.avg_quality_score_laundry_room'
      ),
      'Main entrance': _.get(
        buildingHelloData,
        'details.building_quality.avg_quality_score_main_entrance'
      ),
      'Swimming pool': _.get(
        buildingHelloData,
        'details.building_quality.avg_quality_score_swimming_pool'
      ),
      'Fitness center': _.get(
        buildingHelloData,
        'details.building_quality.avg_quality_score_fitness_center'
      ),
      'Stairs hallway': _.get(
        buildingHelloData,
        'details.building_quality.avg_quality_score_stairs_hallway'
      ),
      'Walk in closet': _.get(
        buildingHelloData,
        'details.building_quality.avg_quality_score_walk_in_closet'
      )
    }
  ];

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        gap: '0px 20px',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        width: '100%'
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '30px 0',
          flex: 1
        }}
      >
        <HelloDataView title='Address' sections={addressDataPoints} />
        <HelloDataView
          title='Property Details'
          sections={propertyDetailsDataPoints}
        />
        <HelloDataView sections={aminitiesDataPoint} isHorizontal={false} />
        <div style={{ color: 'white' }}>
          <h2 className='margin-bottom-16'>Raw Results</h2>
          <ReactJson
            src={buildingHelloData.details}
            theme='monokai'
            enableClipboard
            displayDataTypes
            displayObjectSize
            indentWidth={4}
            groupArraysAfterLength={5}
          />
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '30px 0',
          flex: 1
        }}
      >
        <h2>Demographics</h2>
        <HelloDataView
          title='General'
          sections={generalDataPoints}
          isTableView
        />
        <HelloDataView
          title='Population Percentage'
          sections={populationDataPoints}
          isTableView
          unitString='%'
        />
        <HelloDataView
          title='Education Percentage'
          sections={educationDataPoints}
          isTableView
          unitString='%'
        />
        <HelloDataView
          title='Building Quality'
          sections={buildingQualityDataPoints}
          isTableView
          unitString='%'
        />
      </div>
    </div>
  );
};
