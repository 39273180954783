import { useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import {
  getBuildingEmailDetails,
  getBuildingEmailGroups,
  getBuildingEmailRoles,
  postBuildingEmailDetails
} from '~/api/building';
import { formatBuildingData } from '~/components/CreateEditBuildingForm/helpers';
import { emailRegex } from '~/helpers/helpers';
import { ISelectOption } from '~/helpers/interfaces';
import {
  Building,
  IBuildingEmailDetails,
  IBuildingEmailGroup,
  IBuildingEmailRole,
  IPropertyEmail,
  IPropertyEmailGroup
} from '~/types/building';
import { FormInputConfig } from '~/types/residentManagement';

export default (buildingUuid: string) => {
  const [loader, setLoader] = useState(false);
  const [buildingEmailDetails, setBuildingEmailDetails] =
    useState<IBuildingEmailDetails | null>(null);

  const [emailRoleOptions, setEmailRoleOptions] = useState<ISelectOption[]>();
  const [emailGroupOptions, setEmailGroupOptions] = useState<ISelectOption[]>();

  const getBuildingEmailRoleOptions = async () => {
    try {
      setLoader(true);
      const res = await getBuildingEmailRoles();

      const options: ISelectOption[] = res.data.map(
        (role: IBuildingEmailRole) => {
          const { name } = role;

          return { label: name, value: name }; //roles are powered by building -> property emails
        }
      );

      setEmailRoleOptions(options);
      setLoader(false);
    } catch (e) {
      setLoader(false);
    }
  };

  const getBuildingEmailGroupOptions = async () => {
    try {
      setLoader(true);
      const res = await getBuildingEmailGroups();

      const options: ISelectOption[] = res.data.map(
        (group: IBuildingEmailGroup) => {
          const { name, uuid } = group;

          return { label: name, value: uuid };
        }
      );

      setEmailGroupOptions(options);
      setLoader(false);
    } catch (e) {
      setLoader(false);
    }
  };

  const fetchBuildingEmailDetails = async () => {
    try {
      const res = await getBuildingEmailDetails(buildingUuid);

      setBuildingEmailDetails(res.data);
    } catch (e) {
      setLoader(false);
    }
  };

  useEffect(() => {
    Promise.all([
      getBuildingEmailRoleOptions(),
      getBuildingEmailGroupOptions()
    ]).then(() => {
      fetchBuildingEmailDetails();
    });
  }, []);

  const bucketRolesFormFields: FormInputConfig[] = useMemo(
    () => [
      {
        id: 'uuid' as keyof IPropertyEmailGroup,
        isRequired: false,
        inputType: 'text',
        displayNone: true
      },
      {
        id: 'email' as keyof IPropertyEmailGroup,
        isRequired: false,
        placeholder: 'Enter an email',
        inputType: 'text',
        pattern: emailRegex(),
        patternErrorMsg: 'Please enter a valid email'
      },
      {
        id: 'groupUuid' as keyof IPropertyEmailGroup,
        isRequired: false,
        inputType: 'select',
        placeholder: 'Select Role',
        selectOptions: emailGroupOptions
      }
    ],
    [emailGroupOptions]
  );

  const roleWiseEmailsFormFields: FormInputConfig[] = useMemo(
    () => [
      {
        id: 'email' as keyof IPropertyEmail,
        isRequired: false,
        inputType: 'text',
        pattern: emailRegex(),
        patternErrorMsg: 'Please enter a valid email'
      },
      {
        id: 'type' as keyof IPropertyEmail,
        isRequired: false,
        inputType: 'select',
        placeholder: 'Select Role',
        selectOptions: emailRoleOptions
      }
    ],
    [emailRoleOptions]
  );

  useEffect(() => {
    if (buildingEmailDetails) {
      formatBuildingData(
        buildingEmailDetails as IBuildingEmailDetails as Building
      );

      buildingEmailDetails.propertyEmailGroups = buildingEmailDetails
        ?.propertyEmailGroups.length
        ? buildingEmailDetails?.propertyEmailGroups
        : [{ email: '', groupUuid: '', groupName: '' }];

      buildingEmailDetails.propertyEmails =
        buildingEmailDetails?.propertyEmails || [{ email: '', type: '' }];
    }
  }, [buildingEmailDetails]);

  const onSubmitBuildingEmails = async (
    formData: Partial<IBuildingEmailDetails>
  ) => {
    try {
      setLoader(true);
      await postBuildingEmailDetails(buildingUuid, formData);

      setLoader(false);
      toast('Building Emails Updated Successfully');
    } catch (e) {
      setLoader(false);
    }
  };

  return {
    loader,
    bucketRolesFormFields,
    roleWiseEmailsFormFields,
    buildingEmailDetails: buildingEmailDetails,
    onSubmitBuildingEmails
  };
};
