import { emailRegex, nameRegex } from '~/helpers/helpers';
import { FormInputConfig } from '~/types/residentManagement';

export const landingPageFormConfigV1: FormInputConfig[] = [
  {
    id: 'firstName',
    isRequired: true,
    placeholder: 'First Name',
    pattern: nameRegex(),
    patternErrorMsg: 'Oops! Please enter a valid name',
    styles: {
      inputStyles: {
        minHeight: '45px',
        borderRadius: '30px',
        marginTop: 0,
        marginBottom: 0
      },
      contentStyles: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        color: 'white'
      }
    }
  },
  {
    placeholder: 'Last Name',
    id: 'lastName',
    isRequired: true,
    pattern: nameRegex(),
    patternErrorMsg: 'Oops! Please enter a valid name',
    styles: {
      inputStyles: {
        minHeight: '45px',
        borderRadius: '30px',
        marginTop: 0,
        marginBottom: 0
      },
      contentStyles: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column'
      }
    }
  },
  {
    id: 'email',
    isRequired: true,
    placeholder: 'Email',
    pattern: emailRegex(),
    patternErrorMsg: 'Oops! Please enter a valid email',
    styles: {
      inputStyles: {
        minHeight: '45px',
        borderRadius: '30px',
        marginTop: 0,
        marginBottom: 0
      },
      contentStyles: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column'
      }
    }
  },
  {
    id: 'phoneNumber',
    isRequired: true,
    placeholder: 'Phone Number',
    styles: {
      inputStyles: {
        minHeight: '45px',
        borderRadius: '30px',
        marginTop: 0,
        marginBottom: 0
      },
      contentStyles: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column'
      }
    }
  }
];

export const AvatarColors = [
  { backgroundColor: '#44C0D4', color: '#FFFFFF' },
  { backgroundColor: '#4A6BDF', color: '#FFFFFF' },
  { backgroundColor: '#F15185', color: '#FFFFFF' },
  { backgroundColor: '#FDD019', color: 'rgba(0, 0, 0, 0.5)' },
  { backgroundColor: '#FEAE35', color: 'rgba(0, 0, 0, 0.5)' },
  { backgroundColor: '#4ED293', color: '#FFFFFF' },
  { backgroundColor: '#A56BEE', color: '#FFFFFF' },
  { backgroundColor: '#EF4A4A', color: '#FFFFFF' }
];
