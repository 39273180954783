import React from 'react';
import { Column } from 'react-table';
import moment from 'moment';
import { useHistory } from 'react-router-dom';

import CobuTable from '../CobuTable/CobuTable';
import { ReactComponent as NoReview } from '~/utils/images/no-review.svg';
import { ReviewReceivedType } from '~/types/reviewReceived';
import { ReactComponent as Pencil } from '~/utils/images/pencil.svg';
import { ReactComponent as Link } from '~/utils/images/link.svg';
import RatingDisplay from '../RatingDisplay/RatingDisplay';
import UsernameDisplay from '../UsernameDisplay/UsernameDisplay';
import { Roles } from '~/enums/Roles';
interface IProps {
  data: ReviewReceivedType[];
  openReviewDetails: (row: ReviewReceivedType) => void;
  showEditModal: (row: ReviewReceivedType) => void;
  userRole: string;
}

const ReviewReceivedTable = (props: IProps) => {
  const history = useHistory();
  const { data, openReviewDetails, showEditModal, userRole } = props;
  const tableData = React.useMemo(() => data, [data]);
  const reviewReceivedColumns = React.useMemo(
    () =>
      [
        {
          Header: 'USER NAME',
          accessor: (row: ReviewReceivedType) => {
            return (
              <UsernameDisplay
                firstName={row.user.firstName}
                lastName={row.user.lastName}
                email={row.user.email}
                profileImage={row.user.profileImage}
              />
            );
          }
        },
        {
          Header: 'NAME ON REVIEW',
          id: 'name-on-review',
          accessor: (row: ReviewReceivedType) => {
            return (
              <div
                style={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap'
                }}
              >
                {row.reviewname}
              </div>
            );
          }
        },
        {
          Header: 'RATING',
          id: 'rating',
          accessor: (row: ReviewReceivedType) => {
            return (
              <div
                style={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap'
                }}
              >
                <RatingDisplay value={row.reviewrating} small />
              </div>
            );
          }
        },
        {
          Header: 'DATE OF REVIEW',
          id: 'date-of-review',
          accessor: (row: ReviewReceivedType) => {
            return (
              <div
                style={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap'
                }}
              >
                {moment.parseZone(row.reviewdate).format('MM/DD/YYYY')}
              </div>
            );
          }
        },
        {
          Header: 'REVIEW TEXT',
          id: 'review-text',
          accessor: (row: ReviewReceivedType) => {
            return (
              <div
                style={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  cursor: 'pointer'
                }}
                onClick={() => {
                  openReviewDetails(row);
                }}
              >
                {row.reviewtext}
              </div>
            );
          }
        },
        {
          id: 'link',
          accessor: (row: ReviewReceivedType) => {
            return (
              <div className={`pointer`}>
                <a href={row.reviewlink} target='_blank'>
                  <Link />
                </a>
              </div>
            );
          }
        },
        {
          id: 'edit',
          accessor: (row: ReviewReceivedType) => {
            return userRole !== Roles.Pm ? (
              <div
                className={`pointer`}
                onClick={() => {
                  showEditModal(row);
                }}
              >
                <Pencil />
              </div>
            ) : null;
          }
        }
      ] as Column<ReviewReceivedType>[],
    [history, data]
  );
  return (
    <div>
      {tableData.length > 0 && (
        <div style={{ display: 'flex' }}>
          <CobuTable
            columns={[...reviewReceivedColumns]}
            data={tableData}
            sort={{ id: 'date-of-review', descending: true }}
            hideRightBorder={false}
          />
        </div>
      )}
      {tableData.length <= 0 && (
        <div className='noData'>
          <NoReview />
          <h2>
            There are no reviews based on Cobu’s outreach, yet. Please check
            back often.
          </h2>
        </div>
      )}
    </div>
  );
};

export default ReviewReceivedTable;
