const headerLinesBasedDescriptionLength = {
  [1]: 350,
  [2]: 250,
  [3]: 150
};

export const getPdfDescriptionBoundData = (
  description: string,
  heading: string
) => {
  const trimmedDescription = description.trim();

  const emptyLines = (trimmedDescription.match(/\n/g) || []).length;
  const emptyLineCharters = 32;
  const descriptionLength =
    (trimmedDescription.length || 0) + emptyLines * emptyLineCharters;

  const headerLength = heading.length || 0;
  const numberOfHeaderLines = headerLength > 25 ? 3 : headerLength > 12 ? 2 : 1;

  const allowedCharacters =
    headerLinesBasedDescriptionLength[numberOfHeaderLines];

  return { descriptionLength, allowedCharacters };
};
