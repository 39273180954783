import CobuConstants from "~/helpers/cobuConstants";
import { IMultiSelectOptionList } from "~/helpers/interfaces";

export const onBoardingOptions: IMultiSelectOptionList[] = [
    {value: 'includeRaffle', text: CobuConstants.createBuilding.includeOnboardingRaffle},
    {value: 'onBoardingSkippable', text: CobuConstants.createBuilding.enableSkipDuringOnboarding, isSelected: true}
]

export const editOnBoardingOptions: IMultiSelectOptionList[] = [
    {value: 'skippable', text: CobuConstants.createBuilding.enableSkipDuringOnboarding}
]