import React, { FC, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { FormField } from '~/components/FormField';
import { FormInputConfig } from '~/types/residentManagement';
import Spinner from '~/components/Spinner/Spinner';
import { toast } from 'react-toastify';
import { IEventWithFlyer, IGatheringFlyer } from '~/types/gathering';
import { emailRoles } from '~/components/Header/CreateMenu/constants';
import MultiSelectCheckboxList from '~/components/MultiSelectCheckBoxList/MultiSelectCheckBoxList';
import { pdfEmailContent, pdfWithInstaEmailContent } from './constants';
import { IMultiSelectOptionList } from '~/helpers/interfaces';
import './EmailFlyerForm.css';

export interface IEmailFlyerFormData {
  description: string;
  includeInstagram: boolean;
  pdfFile?: Blob;
  instaFile?: Blob;
}

interface IProps {
  onCancel: () => void;
  loader: boolean;
  eventData: IEventWithFlyer;
  onSubmitForm: (
    propertyEmails: string,
    flyers: IGatheringFlyer[],
    formData: IEmailFlyerFormData
  ) => void;
  templateSelectionOption: IMultiSelectOptionList[];
  setTemplateSelectionOption: (newOptions: IMultiSelectOptionList[]) => void;
  downloadAttachments: (
    flyers: IGatheringFlyer[],
    includeInstagram: boolean
  ) => void;
  flyerGroupEmails: null | string[];
}

export const EmailFlyerForm: FC<IProps> = ({
  loader,
  onCancel,
  onSubmitForm,
  eventData,
  templateSelectionOption,
  setTemplateSelectionOption,
  downloadAttachments,
  flyerGroupEmails
}) => {
  const { register, handleSubmit, errors, control, setValue, watch } =
    useForm<IEmailFlyerFormData>({
      defaultValues: {
        description: pdfEmailContent,
        includeInstagram: false,
        pdfFile: undefined,
        instaFile: undefined
      }
    });

  const includeInstagram = watch('includeInstagram');

  let propertyEmails = useMemo(() => {
    let propertyEmails = '';

    eventData.propertyEmails?.forEach((email) => {
      if (email.type === emailRoles.onsite) {
        propertyEmails = propertyEmails
          ? `${propertyEmails},${email.email}`
          : email.email;
      }
    });

    if (flyerGroupEmails) {
      flyerGroupEmails.forEach((email: string) => {
        propertyEmails = propertyEmails ? `${propertyEmails},${email}` : email;
      });
    }
    return propertyEmails;
  }, []);

  const onSubmit = async (data: IEmailFlyerFormData) => {
    onSubmitForm(propertyEmails, eventData.flyers, data);
  };

  const generateField = (fieldConfig: FormInputConfig) => {
    return (
      <div key={fieldConfig.id}>
        <div className='item'>
          {!!fieldConfig?.label && (
            <label
              style={fieldConfig?.styles?.lableStyles}
              className={`${fieldConfig?.styles?.lableClassName}`}
              htmlFor='title'
            >
              {fieldConfig.label}
            </label>
          )}
          <FormField
            fieldConfig={fieldConfig}
            errors={errors}
            register={register}
            control={control}
          />
        </div>
      </div>
    );
  };

  const onBoardingCheckBox = (value: string, isSelected: boolean) => {
    const newTemplateSelectOptions = [...templateSelectionOption];

    const selectedOption = newTemplateSelectOptions.find(
      (option) => option.value === value
    );

    if (selectedOption) {
      selectedOption.isSelected = isSelected;
    }

    setTemplateSelectionOption(newTemplateSelectOptions);
  };

  const formConfig: FormInputConfig[] = [
    {
      label: 'Flyer PDF file',
      id: 'pdfFile',
      inputType: 'imageDropzone',
      acceptedFiles: 'application/pdf',
      isRequired: true,
      styles: { lableStyles: { margin: '20px 0 10px 0' } }
    },
    ...((includeInstagram
      ? [
          {
            label: 'Flyer Instagram Image',
            id: 'instaFile',
            inputType: 'imageDropzone',
            isRequired: true,
            styles: {
              lableStyles: { margin: '20px 0 10px 0' }
            }
          }
        ]
      : []) as FormInputConfig[])
  ];

  return (
    <div className='float-spinner-parent'>
      {loader && <Spinner float />}
      <div
        style={{ margin: '30px 0' }}
      >{`Send Email to: ${propertyEmails}`}</div>
      <form id='form-container' onSubmit={handleSubmit(onSubmit)}>
        {generateField({
          label: 'Email Description',
          inputType: 'textarea',
          id: 'description',
          placeholder: 'Enter Email Description'
        })}
        {formConfig.map((field) => generateField(field))}
        <Controller
          name='includeInstagram'
          defaultValue={true}
          render={({ onChange }) => (
            <div style={{ margin: '20px 0 0 0' }}>
              <MultiSelectCheckboxList
                options={templateSelectionOption}
                setSelectedOption={(value, isSelected) => {
                  if (isSelected) {
                    setValue('description', pdfWithInstaEmailContent, {
                      shouldDirty: true
                    });
                  } else {
                    setValue('description', pdfEmailContent, {
                      shouldDirty: true
                    });
                  }

                  onChange(isSelected);
                  onBoardingCheckBox(value, isSelected);
                }}
              />
            </div>
          )}
          control={control}
        />
        <div className='formButtons'>
          <button
            className='btn-standard btn-secondary margin-left-16'
            onClick={() =>
              downloadAttachments(eventData.flyers, includeInstagram)
            }
          >
            {`Download PDF${includeInstagram ? ' and instagram' : ''} Flyer`}
          </button>
          <button
            className='btn-standard btn-secondary margin-left-16'
            onClick={onCancel}
          >
            {'Cancel'}
          </button>
          <input
            className='btn-standard btn-primary margin-left-16 '
            type='submit'
          />
        </div>
      </form>
    </div>
  );
};
