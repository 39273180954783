import React, { useEffect, useState } from 'react';
import { connect, ResolveThunks, useDispatch, useSelector } from 'react-redux';

import { fetchBuildingsSuccess } from '~/reducers/building';
import { RootState } from '~/redux/store';

import './BuildingsListContainer.css';
import { Roles } from '~/enums/Roles';
import { LayoutView } from '~/components/layout';
import { Layout } from '~/types/layout';
import { getLayout } from '~/api/layout';
import { AssetManagementTable } from '~/components/AssetMangementTable/AssetMangementTable';
import CobuSwitcher from '~/components/CobuSwitcher/CobuSwitcher';
import { BuildingList } from './BuildingList';
import Spacer from '~/components/Spacer/Spacer';
import { fetchBuildingsRequest } from '~/api/building';
import Axios from 'axios';

interface DispatchProps {
  fetchBuildingsSuccess: typeof fetchBuildingsSuccess;
}

type Props = ResolveThunks<DispatchProps> & ReturnType<typeof mapStateToProps>;

const BuildingsListContainer = (props: Props) => {
  const [loading, setLoading] = useState(true);
  const [layout, setLayout] = useState<Layout>();
  const dispatch = useDispatch();

  const role = useSelector((state: RootState) => {
    return state.auth.userRole;
  });

  const getHomeLayout = () => {
    getLayout('home').then((res) => setLayout(res.data.layout));
  };

  useEffect(() => {
    getHomeLayout();
  }, []);

  useEffect(() => {
    setLoading(true);

    fetchBuildingsRequest().then((res) => {
      dispatch(fetchBuildingsSuccess(res.data));
      setLoading(false);
    });
  }, []);

  const showAssetManagement = role === Roles.Pm ? false : true;

  return (
    <div>
      <div className='buildinglist-container'>
        {(props.userRole === Roles.Superadmin ||
          props.userRole === Roles.Vcm) && (
          <div>
            <p className='buildinglist-title margin-bottom-32'>
              Welcome to Cobu
            </p>
          </div>
        )}
        {showAssetManagement ? (
          <>
            {layout && <LayoutView layout={layout} />}
            <Spacer height={20} />
            {(props.userRole === Roles.Vcm ||
              props.userRole === Roles.Pm ||
              props.userRole === Roles.Superadmin) && (
              <CobuSwitcher
                optionsLabels={['Asset Management', 'Building Listing']}
                content={[
                  <AssetManagementTable
                    showSearchHeader
                    heading='View a Property'
                  />,
                  <BuildingList loading={loading} />
                ]}
                onClickCallback={() => {}}
              />
            )}
          </>
        ) : (
          <BuildingList loading={loading} />
        )}
      </div>
    </div>
  );
};

function mapStateToProps(state: RootState) {
  return {
    buildings: state.building.buildings,
    userRole: state.auth.userRole
  };
}

function mapDispatchToProps(): DispatchProps {
  return {
    fetchBuildingsSuccess
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps()
)(BuildingsListContainer);
