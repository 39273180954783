import React from 'react'
import FullScreenLoader from '~/components/FullScreenLoader';
import { ProvideFullScreenLoader } from "~/hooks/useFullScreenLoader";

export const withFullScreenLoader = (WrappedComponent: any) => {
    const RenderingComponent = ({ ...props }: any) => {
        return (
            <ProvideFullScreenLoader>
                <FullScreenLoader />
                <WrappedComponent {...props} />
            </ProvideFullScreenLoader>
        );
    };
    return RenderingComponent;
};
