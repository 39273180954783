import React from 'react';
import { IMultiSelectOptionList } from '~/helpers/interfaces';
import MultiSelectCheckBox from './MultiSelectCheckBox';

interface IProps {
  options: IMultiSelectOptionList[];
  setSelectedOption: (value: string, isSelected: boolean) => void;
  checkTintColor?: string;
  tickColor?: string;
  enableLabelClickToSelect?: boolean;
}
const MultiSelectCheckboxList = (props: IProps) => {
  const {
    options,
    setSelectedOption,
    checkTintColor,
    tickColor,
    enableLabelClickToSelect = false
  } = props;

  return (
    <div>
      {options.map((option, index) => (
        <div
          key={index}
          style={option.containerStyle}
          {...(enableLabelClickToSelect
            ? {
                onClick: () =>
                  setSelectedOption(option.value, !option.isSelected)
              }
            : {})}
        >
          <MultiSelectCheckBox
            index={index}
            option={option}
            setSelectedOption={setSelectedOption}
            checkTintColor={checkTintColor}
            tickColor={tickColor}
          />
          {option.showSibling && option.sibling}
        </div>
      ))}
    </div>
  );
};
export default MultiSelectCheckboxList;
