import { createSlice } from "@reduxjs/toolkit"
import { ResidentProfileState } from "~/types/residentProfile"


export const initialState: ResidentProfileState = {
    residentData: {
        uuid: '',
        email: '',
        firstName: '',
        lastName: '',
        phone: '',
        type: '',
        bio: '',
        profileImage: '',
        displayName: '',
        roomNumber: '',
        disabledPushNotificationTypes: [],
        comments: [],
        groups: '',
        createdAt: '',
        likesHelpingWith: [],
        needsHelpWith: [],
        rsvps: [],
        groupEventFeedback: [],
        groupEventComments: [],
        acceptedTermsAndConditions: false,
        showFullName: false,
        doNotDeactivate: false
    },
    loadingString: '',
    loading: false,
    notificationTypes: [],
    notifications: [],
    rsvpUIModel: [],
    showUserEditModal: false
}

const residentProfileSlice = createSlice({
    name: "residentProfile",
    reducers: {
        loader: (state, action) => {
            state.loading = true
            state.loadingString = action.payload.loadingString ?? state.loadingString
        },
        hideLoader: (state) => {
            state.loading = false
        },
        setProfileData: (state, action) => {
            state.residentData = action.payload.residentData
            state.notificationTypes = action.payload.notificationData
            state.notifications = action.payload.notifications
            state.rsvpUIModel = action.payload.rsvpData
            state.loading = false
        },
        toggleUserEditModel: (state) => {
            state.showUserEditModal = !state.showUserEditModal
        },
    },
    initialState: initialState
})

export const {
    loader,
    setProfileData,
    toggleUserEditModel,
    hideLoader
} = residentProfileSlice.actions

export default residentProfileSlice.reducer