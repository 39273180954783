import React from 'react';
import Select from 'react-select';
import { Colors } from '~/enums/Colors';
import { SelectType } from '~/enums/SelectType';
import { SelectedOptionType } from '~/types/form';
type Props = {
  options: { label: string; value: string }[];
  onChange: (selectedOption: SelectedOptionType | null) => void;
  placeholder?: string;
  defaultValue?: SelectedOptionType | null;
  isSearchable?: boolean;
  selectType: string;
  disabled?: boolean;
  isClearable?: boolean;
  noOptionsMessage?: string;
  isError?: boolean | null;
};

const CobuSelect = (props: Props) => {
  const {
    options,
    onChange,
    placeholder,
    isSearchable,
    defaultValue,
    selectType,
    disabled,
    isClearable,
    noOptionsMessage,
    isError
  } = props;

  const getHeight = () => {
    if (selectType === SelectType.Table) {
      return 25;
    } else if (selectType === SelectType.Form) {
      return 50;
    }
    return 35;
  };

  const getBorder = (isFocused: boolean) => {
    if (isFocused && selectType === SelectType.Filter) {
      return 'none';
    } else if (selectType != SelectType.Filter) {
      if (isError) {
        return '2px solid #ef4a4a';
      } else {
        return '1px solid rgba(39, 33, 99, 0.1)';
      }
    }
    return 'none';
  };

  const getFontSize = () => {
    if (selectType === SelectType.Table) {
      return '12px';
    } else if (selectType === SelectType.Form) {
      return '16px';
    }
    return '16px';
  };

  const getBackgroundColor = (value: string, isFocused: boolean) => {
    if (isFocused) {
      return 'rgba(39,33,99,0.1)';
    }
    return 'white';
  };
  return (
    <div>
      <Select
        options={options}
        onChange={onChange}
        placeholder={placeholder}
        isSearchable={isSearchable}
        value={defaultValue}
        defaultValue={defaultValue}
        isDisabled={disabled}
        isClearable={isClearable}
        noOptionsMessage={() =>
          noOptionsMessage ? noOptionsMessage : 'No option'
        }
        styles={{
          option: (provided, state) => {
            return {
              fontSize: selectType === SelectType.Form ? '16px' : '12px',
              color: Colors.cobuDarkBlue,
              cursor: 'pointer',
              backgroundColor: getBackgroundColor(
                state.data.value,
                state.isFocused
              ),
              padding: 16,
              fontFamily: 'Roboto',
              fontStyle: 'normal'
            };
          },
          control: (base, state) => {
            return {
              ...base,
              minHeight: getHeight(),
              cursor: 'pointer',
              height: getHeight(),
              alignItems: 'baseline',
              width: selectType === SelectType.Form ? 'inherit' : '180px',
              borderRadius: '8px',
              border: getBorder(state.isFocused),
              boxShadow: 'none'
            };
          },
          valueContainer: (provided, state) => ({
            ...provided,
            height: 'inherit',
            alignItems: 'center',
            position: 'initial',
            overflow: selectType !== SelectType.Form ? 'visible' : 'hidden',
            fontFamily: 'Roboto',
            fontSize: getFontSize()
          }),
          input: (provided, state) => ({
            ...provided,
            margin: '0px',
            border: selectType === SelectType.Form ? 'none' : 'inherit',
            color: '#272163'
          }),
          indicatorSeparator: (state) => ({
            display: 'none'
          }),
          indicatorsContainer: (provided, state) => ({
            ...provided,
            height: getHeight()
          }),
          singleValue: (provided, state) => ({
            alignItems: 'center',
            transform:
              selectType === SelectType.Table
                ? 'translateY(-170%)'
                : selectType === SelectType.Form
                ? 'translatey(-10%)'
                : 'translateY(-90%)',
            position: 'inherit',
            color: 'rgba(39, 33, 99, 1)'
          })
        }}
      ></Select>
    </div>
  );
};

export default CobuSelect;
