import React from 'react';
import Cookies from 'universal-cookie';

import Footer from '~/components/Footer/Footer';
import Header from '~/components/Header/Header';
import NotFound from '~/components/NotFound/NotFound';
import Spinner from '~/components/Spinner/Spinner';
import jwt_decode from 'jwt-decode';

export const renderPublicComponent = (
  Component: React.ComponentType<any> | React.ComponentType
) => {
  return () => {
    return <Component />;
  };
};

export const renderProtectedDashboardComponent = (
  Component: React.ComponentType<any> | React.ComponentType
) => {
  return () => {
    const cookies = new Cookies();
    const token = cookies.get('token');
    if (!token) window.location.href = '/login';
    try {
      const decodedToken: { hasAdminAccess: boolean } = jwt_decode(token);
      if (decodedToken.hasAdminAccess) {
        return (
          <div id='app'>
            <Header />
            <div id='main'>
              <div id='page'>
                <Component />
              </div>
            </div>
            <Footer />
          </div>
        );
      } else if (!decodedToken.hasAdminAccess) {
        return (
          <div>
            <Header />
            <NotFound />
          </div>
        );
      } else {
        return <Spinner />;
      }
    } catch (error) {
      cookies.remove('token');
      window.location.href = '/login';
    }
    return <></>;
  };
};

export const renderProtectedRSVPComponent = (
  Component: React.ComponentType<any> | React.ComponentType
) => {
  return () => {
    const cookies = new Cookies();
    const token = cookies.get('token');
    return token ? (
      <div id='app'>
        <Header />
        <div id='main'>
          <div id='page'>
            <Component />
          </div>
        </div>
        <Footer />
      </div>
    ) : (
      <div>
        <Header />
        <NotFound />
      </div>
    );
  };
};
