import React, { FC, useMemo } from 'react';
import './StatusIndicator.css';

interface IProps {
  value: number | string;
  indicatorFunctions: {
    red?: (val: number) => boolean;
    yellow?: (val: number) => boolean;
    green?: (val: number) => boolean;
  };
}

export const StatusIndicator: FC<IProps> = ({
  value,
  indicatorFunctions,
  children
}) => {
  const className = useMemo(() => {
    for (const [key, randeFunction] of Object.entries(indicatorFunctions)) {
      if (randeFunction(Number(value))) {
        return key;
      }
    }

    return ''; // Default value when none of the conditions are met
  }, [value, indicatorFunctions]);

  return <div className={className}>{children}</div>;
};
