import { useQuery } from 'react-query';
import { fetchBuildingDashboardConfig } from '~/api/building';

export const useBuildingDashboardConfig = (buildingId: string) =>
  useQuery(
    ['buildingDashboardConfig', buildingId],
    () => fetchBuildingDashboardConfig(buildingId),
    {
      refetchOnWindowFocus: false
    }
  );
