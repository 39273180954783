import React, { useEffect, useState } from 'react';
import classes from './CobuSwitcher.module.css';

type Props = {
  optionsLabels: string[];
  content: React.ReactNode[];
  defaultSelected?: number;
  onClickCallback: (index: number) => void;
};

const CobuSwitcher = (props: Props) => {
  const { optionsLabels, content, defaultSelected, onClickCallback } = props;
  const [selctedOption, setSelectedOption] = useState(defaultSelected || 0);

  const getSwitchClass = (index: number, length: number) => {
    let classComposition = `${classes.switchGeneral} pointer `;
    if (index === 0) classComposition += ` ${classes.firstSwitch}`;
    else if (index === --length) classComposition += ` ${classes.lastSwitch}`;
    else classComposition += ` ${classes.switch}`;

    return selctedOption === index
      ? (classComposition += ` ${classes.switchSelected}`)
      : classComposition;
  };

  useEffect(() => {
    if (optionsLabels.length !== content.length) {
      console.error(
        'ERROR IN COBU SWITCHER! optionsLabels and content PARAMS MUST CONTAIN EQUAL NUMBER OF ITEMS'
      );
    }
  }, [optionsLabels.length, content.length]);

  return (
    <div>
      <div className='menu-bar'>
        <div className='tab-container'>
          {optionsLabels.map((label, index) => {
            return (
              <div
                className={`${getSwitchClass(index, optionsLabels.length)}`}
                key={index}
                onClick={() => {
                  setSelectedOption(index);
                  onClickCallback(index);
                }}
              >
                {label}
              </div>
            );
          })}
        </div>
      </div>
      {content[selctedOption]}
    </div>
  );
};

export default CobuSwitcher;
