import React, { useEffect, useState, ChangeEvent } from 'react';
import { connect, ResolveThunks } from 'react-redux';

import {
  updateAdminRoleSuccess,
  saveAdmins,
  createAdminsSuccess,
  updateAdminBuildingsSuccess
} from '~/reducers/admin';
import { RootState } from '~/redux/store';

import { ReactComponent as Search } from '~/utils/images/search.svg';
import Breadcrumbs from '~/components/Breadcrumbs/Breadcrumbs';
import Spinner from '~/components/Spinner/Spinner';
import { createAdminsRequest } from '~/api/admin';
import {
  fetchAllAdminsRequest,
  updateAdminBuildingsRequest,
  updateAdminRoleRequest
} from '~/api/admin';
import AdminTable from '~/components/AdminTable/AdminTable';
import CobuSelect from '~/components/CobuSelect/CobuSelect';

import classes from './Admins.module.css';
import { AdminType, AdminUser, AdminBuildingType } from '~/types/admin';
import { Roles } from '~/enums/Roles';
import { SelectType } from '~/enums/SelectType';
import { SelectedOptionType } from '~/types/form';
import GenericModal from '~/components/GenericModal/GenericModal';
import AdminDetails from '~/components/AdminDetails/AdminDetails';
import useIsMounted from '~/hooks/isMounted';

interface DispatchProps {
  saveAdmins: typeof saveAdmins;
  updateAdminRoleSuccess: typeof updateAdminRoleSuccess;
  createAdminsSuccess: typeof createAdminsSuccess;
  updateAdminBuildingsSuccess: typeof updateAdminBuildingsSuccess;
}

type Props = ResolveThunks<DispatchProps> & ReturnType<typeof mapStateToProps>;

const Admins = (props: Props) => {
  const {
    admins,
    saveAdmins,
    updateAdminRoleSuccess,
    buildings,
    updateAdminBuildingsSuccess,
    createAdminsSuccess
  } = props;
  const [loading, setLoading] = useState(true);
  const [searchRole, setSearchRole] = useState('');
  const [searchNameBuilding, setSearchNameBuilding] = useState('');
  const [showChangeRoleModal, setShowChangeRoleModal] = useState(false);
  const [selectedAdmin, setSelectedAdmin] = useState<AdminUser>();
  const [selectedRoleToChange, setSelectedRoleToChange] = useState('');
  const [showAddUserModal, setShowAddUserModal] = useState(false);
  // assign a role to a user
  const [selectedRole, setSelectedRole] = useState<SelectedOptionType | null>({
    label: '',
    value: ''
  });
  const [
    selectedBuilding,
    setSelectedBuilding
  ] = useState<SelectedOptionType | null>({
    label: '',
    value: ''
  });
  const [emails, setEmails] = useState(['']);
  const [emailError, setEmailError] = useState('');
  const [selectedAdminId, setSelectedAdminId] = useState('');
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [selectedBuildings, setSelectedBuildings] = useState<
    AdminBuildingType[]
  >([]);
  const [
    changeAdminBuildingsDisabled,
    setChangeAdminBuildingsDisabled
  ] = useState(true);
  const [addAdminDisabled, setAddAdminDisabled] = useState(true);
  const isMounted = useIsMounted();
  useEffect(() => {
    let isMounted = true;
    const fetchData = () => {
      fetchAllAdminsRequest().then((res) => {
        if (isMounted) {
          saveAdmins(res.data);
          setLoading(false);
        }
      });
    };

    fetchData();
  }, [saveAdmins, isMounted, setSelectedAdminId, setEmails]);

  const onDetailsClickHandler = (user: AdminUser, role: string) => {
    setShowChangeRoleModal(true);
    setSelectedAdmin(user);
    setSelectedRoleToChange(role);
  };

  const onAdminRoleChangeHandler = () => {
    updateAdminRoleRequest(selectedRoleToChange, selectedAdmin!.uuid).then(
      (res) => {
        updateAdminRoleSuccess({
          role: selectedRoleToChange,
          userId: res.data.user
        });
      }
    );
    setShowChangeRoleModal(false);
  };

  const onChangeAdminBuildings = () => {
    updateAdminBuildingsRequest({
      user: selectedAdminId,
      buildings: selectedBuildings.map((b) => b.uuid)
    }).then((res) => {
      updateAdminBuildingsSuccess(res.data);
      setShowDetailsModal(false);
    });
  };
  const filterAdmins = () => {
    let filteredAdmins;
    if (searchRole !== '' || searchNameBuilding !== '') {
      filteredAdmins = admins.filter((admin: AdminType) => {
        const userNameAndBuilding =
          admin.user.firstName.toLowerCase() +
          ' ' +
          admin.user.lastName.toLowerCase() +
          admin.userBuilding.name.toLowerCase();
        return (
          (searchRole !== '' &&
            searchNameBuilding === '' &&
            admin.role.includes(searchRole)) ||
          (searchRole === '' &&
            searchNameBuilding !== '' &&
            (userNameAndBuilding.includes(searchNameBuilding.toLowerCase()) ||
              admin.user.email.includes(searchNameBuilding.toLowerCase()))) ||
          (searchRole !== '' &&
            searchNameBuilding !== '' &&
            (userNameAndBuilding.includes(searchNameBuilding.toLowerCase()) ||
              admin.user.email.includes(searchNameBuilding.toLowerCase())) &&
            admin.role.includes(searchRole))
        );
      });
      return filteredAdmins;
    }
    return admins;
  };
  const createAdminsFunction = () => {
    const emailsReq = emails.filter((email) => email != '');
    setEmails(emailsReq);
    createAdminsRequest(emailsReq, selectedBuilding!.value, selectedRole!.value)
      .then((r) => {
        createAdminsSuccess(r.data);
        setEmails(['']);
        setAddAdminDisabled(true);
        setShowAddUserModal(false);
      })
      .catch((data) => {
        setEmailError(data.message);
        setAddAdminDisabled(true);
      });
  };
  const addEmailField = () => {
    setEmails([...emails, '']);
  };

  const onRemoveBuildingHandler = (buildingId: string) => {
    const selectedBuildingsReduced = [...selectedBuildings];
    const index = selectedBuildings.findIndex((b) => b.uuid === buildingId);
    selectedBuildingsReduced.splice(index, 1);
    setSelectedBuildings(selectedBuildingsReduced);
    setChangeAdminBuildingsDisabled(false);
  };

  const onAddBuildingHandler = (buildingId: string) => {
    const building = buildings.filter((b) => b.uuid === buildingId)[0];

    const selectedBuildingsCopy = [
      ...selectedBuildings,
      { uuid: building.uuid, name: building.name }
    ];
    setSelectedBuildings(selectedBuildingsCopy);
    setChangeAdminBuildingsDisabled(false);
  };

  const getBuildingsForDropdown = () => {
    const selectedBuildingIds = selectedBuildings.map((b) => b.uuid);
    const buildingsForDropdown: SelectedOptionType[] = [];
    buildings.map((building) => {
      if (!selectedBuildingIds.includes(building.uuid)) {
        buildingsForDropdown.push({
          value: building.uuid,
          label: building.name
        });
      }
    });
    return buildingsForDropdown;
  };
  return (
    <div className='content-container'>
      {loading && <Spinner />}
      {!loading && (
        <div>
          <Breadcrumbs />
          <div className={classes.container}>
            <h1 className='h1-title'>User / Roles management</h1>
            <div className={`${classes.filterSearchContainer}`}>
              <div className={`${classes.search} pointer margin-right-16`}>
                <div
                  className={`${classes.searchIconContainer} margin-left-16 margin-right-8`}
                >
                  <Search />
                </div>
                <div
                  className={`${classes.searchBy} margin-top-8 margin-right-8`}
                >
                  <input
                    className='search-input margin-top-16'
                    placeholder='Search by Name, Email or Building'
                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                      setSearchNameBuilding(event.target.value);
                    }}
                    onClick={(e) => e.stopPropagation()}
                  />
                </div>
              </div>
              <div className={`${classes.search} margin-right-16`}>
                <p className={'margin-left-16 font-16 font-style-normal'}>
                  Filter by:
                </p>
                <CobuSelect
                  options={[
                    { label: Roles.Vcm, value: Roles.Vcm },
                    { label: Roles.Superadmin, value: Roles.Superadmin },
                    { label: Roles.Pm, value: Roles.Pm },
                    { label: 'ALL ROLES', value: '' }
                  ]}
                  selectType={SelectType.Filter}
                  onChange={(selectedOption: SelectedOptionType | null) => {
                    setSearchRole(selectedOption!.value);
                  }}
                  isSearchable={false}
                  placeholder='Role'
                />
              </div>
              <button
                className={`${classes.addUserButton} btn-primary btn-standard`}
                onClick={() => {
                  setEmails(['']);
                  setShowAddUserModal(true);
                }}
              >
                Add a user
              </button>
            </div>
          </div>
          <AdminTable
            data={filterAdmins()}
            onAdminRoleChange={onDetailsClickHandler}
            loggedInUserUuid={props.loggedInUser.uuid}
            onDetailsClick={(userId: string) => {
              setSelectedAdminId(userId);
              setShowDetailsModal(true);
              setChangeAdminBuildingsDisabled(true);
              const buildings = admins.filter(
                (admin) => admin.user.uuid === userId
              )[0].buildings;
              setSelectedBuildings(buildings);
            }}
          />
          {showChangeRoleModal && (
            <GenericModal
              showModal={showChangeRoleModal}
              title={'Change User Role?'}
              actionFunction={onAdminRoleChangeHandler}
              actionText={'Change'}
              onChange={(value) => setShowChangeRoleModal(value)}
              content={
                <div>
                  Are you sure you want to change role of the user{' '}
                  {selectedAdmin?.firstName} {selectedAdmin?.lastName} to{' '}
                  {selectedRoleToChange}?
                </div>
              }
            />
          )}
          <GenericModal
            showModal={showAddUserModal}
            title={'Assign a Role'}
            content={
              <div>
                <div className='margin-top-16 modal-width'>
                  <label>Role</label>
                  <div className='margin-top-4'>
                    <CobuSelect
                      options={[
                        { label: Roles.Vcm, value: Roles.Vcm },
                        { label: Roles.Superadmin, value: Roles.Superadmin },
                        { label: Roles.Pm, value: Roles.Pm }
                      ]}
                      onChange={(selectedOption: SelectedOptionType | null) => {
                        if (
                          selectedBuilding?.value !== '' &&
                          emails[0] !== ''
                        ) {
                          setAddAdminDisabled(false);
                        }
                        setSelectedRole(selectedOption);
                      }}
                      selectType={SelectType.Form}
                    />
                  </div>
                </div>
                <div className='margin-top-16'>
                  <label>Building</label>
                  <div className='margin-top-4'>
                    <CobuSelect
                      options={buildings.map((b) => ({
                        value: b.uuid,
                        label: b.name
                      }))}
                      onChange={(selectedOption: SelectedOptionType | null) => {
                        if (selectedRole?.value !== '' && emails[0] !== '') {
                          setAddAdminDisabled(false);
                        }
                        setSelectedBuilding(selectedOption);
                      }}
                      selectType={SelectType.Form}
                    />
                  </div>
                </div>
                <div className='margin-top-16'>
                  <label>Email</label>
                  {Array.apply(null, Array(emails.length)).map((n, i) => (
                    <>
                      <input
                        className={`email-txt-area input`}
                        type='text'
                        id={`email ${i}`}
                        name='email'
                        value={emails[i]}
                        onChange={(e) => {
                          let newArr = [...emails];
                          newArr[i] = e.target.value;
                          setEmails(newArr);
                          if (
                            selectedRole?.value !== '' &&
                            selectedBuilding?.value !== '' &&
                            emailError === ''
                          ) {
                            setAddAdminDisabled(false);
                          }
                          setEmailError('');
                        }}
                      />
                    </>
                  ))}
                </div>
                {emailError && <p className='form-error '>{emailError}</p>}

                <button
                  className={'btn btn-standard btn-secondary'}
                  onClick={() => addEmailField()}
                >
                  Add new Email
                </button>
              </div>
            }
            actionFunction={() => createAdminsFunction()}
            actionText={'Save'}
            onChange={(value) => {
              setShowAddUserModal(value);
              setAddAdminDisabled(true);
              setEmailError('');
              setEmails(['']);
            }}
            actionButtonDisabled={addAdminDisabled}
          />
        </div>
      )}
      {selectedAdminId !== '' && (
        <GenericModal
          showModal={showDetailsModal}
          title={'User Details'}
          actionText={'Save'}
          actionFunction={onChangeAdminBuildings}
          onChange={(value: boolean) => {
            setSelectedAdminId('');
            setShowDetailsModal(value);
          }}
          actionButtonDisabled={changeAdminBuildingsDisabled}
          content={
            <div>
              <AdminDetails
                data={selectedBuildings}
                onRemoveBuilding={(buildingId: string) =>
                  onRemoveBuildingHandler(buildingId)
                }
                onAddBuilding={(buildingId: string) =>
                  onAddBuildingHandler(buildingId)
                }
                role={
                  admins.filter(
                    (admin) => admin.user.uuid === selectedAdminId
                  )[0]?.role
                }
                buildingsForDropdown={getBuildingsForDropdown()}
              />
            </div>
          }
          actionButtonsInHeader={true}
        />
      )}
    </div>
  );
};

function mapStateToProps(state: RootState) {
  return {
    buildings: state.building.buildings,
    userSettings: state.auth.userSettings,
    admins: state.admins.admins,
    loggedInUser: state.auth.user
  };
}

function mapDispatchToProps(): DispatchProps {
  return {
    saveAdmins,
    updateAdminRoleSuccess,
    createAdminsSuccess,
    updateAdminBuildingsSuccess
  };
}

export default connect(mapStateToProps, mapDispatchToProps())(Admins);
