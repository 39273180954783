import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import Breadcrumbs from '~/components/Breadcrumbs/Breadcrumbs';
import Spinner from '~/components/Spinner/Spinner';
import { IBuildingKey } from '~/types/building';
import useBuildingKey from './hooks';
import { AutoGrowFormField } from '~/components/AutoGrowFormField/AutoGrowFormFiled';
import { sortBy } from 'lodash';

export const BuildingKeys = () => {
  const { buildingKeys, loader, onSubmit, buildingKeyFormFields } =
    useBuildingKey();

  const {
    register,
    handleSubmit,
    errors,
    control,
    reset,
    formState: { isDirty }
  } = useForm<{ buildingKeys: IBuildingKey[] }>({
    defaultValues: {}
  });

  useEffect(() => {
    if (buildingKeys) {
      reset({
        buildingKeys: sortBy(buildingKeys, 'name')
      });
    }
  }, [buildingKeys]);

  return (
    <div className='float-spinner-parent container'>
      {loader && <Spinner float />}
      <Breadcrumbs />
      <form id='form-container' onSubmit={handleSubmit(onSubmit)}>
        <div className='additional-url-container'>
          <AutoGrowFormField
            addNewRowText={'Add New Building Key'}
            errors={errors}
            control={control}
            register={register}
            formFieldName={'buildingKeys'}
            rowConfig={buildingKeyFormFields}
            enableDelete={false}
          />
        </div>
        <input
          disabled={!isDirty}
          className={`btn-standard btn-primary margin-top-32 ${
            isDirty ? '' : 'disabled'
          }`}
          type='submit'
        />
      </form>
    </div>
  );
};
