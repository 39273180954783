import React, { useRef, useState } from 'react';
import '../MoreActionsGatherings/MoreActionsGatherings.css';
import { useOnClickOutside } from '../../helpers/helpers';
import classes from './MoreActions.module.css';

interface IProps {
  title?: string
  options: {
    label: string;
    action: () => void;
  }[];
}
const MoreActions = (props: IProps) => {
  const [selected, setSelected] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  useOnClickOutside(ref, () => setSelected(false));
  const { options, title } = props;

  return (
    <div ref={ref} className={classes.minWidth150}>
      <div
        className={
          selected
            ? 'more-actions-btn more-actions-btn-selected'
            : 'more-actions-btn more-actions-btn-unselected'
        }
        onClick={() => setSelected(!selected)}
      >
        {title ?? `MORE ACTIONS`}
      </div>
      {selected && (
        <div className='more-actions-dropdown'>
          {options.map((option, index) => (
            <div
              key={index}
              className='more-actions-option pointer'
              onClick={() => {
                option.action();
                setSelected(false);
              }}
            >
              {option.label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
export default MoreActions;
