import { emperor } from '~/services';
import { BuildingEntrataIntegration } from '~/types/buildingEntrataIntegration';
import { convertKeyValueToFormData } from '~/helpers/helpers';
import EntrataLeaseJsonToCSV from '~/services/EntrataLeaseJsonToCSV';
import { toast } from 'react-toastify';
import { BuildingDetails } from '~/types/building';

export interface DeactivationLogs {
  uuid: string;
  details: Details;
  buildingId: string;
  createdAt: Date;
  updatedAt: Date;
  updatedBy: string;
  method: string;
}

export interface Details {
  users: DeactivationLogUser[];
  integrationId: string;
  tenantImportBatchUuid: string;
}

export interface DeactivationLogUser {
  uuid: string;
  email: string;
  phone: string;
  intercomUuid: string;
}

export const fetchPmsSyncDeactivationLogs = async (integrationId: string) => {
  return emperor.get<DeactivationLogs>('/pms/latest-deactivation-logs', {
    params: {
      integrationId
    }
  });
};
