import { useEffect, useState } from "react"
import { toast } from "react-toastify"
import { createBuildingTooltip, deactivateTooltips, getAllActiveTooltips } from "~/api/tooltips"
import CobuConstants from "~/helpers/cobuConstants"
import { CreateToolTipBody, Tooltip } from "~/types/tooltip"


export default () => {

    const [activeTooltips, setActiveTooltips] = useState<Tooltip[]>([])
    const [loadingConfig, setLoadingConfig] = useState<{ flag: boolean, comment?: string }>({
        flag: false,
        comment: ''
    })

    useEffect(() => {
        fetchTooltips()
    }, [])

    const fetchTooltips = async (refresh: boolean = false) => {
        setLoadingConfig({
            flag: true, comment: refresh ? CobuConstants.activeTooltips.refreshingActiveSurveys
                : CobuConstants.activeTooltips.fetchingActiveSurveys
        })
        try {
            const activeTooltipsResp = await getAllActiveTooltips()
            console.log(activeTooltipsResp.data)
            setActiveTooltips(activeTooltipsResp.data)
            setLoadingConfig({ flag: false })
        } catch (error) {
            setLoadingConfig({ flag: false })
            if (error) {
                toast(error?.message)
            }
        }
    }

    const deactivateTooltip = async (tooltip: Tooltip) => {
        setLoadingConfig({ flag: true, comment: CobuConstants.activeTooltips.deactivatingSurvey })
        try {
            const updateResp = await deactivateTooltips(tooltip.uuid)
            setLoadingConfig({ flag: false })
            setActiveTooltips(activeTooltips.filter(surv => surv.uuid != tooltip.uuid))
            toast(CobuConstants.activeTooltips.deactivateSurveySuccess)
        } catch (error) {
            setLoadingConfig({ flag: false })
            if (error) {
                toast(error?.message)
            }
        }
    }

    const createTooltip = async (tooltip: CreateToolTipBody) => {
        setLoadingConfig({ flag: true, comment: CobuConstants.activeTooltips.createSurveyLoading })
        try {
            const createResp = await createBuildingTooltip(tooltip)
            console.log("sucessss")
            fetchTooltips(true)
            toast(CobuConstants.activeTooltips.createSurveySuccess)
        } catch (error) {
            setLoadingConfig({ flag: false })
            if (error) {
                toast(error?.message)
            }
        }
    }

    return { activeTooltips, loadingConfig, fetchTooltips, deactivateTooltip, createTooltip }
}