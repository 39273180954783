import { emperor } from "~/services";
import { CreateSurveyBody } from "~/types/surveys";

export const getAllActiveSurveys = async () => {
    return await emperor.get('buildingsurvey/all');
}

export const deactivateBuildingSurvey = async (surveyID: string) => {
    return await emperor.patch(`buildingsurvey/${surveyID}`, { active: false });
}

export const createBuildingSurvey = async (survey: CreateSurveyBody) => {
    return await emperor.post('buildingsurvey',survey);
}