import React, { useEffect, useState } from 'react';
import {
  ModerationActions,
  getCommentsToModerate,
  performModerationActionOnComment
} from '~/api/admin';
import { useFullScreenLoader } from '~/hooks/useFullScreenLoader';
import {
  CommentModerationAction,
  ContentStatus,
  FlaggedComment,
  getColumnsForModerationTable
} from './tableColumns';
import { withFullScreenLoader } from '~/hocs/withFullScreenLoader';
import GenericModal from '~/components/GenericModal/GenericModal';
import ModerationCommentDetails from './CommentDetails';
import { Colors } from '~/enums/Colors';
import './style.css';
import { Tabs } from '~/components/Tabs';
import CobuTableAdvanced from '~/components/CobuTableAdvanced/CobuTableAdvanced';
import { ApiState } from '~/types/common';
import { moveCommentToMarketplace, updateGroupComment } from '~/api/comment';
import { toast } from 'react-toastify';

const filtersList = [
  {
    label: 'ALL',
    value: 'all'
  },
  {
    label: 'FLAGGED BY USER',
    value: 'flagged'
  },
  {
    label: 'HIDDEN POSTS',
    value: 'hidden'
  },
  {
    label: 'IMAGES',
    value: 'containsImages'
  },
  {
    label: 'MARKETPLACE',
    value: 'marketplace'
  }
];

type FilteredComments = {
  hidden: FlaggedComment[];
  flagged: FlaggedComment[];
  containsImages: FlaggedComment[];
  marketplace: FlaggedComment[];
  all: FlaggedComment[];
};

const getFilteredComments = (comments: FlaggedComment[]): FilteredComments => {
  const hidden: FlaggedComment[] = [],
    flagged: FlaggedComment[] = [],
    containsImages: FlaggedComment[] = [],
    marketplace: FlaggedComment[] = [],
    otherGroups: FlaggedComment[] = [];

  comments.forEach((comment) => {
    comment.status == 'HIDDEN' && hidden.push(comment);
    comment.flaggedByUser && flagged.push(comment);
    comment.images?.length > 0 && containsImages.push(comment);
    comment.groupName == 'Marketplace'
      ? marketplace.push(comment)
      : otherGroups.push(comment);
  });

  return {
    hidden,
    flagged,
    containsImages,
    marketplace,
    all: comments
  };
};

const ContentModerationContainer = () => {
  const [flaggedComments, setFlaggedComments] = useState<FlaggedComment[]>([]);
  const [commentToExpand, setCommentToExpand] = useState<FlaggedComment>();
  const { loader } = useFullScreenLoader();
  const [filteredComments, setfilteredComments] = useState<FlaggedComment[]>(
    []
  );
  const [filteredTableData, setfilteredTableData] = useState<FilteredComments>({
    hidden: [],
    flagged: [],
    containsImages: [],
    marketplace: [],
    all: []
  });
  const [selectedFilter, setSelectedFilter] = useState<string>('all');
  const [getCommentsApiState, setgetCommentsApiState] =
    useState<ApiState>('LOADING');

  const getComments = () => {
    loader.show();
    setgetCommentsApiState('LOADING');

    getCommentsToModerate()
      .then((res) => {
        segregateComments(res.data?.comments);
      })
      .finally(() => {
        setgetCommentsApiState('COMPLETED');
        loader.hide();
      });
  };

  const segregateComments = (comments: FlaggedComment[]) => {
    const filtered = getFilteredComments(comments);
    setfilteredTableData(filtered);
    setfilteredComments(filtered[selectedFilter as keyof FilteredComments]);
  };

  const performAction = ({
    id,
    action,
    visibility
  }: {
    id: string[];
    action: ModerationActions;
    visibility?: ContentStatus;
  }) => {
    loader.show();

    performModerationActionOnComment({ id, visibility, action })
      .then(() => {
        const updatedCommentsList = [...filteredTableData.all].filter(
          (comment) => {
            return !id.includes(comment.uuid);
          }
        );

        segregateComments(updatedCommentsList);
      })
      .finally(() => {
        loader.hide();
        setCommentToExpand(undefined);
      });
  };

  const pinComment = async (comment: FlaggedComment) => {
    try {
      loader.show();

      await updateGroupComment({ uuid: comment.uuid, pinComment: true });
      await performAction({ id: [comment.uuid], action: 'MARK_REVIEWED' });

      toast.success('Comment pinned');
    } catch (error) {
      console.error(error);
    }

    loader.hide();
  };

  const moveToMarketplace = async (comment: FlaggedComment) => {
    try {
      loader.show();

      await moveCommentToMarketplace({
        building: {
          uuid: comment.buildingId
        },
        uuid: comment.uuid
      });

      toast.success('Comment moved to marketplace');
      await performAction({ id: [comment.uuid], action: 'MARK_REVIEWED' });
    } catch (error) {
      console.error(error);
    }

    loader.hide();
  };

  const action = async (
    type: CommentModerationAction,
    comment: FlaggedComment
  ) => {
    switch (type) {
      case 'PIN_POST': {
        return pinComment(comment);
      }
      case 'MOVE_TO_MARKETPLACE': {
        return moveToMarketplace(comment);
      }
      case 'HIDE': {
        return changeCommentVisibility(comment, ContentStatus.HIDDEN);
      }
      case 'UNHIDE': {
        return changeCommentVisibility(comment, ContentStatus.VISIBLE);
      }
      case 'MARK_REVIEWED': {
        return markCommentReviewed(comment);
      }
    }
  };

  const markCommentReviewed = (comment: FlaggedComment) =>
    performAction({ id: [comment.uuid], action: 'MARK_REVIEWED' });

  const changeCommentVisibility = (
    comment: FlaggedComment,
    visibility: ContentStatus
  ) =>
    performAction({
      id: [comment.uuid],
      action: 'UPDATE_VISIBILITY',
      visibility
    });

  const reviewAboveComments = (selectedComment: FlaggedComment) => {
    const index = filteredComments.findIndex(
      (comment) => comment.uuid == selectedComment.uuid
    );

    const selectedComments = filteredComments.slice(0, index + 1);

    performAction({
      id: selectedComments.map((comment) => comment.uuid),
      action: 'MARK_REVIEWED'
    });
  };

  const displayCommentDetails = (comment: FlaggedComment) =>
    setCommentToExpand(comment);

  const closeCommentDetailsModal = () => setCommentToExpand(undefined);

  function mouseEnterOnRow(row: FlaggedComment) {
    document.getElementById(`reviewAboveButton-${row.uuid}`)!.style.visibility =
      'visible';
  }
  function mouseLeftRow(row: FlaggedComment) {
    document.getElementById(`reviewAboveButton-${row.uuid}`)!.style.visibility =
      'hidden';
  }

  const selectFilter = (value: string) => {
    // if (selectedFilter?.value == filter.value) {
    //   setSelectedFilter(undefined);
    // } else {
    //   setSelectedFilter(filter);
    // }
    if (!value) return;
    setSelectedFilter(value);
    setfilteredComments(filteredTableData[value as keyof FilteredComments]);
  };

  const getCommentsCountByFilter = (value: string) => {
    return filteredTableData[value as keyof FilteredComments]?.length ?? 0;
  };

  useEffect(() => {
    getComments();
  }, []);

  if (getCommentsApiState == 'LOADING') return null;

  return (
    <div style={{ padding: '24px' }}>
      <div className='flex justify-start margin-bottom-16'>
        <Tabs.Container onChange={selectFilter} value={selectedFilter}>
          {filtersList.map((filter) => (
            <Tabs.Option
              selected={filter.value == selectedFilter}
              key={filter.value}
              value={filter.value}
            >
              {filter.label}
              {/* <div className='bullet-count margin-left-8'>
                {getCommentsCountByFilter(filter.value)}
              </div> */}
            </Tabs.Option>
          ))}
        </Tabs.Container>
      </div>
      <h4 className='margin-bottom-16' style={{ color: Colors.cobuTextBlack }}>
        Total comments - {filteredComments?.length || 0}
      </h4>
      <div id='comments-moderation-table margin-top-16'>
        <CobuTableAdvanced
          rowProps={{
            onMouseEnter: mouseEnterOnRow,
            onMouseLeave: mouseLeftRow
          }}
          columns={getColumnsForModerationTable({
            displayCommentDetails,
            onReviewAboveClicked: reviewAboveComments,
            action
          })}
          data={filteredComments}
          ignoreDefaultSortBehaviour
          noData={{ label: 'No comments found' }}
        ></CobuTableAdvanced>
      </div>
      <GenericModal
        title='Comment'
        showModal={!!commentToExpand}
        hideActionButton
        cancelOverride='Close'
        content={
          <ModerationCommentDetails
            comment={commentToExpand}
            onMarkReviewed={markCommentReviewed}
            onChangeVisibility={changeCommentVisibility}
          />
        }
        onChange={closeCommentDetailsModal}
        enableOverflowScrolls
      ></GenericModal>
    </div>
  );
};

export default withFullScreenLoader(ContentModerationContainer);
