import React, { CSSProperties, MouseEventHandler, useState } from 'react';
import classes from './TextDelimeter.module.css';

interface IProps {
  textElement: string;
  maxDelimiter: number;
  moreMessage: string;
  lessMessage: string;
  contentStyle?: CSSProperties;
  onClick?: MouseEventHandler;
  enableButton?: boolean;
}

const TextDelimeter = (props: IProps) => {
  const {
    textElement,
    maxDelimiter,
    moreMessage,
    lessMessage,
    contentStyle,
    enableButton = false,
    onClick
  } = props;

  const [showAllText, setShowAllText] = useState(false);
  const applyDelimeter = textElement.length > maxDelimiter;

  let textToDisplay =
    applyDelimeter && !showAllText
      ? textElement.substring(0, maxDelimiter)
      : textElement;

  textToDisplay += applyDelimeter ? (showAllText ? ' ' : '...') : '';

  const showFullText = () => {
    setShowAllText(!showAllText);
  };

  return (
    <p
      style={contentStyle}
      className={`${classes.paragraph} ${classes.content} ${
        enableButton ? 'more-actions-btn pointer' : ''
      }`}
      onClick={onClick}
    >
      {textToDisplay}
      {applyDelimeter && (
        <span onClick={showFullText} className={classes.seeMessage}>
          {applyDelimeter && !showAllText ? moreMessage : lessMessage}
        </span>
      )}
    </p>
  );
};

export default TextDelimeter;
