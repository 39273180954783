import React from 'react';
import { useForm } from 'react-hook-form';
import { LoginRequest } from '~/types/auth';

import classes from './LoginForm.module.css';

interface IProps {
  invalidCredentials: boolean;
  onLogin: (text: LoginRequest) => void;
  onChange: () => void;
}
export const LoginForm = (props: IProps) => {
  const { register, handleSubmit, errors } = useForm<LoginRequest>();
  const onSubmit = (data: LoginRequest) => {
    if (data.email !== '' && data.password !== '') {
      props.onLogin(data);
    }
  };

  const handleChange = () => {
    props.onChange();
  };

  const getFieldStyle = (fieldErrors: boolean) => {
    if (fieldErrors) {
      return `${classes.inputField} error-border`;
    } else if (props.invalidCredentials) {
      return `${classes.inputField}  error-text`;
    } else {
      return `${classes.inputField}`;
    }
  };

  return (
    <div className={classes.container}>
      <form onSubmit={handleSubmit(onSubmit)} onChange={handleChange}>
        <div className='input-field-container'>
          <div className='form-field-error-box'>
            {errors.email ? errors.email.message : ''}
          </div>
          <input
            type='text'
            id='email'
            name='email'
            placeholder={'Email Address'}
            className={getFieldStyle(!!errors.email)}
            ref={register({
              required: 'Field is required'
            })}
          />
        </div>

        <div className='input-field-container'>
          <div className='form-field-error-box'>
            {errors.password ? errors.password.message : ''}
          </div>
          <input
            type='password'
            id='password'
            name='password'
            placeholder='Password'
            className={getFieldStyle(!!errors.password)}
            ref={register({
              required: 'Field is required'
            })}
          />
        </div>
        <button
          type='submit'
          className={`submit-button pointer ${classes.loginButton}`}
        >
          Log In
        </button>
      </form>
    </div>
  );
};

export default LoginForm;
