import React from 'react';
import { useForm } from 'react-hook-form';
import { isPhoneFormat } from '../../helpers/helpers';
import './RegisterPhoneForm.css';

interface IProps {
  onSubmit: (phone: string) => void;
  onChange: () => void;
}

export const RegisterPhoneForm = (props: IProps) => {
  const { register, handleSubmit, errors } = useForm<{
    phone: string;
  }>();
  const onSubmit = (data: { phone: string }) => {
    if (data) {
      props.onSubmit(data.phone);
    }
  };
  const handleChange = () => {
    props.onChange();
  };
  return (
    <form
      className='reg-phone-form'
      onSubmit={handleSubmit(onSubmit)}
      onChange={handleChange}
    >
      <div>
        <div className='form-field-error-box'>
          {errors.phone ? errors.phone.message : ''}
        </div>
        <input
          type='text'
          id='phone'
          name='phone'
          placeholder={'Phone number'}
          className={`reg-phone-form-field ${errors.phone && 'border-red'}`}
          ref={register({
            required: 'Please insert your phone',
            validate: isPhoneFormat
          })}
        />
      </div>
      <button type='submit' className='submit-button reg-phone-form-button'>
        Confirm
      </button>
    </form>
  );
};

export default RegisterPhoneForm;
