import React from 'react';
import { useEffect, useState } from 'react';
import { FormInputConfig } from '~/types/residentManagement';
import { IBuildingTileForm, IBuildingTile } from './interfaces';
import { CellProps, Column } from 'react-table';
import { ReactComponent as EditIcon } from '~/utils/images/grid-edit.svg';
import { ReactComponent as TrashIcon } from '~/utils/images/trash.svg';
import { ReactComponent as DragDropIcon } from '~/utils/images/drag-drop.svg';
import {
  deleteBuildingTile,
  getBuildingTiles,
  putBuildingTile,
  sortBuildingTile
} from '~/api/building';
import { SubmitHandler } from 'react-hook-form';
import { toast } from 'react-toastify';
import { convertKeyValueToFormData } from '~/helpers/helpers';

export const useBuildingTileFrom = (
  buildingId: string,
  selectedTile?: IBuildingTileForm,
  onFormSubmitCallBack?: () => void
) => {
  const [loader, setLoder] = useState(false);

  const onSubmit: SubmitHandler<IBuildingTileForm> = async (buildingTile) => {
    try {
      setLoder(true);

      const formData = convertKeyValueToFormData({
        buildingId,
        ...(selectedTile || {}),
        ...buildingTile
      });

      if (typeof buildingTile.image === 'object') {
        formData.append('image', buildingTile.image);
      } else {
        formData.delete('image');
        formData.append('imageUrl', buildingTile.image);
      }

      await putBuildingTile(formData);

      setLoder(false);
      toast(`Tile ${selectedTile?.uuid ? 'updated' : 'added'} successfully`);
      if (onFormSubmitCallBack) {
        onFormSubmitCallBack();
      }
    } catch (e) {
      setLoder(false);
    }
  };

  const formConfig: FormInputConfig[] = [
    {
      id: 'title' as keyof IBuildingTileForm,
      isRequired: true,
      placeholder: 'eg: Marketplace',
      label: 'Title'
    },
    {
      id: 'link' as keyof IBuildingTileForm,
      isRequired: true,
      placeholder:
        'eg: weblink: https://www.google.co.in/, app deeplink: cobu://perks/perkDetails',
      label: 'Link'
    },
    {
      id: 'isWeblink' as keyof IBuildingTileForm,
      isRequired: false,
      inputType: 'switch',
      sibling: <div>Show Link in App</div>,
      styles: {
        contentStyles: {
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          gap: '0 14px',
          backgroundColor: 'rgba(39, 33, 99, 0.06)',
          padding: 20,
          borderRadius: 8,
          border: '1px dashed rgba(39, 33, 99, 0.2)'
        }
      }
    },
    {
      id: 'image' as keyof IBuildingTileForm,
      isRequired: true,
      inputType: 'imageDropzone',
      label: 'Add a Photo'
    }
  ];

  return {
    loader,
    onSubmit,
    formConfig
  };
};

export const useBuildingTileTable = (buildingId: string) => {
  const [buildingTiles, setBuildingTiles] = useState<null | IBuildingTile[]>(
    null
  );
  const [loader, setLoder] = useState(false);
  const [selectedTile, setSelectedTile] = useState<null | IBuildingTileForm>(
    null
  );
  const [showTileForm, setShowTileForm] = useState(false);

  const fetchBuildingTiles = async () => {
    try {
      setLoder(true);
      const res = await getBuildingTiles(buildingId);

      setBuildingTiles(res.data as IBuildingTile[]);
      setLoder(false);
    } catch (e) {
      setLoder(false);
    }
  };

  useEffect(() => {
    fetchBuildingTiles();
  }, []);

  const onEditTileForm = (tile: IBuildingTileForm) => {
    setSelectedTile(tile);
    setShowTileForm(true);
  };

  const onDeleteTile = async (tile: IBuildingTileForm) => {
    if (window.confirm('Are you sure you want to remove this tile?')) {
      try {
        setLoder(true);
        const res = await deleteBuildingTile(tile.uuid);

        setLoder(false);
        toast('Tile deleted successfully');
        fetchBuildingTiles();
      } catch (e) {
        setLoder(false);
      }
    }
  };

  const onAddNewTile = () => {
    setSelectedTile(null);
    setShowTileForm(true);
  };

  const onTileFormSubmit = () => {
    setSelectedTile(null);
    setShowTileForm(false);

    fetchBuildingTiles();
  };

  const onCancelTileForm = () => {
    setSelectedTile(null);
    setShowTileForm(false);
  };

  const columns: Column<any>[] = [
    {
      Header: 'TITLE',
      accessor: 'title',
      disableSortBy: true
    },
    {
      Header: 'IMAGE',
      accessor: 'image',
      Cell: (cell: CellProps<IBuildingTileForm>) => (
        <img
          style={{ borderRadius: '8px' }}
          src={cell.row.original.image as string}
          width={100}
          height={150}
        />
      ),
      disableSortBy: true
    },
    {
      Header: 'LINK',
      accessor: 'user.email',
      Cell: (cell: CellProps<IBuildingTileForm>) => (
        <a href={cell.row.original.link}>{cell.row.original.link}</a>
      ),
      disableSortBy: true
    },
    {
      Header: 'DISPLAY IN APP',
      accessor: 'isWeblink',
      Cell: (cell: CellProps<IBuildingTileForm>) => (
        <div
          style={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap'
          }}
        >
          <div
            style={{
              padding: '4px 50px',
              width: 'max-content',
              borderRadius: '8px',
              backgroundColor: cell.row.original.isWeblink
                ? '#34C759'
                : 'rgba(22, 187, 210, 0.2)'
            }}
          >
            {cell.row.original.isWeblink ? 'Yes' : 'EXTERNAL'}
          </div>
        </div>
      ),
      disableSortBy: true
    },
    {
      Header: 'ACTIONS',
      accessor: '',
      Cell: (cell: CellProps<IBuildingTileForm>) => (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '0 20px'
          }}
        >
          <div
            style={{ cursor: 'pointer' }}
            onClick={() => onEditTileForm(cell.row.original)}
          >
            <EditIcon />
          </div>
          <div
            style={{ cursor: 'pointer' }}
            onClick={() => onDeleteTile(cell.row.original)}
          >
            <TrashIcon />
          </div>
        </div>
      ),
      disableSortBy: true
    }
  ];

  const onSortTiles = async (
    sortedTiles: Record<string, string | number>[]
  ) => {
    const sortOrderPayload: Record<any, any> = {};

    sortedTiles.forEach((tile: Record<any, any>, index) => {
      sortOrderPayload[tile.uuid] = index + 1;
    });

    try {
      setLoder(true);
      const res = await sortBuildingTile(sortOrderPayload);

      setLoder(false);
      toast('Tile sorted successfully');
      fetchBuildingTiles();
    } catch (e) {
      setLoder(false);
    }
  };

  return {
    showTileForm,
    buildingTiles,
    columns,
    loader,
    selectedTile,
    onTileFormSubmit,
    onAddNewTile,
    onCancelTileForm,
    onSortTiles
  };
};
