import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import GroupsRejected from '../../components/GroupsRejected/GroupsRejected';
import {
  getGroupActions,
  chooseGroup,
  deleteProposedGroup
} from '../../actions/GroupActions';
import ReviewRejectedGroup from '../../components/ReviewRejectedGroup/ReviewRejectedGroup';
import '../GroupsContainer/GroupsContainer.css';
import GenericModal from '~/components/GenericModal/GenericModal';
import { useFullScreenLoader } from '~/hooks/useFullScreenLoader';
import { useCobuSelector } from '~/hooks/useCobuSelector';
import { SelectedTableRow } from '~/types/common';
import { Group } from '~/reducers/groupAdmin';

const GroupsRejectedContainer = () => {
  const [showModal, setShowModal] = useState(false);
  const { loader } = useFullScreenLoader();
  const dispatch = useDispatch();

  const { rejectedGroups, loading, rejectedGroup } = useCobuSelector(
    ({ groups }) => ({
      rejectedGroups: groups.rejectedGroups,
      loading: groups.loading,
      // rejectedGroupReview: groups.rejectedGroupReview,
      rejectedGroup: groups.groupSelected
    })
  );

  const reviewRejectedGroup = (row: SelectedTableRow<Group>) => {
    dispatch(chooseGroup(row.original));
    handleShowModal(true);
  };

  const deleteRejectedGroup = (row: SelectedTableRow<Group>) => {
    if (
      window.confirm(
        `Are you sure you want to delete this rejected group proposal for the group named "${row.original.title}" ?`
      )
    ) {
      loader.show();
      deleteProposedGroup(row.original).finally(() => loader.hide());
    }
  };

  const handleShowModal = (show: boolean) => {
    setShowModal(show);
  };

  useEffect(() => {
    dispatch(getGroupActions(true));
  }, []);

  const renderModal = () => {
    return (
      <GenericModal
        title='Rejected Group'
        content={<ReviewRejectedGroup rejectedGroup={rejectedGroup!} />}
        onChange={setShowModal}
        showModal={showModal}
        hideActionButton={true}
        cancelOverride='Close'
      />
    );
  };

  return (
    <div className='marginTop1'>
      <GroupsRejected
        loading={loading}
        rejectedGroups={rejectedGroups}
        reviewRejectedGroup={reviewRejectedGroup}
        deleteRejectedGroup={deleteRejectedGroup}
      />
      {renderModal()}
    </div>
  );
};

export default GroupsRejectedContainer;
