import React from 'react';
import { Layout } from '~/types/layout';
import { getWidgetComponent } from './components';
import { getLayoutWidget } from './widgets';

type LayoutProps = {
  layout: Layout;
};

export const LayoutView = (props: LayoutProps) => {
  const { layout } = props;

  return (
    <div>
      {layout.widgets?.map((widget) => {
        const Widget = getLayoutWidget(widget) as any;

        if (!Widget) return null

        return (
          <div>
            <Widget {...widget} />
          </div>
        );
      })}
    </div>
  );
};
