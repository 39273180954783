import React, { FC } from 'react';
import { HeaderGroup } from 'react-table';

interface IProps {
  column?: HeaderGroup<Record<any, any>>;
  centerAlignedHeaderAndContent: boolean;
}

const columnsWithoutSort = [
  'ROLE',
  'USER NAME',
  'SCORE',
  '# OF POSTS',
  'RSVPs',
  'LAST ACTIVITY',
  'NAME ON REVIEW',
  'REVIEW TEXT',
  'AVG RATING',
  'GROUP',
  'FEEDBACK RESPONSES',
  'GATHERING NAME',
  'HOW TO',
  'RECOMMENDED MONTH',
  'PHONE',
  'UNIT NUMBER',
  'SENTIMENT SCORE',
  'INTERCOM',
  'ONESIGNAL',
  ''
];

export const TableHead: FC<IProps> = ({
  column,
  centerAlignedHeaderAndContent,
  children
}) => {
  return (
    <th
      {...column?.getHeaderProps(column?.getSortByToggleProps())}
      style={{
        borderBottom: 'solid 1px rgba(39, 33, 99, 0.1)',
        padding: '24px 10px',
        color: 'rgba(39, 33, 99, 0.5)',
        fontWeight: 'bold',
        fontFamily: 'Roboto',
        fontSize: '14px',
        textAlign: 'left'
      }}
    >
      <div
        style={{
          display: 'flex',
          cursor: 'pointer',
          alignItems: 'center',
          ...(centerAlignedHeaderAndContent ? { justifyContent: 'center' } : {})
        }}
      >
        <>
          {column ? (
            <>
              {column.render('Header')}
              {typeof column.Header === 'string' &&
                !columnsWithoutSort.includes(column.Header) &&
                !column.disableSortBy && (
                  <div
                    style={{
                      position: 'relative',
                      fontSize: '10px',
                      marginLeft: '10px'
                    }}
                  >
                    {((column.isSorted && !column.isSortedDesc) ||
                      !column.isSorted) && (
                      <div
                        style={{
                          position: 'absolute',
                          bottom: '-3px'
                        }}
                      >
                        ▲
                      </div>
                    )}
                    {((column.isSorted && column.isSortedDesc) ||
                      !column.isSorted) && (
                      <div
                        style={{
                          position: 'absolute',
                          top: '-1px'
                        }}
                      >
                        ▼
                      </div>
                    )}
                  </div>
                )}
            </>
          ) : (
            children
          )}
        </>
      </div>
    </th>
  );
};
