import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { Roles } from '~/enums/Roles';
import { useOnClickOutside } from '~/helpers/helpers';
import { Comment } from '~/types/comment';
import { ReactComponent as Ellipsis } from '~/utils/images/ellipsis.svg';
import { CommentFilterType } from '~/containers/Comments/Comments';
import useIsMounted from '~/hooks/isMounted';
import { useSearchQuery } from '~/hooks/useSearchQuery';

interface IProps {
  comment: Comment;
  index?: number;
  parentComment?: Comment;
  filter: number;
  isThread: boolean;
  currentUserRole: string;
  selectedCommentId: string;
  children?: Object[];
  setSelectedCommentId: (selectedCommentId: string) => void;
  onReplyClick: (comment: Comment) => void;
  onDeleteClick: (comment: Comment) => void;
  notifyResident: (comment: Comment) => void;
  setShowThreadModal: (showThreadModal: boolean) => void;
  hideComment: (comment: Comment) => void;
  pinComment: (comment: Comment) => void;
  onOpenThreadClick: (parentComment: Comment) => void;
  markGreatContent: (comment: Comment) => void;
  moveToMarketplace: (comment: Comment) => void;
  commentType: CommentFilterType;
  preventIndent?: boolean;
}
const CommentBox = (props: IProps) => {
  const ref = useRef<HTMLDivElement>(null);
  useOnClickOutside(ref, () => {
    setSelectedCommentId('');
  });
  const [hasNavigatedToComment, sethasNavigatedToComment] =
    useState<boolean>(false);

  const {
    comment,
    index,
    parentComment,
    filter,
    isThread,
    currentUserRole,
    selectedCommentId,
    children,
    setSelectedCommentId,
    onReplyClick,
    onDeleteClick,
    notifyResident,
    setShowThreadModal,
    hideComment,
    pinComment,
    onOpenThreadClick,
    markGreatContent,
    moveToMarketplace,
    preventIndent = false
  } = props;

  const prettifyTime = (time: string) => {
    return moment(time).fromNow().includes('second') === false &&
      moment(time).fromNow().includes('minute') === false &&
      moment(time).fromNow().includes('hour') === false &&
      moment(time).fromNow().includes('day') === false
      ? moment(time).format('ll')
      : moment(time).fromNow();
  };

  const commentRef = useRef<HTMLElement>();
  const isMounted = useIsMounted();
  const query = useSearchQuery();

  let thisClass =
    comment.parent && !preventIndent ? 'comment-child' : 'comment';

  useEffect(() => {
    if (!hasNavigatedToComment && query?.commentId == comment.uuid) {
      if (commentRef?.current) {
        commentRef?.current?.scrollIntoView({
          block: 'center',
          inline: 'center'
        });
        commentRef.current.className = 'comment-blink';
        sethasNavigatedToComment(true);
      }
    }
  }, [isMounted]);

  return (
    <div ref={commentRef as any} id={`comment-${comment.uuid}`}>
      <div className={thisClass}>
        {comment.parent &&
          parentComment &&
          index !== parentComment.children.length - 1 && (
            <div className='left-line' />
          )}
        {parentComment !== undefined && <div className='curve' />}
        <img
          className='avatar margin-right-8'
          alt='avatar'
          src={comment.user.profileImage}
        />
        {parentComment === undefined &&
          comment.children &&
          comment.children.length > 0 && <div className='line' />}
        <div>
          <div className='checkbox-row'>
            <p className='comments-container-txt margin-bottom-4'>
              {comment.user.displayName}
            </p>
            {comment.removed && (
              <p className='removed-label checkbox-text margin-left-8 warning-background'>
                {`Comment has been DELETED by ${comment.removedBy || 'ADMIN'}`}
              </p>
            )}
            {comment.hidden && (
              <p className='removed-label checkbox-text margin-left-8 warning-background'>
                {`Comment has been HIDDEN by ${comment.hiddenBy || 'ADMIN'}`}
              </p>
            )}
            {((parentComment && parentComment.removed) ||
              (comment.parent && comment.parent.removed)) && (
              <p className='removed-label checkbox-text margin-left-8 warning-background'>
                This reply is NOT displayed. PARENT comment has been DELETED.
              </p>
            )}
            {(parentComment && parentComment.hidden) ||
              (comment.parent && comment.parent.hidden && (
                <p className='removed-label checkbox-text margin-left-8 cobu warning-background'>
                  This reply is NOT displayed. PARENT comment has been HIDDEN.
                </p>
              ))}
            {comment.greatContent && currentUserRole !== Roles.Pm && (
              <p className='removed-label checkbox-text margin-left-8 cobu-dark-blue-background'>
                This is GREAT content
              </p>
            )}
            {comment.pinComment && currentUserRole !== Roles.Pm && (
              <p className='removed-label checkbox-text margin-left-8 cobu-turquoise-background'>
                Pinned Post
              </p>
            )}
          </div>
          <p
            className={
              (parentComment && parentComment.hidden) ||
              (parentComment && parentComment.removed) ||
              comment.removed ||
              comment.hidden
                ? 'comments-container-content-hidden'
                : 'comments-container-content'
            }
          >
            {comment.images?.length ? (
              <>
                {comment.contents && <p>{comment.contents}</p>}
                {comment.images?.length > 0 && (
                  <div style={{ overflow: 'auto', marginTop: 16 }}>
                    {comment.images.map((commentImage) => (
                      <img
                        src={commentImage.image}
                        className={
                          (parentComment && parentComment.hidden) ||
                          (parentComment && parentComment.removed) ||
                          comment.removed ||
                          comment.hidden
                            ? 'bw'
                            : ''
                        }
                        alt='comment img'
                        width='80'
                        height={'80'}
                        style={{ borderRadius: 10, marginRight: 8 }}
                      />
                    ))}
                  </div>
                )}
              </>
            ) : (
              comment.contents
            )}
          </p>
          {filter !== 0 && !isThread ? (
            <>
              <p className='comment-time'>{prettifyTime(comment.createdAt)}</p>
              <p
                className='comment-reply-btn margin-left-16'
                onClick={() => onOpenThreadClick(comment)}
              >
                Open thread
              </p>
            </>
          ) : !comment.parent ? (
            <>
              <p className='comment-time'>{prettifyTime(comment.createdAt)}</p>
              <p
                className='comment-reply-btn margin-left-16'
                onClick={() => {
                  onReplyClick(comment);
                }}
              >
                Reply
              </p>
            </>
          ) : (
            <p className='comment-time'>{prettifyTime(comment.createdAt)}</p>
          )}
        </div>
        {(filter === 0 || isThread) &&
          !!currentUserRole &&
          currentUserRole !== Roles.Pm && (
            <div
              className='ellipsis'
              onClick={() => setSelectedCommentId(comment.uuid)}
            >
              <Ellipsis />
              {selectedCommentId === comment.uuid && (
                <div className='small-list' ref={ref}>
                  <div className='more-actions-dropdown'>
                    {[
                      {
                        label: comment.hidden ? 'Unhide' : 'Hide',
                        action: () => {
                          hideComment(comment);
                        }
                      },
                      {
                        label: comment.removed ? 'Undelete' : 'Delete',
                        action: () => {
                          onDeleteClick(comment);
                        }
                      },
                      {
                        label: 'Notify the Resident',
                        action: () => {
                          notifyResident(comment);
                        }
                      },
                      {
                        label: comment.greatContent
                          ? 'Unmark as Great Content'
                          : 'Mark as Great Content',
                        action: () => {
                          markGreatContent(comment);
                        }
                      },
                      {
                        label: comment.pinComment ? 'Unpin' : 'Pin',
                        action: () => {
                          pinComment(comment);
                        }
                      },
                      {
                        label: 'Move to Marketplace',
                        action: () => {
                          moveToMarketplace(comment);
                        }
                      }
                    ].map((option, index) => (
                      <div
                        key={index}
                        className='more-actions-option pointer'
                        onClick={() => {
                          setShowThreadModal(false);
                          option.action();
                          setSelectedCommentId('');
                        }}
                      >
                        {option.label}
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          )}
      </div>
      <div>{children && children}</div>
    </div>
  );
};

export default CommentBox;
