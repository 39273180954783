import React from 'react';
import CobuConstants from '~/helpers/cobuConstants';
import MultiSelect from '../MultiSelect/MultiSelect';
import Spacer from '../Spacer/Spacer';
import { SelectType } from '~/enums/SelectType';
import useCreateDeeplinkForm from '~/hooks/deeplinks/useCreateDeeplinkForm';
import { AttributionDeeplinkCreateBody } from '~/types/deeplink';

type IProps = {
    createDeeplink: (r: AttributionDeeplinkCreateBody) => void;
    cancel: () => void;
    buildingId: string;
};

const CreateDeeplinkForm = ({ cancel, createDeeplink, buildingId }: IProps) => {
    const {
        items,
        typeMultiValue,
        setTypeMultiValue,
        itemMultiValue,
        setItemMultiValue,
        getDeeplinkTypesForDropdown,
        getItemsForDropdown,
        getCreateDeeplinkPayload
    } = useCreateDeeplinkForm(buildingId);

    const onSubmit = () => {
        let payload = getCreateDeeplinkPayload()
        if(payload)
        createDeeplink(payload)
    };

    return (
        <div>
            <div className='gridContainer'>
                <div>
                    <label htmlFor='type'>
                        Deeplink Type
                    </label>
                    <Spacer height={8} />
                    <MultiSelect
                        singleSelect
                        options={getDeeplinkTypesForDropdown()}
                        selectType={SelectType.Form}
                        multiValue={typeMultiValue ?? [{ label: '', value: '' }]}
                        setMultiValue={setTypeMultiValue}
                        placeholder={'Select Deeplink Type'}
                        cobuPlusBuildings={[]}
                    />
                </div>
                {typeMultiValue.length > 0 && (
                    <div>
                        <div>
                            <label htmlFor='item'>
                                {`${typeMultiValue[0].label}`}
                            </label>
                            <Spacer height={8} />
                            <MultiSelect
                                singleSelect
                                options={getItemsForDropdown()}
                                selectType={SelectType.Form}
                                multiValue={itemMultiValue ?? [{ label: '', value: '' }]}
                                setMultiValue={setItemMultiValue}
                                placeholder={`Select ${typeMultiValue[0].label}`}
                                cobuPlusBuildings={[]}
                            />
                        </div>
                    </div>
                )}
            </div>
            <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
                <button type='button' className='btn-standard btn-secondary' onClick={cancel}>
                    {CobuConstants.createResident.cancel}
                </button>
                <Spacer width={10} />
                <button type='submit' onClick={onSubmit} className='btn-standard btn-primary margin-left-16'>
                    Submit
                </button>
            </div>
        </div>
    );
};

export default CreateDeeplinkForm;
