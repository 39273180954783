import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import classes from './ReviewReceivedForm.module.css';
import Rating from '../Rating/Rating';
import { useForm } from 'react-hook-form';
import { ReviewReceived } from '~/types/form';
import { uriRegex } from '~/helpers/helpers';
import { ReviewReceivedType } from '~/types/reviewReceived';

interface IProps {
  closeModal: () => void;
  review?: ReviewReceivedType;
  prefillForm: boolean;
  onSubmit: (data: ReviewReceived) => void;
}
const ReviewReceivedForm = (props: IProps) => {
  const { closeModal, review, prefillForm, onSubmit } = props;
  const [selectedRating, setSelectedRating] = useState(0);

  const {
    register,
    handleSubmit,
    errors,
    clearErrors,
    reset
  } = useForm<ReviewReceived>({
    defaultValues: {
      name: '',
      dateReview: '',
      reviewText: '',
      notes: '',
      linkToGoogle: ''
    }
  });

  const changeRating = (value: number) => {
    setSelectedRating(value);
  };

  useEffect(() => {
    if (selectedRating) {
      clearErrors('selectedRating');
    }
  }, [selectedRating]);

  useEffect(() => {
    if (prefillForm && review) {
      let defaultValues = {
        name: review.reviewname,
        dateReview: moment.parseZone(review.reviewdate).format('YYYY-MM-DD'),
        reviewText: review.reviewtext,
        notes: review.reviewnotes,
        linkToGoogle: review.reviewlink
      };
      setSelectedRating(review.reviewrating);
      reset(defaultValues);
    }
  }, [prefillForm]);

  const submitHandler = async (data: ReviewReceived) => {
    onSubmit(data);
  };

  const closeFormModal = () => {
    setSelectedRating(0);
    clearErrors();
    closeModal();
  };

  const isValidDate = (date: string) => {
    if (moment().diff(date) < 0) {
      return false;
    }
    return true;
  };

  return (
    <form onSubmit={handleSubmit(submitHandler)}>
      <div className={classes.content}>
        <div className={classes.container}>
          <div className={classes.containerControls}>
            <div className='form-field-error-box margin-right-24'>
              {errors.name ? errors.name.message : ''}
            </div>
            <label>Name as Shown on Google</label>
            <input
              id='name'
              name='name'
              type='text'
              placeholder='Name'
              ref={register({
                required: 'This field is required'
              })}
              className={`margin-right-24 ${errors.name && 'border-red'}`}
            />
          </div>
          <div className={classes.containerControls}>
            <div className='form-field-error-box'>
              {errors.dateReview ? errors.dateReview.message : ''}
            </div>
            <label>Date of Review</label>
            <input
              type='date'
              id='dateReview'
              name='dateReview'
              ref={register({
                required: 'This field is required',
                validate: {
                  checkDate: (date) =>
                    isValidDate(date) || 'The date can not higger than today.'
                },
                maxLength: {
                  value: 40,
                  message: 'Name must be no longer than 40 characters'
                }
              })}
              className={`${errors.dateReview && 'border-red'}`}
            />
          </div>
        </div>
        <div className={classes.container}>
          <div className={classes.containerControls}>
            <label>Review Text</label>
            <textarea
              id='reviewText'
              name='reviewText'
              ref={register()}
              placeholder='E.g. Also bring joy and good will.'
            />
          </div>
        </div>
        <div className={classes.container}>
          <div className={classes.containerControls}>
            <label>Notes</label>
            <textarea
              id='notes'
              name='notes'
              ref={register()}
              placeholder='E.g. Also bring joy and good will.'
            />
          </div>
        </div>
        <div className={classes.container}>
          <div className={classes.containerControls}>
            <div className='form-field-error-box'>
              {errors.selectedRating ? errors.selectedRating.message : ''}
            </div>
            <label>Star Rating</label>
            <Rating
              value={selectedRating}
              setValue={(val) => changeRating(val)}
              otherStyle={`${errors.selectedRating && 'border-red'}`}
            />
            <input
              type='hidden'
              value={selectedRating === 0 ? '' : selectedRating.toString()}
              id='selectedRating'
              name='selectedRating'
              ref={register({
                required: 'This field is required'
              })}
            />
          </div>
          <div className={classes.containerControls}>
            <div className='form-field-error-box'>
              {errors.linkToGoogle ? errors.linkToGoogle.message : ''}
            </div>
            <label>Link to Google</label>
            <input
              type='text'
              id='linkToGoogle'
              name='linkToGoogle'
              placeholder='Add link'
              ref={register({
                pattern: {
                  value: uriRegex(),
                  message: 'Please enter a valid URL'
                }
              })}
              className={`${errors.linkToGoogle && 'border-red'}`}
            />
          </div>
        </div>
        <div className={`margin-top-24 ${classes.buttons}`}>
          <button
            type='reset'
            className='btn-standard btn-secondary'
            onClick={closeFormModal}
          >
            Cancel
          </button>
          <button
            type='submit'
            className='btn-standard btn-primary margin-left-16 '
          >
            Save
          </button>
        </div>
      </div>
    </form>
  );
};

export default ReviewReceivedForm;
