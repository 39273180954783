import React from 'react';
import { ReactComponent as ServerError } from '~/utils/images/server-error.svg';
import classes from './ServerErrorPage.module.css';

type ServerErrorPageProps = {
  errorCode?: string;
  errorMessage?: string;
};

const ServerErrorPage = (props: ServerErrorPageProps) => {
  return (
    <div className={classes.container}>
      <ServerError />
      <div className={classes.title}>{props.errorCode ?? 500}</div>
      <div className={classes.text}>
        {props.errorMessage ?? 'Oops something went wrong!'}
      </div>
      <div className={classes.text}>
        Try to refresh this page or feel free to contact us if the problem
        persists.
      </div>
    </div>
  );
};

export default ServerErrorPage;
