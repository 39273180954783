import { createSlice } from '@reduxjs/toolkit';
import { Roles } from '~/enums/Roles';
import { AdminType } from '~/types/admin';

const initialState = {
  admins: [] as AdminType[]
};

const adminSlice = createSlice({
  name: 'admin',
  initialState: initialState,
  reducers: {
    saveAdmins: (state, action) => {
      state.admins = action.payload;
    },
    updateAdminRoleSuccess: (state, action) => {
      const { userId, role } = action.payload;
      const index = state.admins.findIndex(
        (admin) => admin.user.uuid === userId
      );
      const copyAdmins = [...state.admins];
      if (role === Roles.Resident) {
        copyAdmins.splice(index, 1);
      } else {
        copyAdmins[index] = { ...copyAdmins[index], role };
      }
      state.admins = copyAdmins;
    },
    createAdminsSuccess: (state, action) => {
      const adminsCopy = [...state.admins];
      action.payload.map((data: AdminType) => {
        adminsCopy.push(data);
      });
      state.admins = adminsCopy;
    },
    updateAdminBuildingsSuccess: (state, action) => {
      const copyAdmins = [...state.admins];
      const index = state.admins.findIndex(
        (a) => a.user.uuid === action.payload.adminId
      );
      const admin = state.admins[index];
      if (action.payload.adminBuildings.length === 0) {
        copyAdmins.splice(index, 1);
      } else {
        const adminCopy = {
          ...admin,
          buildings: action.payload.adminBuildings
        };
        copyAdmins.splice(index, 1, adminCopy);
      }
      state.admins = copyAdmins;
    }
  }
});

export const {
  saveAdmins,
  updateAdminRoleSuccess,
  createAdminsSuccess,
  updateAdminBuildingsSuccess
} = adminSlice.actions;
export default adminSlice.reducer;
