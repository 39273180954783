import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { CSVLink } from 'react-csv';
import { toast } from 'react-toastify';
import { RootState } from '~/redux/store';

import {
  fetchOneGatheringRequest,
  getGatheringRsvps
} from '~/api/residentGathering';
import { convertTableColumnsToCsvHeaders } from '~/helpers/helpers';
import classes from './GatheringRSVPs.module.css';
import { ReactComponent as Search } from '~/utils/images/search.svg';
import Breadcrumbs from '~/components/Breadcrumbs/Breadcrumbs';
import { Mixpanel } from '~/services/MixpanelService';
import moment from 'moment';
import { CellProps } from 'react-table';
import _, { Dictionary } from 'lodash';
import { withFullScreenLoader } from '~/hocs/withFullScreenLoader';
import { useFullScreenLoader } from '~/hooks/useFullScreenLoader';
import { GatheringType } from '~/types/gathering';
import CobuTableAdvanced from '~/components/CobuTableAdvanced/CobuTableAdvanced';
import {
  formatAverageRating,
  getEventDuration
} from '~/components/GatheringsTable/GatheringsTable';
import { AxiosError } from 'axios';
import NotFound from '~/components/NotFound/NotFound';
import { ReactComponent as StarOn } from '~/utils/images/starOn.svg';
import { ReactComponent as StarOff } from '~/utils/images/starOff.svg';
import { ApiState } from '~/types/common';
import ServerErrorPage from '~/components/ServerErrorPage/ServerErrorPage';

type Props = ReturnType<typeof mapStateToProps>;
type RsvpersData = {
  user: { firstName: string; lastName: string; email: string };
  createdAt: string;
  totalAttendees: number;
};

const getValidEventDates = (gathering: GatheringType) => {
  return {
    asMap: _.keyBy<GatheringType>(
      [
        gathering,
        ...(gathering?.upcomingEvents ?? []),
        ...(gathering?.pastEvents ?? [])
      ],
      (g) =>
        moment(g.scheduledStartTime)
          .tz(g.buildingTimezone)
          .format('YYYY-MM-DD') ?? ''
    ),
    asArray: [
      gathering,
      ...(gathering?.upcomingEvents ?? []),
      ...(gathering?.pastEvents ?? [])
    ]
      .map((g) =>
        moment(g.scheduledStartTime).tz(g.buildingTimezone).format('YYYY-MM-DD')
      )
      .sort()
  };
};

const GatheringRSVPs = (props: Props) => {
  const { gatheringId } = useParams<{ gatheringId: string }>();
  const [data, setData] = useState<RsvpersData[]>([
    {
      user: { firstName: '', lastName: '', email: '' },
      createdAt: '',
      totalAttendees: 0
    }
  ]);
  const [rsvpsByDate, setrsvpsByDate] = useState<Record<string, RsvpersData[]>>(
    {}
  );
  const [rsvpDates, setrsvpDates] = useState<string[]>([]);
  const [eventDates, seteventDates] = useState<string[]>([]);
  const [showSearchField, setShowSearchField] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [selectedGathering, setSelectedGathering] = useState<GatheringType>();
  const [selectedDate, setselectedDate] = useState<string>();
  const [errorMessage, seterrorMessage] = useState<string>();
  const [getRsvpsErrorMessage, setgetRsvpsErrorMessage] = useState<string>();
  const [getRsvpsApiState, setgetRsvpsApiState] = useState<ApiState>('LOADING');
  const history = useHistory();

  const columnsArray = [
    {
      Header: 'FIRST NAME',
      accessor: 'user.firstName'
    },
    {
      Header: 'LAST NAME',
      accessor: 'user.lastName'
    },
    {
      Header: 'EMAIL',
      accessor: 'user.email'
    },
    {
      Header: 'CREATED AT',
      accessor: 'createdAt',
      Cell: (cell: CellProps<RsvpersData>) => (
        <div>
          {moment(cell.row.original.createdAt)
            .local()
            .format('MM/DD/YYYY hh:mm A')}
        </div>
      )
    },
    {
      Header: 'UNIT',
      accessor: 'user.roomNumber'
    },
    {
      Header: 'GUESTS',
      accessor: 'totalAttendees'
    }
  ];

  const { loader } = useFullScreenLoader();

  const getGatheringDetails = () => {
    loader.show();
    seterrorMessage(undefined);
    fetchOneGatheringRequest(gatheringId)
      .then((res) => {
        setSelectedGathering(res.data);

        Mixpanel.track('did_view_rsvps', {
          $gathering_name: res.data.title
        });
      })
      .catch((e) => {
        const error = e as AxiosError;
        toast(error.message);
        seterrorMessage(error.message);
      })
      .finally(() => {
        loader.hide();
      });
  };

  const getTotalAttendance = (rsvps: RsvpersData[]) => {
    if (!rsvps) return 0;

    let total = 0;

    rsvps.forEach((rsvp) => {
      total += rsvp.totalAttendees;
    });

    return total;
  };

  const getRsvps = () => {
    loader.show();
    setgetRsvpsErrorMessage(undefined);
    setgetRsvpsApiState('LOADING');
    getGatheringRsvps({ gatheringId })
      .then(({ data }) => {
        if (selectedGathering?.recurringTypeName != 'One Time') {
          const rsvpGroupedByInterestedDate = _.groupBy(data, 'interestedDate');
          const rsvpDates = Object.keys(rsvpGroupedByInterestedDate);

          setrsvpsByDate(rsvpGroupedByInterestedDate);
          const {
            asMap: validEventDatesAsMap,
            asArray: validEventDatesAsArray
          } = getValidEventDates(selectedGathering!);

          seteventDates(validEventDatesAsArray);

          const validRsvpDates = rsvpDates.filter(
            (rsvpDate) => validEventDatesAsMap[rsvpDate]
          );

          setrsvpDates(validRsvpDates.sort());

          setselectedDate(
            validRsvpDates?.length ? validRsvpDates[0] : undefined
          );
        } else {
          setrsvpDates([selectedGathering?.scheduledStartTime!]);
          setselectedDate(selectedGathering?.scheduledStartTime!);
          setrsvpsByDate({ [selectedGathering?.scheduledStartTime!]: data });
        }
        setData(data);
        setgetRsvpsApiState('SUCCESS');
      })
      .catch((error) => {
        toast(error.message);
        setgetRsvpsErrorMessage(error.message);
        setgetRsvpsApiState('FAILED');
      })
      .finally(() => {
        loader.hide();
      });
  };

  useEffect(() => {
    getGatheringDetails();
  }, [gatheringId, props.gatherings]);

  useEffect(() => {
    if (selectedGathering?.title) {
      getRsvps();
    }
  }, [selectedGathering]);

  const columns = React.useMemo(() => columnsArray, [columnsArray]);

  const onDateSelect = (e: any) => {
    setselectedDate(e.target.value);
  };

  const getLabelForTable = () => {
    if (getRsvpsApiState === 'LOADING') {
      return 'Fetching RSVPS';
    }

    if (getRsvpsApiState == 'FAILED') {
      return errorMessage ?? 'Something went wrong. Failed to get RSVPs';
    }
  };

  const canEditGathering = () => {
    if (selectedGathering?.cancelled) {
      return false;
    }
    if (
      !selectedGathering?.recurringTypeName ||
      selectedGathering?.recurringTypeName == 'One Time'
    ) {
      return moment(selectedGathering?.startTime).isAfter(moment());
    } else if (
      selectedGathering?.recurringTypeName &&
      selectedGathering?.recurringTypeName != 'One Time' &&
      Array.isArray(selectedGathering?.upcomingEvents) &&
      selectedGathering?.upcomingEvents?.length > 0
    ) {
      return true;
    }

    return false;
  };

  if (getRsvpsErrorMessage)
    return <ServerErrorPage errorMessage={getRsvpsErrorMessage} />;

  if (!selectedGathering) return null;

  return (
    <div>
      <div className={classes.container}>
        <Breadcrumbs />
        <div className={`margin-bottom-16 ${classes.control}`}>
          <h2>{selectedGathering.title}</h2>
          <div className={classes.controlAction}>
            {showSearchField ? (
              <div
                className='search-expanded pointer'
                onClick={() => {
                  setSearchValue('');
                  setShowSearchField(false);
                }}
              >
                <Search
                  onClick={() => {
                    setSearchValue('');
                    setShowSearchField(false);
                  }}
                  className='search-icon'
                />
                <input
                  className='search-input'
                  placeholder='Search by Name or Email'
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                  onClick={(e) => e.stopPropagation()}
                />
              </div>
            ) : (
              <div
                className='search pointer'
                onClick={() => setShowSearchField(true)}
              >
                <Search />
              </div>
            )}
            <div>
              <CSVLink
                onClick={() =>
                  Mixpanel.track('did_download_rsvps', {
                    $gathering_name: selectedGathering.title
                  })
                }
                data={
                  selectedDate && rsvpsByDate[selectedDate]
                    ? rsvpsByDate[selectedDate]?.filter((g: RsvpersData) =>
                        (
                          g.user.firstName.toLowerCase() +
                          ' ' +
                          g.user.lastName.toLowerCase() +
                          ' ' +
                          g.user.email
                        ).includes(searchValue.toLowerCase())
                      )
                    : []
                }
                filename={`${selectedGathering.title} - RSVPs ${
                  selectedGathering?.recurringTypeName != 'One Time'
                    ? `(${selectedDate})`
                    : ''
                }`}
                headers={convertTableColumnsToCsvHeaders(columnsArray)}
                className={`btn-standard btn-primary margin-left-16 ${classes.btnCustom}`}
              >
                Download RSVPs to CSV
              </CSVLink>
            </div>
            {canEditGathering() && (
              <button
                className='btn btn-standard margin-left-8'
                onClick={() =>
                  history.push(
                    `/building/${selectedGathering.buildingId}/gathering/${selectedGathering.uuid}/edit`
                  )
                }
              >
                Edit
              </button>
            )}
          </div>
        </div>
        {selectedGathering?.recurringTypeName != 'One Time' && (
          <div className='flex-row align-center'>
            <p>Start Date - </p>
            <p className='margin-left-8 color-dark-blue'>
              {moment(selectedGathering.scheduledStartTime)
                .tz(selectedGathering.buildingTimezone)
                .format('ddd, DD MMM YYYY')}
            </p>
          </div>
        )}
        {selectedGathering?.recurringTypeName != 'One Time' && (
          <div className='flex-row align-center'>
            <p>Event Start Time - </p>
            <p className='margin-left-8 color-dark-blue'>
              {moment(selectedGathering.scheduledStartTime)
                .tz(selectedGathering.buildingTimezone)
                .format('hh:mm A')}
            </p>
          </div>
        )}
        {selectedGathering?.recurringTypeName == 'One Time' && (
          <div className='flex-row margin-bottom-8'>
            <p>Event Start Date & Time - </p>
            <p className='margin-left-8'>
              {moment(selectedGathering.scheduledStartTime)
                .tz(selectedGathering.buildingTimezone)
                .format('dddd DD MMM YYYY HH:MM A')}
            </p>
          </div>
        )}
        {selectedGathering.scheduledEndTime && (
          <div className='flex-row align-center margin-right-16 margin-top-16 margin-bottom-8'>
            <p>Event Duration - </p>
            <p className='margin-left-16 color-dark-blue'>
              {getEventDuration(
                selectedGathering.scheduledStartTime!,
                selectedGathering.scheduledEndTime
              )}
            </p>
          </div>
        )}
        {selectedGathering.scheduledEndTime && (
          <div className='flex-row align-center margin-right-16 margin-top-16 margin-bottom-8'>
            <p>Overall Rating - </p>
            <p className='margin-left-16 color-dark-blue'>
              {formatAverageRating(selectedGathering.averageRating)}
            </p>
            {selectedGathering.averageRating > 0 && (
              <div className={`margin-left-8 ${classes.starsContainer}`}>
                {[...Array(3)].map((item, index) => {
                  const rating = index + 1;
                  return Math.round(selectedGathering.averageRating) >=
                    rating ? (
                    <StarOn className={classes.star} />
                  ) : (
                    <StarOff className={classes.star} />
                  );
                })}
              </div>
            )}
          </div>
        )}
        {selectedGathering?.recurringTypeName != 'One Time' && (
          <div className='flex-row align-center margin-bottom-16'>
            <p className='margin-right16'>Upcoming Events - </p>
            <div className='margin-left-8 flex-row'>
              {!moment(selectedGathering.scheduledStartTime).isBefore(
                moment()
              ) && (
                <button
                  className={`margin-right-16 cursor-pointer btn btn-small btn-secondary`}
                >
                  {moment(selectedGathering.scheduledStartTime)
                    .tz(selectedGathering?.buildingTimezone)
                    .format('dddd DD MMMM YYYY')}
                </button>
              )}
              {selectedGathering?.upcomingEvents?.slice(0, 5).map((event) => {
                if (event.scheduledStartTime) {
                  return (
                    <button
                      className={`margin-right-16 cursor-pointer btn btn-small btn-secondary`}
                    >
                      {moment(event.scheduledStartTime)
                        .tz(selectedGathering?.buildingTimezone)
                        .format('dddd DD MMMM YYYY')}
                    </button>
                  );
                }
              })}
            </div>
            {selectedGathering?.upcomingEvents.length - 6 > 0 && (
              <p> + {selectedGathering?.upcomingEvents.length - 6} more</p>
            )}
          </div>
        )}
        {selectedGathering?.recurringTypeName != 'One Time' &&
          eventDates.length != 0 && (
            <select onChange={onDateSelect}>
              <option disabled>Select Event Date</option>
              {eventDates.map((date) => (
                <option value={date}>
                  {moment(date).format('dddd DD MMMM YYYY')} - (
                  {getTotalAttendance(rsvpsByDate[date])} RSVP)
                </option>
              ))}
            </select>
          )}
        {true && (
          <div className='margin-top-8'>
            <CobuTableAdvanced
              columns={columns}
              noData={{
                label: getLabelForTable() ?? ' No RSVPs found for the gathering'
              }}
              data={
                selectedDate && rsvpsByDate[selectedDate]
                  ? rsvpsByDate[selectedDate]?.filter((g: RsvpersData) =>
                      (
                        g.user.firstName.toLowerCase() +
                        ' ' +
                        g.user.lastName.toLowerCase() +
                        ' ' +
                        g.user.email
                      ).includes(searchValue.toLowerCase())
                    )
                  : []
              }
              sort={{ id: 'createdAt', descending: false }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

function mapStateToProps(state: RootState) {
  return {
    gatherings: state.building.gatherings
  };
}
export default connect(mapStateToProps)(withFullScreenLoader(GatheringRSVPs));
