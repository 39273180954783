import React, { FC } from 'react';
import { Cell } from 'react-table';
import { Colors } from '~/enums/Colors';

interface IProps {
  mini: boolean;
  showPagination: boolean;
  centerAlignedHeaderAndContent: boolean;
  cell?: Cell<Object, any>;
  cellTextAlign:
    | 'center'
    | 'end'
    | 'justify'
    | 'left'
    | 'match-parent'
    | 'right'
    | 'start';
}

export const DragTableData: FC<IProps> = ({
  cell,
  children,
  mini,
  centerAlignedHeaderAndContent,
  cellTextAlign,
  showPagination
}) => {
  return (
    <td
      {...cell?.getCellProps()}
      style={{
        padding: mini ? '' : '24px 10px',
        fontFamily: 'Roboto',
        color: Colors.cobuDarkBlue,
        fontWeight: 500,
        fontSize: '18px',
        textAlign: centerAlignedHeaderAndContent ? 'center' : cellTextAlign,
        whiteSpace: 'nowrap',
        maxWidth: '300px',
        ...(showPagination
          ? { borderBottom: 'solid 1px rgba(39, 33, 99, 0.1)' }
          : {})
      }}
    >
      {children}
    </td>
  );
};
