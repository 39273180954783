import React, { CSSProperties } from 'react';
import classes from './OverviewCard.module.css';
import { LineItem, WidgetComponent } from '~/types/layout';
import { useHistory } from 'react-router-dom';

type OverviewCardProps = WidgetComponent<{
  content: {
    lineItems: LineItem[];
  };
  style: CSSProperties;
  count: number;
  action: {
    type: string;
    path: string;
  };
}> & {};

export const OverviewCard = (props: OverviewCardProps) => {
  const { data, action } = props;
  const history = useHistory();

  const onComponentClicked = () => {
    if (action?.type === 'navigate' && action?.path) {
      history.push(action?.path);
    }

    if (action?.type === 'external' && action?.path) {
      window.open(action?.path, '_blank')
    }
  };

  return (
    <div
      className={classes.wrapper}
      style={data.style}
      onClick={onComponentClicked}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <p className='margin-right-16'>
          {data?.content?.lineItems?.map((lineItem) => (
            <div style={lineItem.style}>{lineItem.text}</div>
          ))}
        </p>
        <span style={{ fontSize: '24px', fontWeight: 'bold' }}>
          {data.count}
        </span>
      </div>
    </div>
  );
};
