import React, { useEffect, useMemo, useState } from 'react';
import { HexColorPicker } from 'react-colorful';
import { Control, Controller, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  IPayoutTypes,
  IReferralOffer,
  IReferralPageOption,
  IReferralStatus,
  getIncentiveOptions,
  getLandingPageOptions,
  getPayoutOptions,
  getReferralOffer,
  getReferralStatusOptions,
  postReferralOffer
} from '~/api/referral';
import { ICreateReferralFormData } from '~/containers/Referral/CreateEditReferralForm/interfaces';
import {
  ReferralIntroMarkups,
  ReferralLandingPages
} from '~/containers/Referral/constants';
import { numberRegex } from '~/helpers/helpers';
import { ISelectOption } from '~/helpers/interfaces';
import { FormInputConfig } from '~/types/residentManagement';
import useGetBuildingDetails from '../useGetBuildingDetails';
import { Building } from '~/types/building';
import moment from 'moment';
import { updateBuilding } from '~/api/building';
import { UserType } from './constants';

const defaultValues = {
  bannerTitle: 'Refer a resident',
  logoBackgroundColor: 'transparent',
  landingPageTitleAction: 'Activate The $XXX Referral Bonus Now!',
  employeeLandingPageTitleAction: 'Activate The $XXX Referral Bonus Now!',
  popupDescription:
    'Submit this form to activate eligibility. Your submission goes directly to the Property Management team.',
  employeePopupDescription:
    'Submit this form to activate eligibility. Your submission goes directly to the Property Management team.',
  landingPageFormButton: 'Submit Now!',
  landingPageCongratsDescription:
    'The referral bonus eligibility is now active. Your submission has been sent from Cobu to the Property Manager.',
  employeeLandingPagecongratsDescription:
    'The referral bonus eligibility is now active. Your submission has been sent from Cobu to the Property Manager.',
  landingPageCongratsTitle: 'YAY!',
  landingPageCongratsNext:
    "What's Next? Visit the property website to schedule a tour, apply, or to contact the Property."
};

export default (buildingId: string) => {
  const [loader, setLoader] = useState(false);
  const [referralOffer, setReferralOffer] = useState<IReferralOffer | null>(
    null
  );
  const [payoutOptions, setPayoutOptions] = useState<ISelectOption[] | null>(
    []
  );
  const [incentiveOptions, setIncentiveOptions] = useState<
    ISelectOption[] | null
  >([]);

  const [landingPageOptions, setLandingPageOptions] = useState<
    ISelectOption[] | null
  >([]);
  const [referralStatusOptions, setReferralStatusOptions] = useState<
    ISelectOption[] | null
  >([]);

  const [selectedUserType, setSelectedUserType] = useState<UserType>('user');

  const buildingDetails = useGetBuildingDetails(buildingId, true);

  const propertyLogoUrl = (buildingDetails as unknown as Partial<Building>)
    ?.propertyLogoUrl as string;

  const history = useHistory();

  const {
    register,
    handleSubmit,
    errors,
    control,
    watch,
    reset,
    setValue,
    formState: { dirtyFields, isDirty }
  } = useForm<ICreateReferralFormData>({
    defaultValues: defaultValues
  });

  const backgroundColor = watch('logoBackgroundColor');
  const referralLandingPageOptionId = watch('referralLandingPageOption');
  const incentiveType = watch('incentiveType');

  useEffect(() => {
    if (referralOffer && buildingDetails) {
      const {
        cobuPayout,
        residentPayout,
        description,
        title,
        bannerTitle,
        endDate,
        startDate,
        images,
        payoutType,
        referralLandingPageOption,
        referralStatus,
        rulesAndEligibility,
        landingpageIntroParagraph,
        employeeLandingPageIntroParagraph,
        landingPageTitleAction,
        employeeLandingPageTitleAction,
        landingPageLocationBlurb,
        popupTitle,
        employeePopupTitle,
        incentiveType,
        popupDescription,
        employeePopupDescription,
        landingPageFormButton,
        landingPageCongratsDescription,
        employeeLandingPagecongratsDescription,
        landingPageCongratsTitle,
        landingPageCongratsNext
      } = referralOffer;

      const logoBackgroundColor = (
        buildingDetails as unknown as Partial<Building>
      )?.details?.logoBackgroundColor;

      setTimeout(() => {
        reset({
          cobuPayout,
          residentPayout,
          description,
          title,
          bannerTitle: bannerTitle || defaultValues.bannerTitle,
          endDate: moment.parseZone(endDate).format('YYYY-MM-DD'),
          startDate: moment.parseZone(startDate).format('YYYY-MM-DD'),
          images: images?.[0],
          payoutType,
          referralLandingPageOption: (
            referralLandingPageOption as IReferralPageOption
          ).uuid,
          referralStatus,
          rulesAndEligibility,
          incentiveType,
          landingpageIntroParagraph,
          employeeLandingPageIntroParagraph,
          logoBackgroundColor,
          landingPageLocationBlurb,
          popupTitle,
          employeePopupTitle,
          landingPageTitleAction: landingPageTitleAction,
          employeeLandingPageTitleAction: employeeLandingPageTitleAction,
          popupDescription: popupDescription || defaultValues.popupDescription,
          employeePopupDescription:
            employeePopupDescription || defaultValues.popupDescription,
          landingPageFormButton:
            landingPageFormButton || defaultValues.landingPageFormButton,
          landingPageCongratsDescription:
            landingPageCongratsDescription ||
            defaultValues.landingPageCongratsDescription,
          employeeLandingPagecongratsDescription:
            employeeLandingPagecongratsDescription ||
            defaultValues.landingPageCongratsDescription,
          landingPageCongratsTitle:
            landingPageCongratsTitle || defaultValues.landingPageCongratsTitle,
          landingPageCongratsNext:
            landingPageCongratsNext || defaultValues.landingPageCongratsNext
        });
      }, 0);
    }
  }, [referralOffer, buildingDetails]);

  useEffect(() => {
    if (landingPageOptions?.length) {
      setValue(
        'referralLandingPageOption',
        referralOffer?.referralLandingPageOption || landingPageOptions[0].value
      );
    }
  }, [landingPageOptions, referralOffer]);

  useEffect(() => {
    if (incentiveOptions?.length) {
      const singleSide = incentiveOptions.find((option) =>
        option.label.toLocaleLowerCase().includes('single')
      );

      if (singleSide) {
        setValue(
          'incentiveType',
          referralOffer?.incentiveType || singleSide.value
        );
      }
    }
  }, [incentiveOptions, referralOffer]);

  useEffect(() => {
    if (incentiveType && incentiveOptions) {
      if (referralOffer?.landingPageTitleAction) {
        return;
      }

      const selectedOption = incentiveOptions.find(
        (option) => option.value === incentiveType
      );

      if (selectedOption) {
        const isSingleSide = selectedOption
          ? selectedOption.label.toLocaleLowerCase().includes('single')
          : false;

        setValue(
          'landingPageTitleAction',
          isSingleSide
            ? defaultValues.landingPageTitleAction
            : 'Activate $XXX for You & $XXX For Your Referrer Now'
        );

        setValue(
          'employeeLandingPageTitleAction',
          isSingleSide
            ? defaultValues.landingPageTitleAction
            : 'Activate $XXX for You & $XXX For Your Referrer Now'
        );
      }
    }
  }, [
    incentiveType,
    referralOffer,
    incentiveOptions,
    referralLandingPageOptionId
  ]);

  const fetchReferralOffer = async () => {
    try {
      setLoader(true);

      const res = await getReferralOffer(buildingId);

      if (!!res.data) {
        setReferralOffer(res.data);
      }
      setLoader(false);
    } catch (e) {
      setLoader(false);
    }
  };

  const postReferralData = async (formData: ICreateReferralFormData) => {
    const { logoBackgroundColor, ...referralOfferData } = formData;

    try {
      setLoader(true);

      const referralFormData = new FormData();

      Object.entries(referralOfferData).forEach(([key, value]) => {
        if (typeof value === 'string') {
          referralFormData.append(key, value);
        }
      });

      referralFormData.append('buildingId', buildingId);
      referralOfferData.images &&
        referralFormData.append('images', referralOfferData.images);

      await postReferralOffer(referralFormData);

      logoBackgroundColor &&
        (await updateBuilding(
          { details: JSON.stringify({ logoBackgroundColor }) },
          buildingId
        ));

      toast('Referral offer pushed successfully');
      fetchReferralOffer();

      setLoader(false);
    } catch (e) {
      setLoader(false);
    }
  };

  const fetchPayoutOptions = async () => {
    try {
      const res = await getPayoutOptions();

      const options: ISelectOption[] = res.data.map(
        (payoutType: IPayoutTypes) => {
          return { value: payoutType.uuid, label: payoutType.name };
        }
      );

      setPayoutOptions(options);
    } catch (e) {}
  };

  const fetchIncentiveOptions = async () => {
    try {
      const res = await getIncentiveOptions();

      const options: ISelectOption[] = res.data.map(
        (payoutType: IPayoutTypes) => {
          return { value: payoutType.uuid, label: payoutType.name };
        }
      );

      setIncentiveOptions(options);
    } catch (e) {}
  };

  const fetchLandingPageOptions = async () => {
    try {
      const res = await getLandingPageOptions();

      const options: ISelectOption[] = res.data.map(
        (payoutType: IPayoutTypes) => {
          return { value: payoutType.uuid, label: payoutType.name };
        }
      );

      setLandingPageOptions(options);
    } catch (e) {}
  };

  const fetchReferralStatusOptions = async () => {
    try {
      const res = await getReferralStatusOptions();

      const options: ISelectOption[] = res.data.map(
        (payoutType: IReferralStatus) => {
          return { value: payoutType.uuid, label: payoutType.name };
        }
      );

      setReferralStatusOptions(options);
    } catch (e) {}
  };

  useEffect(() => {
    Promise.all([
      fetchPayoutOptions(),
      fetchReferralStatusOptions(),
      fetchLandingPageOptions(),
      fetchIncentiveOptions()
    ]).then(() => {
      fetchReferralOffer();
    });
  }, []);

  const createReferralFormConfig: FormInputConfig[] = [
    {
      label: 'Landing page option',
      id: 'referralLandingPageOption',
      inputType: 'select',
      selectOptions: landingPageOptions as ISelectOption[],
      isRequired: true,
      placeholder: 'Select landing page option'
    },
    {
      label: 'Referral Banner Title',
      id: 'bannerTitle',
      isRequired: true,
      placeholder: 'Enter Referral Banner Title'
    },
    {
      label: 'Referral Title',
      id: 'title',
      isRequired: true,
      placeholder: 'Enter Referral Title'
    },
    {
      label: 'Referral Description',
      id: 'description',
      isRequired: true,
      inputType: 'textarea',
      placeholder: 'Enter Referral Description'
    },
    {
      label: 'Resident Payout',
      id: 'residentPayout',
      pattern: numberRegex(),
      patternErrorMsg: 'Oops! Please enter a valid number',
      isRequired: true,
      placeholder: 'Enter Resident Payout'
    },
    {
      label: 'Cobu Payout',
      id: 'cobuPayout',
      pattern: numberRegex(),
      patternErrorMsg: 'Oops! Please enter a valid number',
      isRequired: true,
      placeholder: 'Enter Cobu Payout'
    },
    {
      label: 'Payout Type',
      id: 'payoutType',
      inputType: 'select',
      selectOptions: payoutOptions as ISelectOption[],
      isRequired: true,
      placeholder: 'Enter Cobu Payout'
    },
    {
      label: 'Incentive Type',
      id: 'incentiveType',
      inputType: 'select',
      selectOptions: incentiveOptions as ISelectOption[],
      isRequired: false,
      placeholder: 'Enter Incentive Type'
    },
    ...((referralOffer
      ? [
          {
            label: 'Referral Status',
            id: 'referralStatus',
            inputType: 'select',
            selectOptions: referralStatusOptions as ISelectOption[],
            isRequired: true,
            placeholder: 'Enter Cobu Referral Status'
          }
        ]
      : []) as FormInputConfig[]),
    {
      label: 'Rules and Eligibility',
      id: 'rulesAndEligibility',
      inputType: 'textarea',
      isRequired: true,
      placeholder: 'Enter Rules and Eligibility'
    },
    {
      label: 'Start Date',
      id: 'startDate',
      inputType: 'date',
      isRequired: true
    },
    {
      label: 'End Date',
      id: 'endDate',
      inputType: 'date',
      isRequired: true
    },
    {
      label: 'Referral Image',
      id: 'images',
      inputType: 'imageDropzone',
      isRequired: true,
      placeholder: 'Select Referral Image',
      styles: {
        lableStyles: { marginBottom: '10px' }
      }
    },
    {
      label: 'Logo background color',
      id: 'logoBackgroundColor',
      inputType: 'custom',
      styles: {
        lableStyles: { marginBottom: '10px' }
      },
      sibling: (
        <input
          type='text'
          name='logoBackgroundColor'
          defaultValue={backgroundColor as unknown as string}
          ref={register()}
        />
      ),
      customComponent: (
        <div style={{ display: 'flex', flexDirection: 'row', gap: '0 20px' }}>
          {propertyLogoUrl ? (
            <img
              width={100}
              height={100}
              src={propertyLogoUrl}
              style={{
                backgroundColor: backgroundColor as unknown as string,
                padding: 50,
                borderWidth: 1,
                borderStyle: 'dotted',
                borderColor: 'black'
              }}
            />
          ) : (
            <button
              onClick={() => {
                history.push(
                  `/building/${buildingId}/edit?showEditSetting=true`
                );
              }}
              className='btn btn-standard btn-primary'
            >
              Add Building Logo
            </button>
          )}
          <Controller
            name={'logoBackgroundColor'}
            defaultValue={backgroundColor as unknown as string}
            control={control}
            render={({ onChange }) => {
              return (
                <HexColorPicker
                  color={backgroundColor as string}
                  onChange={(color) => {
                    onChange(color);
                  }}
                />
              );
            }}
          />
        </div>
      ),
      isRequired: false,
      placeholder: 'Select Referral Image background'
    }
  ];

  const selectedReferralOption: ISelectOption | undefined =
    landingPageOptions?.find(
      (option) => option.value === referralLandingPageOptionId
    );

  const employeePageOptionBasedFormFileds: FormInputConfig[] = useMemo(
    () => [
      {
        label: 'Employee Page Action title',
        id: 'employeeLandingPageTitleAction',
        inputType: 'textarea',
        isRequired: false,
        placeholder: 'eg: claim your $500 rent credit'
      },
      {
        label: `Employee Landing Page Intro(For sharable link) :: ${ReferralIntroMarkups.referredBy} ${ReferralIntroMarkups.buildingName}`,
        id: 'employeeLandingPageIntroParagraph',
        inputType: 'textarea',
        isRequired: false,
        placeholder: `Please enter page intro. Allowed markups: ${ReferralIntroMarkups.referredBy} ${ReferralIntroMarkups.buildingName}`
      },
      {
        label: 'Employee Popup title',
        id: 'employeePopupTitle',
        isRequired: false,
        placeholder: "eg: Don't miss out on your rent credit"
      },
      {
        label: 'Employee Popup description',
        id: 'employeePopupDescription',
        isRequired: false,
        placeholder:
          'eg: Fill out the form below to make sure that you are added to our eligible list!'
      },
      {
        label: 'Employee Congrats description',
        id: 'employeeLandingPagecongratsDescription',
        isRequired: false,
        placeholder:
          'eg: you have successfully claimed your eligibility for the $500 rent credit!'
      }
    ],
    []
  );

  const pageOptionBasedFormFileds: FormInputConfig[] = useMemo(
    () => [
      {
        label: 'Page Action title',
        id: 'landingPageTitleAction',
        inputType: 'textarea',
        isRequired: true,
        placeholder: 'eg: claim your $500 rent credit'
      },
      {
        label: `Landing Page Intro(For sharable link) :: ${ReferralIntroMarkups.referredBy} ${ReferralIntroMarkups.buildingName}`,
        id: 'landingpageIntroParagraph',
        inputType: 'textarea',
        isRequired: false,
        placeholder: `Please enter page intro. Allowed markups: ${ReferralIntroMarkups.referredBy} ${ReferralIntroMarkups.buildingName}`
      },
      {
        label: 'Popup title',
        id: 'popupTitle',
        isRequired: true,
        placeholder: "eg: Don't miss out on your rent credit"
      },
      {
        label: 'Popup description',
        id: 'popupDescription',
        isRequired: true,
        placeholder:
          'eg: Fill out the form below to make sure that you are added to our eligible list!'
      },
      {
        label: 'Congrats description',
        id: 'landingPageCongratsDescription',
        isRequired: true,
        placeholder:
          'eg: you have successfully claimed your eligibility for the $500 rent credit!'
      }
    ],
    []
  );

  const pageOptionBasedDefaultFormFileds: FormInputConfig[] = useMemo(
    () => [
      {
        label: 'Location blurb',
        id: 'landingPageLocationBlurb',
        inputType: 'textarea',
        isRequired: true,
        placeholder: 'eg: located 11 minutes from downtown'
      },
      {
        label: 'Congrats next description',
        id: 'landingPageCongratsNext',
        isRequired: true,
        inputType: 'textarea',
        placeholder:
          "eg: What's next? one of our leading agents should be reaching out to you shortly"
      },
      {
        label: 'Congrats title',
        id: 'landingPageCongratsTitle',
        isRequired: true,
        placeholder: 'eg: Congratulations!'
      },
      {
        label: 'Submit form button',
        id: 'landingPageFormButton',
        isRequired: true,
        placeholder: 'eg: claim rent credit!'
      }
    ],
    []
  );

  return {
    loader,
    referralOffer,
    postReferralData,
    createReferralFormConfig,
    register,
    handleSubmit,
    errors,
    control,
    reset,
    isDirty,
    dirtyFields,
    selectedReferralOptionName: selectedReferralOption?.label,
    pageOptionBasedFormFileds,
    employeePageOptionBasedFormFileds,
    pageOptionBasedDefaultFormFileds,
    selectedUserType,
    setSelectedUserType
  };
};
