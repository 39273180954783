import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { getAllGatheringEventsWithFlyer } from '~/api/gatherings';
import { saveEventsWithFlyers } from '~/reducers/gathering';
import { RootState } from '~/redux/store';

export default () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const eventsWithFlyer = useSelector(
    (state: RootState) => state.gathering.eventsWithFlyer
  );

  const fetchEventsWithFlyers = async () => {
    try {
      setLoading(true);
      const res = await getAllGatheringEventsWithFlyer();

      setLoading(false);
      dispatch(saveEventsWithFlyers(res.data));
    } catch (e) {
      setLoading(false);
      toast(e.message || 'Error fetching Events with flyers');
    }
  };

  useEffect(() => {
    fetchEventsWithFlyers();
  }, []);

  return { eventsWithFlyer, loading, fetchEventsWithFlyers };
};
