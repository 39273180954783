import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import _ from 'lodash';
import moment from 'moment';
import momentTimezone from 'moment-timezone';
import './ScheduleCommentModal.css';
import { createGroupEventComment } from '../../actions/GroupEventCommentActions';
import {
  updateScheduledGroupComment,
  createGroupComment
} from '../../actions/GroupCommentActions';
import ScheduleCommentForm from '../ScheduleCommentForm/ScheduleCommentForm';
import { withFullScreenLoader } from '~/hocs/withFullScreenLoader';
import { useFullScreenLoader } from '~/hooks/useFullScreenLoader';
import { useCobuDispatch } from '~/hooks/useCobuDispatch';
import { useCobuSelector } from '~/hooks/useCobuSelector';
import { getGroups } from '../../actions/GroupActions';

const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png', 'string'];
const ScheduleCommentSchema = Yup.object().shape({
  building: Yup.string().required('Required'),
  group: Yup.string().nullable(true),
  creatorEmail: Yup.string().email('Invalid email').required('Required'),
  postTime: Yup.date()
    .min(moment(), 'This date has already occured')
    .max(moment().add(1, 'y'), 'This date is too far in the future')
});

const ScheduleCommentModal = (props) => {
  const { loader } = useFullScreenLoader();
  const dispatch = useCobuDispatch();
  const { buildings, groups, groupEvents, targetComment } = useCobuSelector(
    (state) => {
      const { building, groups, groupEvents } = state;
      return {
        buildings: building.buildings,
        groups: groups.groups,
        groupEvents: groupEvents.groupEvents
      };
    }
  );

  const handleCreateComment = (values) => {
    const { closeForm } = props;
    const data = new FormData();
    data.append('building', values.building);
    data.append('creatorEmail', values.creatorEmail);
    data.append('group', values.group);
    if (values.groupEvent) data.append('groupEvent', values.groupEvent);
    if (values.contents) data.append('contents', values.contents.trim());
    if (values.postTime) {
      const selectedBuilding = Object.values(props.buildings).filter(
        (b) => b.uuid === values.building
      )[0];
      data.append(
        'postTime',
        formatTime(selectedBuilding, values.postTime, 'formData')
      );
    }
    if (
      !_.isEmpty(values.image) &&
      SUPPORTED_FORMATS.includes(values.image.type)
    ) {
      data.append('images', values.image);
    } else if (
      !_.isEmpty(values.image) &&
      !SUPPORTED_FORMATS.includes(values.image.type)
    ) {
      alert('Unsupported image format');
      return;
    }

    loader.show();

    if (props.targetComment) {
      data.append('uuid', props.targetComment.uuid);
      dispatch(
        updateScheduledGroupComment(data, props.targetComment.uuid)
      ).finally(() => {
        loader.hide();
        closeForm();
      });
    } else if (values.groupEvent)
      dispatch(createGroupEventComment(data, !!values.postTime)).finally(() => {
        loader.hide();
        closeForm();
      });
    else {
      dispatch(createGroupComment(data, !!values.postTime)).finally(() => {
        loader.hide();
        closeForm();
      });
    }

    closeForm();
  };

  const formatTime = (building, startTime, formatType) => {
    if (formatType === 'initialValue') {
      return momentTimezone
        .tz(startTime, building.timezone)
        .format('YYYY-MM-DDTHH:mm');
    }
    if (formatType === 'formData') {
      return moment.tz(startTime, building.timezone).format();
    }
    return startTime;
  };

  let initialValues;
  if (!targetComment) {
    initialValues = {
      building: '',
      group: '',
      groupEvent: '',
      creatorEmail: '',
      postTime: '',
      contents: '',
      image: null
    };
  } else {
    const {
      building,
      group,
      user,
      groupEvent,
      postTime,
      contents,
      images
    } = targetComment;
    initialValues = {
      building: building.uuid,
      group,
      groupEvent,
      creatorEmail: user.email,
      postTime: formatTime(building, postTime, 'initialValue'),
      contents,
      image: images.length ? { preview: images[0].image, type: 'string' } : null
    };
  }

  return (
    <div className='create-group-event-container group-modal-content'>
      <Formik
        initialValues={initialValues}
        validationSchema={ScheduleCommentSchema}
        onSubmit={(values) => handleCreateComment(values)}
      >
        {(formikProps) => (
          <ScheduleCommentForm
            {...formikProps}
            editMode={props.targetComment}
            selectOptions={{ buildings, groups, groupEvents }}
            getGroups={() => dispatch(getGroups)}
          />
        )}
      </Formik>
    </div>
  );
};

export default withFullScreenLoader(ScheduleCommentModal);
