import React, { useEffect, useRef, useState } from 'react';
import GenericModal from '~/components/GenericModal/GenericModal';
import { uploadAndParseCsvForRentRoll } from '~/api/resident';
import { useFullScreenLoader } from '~/hooks/useFullScreenLoader';
import { toast } from 'react-toastify';
import { ReactComponent as FileUploadIcon } from '~/utils/images/file-upload-icon.svg';
import classes from './UploadRentRoll.module.css';
import { AxiosError } from 'axios';

type UploadRentRollCsvProps = {
  onUpload: (rows: any) => void;
  isVisible: boolean;
  onClose: () => void;
  title: string;
};

const UploadRentRollCsv = (props: UploadRentRollCsvProps) => {
  const [selectedFile, setselectedFile] = useState<File>();
  const { loader } = useFullScreenLoader();
  const inputRef = useRef();

  const uploadFile = () => {
    const form = new FormData();

    if (!selectedFile) {
      return toast.error('Please select a file to upload first');
    }

    form.append('file', selectedFile as File, 'file');

    loader.show();

    uploadAndParseCsvForRentRoll(form)
      .then((res: any) => {
        props.onUpload(res.data);
      })
      .catch((e) => {
        const error = e as AxiosError;
        toast.error(error.message);
      })
      .finally(() => {
        setselectedFile(undefined);
        loader.hide();
      });
  };

  useEffect(() => {
    if (props.isVisible) {
      setselectedFile(undefined);
    }
  }, [props.isVisible]);

  return (
    <GenericModal
      showModal={props.isVisible}
      onChange={props.onClose}
      title={props.title}
      content={
        <div>
          <p>Select File</p>
          <div
            className={`flex justify-center align-center ${classes.fileUploadWrapper}`}
          >
            <FileUploadIcon />
            <p className='margin-top-32' style={{ fontSize: '18px' }}>
              {selectedFile?.name ? (
                selectedFile.name
              ) : (
                <div>
                  Drop your file here or{' '}
                  <span
                    style={{ textDecoration: 'underline', fontSize: '18px' }}
                  >
                    browse
                  </span>
                  .
                </div>
              )}
            </p>
            <input
              type='file'
              accept='.csv,.xlxs,.xls,.xlsx'
              ref={inputRef as any}
              className={classes.sheetFileinput}
              onChange={(e) => setselectedFile(e?.target?.files![0])}
            />
          </div>
        </div>
      }
      actionText='Save'
      actionFunction={uploadFile}
    ></GenericModal>
  );
};

export default UploadRentRollCsv;
