import React from 'react';
import { ReactComponent as StarOff } from '~/utils/images/starOff.svg';
import { ReactComponent as StarOn } from '~/utils/images/starOn.svg';

interface IProps {
  value: number;
  small?: boolean;
}
const RatingDisplayed = (props: IProps) => {
  const { value, small } = props;
  return (
    <div>
      {[...Array(5)].map((item, index) => {
        return index + 1 <= value ? (
          <StarOn
            key={index}
            height={small ? 16 : 32}
            width={small ? 16 : 32}
          />
        ) : (
          <StarOff
            key={index}
            height={small ? 16 : 32}
            width={small ? 16 : 32}
          />
        );
      })}
    </div>
  );
};

export default RatingDisplayed;
