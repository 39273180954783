import React, { FC } from 'react';
import { Column } from 'react-table';
import CobuTable from '~/components/CobuTable/CobuTable';
import { toTitleCase } from '~/utils/stringUtils';
import * as _ from 'lodash';

interface IProps {
  title?: string;
  isTableView?: boolean;
  isHorizontal?: boolean;
  sections: Record<any, string | number | Record<string, string>>[];
  tableColumn?: number;
  unitString?: string;
}

export const HelloDataView: FC<IProps> = ({
  title,
  isTableView = false,
  isHorizontal = true,
  sections,
  tableColumn = 5,
  unitString
}) => {
  return (
    <div>
      {title && <h2 className='margin-bottom-16'>{title}</h2>}
      <div>
        {sections.map((section) => {
          if (isTableView) {
            const columnConfig = (() => {
              const config: Column<any>[] = [];

              Object.keys(section).forEach((key) => {
                config.push({
                  Header: key.toUpperCase(),
                  accessor: key,
                  ...(unitString
                    ? {
                        Cell: (cell) => {
                          const isPercentage = unitString === '%';
                          return cell.value
                            ? `${
                                isPercentage
                                  ? Math.round(cell.value * 100)
                                  : cell.value
                              } ${unitString}`
                            : 'Not Available';
                        }
                      }
                    : {})
                });
              });

              return config;
            })();

            const tables = _.chunk(columnConfig, tableColumn);

            return tables.map((table) => (
              <CobuTable columns={table} data={[section]} />
            ));
          }

          const titles = Object.keys(section);
          return titles.map((title) => {
            let value = section[title];
            const isBoolean = typeof value === 'boolean';

            value = isBoolean ? `${value ? 'TRUE' : 'FALSE'}` : value;

            return (
              <div
                style={{
                  display: 'flex',
                  flexDirection: isHorizontal ? 'row' : 'column',
                  alignItems: isHorizontal ? 'center' : 'flex-start',
                  gap: '0 20px'
                }}
              >
                <h3>{`${toTitleCase(title)} : `}</h3>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap'
                  }}
                  className='semi-bold font-18'
                >
                  {value
                    ? unitString
                      ? `${value} ${unitString ?? ''}`
                      : value
                    : 'Not Available'}
                </div>
              </div>
            );
          });
        })}
      </div>
    </div>
  );
};
