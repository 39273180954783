import { useEffect, useState } from "react"
import { toast } from "react-toastify"
import { updateGroupComment } from "~/api/comment"
import { getAllPinnedPosts } from "~/api/postManagement"
import CobuConstants from "~/helpers/cobuConstants"
import { PinPost } from "~/types/postComment"



export default () => {

    const [pinPosts, setPinPosts] = useState<PinPost[]>([])
    const [loadingConfig, setLoadingConfig] = useState<{ flag: boolean, comment?: string }>({
        flag: false,
        comment: ''
    })

    useEffect(() => {
        getPinnedPosts()
    }, [])

    const getPinnedPosts = async () => {
        setLoadingConfig({ flag: true, comment: CobuConstants.pinnedPostManagement.fetchingPinnedPosts })
        try {
            const allPinnedPostsResponse = await getAllPinnedPosts()
            setLoadingConfig({ flag: false })
            setPinPosts(allPinnedPostsResponse.data)
        } catch (error) {
            setLoadingConfig({ flag: false })
            if (error) {
                toast(error?.message)
            }
        }
    }

    const unPinComment = async (comment: PinPost) => {
        setLoadingConfig({ flag: true, comment: CobuConstants.pinnedPostManagement.unPinningPost })
        try {
            let commentBody = {
                uuid: comment.uuid,
                pinComment: false,
            };
            const updateResp = await updateGroupComment(commentBody)
            setLoadingConfig({ flag: false })
            setPinPosts(pinPosts.filter(post => post.uuid != comment.uuid))
            toast(CobuConstants.pinnedPostManagement.unpinPostSuccess)
        } catch (error) {
            setLoadingConfig({ flag: false })
            if (error) {
                toast(error?.message)
            }
        }
    }


    return { pinPosts, loadingConfig, unPinComment }
}