import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { getAllActiveQueues } from "~/api/queue";
import CobuConstants from "~/helpers/cobuConstants";
import { Queue } from "~/types/queues";

export default () => {
    const [activeQueues, setActiveQueues] = useState<Queue[]>([]);
    const [loadingConfig, setLoadingConfig] = useState<{ flag: boolean, comment?: string }>({
        flag: false,
        comment: ''
    });

    useEffect(() => {
        fetchQueues();
    }, []);

    const fetchQueues = async (refresh: boolean = false) => {
        setLoadingConfig({
            flag: true, comment: refresh ? CobuConstants.activeQueues.refreshingActiveQueues
                : CobuConstants.activeQueues.fetchingActiveQueues
        });
        try {
            const activeQueueResp = await getAllActiveQueues();
            console.log(activeQueueResp.data)
            setActiveQueues(activeQueueResp.data);
            setLoadingConfig({ flag: false });
        } catch (error) {
            setLoadingConfig({ flag: false });
            if (error) {
                toast(error?.message);
            }
        }
    };



    return { activeQueues, loadingConfig, fetchQueues };
};