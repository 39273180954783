import React from 'react';
import { CellProps, Column } from 'react-table';
import buttonStyle from '../GatheringsTable/GatheringTable.styles';
import CobuTable from '../CobuTable/CobuTable';
import { useHistory } from 'react-router-dom';
import { AdminType, AdminUser } from '~/types/admin';
import CobuSelect from '../CobuSelect/CobuSelect';
import { Roles } from '~/enums/Roles';
import { SelectType } from '~/enums/SelectType';
import { SelectedOptionType } from '~/types/form';

interface IProps {
  data: AdminType[];
  onAdminRoleChange: (user: AdminUser, role: string) => void;
  loggedInUserUuid: string;
  onDetailsClick: (userId: string) => void;
}
const AdminTable = (props: IProps) => {
  const history = useHistory();
  const { data, onAdminRoleChange, onDetailsClick } = props;
  const roles = [
    { label: Roles.Vcm, value: Roles.Vcm },
    { label: Roles.Superadmin, value: Roles.Superadmin },
    { label: Roles.Pm, value: Roles.Pm },
    { label: Roles.Resident, value: Roles.Resident }
  ];
  const tableData = React.useMemo(() => data, [data]);
  const columns = React.useMemo(
    () =>
      [
        {
          Header: 'FIRST NAME',
          accessor: 'user.firstName',
          Cell: (cell: CellProps<AdminType>) => (
            <div
              style={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap'
              }}
            >
              {cell.row.original.user.firstName}
            </div>
          )
        },
        {
          Header: 'LAST NAME',
          accessor: 'user.lastName',
          Cell: (cell: CellProps<AdminType>) => (
            <div
              style={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap'
              }}
            >
              {cell.row.original.user.lastName}
            </div>
          )
        },
        {
          Header: 'EMAIL',
          accessor: 'user.email',
          Cell: (cell: CellProps<AdminType>) => (
            <div
              style={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap'
              }}
            >
              {cell.row.original.user.email}
            </div>
          )
        },
        {
          Header: 'BUILDING',
          accessor: 'userBuilding.name',
          Cell: (cell: CellProps<AdminType>) => (
            <div
              style={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap'
              }}
            >
              {cell.row.original.userBuilding.name}
            </div>
          )
        },
        {
          Header: 'ROLE',
          accesor: 'role',
          Cell: (cell: CellProps<AdminType>) => {
            const optionsWithoutDefault = roles.filter(
              (role) => role.value !== cell.row.original.role
            );
            return (
              <CobuSelect
                options={optionsWithoutDefault}
                onChange={(selectedOption: SelectedOptionType | null) => {
                  onAdminRoleChange(
                    cell.row.original.user,
                    selectedOption!.value
                  );
                }}
                defaultValue={{
                  label: cell.row.original.role,
                  value: cell.row.original.role
                }}
                isSearchable={false}
                selectType={SelectType.Table}
                disabled={
                  cell.row.original.user.uuid === props.loggedInUserUuid
                }
              />
            );
          }
        },
        {
          id: 'details',
          Cell: (cell: CellProps<AdminType>) => (
            <div
              style={buttonStyle as React.CSSProperties}
              className={`pointer`}
              onClick={() => onDetailsClick(cell.row.original.user.uuid)}
            >
              DETAILS
            </div>
          )
        }
      ] as Column<AdminType>[],
    [history, data]
  );

  return (
    <div>
      {tableData.length > 0 && (
        <CobuTable
          columns={[...columns]}
          data={tableData}
          sort={{ id: 'user.lastName', descending: false }}
        />
      )}
    </div>
  );
};

export default AdminTable;
