import { FormInputConfig } from '~/types/residentManagement';

export const entrataPMSFormConfig: FormInputConfig[] = [
  {
    label: 'Domain',
    inputType: 'text',
    id: 'entrata_domain',
    isRequired: true
  },
  {
    label: 'Username',
    inputType: 'text',
    id: 'entrata_username',
    isRequired: true
  },
  {
    label: 'Password',
    inputType: 'text',
    id: 'entrata_password',
    isRequired: true
  },
  {
    label: 'Property ID',
    inputType: 'text',
    id: 'entrata_property_id',
    isRequired: true
  },
  {
    label: 'Lead source ID (Optional)',
    inputType: 'text',
    id: 'entrataLeadSourceId',
    isRequired: false
  }
];
