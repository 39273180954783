import React, { FC, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormField } from '~/components/FormField';
import { IEventWithFlyer, IGatheringFlyer } from '~/types/gathering';
import { FormInputConfig } from '~/types/residentManagement';
import './EditFlyerForm.css';
import useEditFlyer from '~/hooks/building/flyer/useEditFlyer';
import Spinner from '~/components/Spinner/Spinner';
import { IFlyerFormData } from './interfaces';
import { ReactComponent as Sync } from '~/utils/images/sync.svg';
import Axios from 'axios';
import { toast } from 'react-toastify';
import { ISelectOption } from '~/helpers/interfaces';
import { getPdfDescriptionBoundData } from '~/helpers/flyers';

interface IProps {
  flyerTemplatOptions: ISelectOption[];
  flyerData: IGatheringFlyer;
  eventData: IEventWithFlyer;
  onCancel: () => void;
  fetchEventsWithFlyers: (flyerUuid?: string) => void;
}

const fetchImageFromUnsplash = async (
  queryText: string,
  pageNumber: number
) => {
  if (!queryText) {
    return '';
  }

  const res = await Axios.get('https://api.unsplash.com/search/photos', {
    params: {
      page: pageNumber,
      query: queryText,
      client_id: 'OrzxywoIGbDIGC4HknCuZIE8DmDF2U3TQK4flduppGg',
      per_page: 1
    }
  });

  return res.data.results.length > 0 ? res.data.results[0].urls.raw : '';
};

const getRefetchSiblingProps = (onClick: () => void) => {
  return {
    sibling: (
      <div style={{ cursor: 'pointer' }} onClick={onClick}>
        <Sync />
      </div>
    ),
    styles: {
      contentStyles: { display: 'flex', alignItems: 'center', gap: '0 5px' },
      inputStyles: { flex: 1 }
    }
  };
};

export const EditFlyerForm: FC<IProps> = ({
  flyerData,
  onCancel,
  eventData,
  flyerTemplatOptions,
  fetchEventsWithFlyers
}) => {
  const {
    register,
    handleSubmit,
    errors,
    control,
    reset,
    watch,
    setValue,
    formState: { dirtyFields, isDirty }
  } = useForm<IFlyerFormData>({
    defaultValues: flyerData
  });

  const pageNumber = useRef(2);

  const { loader, patchEventFlyer } = useEditFlyer();

  const watchKeyword = watch('flyerDetails.keyword');
  const watchFlyerHeading = watch('flyerHeading');
  const watchDescription = watch('flyerDescription');

  useEffect(() => {
    pageNumber.current = 2;
  }, [watchKeyword]);

  function getDirtyValues(allValues: IFlyerFormData): IFlyerFormData {
    if (isDirty && Array.isArray(dirtyFields)) return allValues;

    //@ts-ignore
    return Object.keys(allValues).reduce((prev, next) => {
      //@ts-ignore
      if (dirtyFields[next]) {
        //@ts-ignore
        prev[next] = allValues[next];
      }

      return prev;
    }, {});
  }

  const onSubmit = async (data: IFlyerFormData) => {
    if (!isDirty) {
      toast('Please edit to save new data');

      return;
    }

    const formData = getDirtyValues(data);

    await patchEventFlyer(flyerData.flyerUuid, formData, onCancel);

    fetchEventsWithFlyers();
  };

  const generateField = (fieldConfig: FormInputConfig) => {
    return (
      <div key={fieldConfig.id}>
        <div className='item'>
          {!!fieldConfig?.label && (
            <label
              style={fieldConfig?.styles?.lableStyles}
              className={`${fieldConfig?.styles?.lableClassName}`}
              htmlFor='title'
            >
              {fieldConfig.label}
            </label>
          )}
          <FormField
            fieldConfig={fieldConfig}
            errors={errors}
            register={register}
            control={control}
          />
        </div>
      </div>
    );
  };

  const isPdfFlyer = flyerData.flyerTemplateType === 'PDF Flyer';

  const { descriptionLength, allowedCharacters } = getPdfDescriptionBoundData(
    watchDescription as string,
    watchFlyerHeading as string
  );

  const formConfig: FormInputConfig[] = [
    {
      label: 'Flyer Heading',
      inputType: 'textarea',
      id: 'flyerHeading' as keyof IGatheringFlyer,
      placeholder: 'Enter flyer Heading',
      styles: { inputStyles: { maxHeight: '40px', minHeight: 'unset' } }
    },
    {
      label: 'Flyer Sub Heading',
      inputType: 'textarea',
      id: 'flyerSubHeading' as keyof IGatheringFlyer,
      placeholder: 'Enter Flyer Sub Heading',
      styles: { inputStyles: { maxHeight: '40px', minHeight: 'unset' } }
    },
    {
      label: 'Flyer Description',
      inputType: 'textarea',
      id: 'flyerDescription' as keyof IGatheringFlyer,
      placeholder: 'Enter Flyer Description',
      styles: { contentStyles: { display: 'flex', flexDirection: 'column' } },
      sibling: isPdfFlyer ? (
        <div>{`Description characters: ${descriptionLength}, Max: ${allowedCharacters}`}</div>
      ) : null
      // validations: (description) => {
      //   if (isPdfFlyer) {
      //     return descriptionLength < allowedCharacters
      //       ? true
      //       : `Please enter description under ${allowedCharacters} charaters`;
      //   } else {
      //     return true;
      //   }
      // }
    },
    ...((!!flyerTemplatOptions
      ? [
          {
            label: 'Flyer Template',
            inputType: 'select',
            selectOptions: flyerTemplatOptions,
            id: 'flyerTemplateUuid' as keyof IGatheringFlyer,
            placeholder: 'Enter Flyer Description'
          }
        ]
      : []) as FormInputConfig[])
  ];

  const populateEventData = () => {
    const { title, description } = eventData;

    setValue('flyerHeading', title, { shouldDirty: true });
    setValue('flyerDescription', description, { shouldDirty: true });
  };

  return (
    <div className='float-spinner-parent'>
      {loader && <Spinner float />}
      <form id='form-container' onSubmit={handleSubmit(onSubmit)}>
        <div className='grid'>
          {formConfig.map((field) => generateField(field))}
        </div>
        <button
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '0 10px',
            margin: '20px 0 25px 0'
          }}
          className='btn-standard btn-secondary'
          onClick={(event) => {
            event.preventDefault();
            populateEventData();
          }}
        >
          {'Populate Heading and Description from Event'}
          <Sync />
        </button>
        {generateField({
          label: 'Flyer Background Image keyword',
          id: 'flyerDetails.keyword' as keyof IGatheringFlyer,
          placeholder: 'Seach keyword',
          ...getRefetchSiblingProps(async () => {
            const newBgImageUrl = await fetchImageFromUnsplash(
              watchKeyword as string,
              pageNumber.current
            );

            setValue('flyerBgImage', newBgImageUrl, { shouldDirty: true });

            pageNumber.current += 1;
          })
        })}
        {generateField({
          label: 'Flyer Background Image',
          inputType: 'imageDropzone',
          id: 'flyerBgImage' as keyof IGatheringFlyer,
          placeholder: 'Choose Flyer Background Image',
          styles: { lableStyles: { margin: '10px 0' } }
        })}
        {!flyerData.flyerLogo &&
          generateField({
            label: 'Flyer Building Logo',
            id: 'flyerLogo' as keyof IGatheringFlyer,
            inputType: 'imageDropzone',
            placeholder: 'Select Flyer Building Logo',
            styles: { lableStyles: { margin: '15px 0' } }
          })}
        <div className='formButtons'>
          <button
            className='btn-standard btn-secondary'
            onClick={(event) => {
              event.preventDefault();
              reset();
            }}
          >
            {'Reset'}
          </button>
          <button
            className='btn-standard btn-secondary margin-left-16'
            onClick={onCancel}
          >
            {'Cancel'}
          </button>
          <button
            className='btn-standard btn-primary margin-left-16 '
            type='submit'
          >
            {'Save'}
          </button>
        </div>
      </form>
    </div>
  );
};
