import React from 'react';
import Spinner from '../Spinner/Spinner';
import { useFullScreenLoader } from '~/hooks/useFullScreenLoader';

const FullScreenLoader = () => {
  const { isVisible } = useFullScreenLoader();

  if (!isVisible) return null;

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'fixed',
        width: '100%',
        height: '100%',
        background: 'rgba(0,0,0,.1)',
        left: '0px',
        top: '0px',
        zIndex: 10000
      }}
    >
      <Spinner />
    </div>
  );
};

export default FullScreenLoader;
