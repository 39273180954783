import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import React, { FC } from 'react';
import { Row } from 'react-table';
import { DragTableData } from './DragTableData';
import { ReactComponent as DragDropIcon } from '~/utils/images/drag-drop.svg';

interface IProps {
  row: Row<any>;
  onMouseEnter?: (value: any) => void;
  onMouseLeave?: (value: any) => void;
  isDraggable: boolean;
  mini: boolean;
  showPagination: boolean;
  centerAlignedHeaderAndContent: boolean;
  cellTextAlign:
    | 'center'
    | 'end'
    | 'justify'
    | 'left'
    | 'match-parent'
    | 'right'
    | 'start';
}

export const TableRow: FC<IProps> = ({
  row,
  onMouseEnter,
  onMouseLeave,
  isDraggable,
  cellTextAlign,
  centerAlignedHeaderAndContent,
  showPagination,
  mini
}) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({
      id: row.original.id
    });

  const dragStyle = {
    transform: CSS.Transform.toString(transform),
    transition
  };

  return (
    <tr
      ref={setNodeRef}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      {...row.getRowProps()}
      {...(isDraggable ? { style: dragStyle } : {})}
      className='row'
    >
      {isDraggable && (
        <DragTableData
          cellTextAlign={cellTextAlign}
          centerAlignedHeaderAndContent={centerAlignedHeaderAndContent}
          mini={mini}
          showPagination={showPagination}
        >
          <div
            {...(isDraggable ? attributes : {})}
            {...(isDraggable ? listeners : {})}
            style={{
              display: 'flex',
              flex: 1,
              alignItems: 'center',
              justifyContent: 'center',
              padding: 10
            }}
          >
            <DragDropIcon />
          </div>
        </DragTableData>
      )}
      {row.cells.map((cell, index) => {
        return (
          <DragTableData
            key={`${row.original.id} ${index}`}
            cell={cell}
            cellTextAlign={cellTextAlign}
            centerAlignedHeaderAndContent={centerAlignedHeaderAndContent}
            mini={mini}
            showPagination={showPagination}
          >
            {centerAlignedHeaderAndContent ? (
              <div style={{ display: 'inline-block' }}>
                {cell.render('Cell')}
              </div>
            ) : (
              <>{cell.render('Cell')}</>
            )}
          </DragTableData>
        );
      })}
    </tr>
  );
};
