import { emailRegex, nameRegex } from '~/helpers/helpers';
import { FormInputConfig } from '~/types/residentManagement';

export const formConfig: FormInputConfig[] = [
  {
    label: 'First Name',
    id: 'firstName',
    isRequired: true,
    placeholder: 'Enter First Name',
    pattern: nameRegex(),
    patternErrorMsg: 'Oops! Please enter a valid name',
    styles: {
      inputStyles: { minHeight: '55px' },
      lableStyles: { color: '#344054' }
    }
  },
  {
    label: 'Last Name',
    placeholder: 'Enter Last Name',
    id: 'lastName',
    isRequired: true,
    pattern: nameRegex(),
    patternErrorMsg: 'Oops! Please enter a valid name',
    styles: {
      inputStyles: { minHeight: '55px' },
      lableStyles: { color: '#344054' }
    }
  },
  {
    label: 'Email',
    id: 'email',
    isRequired: true,
    placeholder: 'Enter Email',
    pattern: emailRegex(),
    patternErrorMsg: 'Oops! Please enter a valid email',
    styles: {
      inputStyles: { minHeight: '55px' },
      lableStyles: { color: '#344054' }
    }
  },
  {
    label: 'Phone Number',
    id: 'phoneNumber',
    isRequired: true,
    placeholder: 'Enter Phone Number',
    styles: {
      inputStyles: { minHeight: '55px' },
      lableStyles: { color: '#344054' }
    }
  }
];

export enum ReferralIntroMarkups {
  referredBy = '{{referredBy}}',
  buildingName = '{{buildingName}}'
}

export enum ReferralLandingPages {
  V0 = 'original-option',
  V1 = 'diagonal-theme'
}
