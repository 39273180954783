import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  getGroupActions,
  chooseGroup,
  createGroup,
  updateProposedGroup,
  patchApprovedStatus
} from '../../actions/GroupActions';
import { getInterests } from '../../actions/TagActions';
import { getBuildingsSimple } from '../../actions/BuildingActions';
import GroupsProposals from '../../components/GroupsProposals/GroupsProposals';
import EditGroupDetails from '../../components/EditGroupDetails';
import RejectGroup from '../../components/RejectGroup/RejectGroup';
import ScheduleCommentModal from '../../components/ScheduleCommentModal/ScheduleCommentModal';

import '../GroupsContainer/GroupsContainer.css';
import GenericModal from '~/components/GenericModal/GenericModal';
import { useFullScreenLoader } from '~/hooks/useFullScreenLoader';
import { useCobuSelector } from '~/hooks/useCobuSelector';
import { useCobuDispatch } from '~/hooks/useCobuDispatch';
import { CreateGroupFromValues } from '~/components/CreateGroupForm/CreateGroupForm';
import useBuildings from '~/hooks/useBuildings';

enum GROUP_PROPOSAL_MODAL_VIEW_STATES {
  APPROVE = 'approve',
  REJECT = 'reject',
  NEW = 'new',
  SCHEDULE = 'schedule',
  CLOSE = ''
}

const GroupsProposalsContainer = () => {
  const [modalViewState, setModalViewState] =
    useState<GROUP_PROPOSAL_MODAL_VIEW_STATES>(
      GROUP_PROPOSAL_MODAL_VIEW_STATES.CLOSE
    );
  const [reason, setReason] = useState('');

  const { loader } = useFullScreenLoader();
  const dispatch = useCobuDispatch();
  const { buildings } = useBuildings();

  const { groupProposals, loading, groupSelected, interests } = useCobuSelector(
    ({ groups, tags }) => {
      return {
        groupProposals: groups?.groupProposals,
        loading: groups?.loading,
        groupSelected: groups?.groupSelected,
        interests: tags?.interests
      };
    }
  );

  const closeModal = () => {
    setModalViewState(GROUP_PROPOSAL_MODAL_VIEW_STATES.CLOSE);
    dispatch(chooseGroup());
  };

  const openCreateGroupModal = () =>
    setModalViewState(GROUP_PROPOSAL_MODAL_VIEW_STATES.NEW);

  const openApproveGroupModal = () =>
    setModalViewState(GROUP_PROPOSAL_MODAL_VIEW_STATES.APPROVE);

  const handleModalViewState = (status: GROUP_PROPOSAL_MODAL_VIEW_STATES) => {
    if (!status) {
      setReason(reason);
    }
    setModalViewState(status);
  };

  const handleCreateModal = (row: any) => {
    dispatch(chooseGroup(row.original));
    openApproveGroupModal();
  };

  const handleReject = (row: any) => {
    dispatch(chooseGroup(row.original));
    handleModalViewState(GROUP_PROPOSAL_MODAL_VIEW_STATES.REJECT);
  };

  const handleRejectSubmit = () => {
    loader.show();
    //@ts-ignore
    dispatch(updateProposedGroup(groupSelected!, reason)).finally(() => {
      loader.hide();
      dispatch(getGroupActions(false));
    });
    handleModalViewState(GROUP_PROPOSAL_MODAL_VIEW_STATES.CLOSE);
  };

  const handleReasonChange = (event: any) => {
    setReason(event?.target?.value);
  };

  const onCreateGroup = async (values: CreateGroupFromValues) => {
    //@ts-ignore
    const createGroupFormData = new FormData();
    createGroupFormData.append('name', values.title.trim());
    createGroupFormData.append('building', values.building);
    createGroupFormData.append('description', values.description);
    createGroupFormData.append('active', values.active.toString());
    createGroupFormData.append('featured', values.featured.toString());
    createGroupFormData.append('isDefault', values.isDefault.toString());
    createGroupFormData.append('interests', JSON.stringify(values.interests));
    if (groupSelected && groupSelected.suggestingUser) {
      createGroupFormData.append(
        'groupProposer',
        groupSelected.suggestingUser.uuid
      );
    }

    if (values.image) {
      createGroupFormData.append('imageUrl', values.image);
    }

    return dispatch(createGroup(createGroupFormData));
  };

  const onGroupApproved = async (values: CreateGroupFromValues) => {
    loader.show();

    //@ts-ignore
    Promise.all([
      onCreateGroup(values),
      dispatch(patchApprovedStatus(groupSelected?.uuid as string, true))
    ]).finally(() => {
      loader.hide();
      dispatch(getGroupActions(false));
      closeModal();
    });
  };

  const renderModal = () => {
    let content = null;
    if (modalViewState === GROUP_PROPOSAL_MODAL_VIEW_STATES.APPROVE) {
      content = (
        <GenericModal
          title='APPROVE GROUP'
          enableOverflowScrolls
          content={
            <EditGroupDetails
              buildings={buildings}
              groupSelected={groupSelected}
              onConfirm={onGroupApproved}
            />
          }
          cancelOverride='Close'
          showModal={!!modalViewState}
          onChange={closeModal}
          hideActionButton={true}
        />
      );
    } else if (modalViewState === GROUP_PROPOSAL_MODAL_VIEW_STATES.REJECT) {
      content = (
        <GenericModal
          title='Reject Group'
          actionText='Submit'
          content={
            <RejectGroup
              proposedGroup={groupSelected!}
              reasonText={reason}
              handleChange={handleReasonChange}
            />
          }
          actionFunction={handleRejectSubmit}
          onChange={closeModal}
          showModal={!!modalViewState}
        />
      );
    } else if (modalViewState === GROUP_PROPOSAL_MODAL_VIEW_STATES.NEW) {
      content = (
        <GenericModal
          title='CREATE GROUP'
          content={
            <EditGroupDetails
              buildings={buildings}
              onConfirm={onCreateGroup}
              groupSelected={groupSelected}
            />
          }
          cancelOverride='Close'
          showModal={!!modalViewState}
          onChange={closeModal}
          hideActionButton={true}
          actionButtonDisabled={true}
          enableOverflowScrolls={true}
        />
      );
    } else if (modalViewState === GROUP_PROPOSAL_MODAL_VIEW_STATES.SCHEDULE) {
      content = (
        <GenericModal
          title='Create or Schedule a Comment'
          actionText='Submit'
          content={<ScheduleCommentModal closeForm={closeModal} />}
          actionFunction={handleRejectSubmit}
          onChange={closeModal}
          enableOverflowScrolls
          showModal={!!modalViewState}
          hideActionButton
        />
      );
    }

    return content;
  };

  useEffect(() => {
    dispatch(getGroupActions(false));
    dispatch(getBuildingsSimple());
    dispatch(getInterests());
  }, []);

  return (
    <div>
      <GroupsProposals
        groupProposals={groupProposals}
        loading={loading}
        handleCreate={handleCreateModal}
        handleReject={handleReject}
      />
      {renderModal()}
    </div>
  );
};

export default GroupsProposalsContainer;
