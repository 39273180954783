import React, { useEffect, useMemo, useRef, useState } from 'react';
import { CellProps } from 'react-table';
import {
  getAllClientGroupRequest,
  getAllPropertiesWithClientRequest,
  postAddPropertyToOwners,
  postAssignPmToOwner,
  postCreateOwnerGroup,
  postCreatePropertyMngGroup,
  removePropertyAssignment
} from '~/api/assets';
import {
  IBuildingWithClient,
  IManagedProperty,
  IOwnerGroupManagement,
  OwnerManagementAccessor
} from '~/types/assets';
import '../../components/ColumnGrowTable/ColumnGrowTable.css';
import { toast } from 'react-toastify';
import { toTitleCase } from '~/utils/stringUtils';

export enum OwnerManagementModelTypes {
  ownerGroup = 'ownerGroup',
  propertyMngGroup = 'propertyMngGroup',
  property = 'property',
  assignPropertyMngGroup = 'assignPropertyMngGroup'
}

interface ICreateOwnerGroup {
  ownerName: string;
}

interface ICreatePropertyMngGroup {
  propertyMngGroupName: string;
}

interface ICreateProperty {
  buildingId: string;
  clientId: string;
  parentClientId: string;
}

export interface IOwnerManagementTableData {
  [OwnerManagementAccessor.ownerGroup]?: IOwnerGroupManagement[];
  [OwnerManagementAccessor.propertyMngGroup]?: IOwnerGroupManagement[];
  [OwnerManagementAccessor.propertyName]?: IManagedProperty[];
}

export const useOwnerManagement = () => {
  //accessors have to be the keys
  const [tableData, setTableData] = useState<IOwnerManagementTableData>({
    [OwnerManagementAccessor.ownerGroup]: undefined,
    [OwnerManagementAccessor.propertyMngGroup]: undefined,
    [OwnerManagementAccessor.propertyName]: undefined
  });

  const allClients = useRef<IOwnerGroupManagement[]>([]);
  const allPropertyMngGroups = useRef<IOwnerGroupManagement[]>([]);
  const allPropertiesWithClient = useRef<IBuildingWithClient[]>([]);

  const createModalType = useRef<OwnerManagementModelTypes | null>(null);

  const inputRef = useRef<null | HTMLInputElement>(null);
  const selectRef = useRef<null | HTMLSelectElement>(null);

  const modalType = createModalType.current;
  const isOwnerGroup = modalType === OwnerManagementModelTypes.ownerGroup;

  const [selectedGroupsAndProperty, setSelectedGroupsAndProperty] = useState<{
    ownerGroup: IOwnerGroupManagement | null;
    propertyMngGroup: IOwnerGroupManagement | null;
    property: IManagedProperty | null;
  }>({
    ownerGroup: null,
    propertyMngGroup: null,
    property: null
  });

  const [loading, setLoading] = useState(false);

  const [showModal, setShowModal] = useState(false);

  const [isAlsoPropertyMngGroup, setIsAlsoPropertyMngGroup] = useState('');

  const toggleIsAlsoPropertyMngGroup = (value: string) => {
    setIsAlsoPropertyMngGroup((prevState) =>
      value === prevState ? '' : value
    );
  };

  const getClientGroups = async () => {
    try {
      setLoading(true);
      const res = await getAllClientGroupRequest();

      res.data.sort((a, b) => b.name.localeCompare(a.name));

      allClients.current = res.data;

      const ownerGroups: IOwnerGroupManagement[] = [];
      const propertyMngGroups: IOwnerGroupManagement[] = [];

      res.data.forEach((clientGroup) => {
        if (clientGroup.isOwner) {
          ownerGroups.push({
            ...clientGroup,
            [OwnerManagementAccessor.ownerGroup]: clientGroup.name
          });
        }
        if (clientGroup.isPropertyManagementGroup) {
          propertyMngGroups.push({
            ...clientGroup,
            [OwnerManagementAccessor.propertyMngGroup]: clientGroup.name
          });
        }
      });

      setLoading(false);

      allPropertyMngGroups.current = propertyMngGroups;

      if (selectedGroupsAndProperty.ownerGroup) {
        const newPropertyMngGroups = allPropertyMngGroups.current.filter(
          (pmGroups) =>
            pmGroups.parent === selectedGroupsAndProperty.ownerGroup?.uuid
        );

        setTableData({
          [OwnerManagementAccessor.ownerGroup]: ownerGroups,
          [OwnerManagementAccessor.propertyMngGroup]: newPropertyMngGroups
        });
      } else {
        setTableData({
          [OwnerManagementAccessor.ownerGroup]: ownerGroups
        });
      }
    } catch (e) {
      setLoading(false);
    }
  };

  const fetchAllPropertiesWithClient = async () => {
    try {
      const res = await getAllPropertiesWithClientRequest();

      res.data.sort((a, b) => b.name.localeCompare(a.name));

      allPropertiesWithClient.current = res.data;

      if (
        selectedGroupsAndProperty.ownerGroup &&
        selectedGroupsAndProperty.propertyMngGroup
      ) {
        setTableData((prevState) => ({
          ...prevState,
          [OwnerManagementAccessor.propertyName]:
            allPropertiesWithClient.current.filter((propertyWithClient) => {
              return (
                propertyWithClient.clientId ===
                  selectedGroupsAndProperty.propertyMngGroup?.uuid &&
                propertyWithClient.clientParentId ===
                  selectedGroupsAndProperty.ownerGroup?.uuid
              );
            })
        }));
      }
    } catch (e) {
      toast(e.message);
    }
  };

  useEffect(() => {
    //fetch all the client groups and all properties with client
    getClientGroups();
    fetchAllPropertiesWithClient();
  }, []);

  const onTableCell = (
    accessor: string,
    tableCellData: IOwnerGroupManagement | IManagedProperty
  ): void => {
    switch (accessor) {
      case OwnerManagementAccessor.ownerGroup: {
        setSelectedGroupsAndProperty({
          ownerGroup: tableCellData as IOwnerGroupManagement,
          propertyMngGroup: null,
          property: null
        });

        const newPropertyMngGroups = allPropertyMngGroups.current.filter(
          (pmGroups) =>
            pmGroups.parent === (tableCellData as IOwnerGroupManagement).uuid
        );

        setTableData((prevState) => ({
          ...prevState,
          [OwnerManagementAccessor.propertyMngGroup]: newPropertyMngGroups
        }));

        break;
      }
      case OwnerManagementAccessor.propertyMngGroup: {
        setSelectedGroupsAndProperty((prevState) => ({
          ...prevState,
          property: null,
          propertyMngGroup: tableCellData as IOwnerGroupManagement
        }));

        const newProperties = allPropertiesWithClient.current.filter(
          (propertyWithClient) =>
            propertyWithClient.clientId ===
              (tableCellData as IOwnerGroupManagement).uuid &&
            propertyWithClient.clientParentId ===
              selectedGroupsAndProperty.ownerGroup?.uuid
        );

        setTableData((prevState) => ({
          ...prevState,
          [OwnerManagementAccessor.propertyName]: newProperties
        }));
        break;
      }
      case OwnerManagementAccessor.propertyName: {
        setSelectedGroupsAndProperty((prevState) => ({
          ...prevState,
          property: tableCellData as IManagedProperty
        }));
      }
    }
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const onShowModal = () => {
    setShowModal(true);
  };

  const onCreateOwnerGroup = async () => {
    createModalType.current = OwnerManagementModelTypes.ownerGroup;

    onShowModal();
  };

  const onAssignPropertyMngGroup = async () => {
    createModalType.current = OwnerManagementModelTypes.assignPropertyMngGroup;

    onShowModal();
  };

  const onCreatePropertyMngGroup = async () => {
    createModalType.current = OwnerManagementModelTypes.propertyMngGroup;

    onShowModal();
  };

  const onAssignPropertyOwners = () => {
    createModalType.current = OwnerManagementModelTypes.property;

    onShowModal();
  };

  const onDeletePropertAssingmnet = async () => {
    const buildingId = selectedGroupsAndProperty.property?.buildingId;

    if (
      window.confirm('Are you sure you want to remove property assignment?')
    ) {
      try {
        setLoading(true);
        await removePropertyAssignment(buildingId as string);
        await fetchAllPropertiesWithClient();

        setSelectedGroupsAndProperty((prevState) => {
          return { ...prevState, property: null };
        });

        setLoading(false);
      } catch (e) {
        setLoading(false);
        toast('Failed to remove assignment');
      }
    }
  };

  const onCreateEntity = async () => {
    if (!modalType) {
      return;
    }

    switch (modalType) {
      case OwnerManagementModelTypes.ownerGroup:
      case OwnerManagementModelTypes.propertyMngGroup:
        const newOwnerName = inputRef?.current?.value;

        if (!newOwnerName) {
          toast(
            `Empty ${isOwnerGroup ? 'Owner Group' : 'Property Mng Group'} Name`
          );

          return;
        }

        const dublicateOwnerGroup = tableData[
          isOwnerGroup
            ? OwnerManagementAccessor.ownerGroup
            : OwnerManagementAccessor.propertyMngGroup
        ]?.find(
          (ownerGroup) =>
            ownerGroup.name.toLowerCase() === newOwnerName?.toLowerCase()
        );

        if (dublicateOwnerGroup) {
          toast(
            `${
              isOwnerGroup ? 'Group Owner' : 'Property Mng Group'
            } Already present`
          );

          return;
        }

        try {
          if (isOwnerGroup) {
            setLoading(true);

            await postCreateOwnerGroup(
              newOwnerName?.toLowerCase(),
              !!isAlsoPropertyMngGroup
            ).then(() => {
              setLoading(false);
              getClientGroups();
            });
          } else {
            if (
              availablePmOwnerToSelectedOwner &&
              availablePmOwnerToSelectedOwner.length
            ) {
              const shouldAssignPmGroup = availablePmOwnerToSelectedOwner.find(
                (client) =>
                  client.name.toLowerCase() === newOwnerName?.toLowerCase()
              );

              if (shouldAssignPmGroup) {
                toast('Owner Group exist!, please assign the PM group');

                return;
              }
            }

            setLoading(true);

            await postCreatePropertyMngGroup(
              selectedGroupsAndProperty.ownerGroup?.uuid as string,
              newOwnerName?.toLowerCase()
            ).then(() => {
              setLoading(false);
              getClientGroups();
            });
          }

          toast(
            `succussfully added new ${
              isOwnerGroup
                ? `Owner Group${
                    isAlsoPropertyMngGroup ? '/Property Mng Group' : ''
                  }`
                : 'Property Mng Group'
            } : ${newOwnerName}`
          );

          closeModal();
        } catch (e) {
          setLoading(false);
          closeModal();
          toast(e.message);
        }

        break;
      case OwnerManagementModelTypes.property:
        const buildingId = selectRef.current?.value;

        try {
          setLoading(true);

          await postAddPropertyToOwners(
            selectedGroupsAndProperty.ownerGroup?.uuid as string,
            selectedGroupsAndProperty.propertyMngGroup?.uuid as string,
            buildingId as string
          ).then(() => {
            setLoading(false);
            fetchAllPropertiesWithClient();
          });

          closeModal();
        } catch (e) {
          setLoading(false);
          closeModal();
          toast(e.message);
        }

        break;
      case OwnerManagementModelTypes.assignPropertyMngGroup:
        const pmOwnerId = selectRef.current?.value;

        try {
          setLoading(true);

          await postAssignPmToOwner(
            selectedGroupsAndProperty.ownerGroup?.uuid as string,
            pmOwnerId as string
          ).then(() => {
            setLoading(false);
            getClientGroups();
          });

          closeModal();
        } catch (e) {
          setLoading(false);
          closeModal();
          toast(e.message);
        }
        break;
    }
  };

  const columnConfig: any[] = useMemo(() => {
    const showPropertyMngGroupColumn = !!selectedGroupsAndProperty.ownerGroup;

    const showPropertiesColumn =
      selectedGroupsAndProperty.ownerGroup &&
      selectedGroupsAndProperty.propertyMngGroup;

    return [
      {
        Header: 'OWNER GROUP',
        accessor: OwnerManagementAccessor.ownerGroup,
        Cell: (cell: CellProps<IOwnerGroupManagement>) => {
          const isSelected =
            selectedGroupsAndProperty?.ownerGroup?.uuid ===
            cell.row.original.uuid;

          return (
            <div
              onClick={() => {
                onTableCell(
                  OwnerManagementAccessor.ownerGroup,
                  cell.row.original
                );
              }}
              className={`default-text ${
                isSelected ? 'selected-tab-cell' : ''
              }`}
            >
              {toTitleCase(cell.value)}
            </div>
          );
        },
        sibling: (
          <button
            className='btn-standard btn-primary weight-500'
            onClick={onCreateOwnerGroup}
          >
            Add New Owner Group
          </button>
        )
      },
      ...(showPropertyMngGroupColumn
        ? [
            {
              Header: 'PROPERTY MNG GROUP',
              accessor: OwnerManagementAccessor.propertyMngGroup,
              Cell: (cell: CellProps<IOwnerGroupManagement>) => {
                const isSelected =
                  selectedGroupsAndProperty.propertyMngGroup?.uuid ===
                  cell.row.original.uuid;

                return (
                  <div
                    onClick={() =>
                      onTableCell(
                        OwnerManagementAccessor.propertyMngGroup,
                        cell.row.original
                      )
                    }
                    className={`default-text ${
                      isSelected ? 'selected-tab-cell' : ''
                    }`}
                  >
                    {toTitleCase(cell.value)}
                  </div>
                );
              },

              sibling: (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '10px'
                  }}
                >
                  <button
                    className='btn-standard btn-primary weight-500'
                    onClick={onAssignPropertyMngGroup}
                  >
                    Assign Property Mng Group
                  </button>
                  <button
                    className='btn-standard btn-primary weight-500'
                    onClick={onCreatePropertyMngGroup}
                  >
                    Add New Property Mng Group
                  </button>
                </div>
              )
            }
          ]
        : [{}]),
      ...(showPropertiesColumn
        ? [
            {
              Header: 'PROPERTIES',
              accessor: OwnerManagementAccessor.propertyName,
              Cell: (cell: CellProps<IOwnerGroupManagement>) => {
                const isSelected =
                  selectedGroupsAndProperty.property?.name ===
                  cell.row.original.name;

                return (
                  <div
                    onClick={() => {
                      onTableCell(
                        OwnerManagementAccessor.propertyName,
                        cell.row.original
                      );
                    }}
                    className={`default-text ${
                      isSelected ? 'selected-tab-cell' : ''
                    }`}
                  >
                    {toTitleCase(cell.value)}
                  </div>
                );
              },
              sibling: (() => {
                const isSelected = !!selectedGroupsAndProperty.property?.name;

                return (
                  <button
                    className='btn-standard btn-primary weight-500'
                    onClick={
                      isSelected
                        ? onDeletePropertAssingmnet
                        : onAssignPropertyOwners
                    }
                  >
                    {isSelected ? 'Delete Property Assignment' : 'Add Property'}
                  </button>
                );
              })()
            }
          ]
        : [])
    ];
  }, [selectedGroupsAndProperty]);

  const nonManagedProperties = useMemo(() => {
    return allPropertiesWithClient.current.filter(
      (propertyWithClient) =>
        !(propertyWithClient.clientId && propertyWithClient.clientParentId)
    );
  }, [allPropertiesWithClient.current]);

  const availablePmOwnerToSelectedOwner = useMemo(() => {
    const pmGroups = allClients.current.filter(
      (client) => client.parent !== selectedGroupsAndProperty.ownerGroup?.uuid
    );

    const uniqueObjectsMap = new Map();

    //removes dublicate object having same name in array
    pmGroups.map((item: IOwnerGroupManagement) => {
      uniqueObjectsMap.set(item['name'], item);
    });

    return Array.from(uniqueObjectsMap.values());
  }, [selectedGroupsAndProperty.ownerGroup?.uuid]);

  return {
    tableData,
    columnConfig,
    loading,
    showModal,
    closeModal,
    onCreateEntity,
    modalType,
    inputRef,
    selectRef,
    nonManagedProperties,
    isAlsoPropertyMngGroup,
    toggleIsAlsoPropertyMngGroup,
    availablePmOwnerToSelectedOwner: availablePmOwnerToSelectedOwner
  };
};
