import { useEffect, useState } from "react"
import { toast } from "react-toastify"
import { getGoogleReviewsForBuilding } from "~/api/review"
import { GoogleReview } from "~/types/reviewReceived"

/**
 * Custom hook to fetch Google reviews for a specific building
 * @param {string} buildingId - The ID of the building to fetch reviews for
 * @returns {Object} - Contains the fetched Google reviews and loading configuration
 */
const useGoogleReviews = (buildingId: string) => {
    const [googleReviews, setGoogleReviews] = useState<GoogleReview[]>([]);
    const [loadingConfig, setLoadingConfig] = useState<{ flag: boolean, comment?: string }>({
        flag: false,
        comment: ''
    });

    useEffect(() => {
        getBuildingGoogleReviews();
    }, []);

    /**
     * Fetch Google reviews for the building
     * @param {boolean} refresh - Flag to force refresh the reviews
     */
    const getBuildingGoogleReviews = async (refresh: boolean = false) => {
        setLoadingConfig({ flag: true });
        try {
            const buildingReviewsResp = await getGoogleReviewsForBuilding(buildingId);
            setGoogleReviews(buildingReviewsResp.data);
            setLoadingConfig({ flag: false });
        } catch (error) {
            setLoadingConfig({ flag: false });
            if (error) {
                toast(error?.message);
            }
        }
    };

    return { googleReviews, loadingConfig };
};

export default useGoogleReviews;
