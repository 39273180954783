import { emperor } from '~/services';

export const createRSVPGatheringRequest = async (data: {
  groupEvent: string;
  userForRsvp: string;
  totalAttendees: number;
  interestedDate: string;
}) => {
  return await emperor.post(`/groupeventrsvp`, data);
};

export const deleteRSVPGatheringRequest = async (groupEventRsvpId: string) => {
  return await emperor.delete(`/groupeventrsvp/${groupEventRsvpId}`);
};

export const updateRSVPGatheringRequest = async (data: {
  RSVPGatheringId: string;
  totalAttendees: number;
}) => {
  return await emperor.patch(`/groupeventrsvp/${data.RSVPGatheringId}`, {
    totalAttendees: data.totalAttendees
  });
};

export const getGatheringRsvps = async (data: { gatheringId: string }) => {
  return await emperor.get(`/groupevent/${data.gatheringId}/rsvp`);
};

export const getGatheringRsvpsForResident = async (data: {
  gatheringId: string;
  interestedDate?: string;
}) => {
  return await emperor.get(`/groupevent/${data.gatheringId}/rsvp`, {
    params: {
      interestedDate: data.interestedDate,
      client: 'MOBILE'
    }
  });
};

export const getGatheringRSVPInfo = async (gatheringId: string) => {
  return await emperor.get(`/groupeventrsvp/${gatheringId}/info`);
};

export const fetchBuildingCode = async (gatheringId: string) => {
  return await emperor.get('/buildingcode', {
    params: {
      gatheringId
    }
  });
};

export const fetchOneGatheringRequest = async (gatheringId: string) => {
  return await emperor.get(`v2/gatherings/${gatheringId}`);
};
