import { GET_EVENT_STRUCTURES, GET_INTERESTS } from '../actions/TagActions';

export type TagStoreState = {
  structures: any[];
  interests: any[];
}

const initialState: TagStoreState = {
  structures: [],
  interests: [],
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case GET_EVENT_STRUCTURES:
      return { ...state, structures: action.payload };
    case GET_INTERESTS:
      return { ...state, interests: action.payload };
    default:
      return state;
  }
};
