import React, { createContext, useContext, useState } from 'react';

type TFullScreenLoaderContext = {
    isVisible: boolean;
    loader: {
        show: () => void;
        hide: () => void;
    };
};

const FullScreenLoaderContext = createContext<TFullScreenLoaderContext>({
    isVisible: false,
    loader: {
        show: () => null,
        hide: () => null,
    },
});

function useProvideFullScreenLoaderState(): TFullScreenLoaderContext {
    const [isVisible, setIsVisible] = useState<boolean>(false);

    function show() {
        setIsVisible(true);
    }

    function hide() {
        setIsVisible(false);
    }

    return {
        isVisible,
        loader: {
            show,
            hide,
        },
    };
}

type ProvideFullScreenLoaderProps = {
    children: any
};

export function ProvideFullScreenLoader(props: ProvideFullScreenLoaderProps) {
    return (
        <FullScreenLoaderContext.Provider value={useProvideFullScreenLoaderState()}>
            {props.children}
        </FullScreenLoaderContext.Provider>
    );
}

export const useFullScreenLoader = () => useContext(FullScreenLoaderContext);