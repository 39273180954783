import { api, emperor } from '~/services';

//comment|text, image|file, buildings[]
export const createPostGroupComment = async (
  templateData: FormData
): Promise<any> => {
  return await emperor.post('groupcomment/multi-post', templateData);
};

export const fetchCobuPlusBuildings = async (): Promise<any> => {
  return await api.get('/building/cobuplus-buildings');
};

export const getAllPinnedPosts = async () => {
  return await emperor.get('groupcomment/all-pinned');
};
