import React from 'react';
import { useForm } from 'react-hook-form';
import { emailRegex } from '~/helpers/helpers';

import classes from './ForgottenPasswordForm.module.css';
import { ReactComponent as ExclamationMark } from '~/utils/images/exclamation-mark.svg';

interface IProps {
  onSubmit: (email: string) => void;
}
export const ForgottenPasswordForm = (props: IProps) => {
  const { register, handleSubmit, errors } = useForm<{ email: string }>();

  const onSubmit = (data: { email: string }) => {
    props.onSubmit(data.email);
  };

  return (
    <div>
      {errors.email && (
        <div className={classes.errorContainer}>
          <ExclamationMark fill='var(--iconError)' />
          <span className={classes.errorMessage}>
            Incorrect email! Please try again.
          </span>
        </div>
      )}
      <h2 className='bold'>Forgotten your password?</h2>
      <p>
        Don’t worry! Just fill in your email, and we’ll send you a link to reset
        your password.
      </p>
      <form onSubmit={handleSubmit(onSubmit)}>
        <input
          type='text'
          id='email'
          name='email'
          placeholder={'Email Address'}
          className={`${errors.email && 'border-red'}`}
          ref={register({
            required: true,
            pattern: emailRegex()
          })}
        />
        <button type='submit' className={`btn-primary btn-large`}>
          Continue
        </button>
      </form>
    </div>
  );
};

export default ForgottenPasswordForm;
