import { useRef, useEffect, useCallback } from 'react';
import { useCobuSelector } from './useCobuSelector';
import { Building } from '~/types/building';

type UseBuilding = {
  buildings: Building[];
};

export default function useBuildings(): UseBuilding {
  const buildings = useCobuSelector((state) => state.building.buildings);

  return {
    buildings
  };
}
