
import React, { useMemo } from 'react';
import { Column, usePagination, useSortBy, useTable } from 'react-table';
import { Colors } from '~/enums/Colors';
//@ts-ignore
import Pagination from "materialui-pagination-component";


interface IProps {
    columns: Column<any>[];
    data: {}[];
    sort?: {
        id: string;
        descending: boolean;
    };
    hideRightBorder?: boolean;
}

const CobuPaginatedTable = (props: IProps) => {
    const { data, columns, sort, hideRightBorder } = props;
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        gotoPage,
        canNextPage,
        canPreviousPage,
        prepareRow,
        pageOptions,
        state
    } = useTable(
        {
            columns,
            data,
            initialState: {
                sortBy: useMemo(
                    () => [
                        {
                            id: sort?.id || '',
                            desc: sort?.descending || false
                        }
                    ],
                    [sort]
                )
            }
        },
        useSortBy,
        usePagination
    );

    const { pageIndex } = state
    const columnsWithoutSort = [
        'DATE',
        'COMMENT',
        'SCORE',
        'COMMENTS',
        'RSVPS',
        'EVENT RATINGS',
        'NOTIFICATIONS',
        'READ',
        'WRITE'
    ];

    const handleOnChange = (pageValue: number) => {
        gotoPage(pageValue - 1)
    };

    return (
        <div>
            <table
                {...getTableProps()}
                style={{
                    width: '100%',
                    border: 'solid 2px rgba(39, 33, 99, 0.1)',
                    borderRight: hideRightBorder
                        ? 'none'
                        : 'solid 2px rgba(39, 33, 99, 0.1)',
                    borderTopLeftRadius: '12px',
                    borderBottomLeftRadius: '12px',
                    borderTopRightRadius: hideRightBorder ? '0px' : '12px',
                    borderBottomRightRadius: hideRightBorder ? '0px' : '12px'
                }}
            >
                <thead>
                    {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => {
                                return (
                                    <th
                                        {...column.getHeaderProps(column.getSortByToggleProps())}
                                        style={{
                                            borderBottom: 'solid 1px rgba(39, 33, 99, 0.1)',
                                            padding: '24px 10px',
                                            color: 'rgba(39, 33, 99, 0.5)',
                                            fontWeight: 'bold',
                                            fontFamily: 'Roboto',
                                            fontSize: '14px',
                                            textAlign: 'left'
                                        }}
                                    >
                                        <div
                                            style={{
                                                display: 'flex',
                                                cursor: 'pointer',
                                                alignItems: 'center'
                                            }}
                                        >
                                            {column.render('Header')}
                                            {typeof column.Header === 'string' &&
                                                !columnsWithoutSort.includes(column.Header) && (
                                                    <div
                                                        style={{
                                                            position: 'relative',
                                                            fontSize: '10px',
                                                            marginLeft: '10px'
                                                        }}
                                                    >
                                                        {((column.isSorted && !column.isSortedDesc) ||
                                                            !column.isSorted) && (
                                                                <div
                                                                    style={{ position: 'absolute', bottom: '-3px' }}
                                                                >
                                                                    ▲
                                                                </div>
                                                            )}
                                                        {((column.isSorted && column.isSortedDesc) ||
                                                            !column.isSorted) && (
                                                                <div style={{ position: 'absolute', top: '-1px' }}>
                                                                    ▼
                                                                </div>
                                                            )}
                                                    </div>
                                                )}
                                        </div>
                                    </th>
                                );
                            })}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {page.map((row) => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()} className='row'>
                                {row.cells.map((cell) => {
                                    return (
                                        <td
                                            {...cell.getCellProps()}
                                            style={{
                                                padding: '24px 10px',
                                                fontFamily: 'Roboto',
                                                color: Colors.cobuDarkBlue,
                                                fontWeight: 500,
                                                fontSize: '18px',
                                                textAlign: 'left',
                                                whiteSpace: 'nowrap',
                                                maxWidth: '300px'
                                            }}
                                        >
                                            {cell.render('Cell')}
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
            <div>
                <Pagination
                    variant="outlined"
                    selectVariant="button"
                    navigationVariant="text"
                    pageWindowVariant="ellipsis"
                    color="primary"
                    indicatorColor="primary"

                    disablePrevious={!canPreviousPage}
                    disableNext={!canNextPage}

                    page={pageIndex + 1}
                    totalPages={pageOptions.length}

                    onChange={handleOnChange}
                />
            </div>
        </div>
    );
};

export default CobuPaginatedTable;
