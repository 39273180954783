import React from 'react';
import CobuTable from '~/components/CobuTable/CobuTable';
import { useBuildingTileTable } from './hooks';
import { useParams } from 'react-router-dom';
import Spinner from '~/components/Spinner/Spinner';
import classes from './MyBuildingTiles.module.scss';
import Spacer from '~/components/Spacer/Spacer';
import { BuildingTileForm } from './BuildingTileForm';
import { IBuildingTile, IBuildingTileForm } from './interfaces';
import Breadcrumbs from '~/components/Breadcrumbs/Breadcrumbs';
import DraggableCobuTable from '~/components/CobuTable/DraggableCobuTable';

export const MyBuildingTiles = () => {
  const { buildingId } = useParams<{ buildingId: string }>();

  const {
    showTileForm,
    buildingTiles,
    columns,
    loader,
    selectedTile,
    onTileFormSubmit,
    onAddNewTile,
    onCancelTileForm,
    onSortTiles
  } = useBuildingTileTable(buildingId);

  return (
    <div className={'float-spinner-parent'}>
      {loader && <Spinner float />}
      {!!buildingTiles && (
        <div className={classes.container}>
          <Breadcrumbs />
          <Spacer height={30} />
          <DraggableCobuTable
            columns={columns}
            data={buildingTiles as IBuildingTile[]}
            ignoreDefaultSortBehaviour
            isDraggable={true}
            onDragEnd={onSortTiles}
          />
          {!(!selectedTile && !showTileForm) && (
            <BuildingTileForm
              buildingId={buildingId}
              selectedTile={selectedTile as IBuildingTileForm}
              onFormSubmit={onTileFormSubmit}
              onCancel={onCancelTileForm}
              showFormModal={showTileForm}
            />
          )}
          <Spacer height={20} />
          <div className={classes.addNewTile} onClick={onAddNewTile}>
            Add a New Tile
          </div>
        </div>
      )}
    </div>
  );
};
