import moment from 'moment';
import { GatheringType } from '~/types/gathering';

export const sortEvents = (
  events: GatheringType[],
  sort = 'scheduledStartTime ASC'
) => {
  const [target = 'scheduledStartTime', order = 'ASC'] = sort.split(' ');
  let L = 1,
    R = -1;

  if (order.toUpperCase() === 'ASC') {
    L = -1;
    R = 1;
  } else {
    L = 1;
    R = -1;
  }

  if (target == 'scheduledStartTime') {
    return events.sort((a, b) =>
      moment(a[target]).isBefore(moment(b[target])) ? L : R
    );
  }

  return events.sort((a, b) => {
    const aValue = a[target as keyof GatheringType];
    const bValue = b[target as keyof GatheringType];
    if (aValue === undefined || bValue === undefined) return 0;

    return aValue < bValue ? L : R;
  });
};
