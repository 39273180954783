import { createSlice } from '@reduxjs/toolkit';
import { BuildingPerk } from '~/types/buildingPerk';

const perksSlice = createSlice({
  name: 'perks',
  initialState: {
    buildingPerks: [] as BuildingPerk[]
  },
  reducers: {
    fetchPerksSuccess: (state, action) => {
      state.buildingPerks = action.payload;
    },
    updateBuildingPerkSuccess: (state, action) => {
      state.buildingPerks = [...state.buildingPerks, action.payload];
    },
    updatePerkSuccess: (state, action) => {
      const buildingPerk = state.buildingPerks.find(
        (bp: BuildingPerk) => bp.perk.uuid === action.payload.uuid
      );
      if (buildingPerk) {
        buildingPerk.perk = action.payload;
        state.buildingPerks = state.buildingPerks.filter(
          (bp: BuildingPerk) => bp.perk.uuid !== action.payload.uuid
        );
        state.buildingPerks = [...state.buildingPerks, buildingPerk];
      }
    },
    deleteBuildingPerkSuccess: (state, action) => {
      state.buildingPerks = state.buildingPerks.filter(
        (bp: BuildingPerk) => bp.buildingPerkUuid !== action.payload.uuid
      );
    }
  }
});
export const {
  fetchPerksSuccess,
  updateBuildingPerkSuccess,
  deleteBuildingPerkSuccess,
  updatePerkSuccess
} = perksSlice.actions;
export default perksSlice.reducer;
