import moment from 'moment';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { CellProps } from 'react-table';
import { toast } from 'react-toastify';
import momentTimezone from 'moment-timezone';
import {
  getEmailsByFlyerGroup,
  getFlyerStatuses,
  getFlyerTemplates,
  postFlyersOnsite
} from '~/api/gatherings';
import ActionDropDown from '~/components/ActionDropDown/ActionDropDown';
import TextDelimeter from '~/components/TextDelimeter/TextDelimeter';
import { ISelectOption } from '~/helpers/interfaces';
import { IEventWithFlyer, IGatheringFlyer } from '~/types/gathering';
// @ts-ignore
import useEditFlyer from './useEditFlyer';
import useFetchEventsWithFlyer from './useFetchEventsWithFlyer';
import { emailRoles } from '~/components/Header/CreateMenu/constants';
import { IEmailFlyerFormData } from '~/containers/FlyerManagement/FlyerTable/EmailFylerForm/EmailFlyerForm';
import { cloneDeep } from 'lodash';
import { flyerTemplateOptions } from '~/containers/FlyerManagement/FlyerTable/EmailFylerForm/constants';
import html2canvas from 'html2canvas';
import Axios from 'axios';
import useGetBuildingDetails from '../useGetBuildingDetails';
import { getBuildingFromStore } from '~/helpers/reduxStoreHelpers';

export default () => {
  const [editFlyerData, setEditFlyerData] = useState<{
    flyer: IGatheringFlyer | null;
    event: IEventWithFlyer | null;
  }>({
    flyer: null,
    event: null
  });
  const [loading, setLoading] = useState(false);

  const [emailFlyerLoader, setEmailFlyerLoader] = useState(false);
  const [emailFlyerData, setEmailFlyerData] = useState<{
    event: IEventWithFlyer | null;
    flyerGroupEmails: string[] | null;
  }>({
    event: null,
    flyerGroupEmails: null
  });
  const [flyerTemplatOptions, setFlyerTemplateOptions] =
    useState<ISelectOption[]>();

  const pdfIFrameRef = useRef<HTMLIFrameElement>();
  const instaIFrameRef = useRef<HTMLIFrameElement>();

  const [templateSelectionOption, setTemplateSelectionOption] = useState(
    cloneDeep(flyerTemplateOptions)
  );

  const [flyerStatusOptions, setFlyerStatusOptions] =
    useState<ISelectOption[]>();

  const [pdfFlyerHtmlContent, setPdfFlyerHtmlContent] = useState('');
  const [instaFlyerHtmlContent, setInstaFlyerHtmlContent] = useState('');
  const [byIdflyerGroupEmails, setByIdflyerGroupEmails] = useState(null);

  const { patchEventFlyer } = useEditFlyer();
  const {
    fetchEventsWithFlyers,
    eventsWithFlyer,
    loading: eventsFlyerLoading
  } = useFetchEventsWithFlyer();

  const history = useHistory();

  const populateFlyerDataInHtml = (
    htmlString: string,
    flyer: IGatheringFlyer
  ): string => {
    const {
      flyerBgImage,
      flyerHeading,
      flyerSubHeading,
      flyerDescription,
      flyerLogo,
      flyerDetails: {
        footer = '',
        mobileStoreLink = '',
        propertyCode = '',
        propertyQrImage = ''
      }
    } = flyer;

    const dataToPopulate = {
      flyerBgImage,
      flyerHeading: flyerHeading.toUpperCase(),
      flyerSubHeading,
      flyerDescription,
      flyerLogo,
      footer,
      mobileStoreLink,
      propertyCode,
      propertyQrImage
    };

    Object.entries(dataToPopulate).forEach(([key, value]) => {
      htmlString = htmlString.replace(`$${key}`, value);
    });

    return htmlString;
  };

  const fetchFlyerTemplates = async (flyerTemplateType: string) => {
    try {
      const res = await getFlyerTemplates(flyerTemplateType);

      setFlyerTemplateOptions(
        res.data.map((template) => ({
          label: template.name,
          value: template.uuid
        }))
      );
    } catch (e) {
      toast(e.message || 'Failed to fetch templates');
    }
  };

  const downloadAttachment = async (
    isPdf: boolean,
    htmlLocation: string,
    flyer: IGatheringFlyer
  ) => {
    try {
      setLoading(true);

      const fileName = `${flyer.buildingName} - ${
        flyer.flyerEventName
      } - ${moment(flyer.flyerEventDate).format('MM-DD-YYYY')}`;

      const res = await Axios.get(htmlLocation);

      // if (isPdf) {
      //   const { descriptionLength, allowedCharacters } =
      //     getPdfDescriptionBoundData(
      //       flyer.flyerDescription,
      //       flyer.flyerHeading
      //     );

      //   if (descriptionLength > allowedCharacters) {
      //     toast(`description should be under ${allowedCharacters} characters`);

      //     setLoading(false);

      //     return;
      //   }
      // }

      const populatedHtml = populateFlyerDataInHtml(res.data, flyer);

      isPdf
        ? setPdfFlyerHtmlContent(populatedHtml)
        : setInstaFlyerHtmlContent(populatedHtml);

      setTimeout(() => {
        isPdf ? printIframe(fileName) : screenshotIframe(true, fileName);

        setLoading(false);
      }, 1000);
    } catch (e) {
      setLoading(false);

      toast(e.message || 'Failed to download attachment');
    }
  };

  const onChangeFlyerStatus = async (
    flyers: IGatheringFlyer[],
    newStatusUuid: string
  ) => {
    try {
      await Promise.allSettled(
        flyers.map(async (flyer) => {
          await patchEventFlyer(flyer.flyerUuid, {
            flyerStatus: newStatusUuid
          });
        })
      );

      fetchEventsWithFlyers();
    } catch (e) {
      toast(e.message || 'Failed to change flyer status');
    }
  };

  const fetchFlyerStatuses = async () => {
    try {
      const { data: statuses } = await getFlyerStatuses();

      const options = statuses.map((status) => {
        return { label: status.name, value: status.uuid };
      });

      setFlyerStatusOptions(options);
    } catch (e) {
      toast(e.message || 'Failed to fetch flyer statuses');
    }
  };

  const fetchEmailsByFlyerGroup = async () => {
    try {
      setLoading(true);

      const res = await getEmailsByFlyerGroup();

      setByIdflyerGroupEmails(res.data);
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchEmailsByFlyerGroup();
    fetchFlyerStatuses();
  }, []);

  const navigateToBuildingSetting = (buildingId: string) => {
    history.push(`/building/${buildingId}/edit/emails`);
  };

  const openEmailModal = (
    event: IEventWithFlyer,
    flyerGroupEmails: null | string[]
  ) => {
    setEmailFlyerData({ event, flyerGroupEmails });
  };

  const printIframe = (fileName: string) => {
    const prevTitle = window.parent.document.title;

    document.title = fileName;

    const iframeWindow = pdfIFrameRef?.current?.contentWindow;

    if (!iframeWindow) {
      toast('Unable to print!');

      document.title = prevTitle;

      return;
    }

    iframeWindow?.print();

    document.title = prevTitle;

    return false;
  };

  const screenshotIframe = (download = true, fileName: string) => {
    const iframeWindow = instaIFrameRef?.current?.contentWindow;

    if (!iframeWindow?.document?.body) {
      toast('Unable to capture screenshot!');

      return;
    }

    html2canvas(iframeWindow?.document?.body as HTMLElement, {
      allowTaint: true,
      useCORS: true
    }).then(function (canvas) {
      // Convert canvas to data URL
      var imgData = canvas.toDataURL();

      if (download) {
        var imgElement = document.createElement('img');
        imgElement.src = imgData;

        var downloadLink = document.createElement('a');
        downloadLink.href = imgData;
        downloadLink.download = `${fileName}.png`;

        // Programmatically trigger download
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      } else {
        //TODO: Shivam automate image attachment
        return imgData;
      }
    });
  };

  const downloadAttachments = (
    flyers: IGatheringFlyer[],
    includeInstagram: boolean
  ) => {
    (includeInstagram
      ? flyers
      : flyers.filter((flyer) => flyer.flyerTemplateType === 'PDF Flyer')
    ).forEach(async (flyer: IGatheringFlyer) => {
      const { flyerTemplateType, flyerHtmlLocation } = flyer;
      const isPdf = flyerTemplateType === 'PDF Flyer';

      await downloadAttachment(isPdf, flyerHtmlLocation, flyer);
    });
  };

  const onEmailFlyerSubmit = async (
    propertyEmails: string,
    flyers: IGatheringFlyer[], //TODO: Shivam for automating the flyer attachment process
    flyerForm: IEmailFlyerFormData
  ) => {
    const { pdfFile, instaFile } = flyerForm;

    try {
      setEmailFlyerLoader(true);

      const formData = new FormData();

      formData.append('emails', propertyEmails);
      formData.append('content', flyerForm.description);
      pdfFile && formData.append('pdfFile', flyerForm.pdfFile as Blob);
      instaFile &&
        formData.append('instagramImage', flyerForm.instaFile as Blob);

      await postFlyersOnsite(formData);

      setEmailFlyerLoader(false);
      onEmailModalCancel();

      toast('Flyers sent to ON-SITE team');
    } catch (e) {
      toast(e.message || 'Failed to push flyers on-site. Try again');
      setEmailFlyerLoader(false);
    }
  };

  const onEmailModalCancel = () => {
    setEmailFlyerData({ event: null, flyerGroupEmails: null });
  };

  const showFlyerEditModal = (
    flyer: IGatheringFlyer,
    event: IEventWithFlyer
  ) => {
    fetchFlyerTemplates(flyer.flyerTemplateType);
    setEditFlyerData({ flyer, event });
  };

  const onEditFlyerCancel = () => {
    setEditFlyerData({ flyer: null, event: null });
  };

  const columns = [
    {
      Header: 'BUILDING',
      accessor: 'buildingName',
      Cell: (cell: CellProps<IEventWithFlyer>) => (
        <TextDelimeter
          textElement={cell.value}
          maxDelimiter={100}
          moreMessage='See more'
          lessMessage='See less'
          contentStyle={{
            fontSize: '18px',
            color: 'rgb(39, 33, 99)',
            fontWeight: 500
          }}
        />
      )
    },
    {
      Header: 'CREATE DATE',
      accessor: 'createdAt',
      Cell: (cell: CellProps<IEventWithFlyer>) => {
        const buildingDetails = getBuildingFromStore(
          cell.row.original.buildingUuid
        );

        return (
          <div>
            {momentTimezone
              .tz(cell.value, buildingDetails.timezone)
              .format('MM/DD/YYYY')}
          </div>
        );
      }
    },
    {
      Header: 'DATE OF EVENT',
      accessor: 'startTime',
      Cell: (cell: CellProps<IEventWithFlyer>) => {
        const buildingDetails = getBuildingFromStore(
          cell.row.original.buildingUuid
        );

        return (
          <div>
            {momentTimezone
              .tz(cell.value, buildingDetails.timezone)
              .format('MM/DD/YYYY')}
          </div>
        );
      }
    },
    {
      Header: 'TIME OF EVENT',
      accessor: '',
      Cell: (cell: CellProps<IEventWithFlyer>) => {
        const buildingDetails = getBuildingFromStore(
          cell.row.original.buildingUuid
        );

        return (
          <div>
            {momentTimezone
              .tz(cell.row.original.startTime, buildingDetails.timezone)
              .format('hh:mm A')}
          </div>
        );
      }
    },
    {
      Header: 'LOCATION',
      accessor: 'locationString'
    },
    {
      Header: 'EVENT TITLE',
      accessor: 'title',
      Cell: (cell: CellProps<IEventWithFlyer>) => (
        <TextDelimeter
          textElement={cell.value}
          maxDelimiter={100}
          moreMessage='See more'
          lessMessage='See less'
          contentStyle={{
            fontSize: '18px',
            color: 'rgb(39, 33, 99)',
            fontWeight: 500
          }}
        />
      )
    },
    {
      Header: 'EVENT DESCRIPTION',
      accessor: 'description',
      Cell: (cell: CellProps<IEventWithFlyer>) => (
        <TextDelimeter
          textElement={cell.row.original.description}
          maxDelimiter={100}
          moreMessage='See more'
          lessMessage='See less'
          contentStyle={{
            fontSize: '18px',
            color: 'rgb(39, 33, 99)',
            fontWeight: 500
          }}
        />
      )
    },
    {
      Header: 'CREATOR NAME',
      accessor: 'creatorName'
    },
    {
      Header: 'CREATOR EMAIL',
      accessor: 'creatorEmail',
      Cell: (cell: CellProps<IEventWithFlyer>) => (
        <TextDelimeter
          textElement={cell.value}
          maxDelimiter={20}
          moreMessage='See more'
          lessMessage='See less'
          contentStyle={{
            fontSize: '18px',
            color: 'rgb(39, 33, 99)',
            fontWeight: 500
          }}
        />
      )
    },
    {
      Header: 'RSVP',
      accessor: 'isRSVP',
      Cell: (cell: CellProps<IEventWithFlyer>) => (
        <div>{cell.row.original.isRSVP ? 'true' : 'false'}</div>
      )
    },
    {
      Header: 'LEFT SPOTS',
      accessor: 'leftSpots',
      Cell: (cell: CellProps<IEventWithFlyer>) => (
        <div>{cell.value ?? '--'}</div>
      )
    },
    {
      Header: 'STATUS',
      accessor: 'flyerStatus',
      Cell: (cell: CellProps<IEventWithFlyer>) => {
        return (
          <select
            value={cell.row.original.flyerStatusUuid}
            onChange={(event) =>
              onChangeFlyerStatus(cell.row.original.flyers, event.target.value)
            }
          >
            {flyerStatusOptions?.map((option) => (
              <option
                key={option.value}
                label={option.label}
                value={option.value}
              >
                {option.value}
              </option>
            ))}
          </select>
        );
      }
    },
    {
      Header: 'ON-SITE EMAILS',
      accessor: 'propertyEmails',
      Cell: (cell: CellProps<IEventWithFlyer>) => {
        let propertyEmails = '';

        const flyerGroupEmails =
          byIdflyerGroupEmails &&
          byIdflyerGroupEmails[cell.row.original.buildingUuid];

        cell.row.original?.propertyEmails?.forEach((email) => {
          if (email.type === emailRoles.onsite) {
            propertyEmails = propertyEmails
              ? `${propertyEmails},${email.email}`
              : email.email;
          }
        });

        if (flyerGroupEmails) {
          (flyerGroupEmails as string[]).forEach((email) => {
            propertyEmails = propertyEmails
              ? `${propertyEmails},${email}`
              : email;
          });
        }

        return (
          <TextDelimeter
            textElement={propertyEmails ? `${propertyEmails}` : 'Add Emails'}
            maxDelimiter={15}
            contentStyle={{
              color: 'rgb(39, 33, 99)',
              fontWeight: 500
            }}
            moreMessage='See more'
            lessMessage='See less'
            enableButton
            onClick={() => {
              if (propertyEmails) {
                openEmailModal(cell.row.original, flyerGroupEmails);

                return;
              }

              navigateToBuildingSetting(cell.row.original.buildingUuid);
            }}
          />
        );
      }
    },
    {
      Header: 'ACTIONS',
      id: 'ACTIONS',
      Cell: (cell: CellProps<IEventWithFlyer>) => {
        let propertyEmails = '';

        const flyerGroupEmails =
          byIdflyerGroupEmails &&
          byIdflyerGroupEmails[cell.row.original.buildingUuid];

        cell.row.original?.propertyEmails?.forEach((email) => {
          if (email.type === emailRoles.onsite) {
            propertyEmails = propertyEmails
              ? `${propertyEmails},${email.email}`
              : email.email;
          }
        });

        if (flyerGroupEmails) {
          (flyerGroupEmails as string[]).forEach((email) => {
            propertyEmails = propertyEmails
              ? `${propertyEmails},${email}`
              : email;
          });
        }

        const content = cloneDeep(cell.row.original.flyers)
          .sort((a, b) => {
            if (a.flyerTemplateType < b.flyerTemplateType) {
              return 1;
            } else if (a.flyerTemplateType > b.flyerTemplateType) {
              return -1;
            }

            return 0;
          })
          .map((flyer) => {
            const { flyerTemplateType, flyerHtmlLocation, flyerUuid } = flyer;

            const isPdf = flyerTemplateType === 'PDF Flyer';

            const option = (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '10px 10px'
                }}
                key={flyerUuid}
              >
                <div
                  onClick={() =>
                    downloadAttachment(isPdf, flyerHtmlLocation, flyer)
                  }
                  className='more-actions-btn pointer'
                >{`Download ${isPdf ? 'PDF' : 'Instagram pic'}`}</div>
                <div
                  onClick={() => showFlyerEditModal(flyer, cell.row.original)}
                  className='more-actions-btn pointer'
                >{`Edit ${isPdf ? 'PDF' : 'Instagram'} flyer`}</div>
                <TextDelimeter
                  textElement={
                    propertyEmails ? `${propertyEmails}` : 'Add Emails'
                  }
                  maxDelimiter={15}
                  contentStyle={{
                    color: 'rgb(39, 33, 99)',
                    fontWeight: 500
                  }}
                  moreMessage='See more'
                  lessMessage='See less'
                  enableButton
                  onClick={() => {
                    if (propertyEmails) {
                      openEmailModal(cell.row.original, flyerGroupEmails);

                      return;
                    }

                    navigateToBuildingSetting(flyer.flyerBuilding);
                  }}
                />
              </div>
            );

            return (
              <ActionDropDown
                title={isPdf ? 'PDF' : 'INSTAGRAM'}
                containerStyles={{ margin: '0 5px' }}
                optionStyles={{ width: 'unset', right: '50px' }}
                showActionPointer={false}
                containerClassCb={(isSelected) =>
                  isSelected
                    ? 'more-actions-btn more-actions-btn-selected'
                    : 'more-actions-btn more-actions-btn-unselected'
                }
                options={[{ content: option }]}
              />
            );
          });

        return <div style={{ display: 'flex' }}>{content}</div>;
      }
    }
  ];

  return {
    columns,
    editFlyerData,
    onEditFlyerCancel,
    emailFlyerLoader,
    emailFlyerData,
    onEmailFlyerSubmit,
    onEmailModalCancel,
    flyerTemplatOptions,
    templateSelectionOption,
    setTemplateSelectionOption,
    pdfFlyerHtmlContent,
    instaFlyerHtmlContent,
    pdfIFrameRef,
    instaIFrameRef,
    loading: loading || eventsFlyerLoading,
    downloadAttachments,
    eventsWithFlyer,
    fetchEventsWithFlyers
  };
};
