import classes from './VendorFormContainer.module.css';
import React, { useEffect, useState } from 'react';
import Logo from '~/utils/images/logo.svg';
import VendorForm from '~/components/VendorForm/VendorForm';
import { useRef } from 'react';

import {
  createBuildingPerk,
  createPerk,
  fetchOnePerkProposal
} from '~/api/perks';
import { useParams } from 'react-router-dom';
import { PerkProposal } from '~/types/perk';
import { toast } from 'react-toastify';
import Spinner from '~/components/Spinner/Spinner';

const VendorFormContainer = () => {
  const [perkProposal, setPerkProposal] = useState<PerkProposal>(
    {} as PerkProposal
  );
  const [loading, setLoading] = useState(true);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [isSubmitEnabled, setIsSubmitEnabled] = useState(false);
  const [isFormAlreadySubmitted, setIsFormAlreadySubmitted] = useState(false);
  const { proposalId } = useParams<{ proposalId: string }>();
  let btnRef = useRef<HTMLButtonElement>(null);

  const onCreatePerk = (data: {
    name: string;
    offerType: string;
    description: string;
    redeemLink: string;
  }) => {
    if (btnRef.current) {
      btnRef.current.setAttribute('disabled', 'disabled');
    }
    const { name, description, redeemLink, offerType } = data;
    const { merchantId, buildingId } = perkProposal;
    createPerk({
      proposalUuid: proposalId,
      merchant: merchantId,
      name: name,
      offerType: offerType,
      description: description,
      redeemLink: redeemLink
    })
      .then((res) => {
        const { uuid } = res.data;
        createBuildingPerk(buildingId, uuid).then((BPRes) => {
          if (BPRes.status === 200) {
            setIsFormSubmitted(true);
          }
        });
      })
      .catch(() => {
        toast('Something went wrong');
      });
  };

  const enableSubmit = (
    isValid: boolean,
    isSubmitting: boolean,
    isSubmitSuccessful: boolean
  ) => {
    setIsSubmitEnabled(isValid && !isSubmitting && !isSubmitSuccessful);
  };
  useEffect(() => {
    fetchOnePerkProposal(proposalId)
      .then((res) => {
        setPerkProposal({
          perkProposalId: res.data.uuid,
          buildingId: res.data.building.uuid,
          buildingName: res.data.building.name,
          merchantId: res.data.merchant.uuid,
          merchantName: res.data.merchant.name
        });
        setLoading(false);
      })
      .catch((err) => {
        if (err.status === 404) {
          setIsFormAlreadySubmitted(true);
          setLoading(false);
        }
      });
  }, [proposalId]);
  return (
    <div className={classes.container}>
      {!isFormAlreadySubmitted ? (
        !loading && (
          <div>
            <div className={classes.header}>
              <img className='logo' src={Logo} alt='Cobu' />
              <button
                ref={btnRef}
                type='submit'
                form='vendor-form'
                className='btn-standard btn-primary'
                disabled={!isSubmitEnabled}
              >
                Submit
              </button>
            </div>
            <div className={classes.form}>
              <VendorForm
                perkProposal={perkProposal}
                onCreate={onCreatePerk}
                enableSubmitButton={enableSubmit}
              />
            </div>
            {loading && (
              <div className={classes.spinner}>
                <Spinner />
              </div>
            )}
          </div>
        )
      ) : (
        <div className={classes.centerModal}>
          <div className={'modal-content'}>
            {
              <div>
                <p className='modal-title'>
                  This form has already been submitted!
                </p>
              </div>
            }
            <p className='modal-text'>
              For support questions or feature requests, please email
              <span className='bold'> feedback@livecobu.com</span>
            </p>
          </div>
        </div>
      )}

      {isFormSubmitted && (
        <div className={`${classes.overlay}`}>
          <div className={'modal-content'}>
            {
              <div>
                <p className='modal-title'>Form submitted!</p>
              </div>
            }
            <p className='modal-text'>
              The form has been submitted. All the information has been sent to
              the property manager of
              <span className='bold'> {perkProposal.buildingName}!</span>
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default VendorFormContainer;
