import React from 'react';
import styled from 'styled-components';
import { Group } from '~/reducers/groupAdmin';
import CobuTable from '../CobuTable/CobuTable';
import './../GroupsProposals/GroupsProposals.css';

const Styles = styled.div`
  padding: 1rem;
  white-space: nowrap;
  overflow-x: auto;
  table {
    display: inline-block;
    border-spacing: 0;
    tr {
      :nth-child(even) {
        background-color: #f2f2f2;
      }
      :hover {
        background-color: #e9e9e9;
      }
    }

    th,
    td {
      white-space: normal;
      margin: 0;
      padding: 0.5rem;
      text-align: center;
    }
  }

  input {
    width: 100%;
  }
`;

type GroupsRejectedProps = {
  rejectedGroups: Group[];
  loading: boolean;
  reviewRejectedGroup: (row: any) => void;
  deleteRejectedGroup: (row: any) => void;
};

const GroupsRejected = (props: GroupsRejectedProps) => {
  const {
    rejectedGroups = [],
    loading,
    reviewRejectedGroup,
    deleteRejectedGroup
  } = props;

  const rejectedGroupsColumns = React.useMemo(
    () => [
      {
        Header: 'Suggesting User',
        id: 'suggestingUser',
        disableSortBy: true,
        columns: [
          {
            Header: 'Name',
            disableSortBy: true,
            accessor: ({ suggestingUser: user }: { suggestingUser: any }) => (
              <div className='suggestingUser'>
                {user.firstName} ${user.lastName}
              </div>
            ),
            id: 'name'
          }
        ]
      },
      {
        Header: 'Rejected Group',
        id: 'rejected-group',
        disableSortBy: true,
        columns: [
          {
            Header: 'Title',
            id: 'title.title',
            disableSortBy: true,
            accessor: (row: any) => (
              <div className='proposedGroupTitle'>{row.title}</div>
            )
          },
          {
            Header: 'Building',
            id: 'building.name',
            disableSortBy: true,
            accessor: (row: any) => <div>{row.building.name}</div>
          }
        ]
      },
      {
        Header: 'Additional Info',
        id: 'additional',
        disableSortBy: true,
        columns: [
          {
            Header: 'Description',
            accessor: (row: any) => (
              <div className='proposedGroupDescription'>{row.description}</div>
            ),
            disableSortBy: true,
            id: 'description',
            width: 225
          },
          {
            Header: 'Rejection Reason',
            accessor: (row: any) => (
              <div className='rejectionReason proposedGroupDescription'>
                {row.rejectionReason}
              </div>
            ),
            disableSortBy: true,
            id: 'rejectionReason',
            width: 250
          },
          {
            Header: 'Actions',
            id: 'rejected_actions',
            disableSortBy: true,
            width: 240,
            Cell: ({ row }: any) => (
              <div className='actionsColumn'>
                <button
                  color='primary'
                  onClick={() => reviewRejectedGroup(row)}
                  className='btn-primary btn-small'
                >
                  REVIEW
                </button>
                <button
                  color='secondary'
                  onClick={() => deleteRejectedGroup(row)}
                  className='btn-small btn-secondary margin-left-16'
                >
                  DELETE
                </button>
              </div>
            )
          }
        ]
      }
    ],
    [reviewRejectedGroup]
  );

  if (
    !Array.isArray(rejectedGroups) ||
    rejectedGroups?.length == 0 ||
    loading
  ) {
    return null;
  }

  const groups = rejectedGroups?.filter(
    (group) =>
      group.suggestingUser &&
      group.building &&
      !group.approved &&
      group.rejected
  );

  return (
    <div>
      <h2 className='margin-bottom-16'>Rejected Groups</h2>
      <div id='proposed-groups'>
        <CobuTable data={groups} columns={rejectedGroupsColumns} />
      </div>
    </div>
  );
};

export default GroupsRejected;
