import React from 'react';
import { ReactComponent as NoCommentImage } from '~/utils/images/no-comments.svg';
import classes from './NoComments.module.css';

const NoComments = () => {
  return (
    <div className={classes.contentContainer}>
      <NoCommentImage />
      <h1 className='font-size-24 margin-top-24'>No comments!</h1>
      <div className='modal-text margin-top-24'>
        There are no comments, yet. Add your comments below.
      </div>
    </div>
  );
};

export default NoComments;
