import React, { CSSProperties, FC } from 'react';
import classes from './FormSection.module.scss';

interface IProps {
  bgColor: string;
  showTopBorder?: boolean;
  showBottomBorder?: boolean;
  noMarginTop?: boolean;
  bgImage?: string;
  contentStyles?: CSSProperties;
}

export const FromSection: FC<IProps> = ({
  bgColor,
  children,
  showTopBorder = true,
  showBottomBorder = true,
  bgImage,
  noMarginTop = false,
  contentStyles
}) => {
  const hideTopBorder = (
    !showTopBorder ? { margin: 0, '--showTopBorder': 'none' } : {}
  ) as React.CSSProperties;

  const hideBottomBorder = (
    !showBottomBorder ? { '--showBottomBorder': 'none' } : {}
  ) as React.CSSProperties;

  const noMarginTopStyle = (
    noMarginTop ? { marginTop: 0 } : {}
  ) as React.CSSProperties;

  if (bgImage) {
    return (
      <div
        className={`${classes.container} ${classes['bg-image']}`}
        style={{
          backgroundImage: `url(${bgImage})`,
          ...noMarginTopStyle
        }}
      >
        {children}
      </div>
    );
  }

  return (
    <div
      className={classes.rhombus}
      style={
        {
          '--rhombusColor': bgColor,
          ...hideTopBorder,
          ...hideBottomBorder,
          ...noMarginTopStyle
        } as React.CSSProperties
      }
    >
      <div style={contentStyles} className={classes.content}>
        {children}
      </div>
    </div>
  );
};
