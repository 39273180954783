import { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { getAllUpcomingEvents, updateEventRemainder } from '~/api/events';
import CobuConstants from '~/helpers/cobuConstants';
import { UpcomingEvent } from '~/types/gathering';

export default () => {
  const [upcomingEvents, setUpcomingEvents] = useState<UpcomingEvent[]>([]);
  const [loadingConfig, setLoadingConfig] = useState<{
    flag: boolean;
    comment?: string;
  }>({
    flag: false,
    comment: ''
  });

  const updatedData = useRef<any>({});

  useEffect(() => {
    getUpcomingEvents();
  }, []);

  const getUpcomingEvents = async () => {
    setLoadingConfig({
      flag: true,
      comment: CobuConstants.eventManagement.fetchingUpcomingEvents
    });
    try {
      const upcomingEventsResponse = await getAllUpcomingEvents();
      setLoadingConfig({ flag: false });
      setUpcomingEvents(upcomingEventsResponse.data.reverse());
    } catch (error) {
      setLoadingConfig({ flag: false });
      if (error) {
        toast(error?.message);
      }
    }
  };

  const toggleDataForEvent = async (event: UpcomingEvent) => {
    try {
      let resp = await updateEventRemainder(event.uuid);
      setUpcomingEvents((prevEvents) => {
        const updatedEvents = prevEvents.map((prevEvent) =>
          prevEvent.uuid === event.uuid
            ? { ...prevEvent, reqAutoReminder: !prevEvent.reqAutoReminder }
            : prevEvent
        );
        return updatedEvents;
      });
    } catch (error) {
      if (error) {
        toast(
          `Updating Auto Remander Settings for ${event.title} has failed. Please try again`
        );
      }
    }
  };

  return { upcomingEvents, loadingConfig, toggleDataForEvent };
};
