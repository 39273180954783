import React from 'react';
import { ColumnGrowTable } from '~/components/ColumnGrowTable/ColumnGrowTable';
import {
  ITagAssignmentTableData,
  usePropertyAssignment
} from '~/hooks/assets/usePropertyAssignment';
import './PropertyAssignment.css';
import Spinner from '~/components/Spinner/Spinner';
import GenericModal from '~/components/GenericModal/GenericModal';
import Breadcrumbs from '~/components/Breadcrumbs/Breadcrumbs';
import Spacer from '~/components/Spacer/Spacer';

export const PropertyAssignment = () => {
  const {
    tableData,
    columnConfig,
    loading,
    showModal,
    closeModal,
    onCreateTag,
    inputRef
  } = usePropertyAssignment();

  return (
    <div className='property-container float-spinner-parent'>
      <Breadcrumbs />
      <Spacer height={20} />
      {loading && <Spinner float />}
      <ColumnGrowTable<ITagAssignmentTableData>
        columns={columnConfig}
        tableData={tableData}
        height={window.screen.height * 0.5}
      />
      {showModal && (
        <GenericModal
          showModal={showModal}
          title={'Add Tag Name'}
          content={
            <input
              className='tag-input'
              type='text'
              placeholder={'E.g. Melissa'}
              ref={inputRef}
            />
          }
          actionText={'Create Tag'}
          onChange={closeModal}
          actionButtonDisabled={false}
          actionFunction={onCreateTag}
        />
      )}
    </div>
  );
};
