import React, { useEffect, useState } from 'react';
import classes from './TenantMatching.module.css';
import { useFullScreenLoader } from '~/hooks/useFullScreenLoader';
import { withFullScreenLoader } from '~/hocs/withFullScreenLoader';
import { Building } from '~/types/building';
import {
  BuildingWithUserMissingTenantKey,
  bindUserWithTenant,
  fetchBuildingDetails,
  fetchUsersNotLinkedWithTenant,
  getBuildingsWithTotalUsersMissingTenantKey,
  searchTenant
} from '~/api/building';
import { useParams } from 'react-router-dom';
import { Colors } from '~/enums/Colors';
import TenantMatchingTable from './TenantMatchingTable';
import { ResidentUIModel } from '~/types/residentManagement';
import { TenantInfo } from '../RentRoll/TenantRentRollTable';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';
import { ApiState } from '~/types/common';
import useBuildings from '~/hooks/useBuildings';
import CobuTable from '~/components/CobuTable/CobuTable';

const getColumnsForBuildingsTable = ({
  onBuildingSelect
}: {
  onBuildingSelect: (value: string) => void;
}) => {
  return [
    {
      Header: 'Building Name',
      accessor: 'name'
    },
    {
      Header: 'Total Users Missing Tenant Key',
      accessor: 'totalUsersMissingTenantKey'
    },
    {
      Header: 'Actions',
      disableSortBy: true,
      accessor: (row: BuildingWithUserMissingTenantKey) => {
        return (
          <button
            className='btn btn-standard btn-primary'
            onClick={() => onBuildingSelect(row.uuid)}
          >
            Match Tenants
          </button>
        );
      }
    }
  ];
};

const TenantMatching = () => {
  const [buildings, setbuildings] = useState<
    BuildingWithUserMissingTenantKey[]
  >([]);
  const [users, setusers] = useState<ResidentUIModel[]>();
  const [totalUsers, setTotalUsers] = useState<number>(0);
  const [searchInput, setsearchInput] = useState<string>('');
  const [searchResults, setsearchResults] = useState<
    Record<number, TenantInfo[]>
  >({});
  const [userTenantMatches, setuserTenantMatches] = useState<
    Record<string, TenantInfo>
  >({});
  const [getTenantsApiState, setgetTenantsApiState] =
    useState<ApiState>('LOADING');
  const [selectedBuilding, setselectedBuilding] =
    useState<BuildingWithUserMissingTenantKey>();

  const { loader } = useFullScreenLoader();

  const buildingId = selectedBuilding?.uuid;

  const displayError = (err: any) => {
    const error = err as AxiosError;

    toast(error?.response?.data?.message ?? 'Something went wrong');
  };

  const getUsersMissingTenantInfo = () => {
    loader.show();
    setgetTenantsApiState('LOADING');
    fetchUsersNotLinkedWithTenant(buildingId!)
      .then((res) => {
        setusers(res.data?.result);
        setTotalUsers(res.data?.totalUsers);
      })
      .finally(() => {
        setgetTenantsApiState('COMPLETED');
        loader.hide();
      });
  };

  const getBuildings = () => {
    loader.show();
    getBuildingsWithTotalUsersMissingTenantKey()
      .then((res) => {
        setbuildings(res.data?.buildings ?? []);
      })
      .catch((err) => displayError(err))
      .finally(() => loader.hide());
  };

  const onInputClicked = (index: number) => {
    setsearchInput('');
  };

  const onSearchInput = (index: number, input: string) => {
    const resident = users![index];

    const clone = { ...userTenantMatches };
    delete clone[resident.uuid];

    setuserTenantMatches(clone);

    if (input.length < 3) return;

    loader.show();

    searchTenant(buildingId!, input)
      .then((res) =>
        setsearchResults({ ...searchResults, [index]: res.data?.result })
      )
      .finally(() => {
        loader.hide();
      });
  };

  const removeUserFromTable = (resident: ResidentUIModel) => {
    if (Array.isArray(users)) {
      setusers(users.filter((user) => user.uuid != resident.uuid));
    }
  };

  const onTenantSelected = (resident: ResidentUIModel, tenant: TenantInfo) => {
    loader.show();

    bindUserWithTenant(buildingId!, [
      {
        userId: resident.uuid,
        tenant: tenant
      }
    ])
      .then(() => {
        toast('Users successfully linked to tenants');
        // removeUserFromTable(resident);
        getUsersMissingTenantInfo();
        setsearchResults({});
        setsearchInput('');
        setuserTenantMatches({});
      })
      .finally(() => loader.hide());
  };

  const onBuildingSelect = (value: string) => {
    setselectedBuilding(buildings.find((building) => building.uuid === value));
  };

  useEffect(() => {
    getBuildings();
  }, []);

  useEffect(() => {
    if (buildingId) {
      getUsersMissingTenantInfo();
      setsearchResults({});
      setsearchInput('');
      setuserTenantMatches({});
    }
  }, [selectedBuilding]);

  return (
    <div className={classes.pageWrapper}>
      <div
        className={`flex-row align-start justify-between align-center ${classes.headerWrapper}`}
      >
        <div>
          <div
            className='margin-bottom-16 flex-row align-center'
            style={{ fontSize: '20px', color: Colors.cobuDarkBlue }}
          >
            Tenant Matching {!!selectedBuilding ? ' in ' : ''}
            <b className='margin-left-8'>
              {selectedBuilding ? ` ${selectedBuilding?.name}` : ''}
            </b>
          </div>
        </div>
        {!!selectedBuilding && (
          <button
            className='btn btn-small btn-secondary margin-left-16'
            onClick={() => setselectedBuilding(undefined)}
          >
            Change Building
          </button>
        )}
      </div>
      {!buildingId && (
        <p className='form-field-error-box'>
          Please select a building to proceed
        </p>
      )}
      {!selectedBuilding?.uuid && (
        <CobuTable
          data={buildings}
          columns={getColumnsForBuildingsTable({ onBuildingSelect })}
          sort={{ id: 'totalUsersMissingTenantKey', descending: true }}
        ></CobuTable>
      )}
      {buildingId && getTenantsApiState != 'LOADING' && (
        <>
          <TenantMatchingTable
            rows={users}
            totalUsers={totalUsers}
            onInputFocus={onInputClicked}
            onSearchInput={onSearchInput}
            searchResults={searchResults}
            onTenantSelected={onTenantSelected}
            userTenantMatches={userTenantMatches}
          />
        </>
      )}
    </div>
  );
};

export default withFullScreenLoader(TenantMatching);
