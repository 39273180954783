import { WidgetComponent } from '~/types/layout';
import { OverviewCard } from './OverviewCard';

const widgetComponentsMap = new Map([['OVERVIEW_CARD', OverviewCard]]);

export const getWidgetComponent = (component: WidgetComponent) => {
  if (widgetComponentsMap.has(component.type)) {
    return widgetComponentsMap.get(component.type);
  } else {
    return null;
  }
};
