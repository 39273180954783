import React from 'react';

import { ReactComponent as CheckboxUnselected } from '~/utils/images/checkbox-unchecked.svg';
import { ReactComponent as CheckboxSelected } from '~/utils/images/checkbox-checked.svg';
import { IMultiSelectOption } from '~/helpers/interfaces';
import './MultiSelectCheckBox.css';

interface IProps {
  index: number;
  option: IMultiSelectOption;
  setSelectedOption: (value: string, isSelected: boolean) => void;
  checkTintColor?: string;
  tickColor?: string;
  enableLabelClickToSelect?: boolean;
}
const MultiSelectCheckBox = (props: IProps) => {
  const {
    option,
    setSelectedOption,
    index,
    checkTintColor = '#FEC100',
    tickColor
  } = props;

  return (
    <div
      key={index}
      style={{ color: checkTintColor }}
      className='checkbox-row'
      onClick={() => setSelectedOption(option.value, !option.isSelected)}
    >
      <div
        style={
          option.isSelected
            ? {
                backgroundColor: tickColor,
                width: '20px',
                height: '20px',
                borderRadius: '20px'
              }
            : {}
        }
      >
        {option.isSelected ? <CheckboxSelected /> : <CheckboxUnselected />}
      </div>
      <p className='margin-left-16'>{option.text}</p>
    </div>
  );
};
export default MultiSelectCheckBox;
