/* eslint-disable no-useless-escape */
import { useEffect, RefObject } from 'react';
import { FieldNamesMarkedBoolean, FieldValues } from 'react-hook-form';
import { OS } from '~/enums/OS';
type Event = MouseEvent | TouchEvent;
function useOnClickOutside<T extends HTMLElement = HTMLElement>(
  ref: RefObject<T>,
  handler: (event: Event) => void
) {
  useEffect(() => {
    const listener = (event: Event) => {
      const el = ref?.current;
      // Do nothing if clicking ref's element or descendent elements
      if (!el || el.contains((event?.target as Node) || null)) {
        return;
      }
      handler(event);
    };
    document.addEventListener(`mousedown`, listener);
    document.addEventListener(`touchstart`, listener);
    return () => {
      document.removeEventListener(`mousedown`, listener);
      document.removeEventListener(`touchstart`, listener);
    };
    // Reload only if ref or handler changes
  }, [ref, handler]);
}

function emailRegex(): RegExp {
  return /^\S+@\S+$/i;
}

function numberRegex(): RegExp {
  return /^-?\d+$/;
}

function nameRegex(): RegExp {
  return /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð1234567890 ,.'-]+$/u;
}

function isValidBuildingName() {
  const regex = /^[\p{L}\p{N}\p{P}\p{S}\s]+$/u;
  return regex;
}

function phoneRegex(): RegExp {
  return /^\d{10}$/;
}

function uriRegex(): RegExp {
  return /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;
}

function featureIdRegex(): RegExp {
  return /\b0x[0-9a-fA-F]+(?:%3A0x[0-9a-fA-F]+|:0x[0-9a-fA-F]+)\b/g;
}

function isPhoneFormat(phone: string): boolean {
  return !!(
    phone.match(/^\d{10}/) ||
    phone.match(/^\d{3}\s\d{3}\s\d{4}$/) ||
    phone.match(/^\d{3}[-]{1}\d{3}[-]{1}\d{4}$/) ||
    phone.match(/^[(]{1}\d{3}[)]{1}\s{1}\d{3}[-]{1}\d{4}$/)
  );
}

function convertKeyValueToFormData(data: any) {
  let formData = new FormData();
  for (let key in data) {
    const value = data[key];
    const type = typeof value;

    switch (type) {
      case 'string':
        formData.append(key, value as string);
        break;
      case 'boolean':
        formData.append(key, value as string);
        break;
    }
  }
  return formData;
}

function convertKeyValueToFormDataV2(data: any) {
  let formData = new FormData();
  const { image } = data;

  delete data.image;

  for (let key in data) {
    if (typeof data[key] == 'object') {
      formData.append(key, JSON.stringify(data[key]));
    } else {
      formData.append(key, data[key]);
    }
  }

  if (image) {
    formData.append('image', image);
  }

  return formData;
}

function convertTableColumnsToCsvHeaders(
  columns: { Header: string; accessor: string }[]
) {
  const CsvHeaders: { label: string; key: string }[] = [];
  columns.forEach((c) => {
    CsvHeaders.push({
      label: c.Header,
      key: c.accessor
    });
  });
  return CsvHeaders;
}

/**
 * MORE INFO https://dev.to/rajnishkatharotiya/select-a-nested-value-from-the-object-in-javascript-2fjd
 * const obj = {
 *   profile : { user : { name: "xyz", surname: "abc" }},
 *   relations: [ { name: 'one' }, { name: 'two' } ]
 * };
 * CALL getNestedField(obj, 'profile.user.name', 'relations[0]', 'relations[2].name');<br>
 * OUTPUT ['xyz', { name: 'one' }, undefined]
 * @param {Number} params
 **/
function getNestedField(obj: Object, ...selectors: string[]) {
  return [...selectors].map((s) =>
    s
      .replace(/\[([^\[\]]*)\]/g, '.$1.')
      .split('.')
      .filter((t: string) => t !== '')
      .reduce(
        (prev: { [x: string]: any }, cur: string | number) => prev && prev[cur],
        obj
      )
  );
}

function composePerkProposalLink(perkProposalId: string) {
  return `${window.location.origin.toString()}/proposal/${perkProposalId}`;
}

function getOS() {
  const userAgent = window.navigator.userAgent,
    platform = window.navigator.platform,
    iosPlatforms = [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ];
  let os = null;

  if (
    iosPlatforms.includes(platform) ||
    (userAgent.includes('Mac') && 'ontouchend' in document)
  ) {
    os = OS.iOS;
  } else if (/Android/.test(userAgent)) {
    os = OS.Android;
  } else {
    os = OS.Other;
  }

  return os;
}

export const trimData = <T>(dataToTrim: T): T => {
  const dataType: string = typeof dataToTrim;

  const isFileType = dataToTrim instanceof File;

  if (isFileType) {
    return dataToTrim;
  }

  if (dataType === 'string') {
    //@ts-ignore
    return (dataToTrim as string).trim() as T;
  } else if (dataType === 'number') {
    //@ts-ignore
    return String(dataToTrim).trim() as T;
  } else if (Array.isArray(dataToTrim)) {
    //@ts-ignore
    return dataToTrim.map((item) => trimData(item)) as T;
  } else if (typeof dataToTrim === 'object' && dataToTrim !== null) {
    const trimmedObject: Record<string, any> = {};

    for (const key in dataToTrim) {
      //@ts-ignore
      if (dataToTrim.hasOwnProperty(key)) {
        const objectValue = (dataToTrim as any)[key];

        trimmedObject[key] = trimData(objectValue);
      }
    }
    //@ts-ignore
    return trimmedObject as T;
  }

  return dataToTrim;
};

const getDirtyValues = <T>(
  isDirty: boolean,
  dirtyFields: FieldNamesMarkedBoolean<FieldValues>,
  allValues: T
): Partial<T> => {
  if (isDirty && Array.isArray(dirtyFields)) return allValues;

  //@ts-ignore
  return Object.keys(allValues).reduce((prev, next) => {
    //@ts-ignore
    if (dirtyFields[next]) {
      //@ts-ignore
      prev[next] = allValues[next];
    }

    return prev;
  }, {});
};

function getObjectDiff<T>(prevObj: T, newObj: T) {
  const diff = {};

  for (const key in newObj) {
    //@ts-ignore
    if (newObj.hasOwnProperty(key) && newObj[key] !== prevObj[key]) {
      //@ts-ignore
      diff[key] = newObj[key];
    }
  }

  return diff;
}

export {
  useOnClickOutside,
  emailRegex,
  nameRegex,
  uriRegex,
  isPhoneFormat,
  convertKeyValueToFormData,
  convertKeyValueToFormDataV2,
  convertTableColumnsToCsvHeaders,
  getOS,
  composePerkProposalLink,
  getNestedField,
  phoneRegex,
  featureIdRegex,
  numberRegex,
  getDirtyValues,
  isValidBuildingName,
  getObjectDiff
};
