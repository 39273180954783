import React from "react";
import { CellProps, Column } from "react-table";
import { ReviewAlgorithmResult, ReviewAlgorithmResultAccessor } from "~/types/reviewReceived";
import CobuConstants from "~/helpers/cobuConstants";

export const getReviewAlgorithmTableConfig = () => {
    const reviewAlgorithmTableConfig: Column<ReviewAlgorithmResult>[] = [
        {
            Header: CobuConstants.reviewTableHeaders.name,
            accessor: ReviewAlgorithmResultAccessor.firstName, 
            Cell: (cell: CellProps<ReviewAlgorithmResult>) => (
                <div style={{ whiteSpace: 'normal' }} className='default-text'>
                    {cell.row.original.firstName} {cell.row.original.lastName}
                </div>
            ),
        },
        {
            Header: CobuConstants.reviewTableHeaders.recentComments,
            accessor: ReviewAlgorithmResultAccessor.recentComments, 
        },
        {
            Header: CobuConstants.reviewTableHeaders.oldComments,
            accessor: ReviewAlgorithmResultAccessor.oldComments, 
        },
        {
            Header: CobuConstants.reviewTableHeaders.recentGreatComments,
            accessor: ReviewAlgorithmResultAccessor.recentGreatComments, 
        },
        {
            Header: CobuConstants.reviewTableHeaders.oldGreatComments,
            accessor: ReviewAlgorithmResultAccessor.oldGreatComments, 
        },
        {
            Header: CobuConstants.reviewTableHeaders.recentRsvps,
            accessor: ReviewAlgorithmResultAccessor.recentRsvps, 
        },
        {
            Header: CobuConstants.reviewTableHeaders.oldRsvps,
            accessor: ReviewAlgorithmResultAccessor.oldRsvps, 
        },
        {
            Header: CobuConstants.reviewTableHeaders.residentScore,
            accessor: ReviewAlgorithmResultAccessor.residentScore, 
        },
        {
            Header: CobuConstants.reviewTableHeaders.negativeComments,
            accessor: ReviewAlgorithmResultAccessor.negativeComments, 
        },
        {
            Header: CobuConstants.reviewTableHeaders.totalScore,
            accessor: ReviewAlgorithmResultAccessor.score, 
        },
        {
            Header: CobuConstants.reviewTableHeaders.mode,
            Cell: (cell: CellProps<ReviewAlgorithmResult>) => (
                <div style={{ whiteSpace: 'normal' }} className='default-text'>
                    {cell.row.original.isSMS ? 'SMS' : 'Email + Pop'}
                </div>
            ),
        },
    ];

    return reviewAlgorithmTableConfig;
};
