import React from 'react';
import { Column } from 'react-table';
import classes from './ContentModerationContainer.module.css';
import { ReactComponent as Comments } from '~/utils/images/comments.svg';
import qs from 'querystring';

const getSentimentColumnClass = (sentiment: string) => {
  switch (sentiment) {
    case 'Positive':
      return classes.positiveSentiment;
    case 'Negative':
      return classes.negativeSentiment;

    default:
      return classes.undefinedSentiment;
  }
};

const getCroppedContent = (content: string) => {
  if (content.length <= 240) {
    return <>{content}</>;
  }

  return (
    <>
      {`${content.slice(0, 240)} ...`}
      <button className='btn-ghost'>View more</button>
    </>
  );
};

export enum ContentStatus {
  HIDDEN = 'HIDDEN',
  VISIBLE = 'VISIBLE'
}

export type FlaggedComment = {
  uuid: string;
  buildingId: string;
  buildingName: string;
  groupName: string;
  groupId: string;
  userName: string;
  authorName: string;
  reporterName: string;
  createdAt: {
    displayValue: string;
    value: string;
  };
  content: string;
  comments: string;
  status: ContentStatus;
  flaggedByUser: boolean;
  sentimentScore: string;
  confidenceRating: number;
  images: string[];
};

export type CommentModerationAction =
  | 'PIN_POST'
  | 'MOVE_TO_MARKETPLACE'
  | 'HIDE'
  | 'UNHIDE'
  | 'REVIEW_ALL_ABOVE'
  | 'MARK_REVIEWED';

export const getColumnsForModerationTable = ({
  displayCommentDetails,
  onReviewAboveClicked,
  action
}: {
  displayCommentDetails: (comment: FlaggedComment) => void;
  action: (type: CommentModerationAction, comment: FlaggedComment) => void;
  onReviewAboveClicked: (comment: FlaggedComment) => void;
}): Column<any>[] => [
  {
    Header: 'Author',
    id: 'user',
    disableSortBy: true,
    accessor: (row: FlaggedComment) => {
      return (
        <div
          title={row.authorName}
          className={`${classes.tableColumn} ${classes.userName}`}
        >
          {row.authorName}
        </div>
      );
    }
  },
  {
    Header: 'Status',
    disableSortBy: true,
    accessor: (row: FlaggedComment) => {
      const visibleClassName =
        row.status == ContentStatus.HIDDEN
          ? classes.redText
          : classes.greenText;

      return (
        <div
          className={`${classes.tableColumn} ${classes.smallColumn} ${visibleClassName}`}
        >
          {row.status}
        </div>
      );
    }
  },
  {
    Header: 'Content',
    disableSortBy: true,
    width: '300px',
    accessor: (row: FlaggedComment) => {
      return (
        <div
          className={`${classes.tableColumn} ${classes.contentColumn}`}
          onClick={() => displayCommentDetails(row)}
        >
          <div>{getCroppedContent(row.content)}</div>
          {Array.isArray(row.images) && row.images.length > 0 && (
            <div className={`margin-top-16`}>
              <div className={classes.contentImages}>
                {row.images[0] && (
                  <div
                    className='margin-left-8'
                    style={{ background: `url(${row.images[0]})` }}
                  />
                )}
                {row.images[1] && (
                  <div
                    className='margin-left-8'
                    style={{ background: `url(${row.images[1]})` }}
                  />
                )}
                {/* {row.images[0] && <img className='margin-left-8' src={row.images[0]} />} */}
              </div>
              {row.images.length > 1 && (
                <button className='btn-ghost'>
                  + {row.images.length - 2} images
                </button>
              )}
            </div>
          )}
        </div>
      );
    }
  },
  {
    Header: 'Comment',
    disableSortBy: true,
    accessor: (row: FlaggedComment) => {
      if (!row.buildingId) return null;

      const query = qs.stringify({
        commentId: row.uuid,
        ...(row.groupId && { groupId: row.groupId })
      });

      return (
        <div className={`${classes.tableColumn} ${classes.smallColumn}`}>
          <a
            href={`/building/${row.buildingId}/comments?${query}`}
            className='btn-ghost'
            target='_blank'
          >
            <Comments height={24} width={24} />
          </a>
          {row.comments}
        </div>
      );
    }
  },
  {
    Header: 'Flagged',
    disableSortBy: true,
    accessor: (row: FlaggedComment) => {
      const className = row.flaggedByUser && classes.redText;
      return (
        <div className={`${classes.tableColumn} ${className}`}>
          {row.flaggedByUser ? 'Flagged' : 'Not Flagged'}
        </div>
      );
    }
  },
  {
    Header: 'Who Flagged',
    disableSortBy: true,
    accessor: (row: FlaggedComment) => {
      const className = row.flaggedByUser && classes.redText;
      return (
        <div
          className={`${classes.tableColumn} ${className} ${classes.smallColumn}`}
        >
          {row.flaggedByUser ? 'USER' : 'BOT'}
        </div>
      );
    }
  },
  {
    Header: 'Reporter',
    disableSortBy: true,
    accessor: (row: FlaggedComment) => {
      const className = row.reporterName && classes.redText;
      return (
        <div className={`${classes.tableColumn} ${className}`}>
          {row.reporterName}
        </div>
      );
    }
  },
  {
    Header: 'Sentiment',
    disableSortBy: true,
    accessor: (row: FlaggedComment) => {
      const visibleClassName = getSentimentColumnClass(row.sentimentScore);

      return (
        <div className={`${classes.tableColumn} ${visibleClassName}`}>
          {row.sentimentScore || 'Undefined'}
        </div>
      );
    }
  },
  {
    Header: 'Score',
    disableSortBy: true,
    accessor: (row: FlaggedComment) => {
      return (
        <div className={`${classes.tableColumn} ${classes.smallColumn}`}>
          {Math.floor(row.confidenceRating * 100)}%
        </div>
      );
    }
  },
  {
    Header: 'Building',
    disableSortBy: true,
    accessor: (row: FlaggedComment) => {
      return (
        <div className={`${classes.tableColumn} ${classes.building}`}>
          {row.buildingName}
        </div>
      );
    }
  },
  {
    Header: 'Group',
    disableSortBy: true,
    accessor: (row: FlaggedComment) => {
      return (
        <div className={`${classes.tableColumn} ${classes.groupColumn}`}>
          {row.groupName}
        </div>
      );
    }
  },
  {
    Header: 'When',
    id: 'when',
    disableSortBy: true,
    accessor: (row: FlaggedComment) => {
      return (
        <div className={`${classes.tableColumn} ${classes.contentCreatedAt}`}>
          {row.createdAt.displayValue}
        </div>
      );
    }
  },
  {
    Header: 'Actions',
    disableSortBy: true,
    accessor: (row: FlaggedComment) => {
      return (
        <div>
          <div className={`${classes.tableColumn} ${classes.actionsColumn}`}>
            {row.status == ContentStatus.VISIBLE && (
              <button
                className={classes.redText}
                onClick={() => action('HIDE', row)}
              >
                HIDE
              </button>
            )}
            {row.status == ContentStatus.HIDDEN && (
              <button
                className={classes.greenText}
                onClick={() => action('UNHIDE', row)}
              >
                UNHIDE
              </button>
            )}
            <button
              className={classes.reviewButton}
              onClick={() => action('MARK_REVIEWED', row)}
            >
              REVIEW
            </button>
            <button
              id={`reviewAboveButton-${row.uuid}`}
              className={classes.reviewAboveButton}
              onClick={() => onReviewAboveClicked(row)}
            >
              REVIEW ABOVE
            </button>
          </div>
          <div className={`${classes.tableColumn} ${classes.actionsColumn}`}>
            <button
              className={classes.reviewButton}
              onClick={() => action('PIN_POST', row)}
            >
              PIN POST
            </button>
            {row.groupName != 'Marketplace' && (
              <button
                className={classes.reviewButton}
                onClick={() => action('MOVE_TO_MARKETPLACE', row)}
              >
                MOVE TO MARKETPLACE
              </button>
            )}
          </div>
        </div>
      );
    }
  }
];
