import { FC } from 'react';
import { Column } from 'react-table';
import CobuTableAdvanced from '../CobuTableAdvanced/CobuTableAdvanced';
import React from 'react';

interface IProps {
  column: Column<any>;
  columnData: any;
  height?: number;
  sort?: {
    id: string;
    descending: boolean;
  };
}

export const TableColumn: FC<IProps> = ({
  column,
  columnData,
  height,
  sort
}) => {
  return (
    <CobuTableAdvanced
      columns={[column]}
      data={columnData}
      noData={{
        label: 'No Data',
        action: undefined,
        image: undefined
      }}
      cellTextAlign='center'
      height={height}
      sort={sort}
    />
  );
};
