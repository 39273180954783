import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import Cookies from 'universal-cookie';
import RsvpLogo from '~/utils/images/RsvpLogo.svg';

import community from '~/utils/images/community.svg';
import { ReactComponent as Android } from '~/utils/images/downloadAndroid.svg';
import { ReactComponent as AppleMobile } from '~/utils/images/ios.svg';

import './RSVPHomePageContainer.css';
import { RootState } from '~/redux/store';
import { termsURL } from '~/helpers/constants';
import { getOS } from '~/helpers/helpers';
import { OS } from '~/enums/OS';
import { fetchBuildingCode } from '~/api/residentGathering';

type Props = ReturnType<typeof mapStateToProps>;

const RSVPHomePageContainer = (props: Props) => {
  const history = useHistory();
  const cookies = new Cookies();
  const [showMobileBanner, setShowMobileBanner] = useState(false);
  const [mobileLink, setMobileLink] = useState('');
  const [device, setDevice] = useState<OS>(OS.Other);
  const { gatheringId } = useParams<{ gatheringId: string }>();

  useEffect(() => {
    const getLink = async () => {
      const res = await fetchBuildingCode(gatheringId);
      setMobileLink(res.data[0].link);
    };

    getLink();
    setDevice(getOS());
    if (device !== OS.Other) {
      setShowMobileBanner(true);
    } else {
      redirectIfNeeded();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [device, gatheringId]);

  const redirectIfNeeded = () => {
    const { user } = props;
    const token = cookies.get('token');

    if (cookies.get('token') && user.verifiedPhone && user.verified) {
      history.push(`/rsvp/gathering/${gatheringId}`);
    } else if (user.phone && token) {
      history.push('/rsvp-register/verification');
    } else if (user.email !== '' && token) {
      history.push('/rsvp-register/phone');
    }
  };

  return (
    <div>
      {showMobileBanner && (
        <div className='mobileWrapper'>
          <div className='mobileContent'>
            <span
              className='skipBtn'
              onClick={() => {
                setShowMobileBanner(false);
                redirectIfNeeded();
              }}
            >
              Skip
            </span>
            <div className='contentMain'>
              <h1>Welcome Resident</h1>
              <p>
                Get Cobu to RSVP on your mobile device. With Cobu, you can not
                only RSVP to events but also get help from your neighbors, join
                interest groups and trade items safely.
              </p>

              {device === OS.iOS && (
                <AppleMobile
                  onClick={() => {
                    window.location.href = mobileLink;
                  }}
                />
              )}
              {device === OS.Android && (
                <Android
                  onClick={() => {
                    window.location.href = mobileLink;
                  }}
                />
              )}

              <p>
                You can also open the email on a desktop computer and RSVP
                without installing the app.
              </p>
            </div>
          </div>
        </div>
      )}
      {!showMobileBanner && (
        <div className='login-container'>
          <div className='rsvp-left-container'>
            <img
              src={RsvpLogo}
              alt='RSVP on Cobu'
              className='margin-bottom-32'
            />
            <p className='RsvpDescription'>
              Log in or Register below to RSVP for your upcoming event.
            </p>
            <p className='RsvpDescription'>
              Use Cobu to get help from your neighbors, join interest groups,
              trade items safely and attend fun events together.
            </p>
            <div className='rsvp-button-container'>
              <button
                className='submit-button pointer rsvp-login-button'
                onClick={() => history.push(`/rsvp-login/${gatheringId}`)}
              >
                Log in
              </button>
              <button
                className='rsvp-register-button'
                onClick={() => history.push(`/rsvp-register/${gatheringId}`)}
              >
                Register
              </button>
            </div>
            <p className='terms'>
              By using the Cobu App you Agree to our
              <a href={termsURL()}> Terms</a>
            </p>
          </div>
          <div className='rsvp-image-container'>
            <img src={community} alt='rsvp-home' />
          </div>
        </div>
      )}
    </div>
  );
};

function mapStateToProps(state: RootState) {
  return {
    user: state.auth.user
  };
}

export default connect(mapStateToProps, null)(RSVPHomePageContainer);
