import React from 'react';
import classes from './UsernameDisplay.module.css';

type Props = {
  firstName: string;
  lastName: string;
  email: string;
  profileImage?: string;
};

const UsernameDisplay = (props: Props) => {
  const { firstName, lastName, profileImage, email } = props;
  return (
    <div className={classes.container}>
      {profileImage && (
        <img alt='Profile' src={profileImage} className='profile-image'></img>
      )}
      <div
        style={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          display: 'flex',
          alignItems: 'center'
        }}
      >
        <div className='margin-left-8'>
          <div className='weight-500 font-18'>
            {firstName} {lastName}
          </div>
          <div className='font-14'>{email}</div>
        </div>
      </div>
    </div>
  );
};

export default UsernameDisplay;
