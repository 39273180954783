import React, { useMemo, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';
import { CellProps } from 'react-table';
import { toast } from 'react-toastify';
import { deleteGatheringTemplate } from '~/api/gatherings';
import { Roles } from '~/enums/Roles';
import { useGatheringTemplates } from '~/hooks/useGatheringTemplates';
import { GatheringTemplate } from '~/types/gathering';
import CobuTable from '../CobuTable/CobuTable';
import buttonStyle from '../GatheringsTable/GatheringTable.styles';
import GenericModal from '../GenericModal/GenericModal';

interface Props {
  searchValue: string;
  userRole: string;
}

const GatheringTemplates = ({ searchValue, userRole }: Props) => {
  const { buildingId } = useParams<{ buildingId: string }>();
  const queryClient = useQueryClient();
  const history = useHistory();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState<GatheringTemplate>();

  const templateQuery = useGatheringTemplates(buildingId);

  const filteredTemplates = useMemo(() => {
    if (!templateQuery.isSuccess) {
      return [];
    }

    if (!searchValue) {
      return templateQuery.data;
    }

    return templateQuery.data.filter(
      (template) =>
        template.title.toLowerCase().includes(searchValue.toLowerCase()) ||
        template.description
          .toLowerCase()
          .includes(searchValue.toLowerCase()) ||
        template.recommendedMonth
          .toLowerCase()
          .includes(searchValue.toLowerCase())
    );
  }, [templateQuery.data, searchValue]);

  const deleteTemplateMutation = useMutation(deleteGatheringTemplate, {
    onSuccess: () => {
      toast('Successfully deleted template');
      queryClient.invalidateQueries('gathering-templates');
    },
    onError: (e: any) => {
      toast(
        `There was an error deleting the template: ${e.response.data.message.join(
          ','
        )}`
      );
    }
  });

  const baseColumns = useMemo(
    () => [
      {
        Header: 'GATHERING NAME',
        accessor: (row: GatheringTemplate) => {
          return (
            <div
              style={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                display: 'flex',
                flexDirection: 'column'
              }}
            >
              <div>{row.title}</div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginTop: '8px',
                  fontWeight: 500,
                  fontSize: '14px'
                }}
              >
                <div style={{ marginRight: '4px' }}>CREATOR:</div>
                <div style={{ color: 'rgba(39, 33, 99, 0.5)' }}>
                  {row.creator.firstName} {row.creator.lastName}
                </div>
              </div>
            </div>
          );
        }
      },
      {
        Header: 'DESCRIPTION',
        accessor: (row: GatheringTemplate) => {
          return (
            <div
              style={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap'
              }}
            >
              {row.description}
            </div>
          );
        }
      },
      {
        Header: 'WHAT TO BRING',
        accessor: (row: GatheringTemplate) => {
          return (
            <div
              style={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap'
              }}
            >
              {row.whatToBring}
            </div>
          );
        }
      },
      {
        Header: 'RECOMMENDED MONTH',
        accessor: (row: GatheringTemplate) => {
          return (
            <div
              style={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap'
              }}
            >
              {row.recommendedMonth}
            </div>
          );
        }
      },
      {
        Header: 'HOW TO',
        accessor: (row: GatheringTemplate) => {
          return (
            <div
              style={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap'
              }}
            >
              {row.howToUrl && (
                <a href={row.howToUrl} target='_blank'>
                  Link
                </a>
              )}
            </div>
          );
        }
      },
      {
        id: 'create',
        Cell: (cell: CellProps<GatheringTemplate>) => (
          <div
            style={buttonStyle}
            className={`pointer`}
            onClick={() =>
              history.push(`/building/${buildingId}/gathering/create`, {
                fromTemplate: true,
                template: {
                  title: cell.row.original.title,
                  image: cell.row.original.image,
                  description: cell.row.original.description,
                  whatToBring: cell.row.original.whatToBring,
                  isRSVP: true
                }
              })
            }
          >
            CREATE GATHERING
          </div>
        )
      }
    ],
    [buildingId]
  );

  const columns = useMemo(() => {
    if (userRole === Roles.Vcm || userRole === Roles.Superadmin) {
      return [
        ...baseColumns,
        {
          id: 'edit',
          Cell: (cell: CellProps<GatheringTemplate>) => (
            <div
              style={buttonStyle}
              className={`pointer`}
              onClick={() =>
                history.push(
                  `/building/${buildingId}/gathering-template/${cell.row.original.uuid}/edit`
                )
              }
            >
              EDIT
            </div>
          )
        },
        {
          id: 'delete',
          Cell: (cell: CellProps<GatheringTemplate>) => (
            <div
              style={buttonStyle}
              className={`pointer`}
              onClick={() => {
                setSelectedTemplate(cell.row.original);
                setShowDeleteModal(true);
              }}
            >
              DELETE
            </div>
          )
        }
      ];
    }

    return baseColumns;
  }, []);

  if (templateQuery.isSuccess) {
    return (
      <div>
        <CobuTable data={filteredTemplates} columns={columns} />
        <GenericModal
          showModal={showDeleteModal}
          title={'Delete template?'}
          actionText={'Delete'}
          actionFunction={() => {
            selectedTemplate &&
              deleteTemplateMutation.mutate(selectedTemplate?.uuid);
            setShowDeleteModal(false);
          }}
          onChange={(value: boolean) => {
            setShowDeleteModal(value);
          }}
          content={
            <div className='text-align-left'>
              Are you sure you want to delete {selectedTemplate?.title}
            </div>
          }
        />
      </div>
    );
  }

  return null;
};

export default GatheringTemplates;
