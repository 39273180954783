import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import CreateGroupForm, {
  CreateGroupFromValues
} from '../CreateGroupForm/CreateGroupForm';
import { GroupType } from '~/types/group';
import { useCobuSelector } from '~/hooks/useCobuSelector';

const getFormSchema = (options: {
  canEditBuilding?: boolean;
  canEditInterests?: boolean;
}) => {
  const createGroupSchema = Yup.object().shape({
    title: Yup.string()
      .max(500, 'The title must be no more than 500 characters')
      .required('Title is Required'),
    building: options.canEditBuilding
      ? Yup.string().required('Building is required')
      : Yup.string(),
    description: Yup.string()
      .min(10, 'The description must be at least 10 characters')
      .required('Description is required')
    // interests: Yup.array()
    //   .min(1, 'Please select at least one interest!')
    //   .required('Interest Field is required'),
    // image: Yup.mixed()
    //   .required('Uploading an image is required')
    //   .test(
    //     'fileFormat',
    //     'Image must be .jpg, .jpeg, or .png',
    //     (value) => value && SUPPORTED_FORMATS.includes(value.type)
    //   )
  });

  return createGroupSchema;
};

type CreateGroupProps = {
  groupSelected: GroupType | any;
  options?: {
    hideBuildingField?: boolean;
    hideInterestsField?: boolean;
  };
  onConfirmOld?: (data: FormData) => any;
  onConfirm: (values: CreateGroupFromValues) => any;
  buildings?: any[];
  Action?: any;
};

const CreateGroups = (props: CreateGroupProps) => {
  const { groupSelected, buildings = [], Action } = props;

  const { interests } = useCobuSelector(({ tags }) => ({
    interests: tags?.interests
  }));

  const handleTheSubmit = (values: CreateGroupFromValues) => {
    props.onConfirm(values);
  };

  if (!groupSelected) return <div></div>;

  return (
    <div>
      <Formik
        initialValues={{
          title:
            (groupSelected && groupSelected.title) || groupSelected.name || '',
          building: (groupSelected && groupSelected.building.uuid) || '',
          description: (groupSelected && groupSelected.description) || '',
          featured: groupSelected.featured ?? false,
          active: groupSelected.active ?? true,
          isDefault: groupSelected.isDefault ?? false,
          interests: [],
          image: groupSelected?.imageUrl || groupSelected?.image
        }}
        validationSchema={getFormSchema({
          canEditBuilding: !props.options?.hideBuildingField,
          canEditInterests: !props.options?.hideInterestsField
        })}
        onSubmit={handleTheSubmit}
      >
        {(formikProps) => (
          <CreateGroupForm
            {...formikProps}
            buildings={buildings}
            groupSelected={groupSelected}
            interests={interests}
            Action={Action}
            options={{
              canEditBuilding: !props.options?.hideBuildingField,
              canEditInterests: !props.options?.hideInterestsField
            }}
          />
        )}
      </Formik>
    </div>
  );
};

export default CreateGroups;
