import { ContentStatus, FlaggedComment } from '~/containers/ContentModerationContainer/tableColumns';
import { emperor } from '~/services';

export const fetchAllAdminsRequest = async () => {
  return await emperor.get(`/v2/admin/all`);
};

export const updateAdminRoleRequest = async (role: string, userId: string) => {
  return await emperor.patch(`/buildinguser/${userId}`, { role });
};

export const createAdminsRequest = async (
  emails: string[],
  building: string,
  role: string
) => {
  return await emperor.post(`v2/admins`, {
    building,
    emails: emails.filter((e) => !!e),
    role
  });
};

export const deleteOneBuildingUserRequest = async (data: {
  buildingId: string;
  userId: string;
}) => {
  return await emperor.post(`/buildinguser/remove`, data);
};

export const updateAdminBuildingsRequest = async (data: {
  user: string;
  buildings: string[];
}) => {
  return await emperor.post('/buildinguser/admin', data);
};

type GetCommentsToModerateResponse = {comments: FlaggedComment[]} 

export const getCommentsToModerate = async () => {
  return emperor.get<GetCommentsToModerateResponse>('/user/moderation/comments');
};

export type ModerationActions = 'MARK_REVIEWED' | 'UPDATE_VISIBILITY'

export const performModerationActionOnComment = async (payload: {id: string[], visibility?: ContentStatus, action: ModerationActions}) => {
  return emperor.put<GetCommentsToModerateResponse>('/user/moderation/comments', payload);
};
