
import React, { FC, useState } from 'react';
import { EditBuildingReviewSettings } from '~/components/EditBuildingReviewSettings/EditBuildingReviewSettings';
import GenericModal from '~/components/GenericModal/GenericModal';
import { Building } from '~/types/building';
import useGoogleReviewBuildingSettings from '~/hooks/googlereviews/useGoogleReviewBuildingSettings';
import { getGoogleSettingFormConfig } from '~/helpers/formHelpers';
import { GoogleInvitationSettings } from '~/types/reviewReceived';
import ReviewAlgorithResults from '~/components/ReviewAlgorithmResults/ReviewAlgorithmResults';
import CobuConstants from '~/helpers/cobuConstants';

interface IProps {
  building: Building;
  onEditBuilding: () => Promise<void>;
}

export const EditReviewSetting: FC<IProps> = ({ building, onEditBuilding }) => {
  const [showEditReviewSettingsForm, setShowEditReviewSettingsForm] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const { reviewSettings, getGoogleReviewSettings } = useGoogleReviewBuildingSettings(building);
  const [algoSettings, setAlgoSettings] = useState<GoogleInvitationSettings | undefined>();

  const toggleShowBuildingForm = () => {
    getGoogleReviewSettings();
    setShowEditReviewSettingsForm(prevState => !prevState);
  };

  const showAlgoPreview = (settings: GoogleInvitationSettings) => {
    setAlgoSettings(settings);
    setShowPreview(true);
  };

  return (
    <>
      <button className="btn-standard btn-primary" onClick={toggleShowBuildingForm}>
        {CobuConstants.editGoogleReviewSettings.buttonText.editGoogleReviewSettings}
      </button>

      <GenericModal
        showModal={showEditReviewSettingsForm}
        title={CobuConstants.editGoogleReviewSettings.modalTitles.editGoogleReviewSettings}
        enableOverflowScrolls
        dismissOnTapOutside={!showPreview}
        content={
          showEditReviewSettingsForm && (
            <EditBuildingReviewSettings
              defaultData={reviewSettings}
              building={building}
              showPreview={showAlgoPreview}
              formConfig={getGoogleSettingFormConfig()}
              onCancel={toggleShowBuildingForm}
              onEditBuilding={onEditBuilding}
            />
          )
        }
        hideActionButton
        canceActionButton
        actionText={CobuConstants.editGoogleReviewSettings.buttonText.editActionTitle}
        actionFunction={() => {}}
        onChange={() => {}}
      />
      <GenericModal
        showModal={showPreview && algoSettings !== undefined}
        title={CobuConstants.editGoogleReviewSettings.modalTitles.results}
        enableOverflowScrolls
        width={'1200px'}
        content={
          showPreview && algoSettings && (
            <ReviewAlgorithResults settings={algoSettings} building={building} onCancel={() => setShowPreview(false)} />
          )
        }
        hideActionButton
        canceActionButton
        actionText={CobuConstants.editGoogleReviewSettings.buttonText.editActionTitle}
        actionFunction={() => {}}
        onChange={() => {}}
      />
    </>
  );
};
