import Spinner from '~/components/Spinner/Spinner';
import React, { FC, useEffect, useState } from 'react';
import { fetchBuildingsRequest } from '~/api/building';
import useIsMounted from '~/hooks/isMounted';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchBuildingDetailsSuccess,
  fetchBuildingsSuccess
} from '~/reducers/building';
import BuildingCard from '~/components/BuildingCard/BuildingCard';
import { useHistory } from 'react-router-dom';
import { RootState } from '~/redux/store';
import { Building } from '~/types/building';
import './BuildingsListContainer.css';

interface IProps {
  loading: boolean;
}

export const BuildingList: FC<IProps> = ({ loading }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const buildings = useSelector((state: RootState) => state.building.buildings);

  const onClick = (uuid: string, building: Building) => {
    dispatch(fetchBuildingDetailsSuccess(building));

    history.push(`/building/${uuid}`);
  };

  return (
    <>
      <p className='buildinglist-title'>Choose your building</p>
      {loading && <Spinner />}
      {!loading && (
        <div className='buildinglist-grid'>
          {buildings?.map((b: Building) => (
            <BuildingCard buildingCard={b} onClick={onClick} key={b.uuid} />
          ))}
        </div>
      )}
    </>
  );
};
