import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { connect, ResolveThunks } from 'react-redux';
import { sendCode, verifyCode } from '~/api/auth';
import InvalidBlock from '~/components/InvalidBlock/InvalidBlock';

import RegisterVerificationForm from '~/components/RegisterVerificationForm/RegisterVerificationForm';
import { saveUser } from '~/reducers/auth';
import { RootState } from '~/redux/store';

import './RegisterVerificationContainer.css';

interface DispatchProps {
  saveUser: typeof saveUser;
}

type Props = ResolveThunks<DispatchProps> & ReturnType<typeof mapStateToProps>;

const RegisterVerificationContainer = (props: Props) => {
  const [invalidCode, setInvalidCode] = useState(false);
  const { user, saveUser } = props;
  const history = useHistory();

  const invalidCodeText =
    'The code you entered seems to be invalid. Please try again.';
  const onSubmit = (data: string) => {
    const code = data;
    verifyCode({ userId: user.uuid, code: code }).then(async (res) => {
      if (!res || res.status !== 200) {
        setInvalidCode(true);
      } else {
        saveUser({
          ...user,
          verified: true,
          verifiedPhone: true
        });
        history.push('/rsvp-register/success');
      }
    });
  };
  return (
    <div className='reg-ver-container'>
      <div className='reg-ver-content'>
        {invalidCode && <InvalidBlock text={invalidCodeText} />}
        <div className='reg-ver-title'>Verify Your Phone Number</div>
        <div className='reg-ver-text'>
          Enter the code we sent to
          <div className='phone-number'>{user.phone}</div>
        </div>
        <RegisterVerificationForm onSubmit={onSubmit} />
        <div className='reg-ver-footer'>
          <div className='reg-ver-footer-row'>
            <div className='reg-ver-second-text'>
              You didn't receive a code?
            </div>
            <div
              className='reg-var-action-text'
              onClick={() => sendCode(user.uuid)}
            >
              RESEND
            </div>
          </div>
          <div className='reg-ver-footer-row'>
            <div className='reg-ver-second-text'>Wrong phone number?</div>
            <div
              className='reg-var-action-text'
              onClick={() => history.push('/rsvp-register/phone')}
            >
              UPDATE NUMBER
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state: RootState) {
  return {
    user: state.auth.user
  };
}

function mapDispatchToProps(): DispatchProps {
  return {
    saveUser
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps()
)(RegisterVerificationContainer);
