import React from 'react';
import classes from './ContentModerationContainer.module.css';
import { ContentStatus, FlaggedComment } from './tableColumns';

type ModerationCommentDetailsProps = {
  comment?: FlaggedComment;
  onMarkReviewed: (comment: FlaggedComment) => void;
  onChangeVisibility: (
    comment: FlaggedComment,
    visibility: ContentStatus
  ) => void;
};

const ModerationCommentDetails = (props: ModerationCommentDetailsProps) => {
  if (!props.comment) {
    return null;
  }

  return (
    <div>
      <p className='margin-bottom-16'>
        <blockquote>{props.comment?.content}</blockquote>
      </p>
      <div>
        {props.comment?.images.map((image) => (
          <img width='100%' height='auto' src={image} />
        ))}
        <div className={classes.commentDetailsActions}>
          <button
            className='btn-primary btn-standard margin-left-16'
            onClick={() => props.onMarkReviewed(props.comment!)}
          >
            Mark Reviewed
          </button>
          {props.comment?.status == ContentStatus.HIDDEN && (
            <button
              className='btn-ghost btn-standard margin-left-16'
              onClick={() =>
                props.onChangeVisibility(props.comment!, ContentStatus.VISIBLE)
              }
            >
              Un-hide
            </button>
          )}
          {props.comment?.status == ContentStatus.VISIBLE && (
            <button
              className='btn-secondary btn-standard margin-left-16'
              onClick={() =>
                props.onChangeVisibility(props.comment!, ContentStatus.HIDDEN)
              }
            >
              Hide
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default ModerationCommentDetails;
