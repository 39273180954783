import { useEffect, useState } from "react"
import { toast } from "react-toastify"
import { getGoogleReviewsSettingsForBuilding, updateGoogleReviewsSettingsForBuilding } from "~/api/review"
import CobuConstants from "~/helpers/cobuConstants"
import { Building } from "~/types/building"
import { GoogleInvitationSettings } from "~/types/reviewReceived"

export default (building: Building) => {

    const [reviewSettings, setReviewSettings] = useState<GoogleInvitationSettings | undefined>(undefined)
    const [loader, setLoader] = useState<Boolean>(false)

    useEffect(() => {
        getGoogleReviewSettings()
    }, [building])

    const getGoogleReviewSettings = async () => {
        try {
            let settings = await getGoogleReviewsSettingsForBuilding(building.uuid)
            setReviewSettings(settings.data)
        } catch (error) {
            console.log(error)
        }
    }

    const updateGoogleReviewSettings = async (settings: GoogleInvitationSettings) => {

        try {
            setLoader(true)
            let updates = await updateGoogleReviewsSettingsForBuilding(building.uuid, settings)
            setReviewSettings(updates.data)
            setLoader(false)
            toast(CobuConstants.editGoogleReviewSettings.googleReviewSettingsUpdateSuccess)
        } catch (error) {
            console.log(error)
            setLoader(false)
            toast(CobuConstants.editGoogleReviewSettings.googleReviewSettingsUpdateFailed)
        }
    }

    return { getGoogleReviewSettings, updateGoogleReviewSettings, reviewSettings, loader }
}