import React, { FC, useRef } from 'react';
import {
  useOwnerGroupAsssets,
  useOwnerGroupGoogleReviews
} from '~/hooks/assets/useAssetManagement';
import CobuTableAdvanced from '../CobuTableAdvanced/CobuTableAdvanced';
import './AssetMangementTable.css';
import Spinner from '../Spinner/Spinner';
import {
  AssetGoogleReviewAccessor,
  IOwnerGroupGoogleRevew
} from '~/types/assets';
import Spacer from '../Spacer/Spacer';
import { Roles } from '~/enums/Roles';
import { RootState } from '~/redux/store';
import { useSelector } from 'react-redux';

interface IProps {
  heading: string;
  ownerId: string;
  isFromOwnerGroup: boolean;
}

export const AssetGoogleReviewsTable: FC<IProps> = ({
  heading,
  ownerId,
  isFromOwnerGroup
}) => {
  const { loading, ownerPropertiesGoogleReviews, googleReviewTableConfig } =
    useOwnerGroupGoogleReviews(ownerId, isFromOwnerGroup);

  const role = useSelector((state: RootState) => {
    return state.auth.userRole;
  });

  if (loading) {
    return <Spinner />;
  }

  const isVCMorSuperAdmin = role === Roles.Superadmin || role === Roles.Vcm;

  return (
    <>
      <div className='tableHeader'>
        <div className='title bold tableTitle'>{heading}</div>
      </div>
      <Spacer height={10} />
      {!!ownerPropertiesGoogleReviews && (
        <CobuTableAdvanced
          {...(isVCMorSuperAdmin
            ? { containerStyle: { position: 'relative' } }
            : {})}
          actionsStyle={
            isVCMorSuperAdmin
              ? {
                  top: '-65px',
                  right: '0px'
                }
              : {
                  position: 'static',
                  justifyContent: 'flex-end',
                  marginBottom: '10px'
                }
          }
          columns={[...googleReviewTableConfig]}
          data={ownerPropertiesGoogleReviews as IOwnerGroupGoogleRevew[]}
          noData={{
            label: 'No Data',
            action: undefined,
            image: undefined
          }}
          showPagination
          cellTextAlign='center'
          search={{
            searchField: AssetGoogleReviewAccessor.buildingName,
            placeholder: 'Search by property'
          }}
        />
      )}
    </>
  );
};
