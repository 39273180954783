//TO:DO :- Implement generics to fix type casting for response

import React, { useEffect, useMemo, useReducer, useState } from "react"
import { CellProps } from "react-table"
import { toast } from "react-toastify"
import { SendReviewInvitationApiPayload, activateMultipleResidents, activateResident, createResident, deactivateMultipleResidents, deactivateResident, getResidents, sendReviewInvitation, sendWelcomeMail } from "~/api/resident"
import Checkbox from "~/components/CheckBox/CheckBox"
import CobuConstants from "~/helpers/cobuConstants"
import { CreateResidentBodyType, ResidentUIModel } from "~/types/residentManagement"
import residentManagement, { loader, setResidents, toggleActive, resetState, updateSelection, initialState, hideLoader, toggleUserCreateModel } from "~/reducers/residentManagement"
import { useHistory } from 'react-router-dom';


export default (buildingId: string) => {

    const [residentState, dispatch] = useReducer(residentManagement, initialState)
    const history = useHistory()

    /* Data Logics*/

    useEffect(() => {
        getBuildingResidents(false)
    }, [])

    const getBuildingResidents = async (isRefresh: boolean = true) => {

        dispatch(loader({
            loadingString: isRefresh ? CobuConstants.residentManagement.refreshData : ''
        }))

        try {
            const residentsResponse = await getResidents(buildingId)
            const residents = sanitizeResidentdata(residentsResponse.data.residents)
            const active = residents.filter((r: ResidentUIModel) => !r.deactivated)
            const deactivated = residents.filter((r: ResidentUIModel) => r.deactivated)
            dispatch(setResidents({ activeResidents: sortData(active), deactivatedResidents: sortData(deactivated) }))
        } catch (error) {
            if (error) {
                toast(error?.message)
            }
        }
    }

    const sanitizeResidentdata = (residents: ResidentUIModel[]): ResidentUIModel[] => {
        return residents.map((resident) => {

            let intercomidSplit = resident.intercomUuid ? resident.intercomUuid.split('-') : []
            let oneSignalidSplit = resident.playerId ? resident.playerId.split('-') : []
            return {
                ...resident,
                fullName: resident.firstName + ' ' + resident.lastName,
                displayEmail: resident.email.substring(0, 30),
                displayIntercomId: intercomidSplit.length > 0 ? intercomidSplit[0] : '',
                displayOneSignaId: oneSignalidSplit.length > 0 ? oneSignalidSplit[0] : '',
            }
        })
    }

    const sortData = (items: ResidentUIModel[]): ResidentUIModel[] => {
        return items.sort((first, second) => first.fullName.toLowerCase() < second.fullName.toLowerCase() ? 1 : -1)
    }



    const createBuildingResident = async (resident: CreateResidentBodyType) => {

        dispatch(loader({
            loadingString: CobuConstants.residentManagement.createResidentLoader
        }))

        try {
            const createdResident = await createResident(resident)
            toast(CobuConstants.residentManagement.userCreateSuccess)
            refreshData()
        } catch (error) {
            dispatch(hideLoader())
            if (error) {
                toast(error?.message)
            }
        }
    }


    const deactivateBuildingResident = async (residentId: string) => {

        dispatch(loader({
            loadingString: CobuConstants.residentManagement.deactivateLoader
        }))

        try {
            const deactivateUserResp = await deactivateResident(residentId)
            toast(CobuConstants.residentManagement.deactivateSuccess)
            refreshData()
        } catch (error) {
            dispatch(hideLoader())
            if (error) {
                toast(error?.message)
            }
        }
    }


    const deactivateMultipleBuildingResident = async (residentIds: string[]) => {
        if (!residentIds.length) {
            toast(CobuConstants.residentManagement.nilSelectionError)
            return
        }
        dispatch(loader({
            loadingString: CobuConstants.residentManagement.deactivateMultipleLoader(residentIds.length)
        }))

        try {
            const deactivateUserResp = await deactivateMultipleResidents(residentIds)
            toast(CobuConstants.residentManagement.deactivateMultipleSuccess(residentIds.length))
            refreshData()
        } catch (error) {
            dispatch(hideLoader())
            if (error) {
                toast(error?.message)
            }
        }
    }

    const activateBuildingResident = async (residentId: string) => {

        dispatch(loader({
            loadingString: CobuConstants.residentManagement.activateLoader
        }))

        try {
            const deactivateUserResp = await activateResident(residentId)
            toast(CobuConstants.residentManagement.activateSuccess)
            refreshData()
        } catch (error) {
            dispatch(hideLoader())
            if (error) {
                toast(error?.message)
            }
        }
    }


    const activateMultipleBuildingResident = async (residentIds: string[]) => {
        if (!residentIds.length) {
            toast(CobuConstants.residentManagement.nilSelectionError)
            return
        }
        dispatch(loader({
            loadingString: CobuConstants.residentManagement.activateMultipleLoader(residentIds.length)
        }))

        try {
            const deactivateUserResp = await activateMultipleResidents(residentIds)
            toast(CobuConstants.residentManagement.activateMultipleSuccess(residentIds.length))
            refreshData()
        } catch (error) {
            dispatch(hideLoader())
            if (error) {
                toast(error?.message)
            }
        }
    }

    const welcomeMultipleBuildingResident = async (residentIds: string[]) => {
        if (!residentIds.length) {
            toast(CobuConstants.residentManagement.nilSelectionError)
            return
        }
        dispatch(loader({
            loadingString: residentIds.length > 1 ?
                CobuConstants.residentManagement.welcomeMailMultipleLoader(residentIds.length) :
                CobuConstants.residentManagement.welcomeMailLoader

        }))

        try {
            const deactivateUserResp = await sendWelcomeMail(residentIds)
            toast(residentIds.length > 1 ?
                CobuConstants.residentManagement.welcomeMailMultipleSuccess(residentIds.length) :
                CobuConstants.residentManagement.welcomeMailSuccess)
            refreshData()
        } catch (error) {
            dispatch(hideLoader())
            if (error) {
                toast(error?.message)
            }
        }
    }

    const sendReviewInvitationForResident = async (payload: SendReviewInvitationApiPayload) => {
        try {
            const sendReviewInvitationResp = await sendReviewInvitation(payload)
            toast(sendReviewInvitationResp.data.message)
        } catch (error) {
            dispatch(hideLoader())
            if (error) {
                toast(error?.message)
            }
        }
    }



    /* UI Logics */

    const toggleActiveState = () => {
        dispatch(toggleActive())
    }

    const showHideUserCreateModal = () => {
        dispatch(toggleUserCreateModel())
    }

    const refreshData = () => {
        dispatch(resetState())
        getBuildingResidents()
    }

    /* Need to decouple this logic to UI hook */
    const residentTableConfig = useMemo(() => [
        {
            id: 'check',
            Cell: (cell: CellProps<ResidentUIModel>) => {
                const option = {
                    text: CobuConstants.empty,
                    value: cell.row.original.uuid
                }
                let selectedOption = ''
                if (residentState.selectedIds.activeSelectedIds.includes(cell.row.original.uuid)
                    || residentState.selectedIds.deactivatedSelectedIds.includes(cell.row.original.uuid)) {
                    selectedOption = cell.row.original.uuid
                }
                return <Checkbox
                    index={cell.row.index}
                    defaultSetting
                    option={option}
                    selectedOption={selectedOption}
                    setSelectedOption={
                        function (value: string): void {
                            const updatedSelection = residentState.isActive ?
                                {
                                    ...residentState.selectedIds,
                                    activeSelectedIds: residentState.selectedIds.activeSelectedIds.includes(value) ?
                                        residentState.selectedIds.activeSelectedIds.filter((v) => v != value)
                                        :
                                        [...residentState.selectedIds.activeSelectedIds, value]
                                }
                                :
                                {
                                    ...residentState.selectedIds,
                                    deactivatedSelectedIds: residentState.selectedIds.deactivatedSelectedIds.includes(value) ?
                                        residentState.selectedIds.deactivatedSelectedIds.filter((v) => v != value)
                                        : [...residentState.selectedIds.deactivatedSelectedIds, value]
                                }
                            dispatch(updateSelection(updatedSelection))
                        }
                    }
                />
            }

        },
        {
            Header: 'NAME',
            accessor: 'fullName',
            Cell: (cell: CellProps<ResidentUIModel>) => {
                return <div
                    onClick={() => history.push(CobuConstants.navUrls.residentProfileLink(buildingId, cell.row.original.uuid))}
                    className='default-text pointer'>
                    {cell.row.original.fullName}
                </div>
            }
        },
        {
            Header: 'EMAIL',
            accessor: 'displayEmail'
        },
        {
            Header: 'UNIT NUMBER',
            accessor: 'roomNumber'
        },
        {
            Header: 'PHONE',
            accessor: 'phone'
        },
        {
            Header: 'INTERCOM',
            accessor: 'displayIntercomId',
            Cell: (cell: CellProps<ResidentUIModel>) => {
                let id = cell.row.original.displayIntercomId ? cell.row.original.displayIntercomId + ' \u2192' : ''
                return <a
                    href={CobuConstants.urls.intercomUrl(cell.row.original.intercomUuid)}
                    target="_blank"
                    className='default-text'>
                    {id}
                </a>
            }

        },
        {
            Header: 'ONESIGNAL',
            accessor: 'displayOneSignaId',
            Cell: (cell: CellProps<ResidentUIModel>) => {
                let id = cell.row.original.displayOneSignaId ? cell.row.original.displayOneSignaId + ' \u2192' : ''
                return <a
                    href={CobuConstants.urls.oneSignalUrl(cell.row.original.playerId)}
                    target="_blank"
                    className='default-text'>
                    {id}
                </a>
            }
        },
    ], [residentState.selectedIds, residentState.isActive])

    return {
        residentState,
        residentTableConfig,

        createBuildingResident,
        deactivateBuildingResident,
        deactivateMultipleBuildingResident,
        activateBuildingResident,
        activateMultipleBuildingResident,
        welcomeMultipleBuildingResident,
        sendReviewInvitationForResident,

        toggleActiveState,
        showHideUserCreateModal
    }
}

