import React, { useState } from 'react';
import GenericModal from '~/components/GenericModal/GenericModal';
import { actionButtonDisabled, modalStatus } from './PerksUtils';
import { toast } from 'react-toastify';
import { fetchBuildingMerchants } from '~/api/building';
import { connect } from 'react-redux';
import {
  updateMerchant,
  createPerkProposal,
  fetchPerksForBuilding,
  updateBuildingPerkValid,
  createMerchant
} from '~/api/perks';
import { deleteBuildingPerkSuccess, fetchPerksSuccess } from '~/reducers/perks';
import { BuildingPerk } from '~/types/buildingPerk';
import { Merchant, MerchantPatch } from '~/types/merchant';

interface DispatchProps {
  fetchPerksSuccess: typeof fetchPerksSuccess;
  deleteBuildingPerkSuccess: typeof deleteBuildingPerkSuccess;
}

interface IProps {
  showCreateOfferModal: boolean;
  offerModalTitle: string;
  modalIndex: number;
  selectedMerchant: string;
  createdProposal: string;
  newMerchantName: string;
  selectedCategory: string;
  actionTextModal: string;
  setModalIndex: (value: number) => void;
  setCreatedProposal: (value: string) => void;
  composePerkProposalLink: (value: string) => string;
  buildingId: string;
  setPerkProposals: (value: BuildingPerk[]) => void;
  perkProposals: BuildingPerk[];
  setShowCreateOfferModal: (value: boolean) => void;
  setSelectedMerchant: (value: string) => void;
  setSelectedCategory: (value: string) => void;
  setNewMerchantName: (value: string) => void;
  setBuildingMerchants: (value: Merchant[]) => void;
  merchantForEdit: MerchantPatch;
  modalContent: any;
  showDeleteModal: boolean;
  selectedBuildingPerk: BuildingPerk | null;
  setShowDeleteModal: (value: boolean) => void;
  actionSuccess: (value: boolean) => void;
}

const PerksModal = (props: IProps) => {
  const [disableActionButton, setDisableActionButton] = useState(false);

  const {
    showCreateOfferModal,
    offerModalTitle,
    modalIndex,
    selectedMerchant,
    createdProposal,
    newMerchantName,
    selectedCategory,
    actionTextModal,
    setModalIndex,
    setCreatedProposal,
    composePerkProposalLink,
    buildingId,
    setPerkProposals,
    perkProposals,
    setShowCreateOfferModal,
    setSelectedMerchant,
    setSelectedCategory,
    setNewMerchantName,
    setBuildingMerchants,
    merchantForEdit,
    modalContent,
    showDeleteModal,
    selectedBuildingPerk,
    setShowDeleteModal,
    actionSuccess
  } = props;

  const {
    NEW_OFFER,
    GET_OFFER_LINK,
    NEW_MERCHANT,
    MERCHANT_CREATED_SUCCESS,
    EDIT_MERCHANT
  } = modalStatus;

  return (
    <div id='modalContainer'>
      <GenericModal
        showModal={showCreateOfferModal}
        title={offerModalTitle}
        actionText={actionTextModal}
        actionButtonDisabled={actionButtonDisabled(
          modalIndex,
          selectedMerchant,
          disableActionButton,
          createdProposal,
          newMerchantName,
          selectedCategory
        )}
        actionFunction={() => {
          if (modalIndex === NEW_OFFER) {
            if (selectedMerchant) {
              if (selectedMerchant === 'null') {
                setModalIndex(NEW_MERCHANT);
                return;
              }
              createPerkProposal(selectedMerchant, buildingId).then((res) => {
                setCreatedProposal(res.data.uuid);
                setPerkProposals([...perkProposals, res.data]);
              });
            }
            setModalIndex(GET_OFFER_LINK);
          } else if (modalIndex === GET_OFFER_LINK) {
            navigator.clipboard.writeText(
              composePerkProposalLink(createdProposal)
            );
            toast('Link copied to clipboard.');
            setModalIndex(NEW_OFFER);
            setCreatedProposal('');
            setShowCreateOfferModal(false);
          } else if (modalIndex === NEW_MERCHANT) {
            setDisableActionButton(true);
            createMerchant(newMerchantName, selectedCategory, buildingId).then(
              (res) => {
                setModalIndex(NEW_OFFER);
                setSelectedCategory('');
                setNewMerchantName('');
                fetchBuildingMerchants(buildingId).then((res) => {
                  setBuildingMerchants(res.data);
                });
                setDisableActionButton(false);
                setModalIndex(MERCHANT_CREATED_SUCCESS);
              }
            );
          } else if (modalIndex === MERCHANT_CREATED_SUCCESS) {
            setModalIndex(NEW_OFFER);
          } else if (modalIndex === EDIT_MERCHANT) {
            actionSuccess(false);
            setDisableActionButton(true);
            updateMerchant({
              uuid: merchantForEdit.uuid,
              name: newMerchantName,
              category: selectedCategory
            }).then(() => {
              fetchPerksForBuilding(buildingId).then((res) => {
                fetchPerksSuccess(res.data);
                actionSuccess(true);
              });
              setShowCreateOfferModal(false);
              setDisableActionButton(false);
              setModalIndex(NEW_OFFER);
            });
          }
        }}
        onChange={(value: boolean) => {
          setShowCreateOfferModal(value);
          setCreatedProposal('');
          setSelectedMerchant('');
          setSelectedCategory('');
          setNewMerchantName('');
          setModalIndex(NEW_OFFER);
        }}
        content={modalContent}
      />
      <GenericModal
        showModal={showDeleteModal}
        title={'Delete Perk?'}
        actionText={'Delete'}
        actionButtonDisabled={disableActionButton}
        actionFunction={() => {
          actionSuccess(false);
          if (selectedBuildingPerk !== null) {
            setDisableActionButton(true);
            updateBuildingPerkValid(
              selectedBuildingPerk.buildingPerkUuid,
              false
            ).then(() => {
              deleteBuildingPerkSuccess({
                uuid: selectedBuildingPerk.buildingPerkUuid
              });
              toast(
                `Perk ${selectedBuildingPerk.perk.name} sucesfully deleted`
              );
              setDisableActionButton(false);
              setShowDeleteModal(false);
              actionSuccess(true);
            });
          }
        }}
        onChange={(value: boolean) => {
          setShowDeleteModal(value);
        }}
        content={
          <div className='text-align-left'>
            Are you sure you want to delete&nbsp;
            {selectedBuildingPerk?.perk.name} perk?
          </div>
        }
      />
    </div>
  );
};

function mapDispatchToProps(): DispatchProps {
  return {
    fetchPerksSuccess,
    deleteBuildingPerkSuccess
  };
}

export default connect(null, mapDispatchToProps())(PerksModal);
