import React from 'react';
import { ColumnGrowTable } from '~/components/ColumnGrowTable/ColumnGrowTable';
import './OwnerManagement.css';
import Spinner from '~/components/Spinner/Spinner';
import GenericModal from '~/components/GenericModal/GenericModal';
import {
  IOwnerManagementTableData,
  OwnerManagementModelTypes,
  useOwnerManagement
} from '~/hooks/assets/useOwnerManagement';
import {
  IBuildingWithClient,
  IOwnerGroupManagement,
  OwnerManagementAccessor
} from '~/types/assets';
import { toTitleCase } from '~/utils/stringUtils';
import Checkbox from '~/components/CheckBox/CheckBox';
import Breadcrumbs from '~/components/Breadcrumbs/Breadcrumbs';
import Spacer from '~/components/Spacer/Spacer';

export const OwnerManagement = () => {
  const {
    tableData,
    columnConfig,
    loading,
    showModal,
    closeModal,
    onCreateEntity,
    modalType,
    inputRef,
    selectRef,
    nonManagedProperties,
    isAlsoPropertyMngGroup,
    toggleIsAlsoPropertyMngGroup,
    availablePmOwnerToSelectedOwner
  } = useOwnerManagement();

  const isOwnerGroup = modalType === OwnerManagementModelTypes.ownerGroup;
  const isPropertyMngGroup =
    modalType === OwnerManagementModelTypes.propertyMngGroup;
  const isPropertyModal = modalType === OwnerManagementModelTypes.property;

  const renderContent = () => {
    switch (modalType) {
      case OwnerManagementModelTypes.ownerGroup:
      case OwnerManagementModelTypes.propertyMngGroup:
        return (
          <>
            <input
              className='tag-input'
              type='text'
              placeholder={`E.g. ${
                isOwnerGroup || isPropertyMngGroup ? 'Paradigm' : ''
              }`}
              ref={inputRef}
            />
            {isOwnerGroup && (
              <Checkbox
                highlight
                index={0}
                option={{
                  text: 'is this group also a PROPERTY MANAGEMENT GROUP',
                  value: '1'
                }}
                selectedOption={isAlsoPropertyMngGroup}
                setSelectedOption={toggleIsAlsoPropertyMngGroup}
              />
            )}
          </>
        );
      case OwnerManagementModelTypes.property:
        return (
          <select ref={selectRef} className='height-50'>
            {nonManagedProperties.map(
              (propertyWithClient: IBuildingWithClient) => {
                return (
                  <option
                    value={propertyWithClient.buildingId}
                    key={propertyWithClient.buildingId}
                  >
                    {toTitleCase(propertyWithClient.name)}
                  </option>
                );
              }
            )}
          </select>
        );
      case OwnerManagementModelTypes.assignPropertyMngGroup:
        return (
          <select ref={selectRef} className='height-50'>
            {availablePmOwnerToSelectedOwner.map(
              (pmGroup: IOwnerGroupManagement) => {
                return (
                  <option value={pmGroup.uuid} key={pmGroup.uuid}>
                    {toTitleCase(pmGroup.name)}
                  </option>
                );
              }
            )}
          </select>
        );
    }
  };

  return (
    <div className='property-container float-spinner-parent'>
      <Breadcrumbs />
      <Spacer height={20} />
      {loading && <Spinner float />}
      <ColumnGrowTable<IOwnerManagementTableData>
        columns={columnConfig}
        tableData={tableData}
        height={window.screen.height * 0.5}
      />
      {showModal && modalType && (
        <GenericModal
          showModal={showModal}
          title={`Add ${
            isOwnerGroup
              ? 'Owner Group'
              : isPropertyModal
              ? 'Property'
              : 'Property Management Group'
          }`}
          content={renderContent()}
          actionText={isOwnerGroup || isPropertyMngGroup ? 'Create' : 'Save'}
          onChange={closeModal}
          actionButtonDisabled={false}
          actionFunction={onCreateEntity}
        />
      )}
    </div>
  );
};
