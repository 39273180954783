import React from 'react';
import { useForm } from 'react-hook-form';

import classes from './ResetPasswordForm.module.css';
import { ReactComponent as ExclamationMark } from '~/utils/images/exclamation-mark.svg';
import { PasswordResetFormType } from '~/types/auth';

interface IProps {
  onSubmit: (data: PasswordResetFormType) => void;
}
export const ResetPasswordForm = (props: IProps) => {
  const { register, handleSubmit, errors, watch } = useForm<{
    password: string;
    passwordConfirm: string;
  }>();

  const onSubmit = (data: PasswordResetFormType) => {
    props.onSubmit(data);
  };

  return (
    <div>
      {errors && Object.keys(errors).length !== 0 && (
        <div className={classes.errorContainer}>
          <ExclamationMark fill='var(--cobuError' />
          <span className={classes.errorMessage}>
            {errors.passwordConfirm
              ? errors.passwordConfirm.message
              : errors.password?.message}
          </span>
        </div>
      )}
      <h2 className='bold'>Change password?</h2>
      <p>Enter your new password for your Cobu account.</p>
      <form onSubmit={handleSubmit(onSubmit)}>
        <input
          name='password'
          type='password'
          placeholder={'Enter a new password'}
          className={`${errors.password && 'border-red'}`}
          ref={register({
            required: 'You must specify a password',
            minLength: {
              value: 8,
              message: 'Password must have at least 8 characters'
            }
          })}
        />
        <input
          name='passwordConfirm'
          type='password'
          placeholder={'Confirm your password'}
          className={`${errors.passwordConfirm && 'border-red'}`}
          ref={register({
            validate: (value) => {
              return (
                value === watch('password') ||
                'Passwords don’t match! Please try again.'
              );
            }
          })}
        />

        <button
          type='submit'
          className='btn-primary btn-large margin-top-8 margin-bottom-8 pointer margin'
        >
          Continue
        </button>
      </form>
    </div>
  );
};

export default ResetPasswordForm;
