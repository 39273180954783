import React, { useState } from 'react';
import { useInfiniteQuery, useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import {
  fetchCommentThreadRequest,
  fetchGroupEventCommentThreadRequest,
  fetchSentimentComments
} from '~/api/building';
import { buildingSelector } from '~/redux/selectors';
import { RootState } from '~/redux/store';
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';
import GenericModal from '../GenericModal/GenericModal';
import Spinner from '../Spinner/Spinner';
import BasicComment from './BasicComment';
import styles from './styles.module.css';

const SentimentComments = () => {
  const { pathname } = useLocation();
  const { buildingId } = useParams<{ buildingId: string }>();
  const building = useSelector((state: RootState) =>
    buildingSelector(state, buildingId)
  );
  const [selectedComment, setSelectedComment] = useState<string | null>(null);
  const [selectedCommentType, setSelectedCommentType] = useState<string>(
    'groupcomment'
  );

  const sentiment = pathname.substring(pathname.lastIndexOf('/') + 1);
  const capitalSentiment =
    sentiment.charAt(0).toUpperCase() + sentiment.slice(1);

  const { data, isLoading, fetchNextPage, hasNextPage } = useInfiniteQuery(
    ['sentimentComments', buildingId, sentiment],
    ({ pageParam = 0 }) =>
      fetchSentimentComments(sentiment, buildingId, pageParam),
    {
      getNextPageParam: (lastPage, pages) =>
        lastPage.length ? pages.length : undefined
    }
  );

  const { data: threadData, isLoading: isThreadLoading } = useQuery(
    ['thread', selectedComment],
    selectedCommentType === 'groupcomment'
      ? () => fetchCommentThreadRequest(selectedComment || '')
      : () => fetchGroupEventCommentThreadRequest(selectedComment || ''),
    {
      enabled: !!selectedComment
    }
  );

  const handleShowThreadClick = (commentId: string, type: string) => {
    setSelectedComment(commentId);
    setSelectedCommentType(type);
  };

  const ModalContent = () => {
    if (isThreadLoading || !threadData) {
      return <Spinner />;
    }

    const parentComment = threadData.data[0];

    return (
      <div>
        <BasicComment comment={parentComment} parent={true} />
        {parentComment.children.length
          ? parentComment.children.map((child) => (
              <BasicComment comment={child} parent={false} />
            ))
          : null}
      </div>
    );
  };

  const renderContent = () => {
    if (isLoading) return <Spinner />;
    return (
      <div className={styles.container}>
        <p className={styles.commentHeader}>COMMENTS</p>
        <div className={styles.commentContainer}>
          {data &&
            data.pages.map((page, i) => (
              <React.Fragment key={i}>
                {page.map((comment) => (
                  <div key={comment.uuid} className={styles.comment}>
                    <p className={styles.contents}>{comment.contents}</p>
                    {comment.removed && (
                      <p className='removed-label checkbox-text margin-left-8 warning-background'>
                        {`Comment has been DELETED by ${
                          comment.removedBy || 'ADMIN'
                        }`}
                      </p>
                    )}
                    {comment.hidden && (
                      <p className='removed-label checkbox-text margin-left-8 warning-background'>
                        {`Comment has been HIDDEN by ${
                          comment.hiddenBy || 'ADMIN'
                        }`}
                      </p>
                    )}
                    <div
                      className={styles.viewThread}
                      onClick={() =>
                        handleShowThreadClick(comment.uuid, comment.type)
                      }
                    >
                      View comment thread
                    </div>
                  </div>
                ))}
              </React.Fragment>
            ))}
          {hasNextPage && (
            <div className={styles.fetchMore} onClick={() => fetchNextPage()}>
              Fetch more
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className='content-container'>
      <Breadcrumbs indexesToHide={[2]} />
      <p className='h1-title'>
        {capitalSentiment} comments in{' '}
        <strong>{building && building.name}</strong>
      </p>
      {renderContent()}
      <GenericModal
        showModal={!!selectedComment}
        title={''}
        content={<ModalContent />}
        actionText={''}
        actionFunction={() => {}}
        onChange={() => {
          setSelectedComment(null);
        }}
        hideActionButton
      />
    </div>
  );
};

export default SentimentComments;
