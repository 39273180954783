import React from 'react';
import { Field, FormikProps } from 'formik';
import ImageDropzone from '../ImageDropzone/ImageDropzone';
import Tags from '../Tags/Tags';
import { Colors } from '~/enums/Colors';
import { Building } from '~/types/building';
import { Group } from '~/reducers/groupAdmin';
import { CobuCheckbox } from '../CobuCheckbox';

export interface CreateGroupFromValues {
  title: string;
  building: string;
  description: string;
  active: boolean;
  featured: boolean;
  interests: any[];
  image: any;
  isDefault: boolean;
}

export interface CreateGroupFromValueErrors {
  title: string;
  description: string;
  building: string;
  featured: string;
  active: string;
}

type CreateGroupFormProps = FormikProps<CreateGroupFromValues> & {
  buildings?: Building[];
  groupSelected: Group;
  interests: any[];
  Action?: any;
  options?: {
    canEditBuilding?: boolean;
    canEditInterests?: boolean;
  };
};

const CreateGroupForm = (props: CreateGroupFormProps) => {
  const {
    values,
    errors,
    touched,
    setFieldValue,
    handleSubmit,
    setFieldTouched,
    interests,
    handleChange
  } = props;

  const handleCheckbox = (key: string, value: boolean) => {
    setFieldValue(key, value, true);
  };

  return (
    <form className='group-form' onSubmit={handleSubmit}>
      <div>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <label>Group Title</label>
          <input
            id='title'
            name='title'
            onChange={handleChange}
            onBlur={() => setFieldTouched('title', true, false)}
            value={values.title}
          />
          {errors.title && (
            <label style={{ color: Colors.cobuRed }}>{errors.title}</label>
          )}
        </div>
        {props.options?.canEditBuilding && (
          <div className='margin-top-16'>
            <label id='building-label'>Building</label>
            <select
              name='building'
              value={values.building}
              onChange={handleChange}
              className='margin-left-16'
            >
              <option value='' disabled>
                Select a Building
              </option>
              {props.buildings?.map((building) => (
                <option value={building.uuid} key={building.uuid}>
                  {building.name}
                </option>
              ))}
            </select>

            <p style={{ color: Colors.cobuRed }} className='interestErrorTag'>
              {touched.building && Boolean(errors.building)
                ? errors.building
                : null}
            </p>
          </div>
        )}
        <div
          className='margin-top-16 margin-bottom-16'
          style={{ display: 'flex', flexDirection: 'column' }}
        >
          <label>Group Description</label>
          <textarea
            id='description'
            name='description'
            onChange={handleChange}
            onBlur={() => setFieldTouched('description', true, false)}
            value={values.description}
          />
          {touched.description && Boolean(errors.description) && (
            <p style={{ color: Colors.cobuRed }}>{errors.description}</p>
          )}
        </div>
        <CobuCheckbox
          checked={values.active}
          onChange={(value: boolean) => handleCheckbox('active', value)}
          title='Viewable to residents'
        ></CobuCheckbox>
        <CobuCheckbox
          checked={values.featured}
          onChange={(value: boolean) => handleCheckbox('featured', value)}
          title='Featured'
        ></CobuCheckbox>
        <CobuCheckbox
          checked={values.isDefault}
          onChange={(value: boolean) => handleCheckbox('isDefault', value)}
          title='Default'
        ></CobuCheckbox>
        {props.options?.canEditInterests && (
          <Field
            name='interests'
            component={(formikProps: FormikProps<any>) => (
              // @ts-ignore
              <Tags {...formikProps} type='interests' tagObjects={interests} />
            )}
          />
        )}
        <div className='margin-top-16'>
          <Field
            name='image'
            component={(formikProps: FormikProps<any>) => (
              <ImageDropzone
                image={values?.image}
                handleDrop={(files) => {
                  setFieldValue('image', files[0], true);
                  setFieldTouched('image', true, false);
                }}
                {...formikProps}
              />
            )}
          />
        </div>
        {touched.description && Boolean(errors.description) && (
          <p style={{ color: Colors.cobuRed }}>{errors.description}</p>
        )}
      </div>
      {props.Action ?? (
        <button type='submit' className='btn-standard btn-primary'>
          Create Group
        </button>
      )}
    </form>
  );
};

export default CreateGroupForm;
