import React, { CSSProperties, useMemo, useRef, useState } from 'react';
import './ActionDropDown.css';
import { useOnClickOutside } from '../../helpers/helpers';
import '../MoreActionsGatherings/MoreActionsGatherings.css';

interface IProps {
  title?: string;
  options: {
    name?: string;
    content?: any;
    label?: string;
    action?: () => void;
  }[];
  showIcon?: boolean;
  showActionPointer?: boolean;
  icon?: React.ReactNode;
  containerStyles?: CSSProperties;
  optionStyles?: CSSProperties;
  containerClassCb?: (isSelected: boolean) => string;
}

const ActionDropDown = (props: IProps) => {
  const [selected, setSelected] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  useOnClickOutside(ref, () => setSelected(false));
  const {
    options,
    title,
    showIcon = false,
    icon,
    containerStyles,
    optionStyles,
    showActionPointer = true,
    containerClassCb
  } = props;

  const containerClassName = useMemo(() => {
    if (containerClassCb) {
      return containerClassCb(selected);
    }

    return selected
      ? 'dropdown-actions-btn dropdown-actions-btn-selected'
      : 'dropdown-actions-btn dropdown-actions-btn-unselected';
  }, [selected]);

  return (
    <div ref={ref} className={'menuIcons floatLeft'}>
      <div
        style={containerStyles}
        className={containerClassName}
        onClick={() => setSelected(!selected)}
      >
        {showIcon && icon}
        {title ?? `MORE ACTIONS`}
      </div>
      {selected && (
        <div className='dropdown-actions-dropdown' style={optionStyles}>
          {options.map((option, index) => (
            <div
              key={index}
              className={`dropdown-actions-option ${
                showActionPointer ? 'pointer' : ''
              }`}
              onClick={() => {
                if (option.action) {
                  option.action();
                  setSelected(false);
                }
              }}
            >
              {option.content || option.label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
export default ActionDropDown;
