import store from '~/redux/store';
import { Building } from '~/types/building';
import { GatheringType } from '~/types/gathering';

function getBuildingFromStore(buildingId: string) {
  return (
    store.getState().building.buildings.find((b) => b.uuid === buildingId) ||
    ({} as Building)
  );
}

function getGatheringFromStore(gatheringId: string) {
  return (
    store.getState().building.gatherings.find((g) => g.uuid === gatheringId) ||
    ({} as GatheringType)
  );
}
export { getBuildingFromStore, getGatheringFromStore };
