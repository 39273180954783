const buttonStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignContent: 'center',
  borderRadius: '4px',
  padding: '4px 12px 4px 12px',
  margin: '0 auto',
  backgroundColor: 'rgba(39,33,99,0.1)',
  width: 'fit-content',
  fontSize: '12px',
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  fontWeight: 500
};

export default buttonStyle;
