import React, { useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import Spinner from '~/components/Spinner/Spinner';
import useCreateReferralLead from '~/hooks/building/referral/useCreateReferralLead';
import {
  ReferralIntroMarkups,
  ReferralLandingPages,
  formConfig
} from './constants';
import { FormInputConfig } from '~/types/residentManagement';
import { FormField } from '~/components/FormField';
import './DefaultReferralPage.css';
import { useParams } from 'react-router-dom';
import { IReferralLeadFormData } from './interfaces';
import { toast } from 'react-toastify';
import cobuLogo from '~/utils/images/cobuLogo.svg';
import Spacer from '~/components/Spacer/Spacer';
import MultiSelectCheckboxList from '~/components/MultiSelectCheckBoxList/MultiSelectCheckBoxList';
import { CobuFormErrors } from '~/helpers/cobuConstants';
import GenericModal from '~/components/GenericModal/GenericModal';
import { Mixpanel } from '~/services/MixpanelService';
import { convertToBold } from '~/utils/stringUtils';
import { ReferralPageV1 } from './ReferralPages/ReferrralPageV1/ReferralPageV1';
import { IReferralPageOption } from '~/api/referral';

declare global {
  interface Window {
    isAppReferral: boolean;
    isPreviewOnly: boolean;
  }
}

export const ReferralForm = () => {
  const isAppReferral = !!window.isAppReferral;
  const isPreviewOnly = !!window.isPreviewOnly;

  const { referralCode } = useParams<{
    referralCode: string;
  }>();

  const {
    loader,
    referraFormlData,
    onSubmit,
    showThankYou,
    onShowThankYou,
    submitAnother,
    termsAndConditionOption,
    setTermsAndConditionOption,
    emailConfirmationOption,
    setEmailConfirmationOption,
    showTermAndCondition,
    setShowTermAndCondition,
    buildingConfig
  } = useCreateReferralLead(referralCode, isAppReferral, isPreviewOnly);

  const {
    register,
    handleSubmit,
    errors,
    control,
    formState: { isDirty },
    setValue,
    reset
  } = useForm<IReferralLeadFormData>({
    defaultValues: {}
  });

  const referralLandingPage = (
    referraFormlData?.referralOffer
      ?.referralLandingPageOption as IReferralPageOption
  )?.name;

  const onSubmitForm = async (data: IReferralLeadFormData) => {
    if (!referraFormlData) {
      toast('There is no referral offer for this building');
      return;
    }

    if (!isDirty) {
      toast('Please fill the form');

      return;
    }

    const { tc, ...formData } = data;
    const isEmployee = !!referraFormlData?.referredUser?.isEmployee;

    onSubmit(
      formData,
      referralLandingPage,
      { isAppReferral, isEmployee },
      () => {
        if (!isPreviewOnly) {
          Mixpanel.track('referral_form_submit', {
            formData,
            buildingId: referraFormlData?.building.uuid,
            buildingName: referraFormlData?.building.name,
            referrerId: referraFormlData?.referredUser.uuid,
            referralOfferId: referraFormlData?.referralOffer.uuid,
            isAppReferral,
            referralLandingPage,
            isEmployee
          });
        }

        reset();

        onShowThankYou();
      }
    );
  };

  const onTermAndCondition = (value: string, isSelected: boolean) => {
    const newTermConditionOption = [...termsAndConditionOption];

    const selectedOption = newTermConditionOption.find(
      (option) => option.value === value
    );

    if (selectedOption) {
      selectedOption.isSelected = isSelected;
    }

    setTermsAndConditionOption(newTermConditionOption);
  };

  const onEmailConfirmation = (value: string, isSelected: boolean) => {
    const newEmailConfirmationOption = [...emailConfirmationOption];

    const selectedOption = newEmailConfirmationOption.find(
      (option) => option.value === value
    );

    if (selectedOption) {
      selectedOption.isSelected = isSelected;
    }

    setEmailConfirmationOption(newEmailConfirmationOption);
  };

  const generateField = (fieldConfig: FormInputConfig) => {
    return (
      <div key={fieldConfig.id}>
        <div className='item'>
          {!!fieldConfig?.label && (
            <label
              style={fieldConfig?.styles?.lableStyles}
              className={`${fieldConfig?.styles?.lableClassName}`}
              htmlFor='title'
            >
              {fieldConfig.label}
            </label>
          )}
          <FormField
            fieldConfig={fieldConfig}
            errors={errors}
            register={register}
            control={control}
          />
        </div>
      </div>
    );
  };

  const onSubmitAnotherLead = () => {
    onTermAndCondition(termsAndConditionOption[0].value, false);

    submitAnother();
  };

  const referrerName = `${referraFormlData?.referredUser.firstName ?? ''} ${
    referraFormlData?.referredUser.lastName ?? ''
  }`;

  const referralIntroHeader = useMemo(() => {
    if (referraFormlData?.userCustomization?.welcome_message) {
      return referraFormlData?.userCustomization?.welcome_message;
    }

    let referralHeader = referraFormlData?.referredUser?.isEmployee
      ? referraFormlData?.referralOffer?.employeeLandingPageIntroParagraph ||
        referraFormlData?.referralOffer.landingpageIntroParagraph
      : referraFormlData?.referralOffer.landingpageIntroParagraph;

    if (!referralHeader) {
      return null;
    }

    const parts = referralHeader.split(
      new RegExp(
        `(${ReferralIntroMarkups.referredBy}|${ReferralIntroMarkups.buildingName})`
      )
    );

    const replacedString: string[] = parts.reduce((prev, next) => {
      switch (next) {
        case ReferralIntroMarkups.referredBy:
          prev.push(`<strong>${referrerName}</strong>`);
          break;

        case ReferralIntroMarkups.buildingName:
          prev.push(`<strong>${referraFormlData?.building?.name}</strong>`);
          break;

        default:
          prev.push(next);
      }

      return prev;
    }, [] as string[]);

    return replacedString.join('');
  }, [referraFormlData]);

  const backgroundColor =
    referraFormlData?.building?.details?.logoBackgroundColor;

  if (!referraFormlData) {
    return <Spinner float />;
  }

  //render page based on: page option and is not from app
  if (
    !isAppReferral &&
    referraFormlData?.referralOffer?.referralLandingPageOption
  ) {
    switch (referralLandingPage) {
      case ReferralLandingPages.V1:
        return (
          <ReferralPageV1
            onSubmitForm={
              isPreviewOnly
                ? async (e?: React.BaseSyntheticEvent) => {}
                : handleSubmit(onSubmitForm)
            }
            referraFormlData={referraFormlData}
            errors={errors}
            control={control}
            register={register}
            emailConfirmationOption={emailConfirmationOption}
            onEmailConfirmation={onEmailConfirmation}
            showThankYou={showThankYou}
            isAppReferral={isAppReferral}
            isPreviewOnly={isPreviewOnly}
            loading={loader}
          />
        );
    }
  }

  return (
    <div className='float-spinner-parent'>
      {loader && <Spinner float />}
      <div className='form-container'>
        <div>
          {referraFormlData?.building.propertyLogoUrl && (
            <div
              className='logo-container'
              style={backgroundColor ? { backgroundColor } : {}}
            >
              <img
                src={referraFormlData?.building.propertyLogoUrl as string}
                className='building-logo'
              />
            </div>
          )}
          {showThankYou ? (
            <div className='thankyou-container'>
              <div className='label footer'>
                {isAppReferral
                  ? `Thank you for referring a friend.  This referral has been sent to the property management team at ${referraFormlData?.building.name} along with your information as the person who referred them.`
                  : `Thank you for submitting your information! It has been sent to the property management team at ${referraFormlData?.building.name}.`}
              </div>
              {!!referraFormlData?.building.propertyWebsiteURL &&
                !isAppReferral && (
                  <>
                    <div className='label footer'>{`Please click the link below to visit the property website to book a tour or submit an application.`}</div>
                    <a href={referraFormlData?.building.propertyWebsiteURL}>
                      {referraFormlData?.building.propertyWebsiteURL}
                    </a>
                  </>
                )}
              <Spacer height={40} />
              {isAppReferral && (
                <div
                  className='btn-secondary2 submit-another'
                  onClick={onSubmitAnotherLead}
                >
                  Submit Another
                </div>
              )}
            </div>
          ) : (
            <>
              <form
                id='form-container'
                onSubmit={isPreviewOnly ? () => {} : handleSubmit(onSubmitForm)}
              >
                <div className='content'>
                  <div className='bold ceterAlign referAFriend'>
                    {`Refer Friends`}
                  </div>
                  {isAppReferral ? (
                    <div className='ceterAlign referredBy'>
                      {`Thanks ${referrerName}
                    
                    `}
                      <div className='bold' style={{ display: 'inline-block' }}>
                        {`Please fill out the information below for the person you are referring to live at ${referraFormlData?.building?.name}.`}
                      </div>
                    </div>
                  ) : referralIntroHeader ? (
                    <div
                      className='ceterAlign referredBy'
                      dangerouslySetInnerHTML={{ __html: referralIntroHeader }}
                    />
                  ) : (
                    <div className='ceterAlign referredBy'>
                      {`You are being referred by `}
                      <div className='bold' style={{ display: 'inline-block' }}>
                        {referrerName}
                      </div>
                      {`
              
              Please fill out your personal information below to be considered as someone interested in living at ${referraFormlData?.building?.name} property.`}
                    </div>
                  )}
                  {formConfig.map((field) => generateField(field))}
                  {/* {!!buildingConfig?.config?.communityLeadsTandC && (
                  <>
                    <Controller
                      name='tc'
                      defaultValue={false}
                      rules={{ required: true }}
                      render={({ onChange }) => (
                        <MultiSelectCheckboxList
                          options={termsAndConditionOption}
                          setSelectedOption={(value, isSelected) => {
                            onChange(isSelected);

                            onTermAndCondition(value, isSelected);
                          }}
                        />
                      )}
                      control={control}
                    />
                    {errors?.['tc'] && errors?.['tc'].type === 'required' && (
                      <span className='errorTitle' role='alert'>
                        {CobuFormErrors.requiredError}
                      </span>
                    )}
                  </>
                )} */}
                  {!isAppReferral && (
                    <Controller
                      name='confirmationEmail'
                      defaultValue={false}
                      rules={{ required: false }}
                      render={({ onChange }) => (
                        <MultiSelectCheckboxList
                          options={emailConfirmationOption}
                          setSelectedOption={(value, isSelected) => {
                            onChange(isSelected);

                            onEmailConfirmation(value, isSelected);
                          }}
                        />
                      )}
                      control={control}
                    />
                  )}
                  {isAppReferral &&
                    !!referraFormlData?.referralOffer.rulesAndEligibility && (
                      <div className='label footer'>
                        {referraFormlData?.referralOffer.rulesAndEligibility}
                      </div>
                    )}
                  <button
                    className='btn-secondary2 form-submit margin-top-32'
                    type='submit'
                  >
                    {'Submit'}
                  </button>
                </div>
              </form>
              <div className='footer-img-container'>
                <div className='label footer-description'>
                  © 2024 Live Cobu - Power Genuine Community
                </div>
                <img className='footer-img' src={cobuLogo} alt='rsvp-home' />
              </div>
            </>
          )}
        </div>
      </div>
      <GenericModal
        width={'70%'}
        enableOverflowScrolls
        showModal={showTermAndCondition}
        title={'Terms and Conditions'}
        titleStyle={{ fontSize: '24px', lineHeight: '26px' }}
        actionFunction={() => {
          setShowTermAndCondition(false);
          setValue('tc', true, { shouldValidate: true });
          onTermAndCondition(termsAndConditionOption[0].value, true);
        }}
        actionText={'I Agree'}
        onChange={() => {
          setShowTermAndCondition(false);
        }}
        content={
          <div className='terms-and-condition'>
            {buildingConfig?.config?.communityLeadsTandC}
          </div>
        }
      />
    </div>
  );
};
