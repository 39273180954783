import React, { FC } from 'react';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import CobuConstants from '~/helpers/cobuConstants';
import { Building } from '~/types/building';
import lang from '~/assets/languages';
import './CreateEditBuildingForm.css';
import { FormInputConfig } from '~/types/residentManagement';
import MultiSelectCheckboxList from '../MultiSelectCheckBoxList/MultiSelectCheckBoxList';
import {
  additionalGoogleReviewFormFields,
  boardingOptionsFormField,
  buildingImageFormField,
  buildingLogoFormField,
  featureIdsFormFields,
  reviewUrlConfig
} from '../Header/CreateMenu/constants';
import useCreateBuilding from '~/hooks/building/useCreateBuilding';
import Spinner from '../Spinner/Spinner';
import { trimData } from '~/helpers/helpers';
import { FormField } from '../FormField';
import { AutoGrowFormField } from '../AutoGrowFormField/AutoGrowFormFiled';
import moment from 'moment';

interface IProps {
  isEditMode?: boolean;
  defaultData?: Building;
  formConfig: FormInputConfig[];
  onCancel: () => void;
  onEditBuilding?: () => Promise<void>;
  buildingId?: string;
}

export const CreateEditBuildingForm: FC<IProps> = ({
  isEditMode = false,
  defaultData,
  formConfig,
  onCancel,
  buildingId,
  onEditBuilding
}) => {
  const {
    loader,
    boardingOptions,
    setBoardingOptions,
    createBuilding,
    editBuilding,
    editBoardingOptions,
    setEditBoardingOptions
  } = useCreateBuilding(defaultData);

  //@ts-ignore
  let updatedData = {
    ...defaultData,
    buildingGoLive: moment(defaultData?.buildingGoLive).format('YYYY-MM-DD'),
    startReviewOutreach: moment(defaultData?.startReviewOutreach).format(
      'YYYY-MM-DD'
    )
  };

  let formDefaultData = { ...(isEditMode ? updatedData : {}), VCM: '1' }; //VCM number is constant for all the buildings

  const { register, handleSubmit, errors, control, unregister } =
    useForm<Building>({
      defaultValues: formDefaultData
    });

  const generateField = (fieldConfig: FormInputConfig) => {
    return (
      <div key={fieldConfig.id}>
        <div className='item'>
          {!!fieldConfig?.label && (
            <label
              style={fieldConfig?.styles?.lableStyles}
              className={`${fieldConfig?.styles?.lableClassName}`}
              htmlFor='title'
            >
              {fieldConfig.label}
            </label>
          )}
          <FormField
            fieldConfig={fieldConfig}
            errors={errors}
            register={register}
            control={control}
          />
        </div>
      </div>
    );
  };

  const onSubmit: SubmitHandler<Building> = (building) => {
    const trimmedData = trimData<Building>(building);
    if (trimmedData.hasOwnProperty('reportEmails')) {
      const commaSeperatedEmails = trimmedData.reportEmails
        ?.split('\n')
        .join(',');

      trimmedData.reportEmails = `{${commaSeperatedEmails}}`;
    }

    if (!isEditMode) {
      createBuilding(trimmedData, onCancel);
      return;
    }

    if (trimmedData.hasOwnProperty('additional_reviewurls')) {
      const nonEmptyGoogleReviewURLs =
        trimmedData?.additional_reviewurls?.filter(
          (reviewUrl) => !!reviewUrl.building
        );

      trimmedData.additional_reviewurls = nonEmptyGoogleReviewURLs?.length
        ? nonEmptyGoogleReviewURLs
        : null;
    } else {
      trimmedData.additional_reviewurls = null;
    }

    if (trimmedData.hasOwnProperty('google_feature_ids')) {
      const nonEmptyProductIds = trimmedData?.google_feature_ids?.filter(
        (reviewUrl) => !!reviewUrl.building
      );

      trimmedData.google_feature_ids = nonEmptyProductIds?.length
        ? nonEmptyProductIds
        : null;
    } else {
      trimmedData.google_feature_ids = null;
    }

    editBuilding(buildingId as string, trimmedData, (): Promise<void> => {
      return (
        (onEditBuilding &&
          onEditBuilding().then(() => {
            onCancel();
            return new Promise((r) => r());
          })) ||
        new Promise((resolve) => resolve())
      );
    });
  };

  const onBoardingCheckBox = (value: string, isSelected: boolean) => {
    const newBoardingOptions = [...boardingOptions];

    const selectedOption = newBoardingOptions.find(
      (option) => option.value === value
    );

    if (selectedOption) {
      if (value === 'includeRaffle') {
        selectedOption.showSibling = isSelected;
      }

      selectedOption.isSelected = isSelected;
    }

    setBoardingOptions(newBoardingOptions);
  };

  const editonBoardingCheckBox = (value: string, isSelected: boolean) => {
    const newBoardingOptions = [...editBoardingOptions];

    const selectedOption = newBoardingOptions.find(
      (option) => option.value === value
    );

    if (selectedOption) {
      selectedOption.isSelected = isSelected;
    }

    setEditBoardingOptions(newBoardingOptions);
  };

  const renderAdditionalGoogleReview = () => {
    return (
      <div className='additional-url-container'>
        <p className='label bold'>{lang.building.additionalGoogleReviewURLs}</p>
        <p className='review-description'>
          {lang.building.multiplePropertiesMergedIntoOneBuilding}
        </p>
        <AutoGrowFormField
          addNewRowText={lang.building.addNewGoogleReviewURL}
          errors={errors}
          control={control}
          register={register}
          formFieldName={'additional_reviewurls' as keyof Building}
          rowConfig={additionalGoogleReviewFormFields}
        />
      </div>
    );
  };

  const renderFeatureIds = () => {
    return (
      <div className='additional-url-container'>
        <p className='label bold'>{lang.building.featureIds}</p>
        <p className='review-description'>
          {lang.building.googleFeatureIdNeeded}
        </p>
        <AutoGrowFormField
          addNewRowText={lang.building.addNewFeatureId}
          errors={errors}
          control={control}
          register={register}
          formFieldName={'google_feature_ids' as keyof Building}
          rowConfig={featureIdsFormFields}
        />
      </div>
    );
  };

  boardingOptions[0].sibling = (
    <div className='grid'>
      {boardingOptionsFormField.map((field) => generateField(field))}
    </div>
  );

  return (
    <div className='float-spinner-parent'>
      {loader && <Spinner float />}
      <form id='form-container' onSubmit={handleSubmit(onSubmit)}>
        <div className='grid'>
          {formConfig.map((field) => generateField(field))}
        </div>
        <>{generateField(buildingLogoFormField)}</>
        <div id='noGridInput'>
          {isEditMode ? (
            <>
              <p id='onBoardingHeading' className='heading'>
                {CobuConstants.createBuilding.onBoarding}
              </p>
              <Controller
                name='skippable'
                defaultValue={true}
                render={({ onChange }) => (
                  <MultiSelectCheckboxList
                    options={editBoardingOptions}
                    setSelectedOption={(value, isSelected) => {
                      if (value === 'skippable') {
                        onChange(isSelected);
                      }
                      editonBoardingCheckBox(value, isSelected);
                    }}
                  />
                )}
                control={control}
              />
            </>
          ) : (
            <>
              {generateField(buildingImageFormField)}
              <p id='onBoardingHeading' className='heading'>
                {CobuConstants.createBuilding.onBoarding}
              </p>
              <Controller
                name='onBoardingSkippable'
                defaultValue={true}
                render={({ onChange }) => (
                  <MultiSelectCheckboxList
                    options={boardingOptions}
                    setSelectedOption={(value, isSelected) => {
                      if (value === 'onBoardingSkippable') {
                        onChange(isSelected);
                      }

                      if (value === 'includeRaffle' && !isSelected) {
                        unregister(['raffleDescription', 'raffleEndDate']);
                      }

                      onBoardingCheckBox(value, isSelected);
                    }}
                  />
                )}
                control={control}
              />
            </>
          )}
        </div>
        {isEditMode && (
          <>
            {generateField(reviewUrlConfig)}
            {renderAdditionalGoogleReview()}
            {renderFeatureIds()}
          </>
        )}
        <div className='formButtons'>
          <button className='btn-standard btn-secondary' onClick={onCancel}>
            {CobuConstants.createBuilding.cancel}
          </button>
          <input
            className='btn-standard btn-primary margin-left-16 '
            type='submit'
          />
        </div>
      </form>
    </div>
  );
};
