import { combineReducers } from 'redux';
import authReducer from './auth';
import buildingReducer, { BuildingStoreState } from './building';
import residentGatheringReducer from './residentGathering';
import perksReducer from './perks';
import adminReducer from './admin';
import groupAdmin, { GroupAdminStoreState } from './groupAdmin';
import tag, { TagStoreState } from './tag';
import groupEvents from './groupEvents';
import assetReducer from './assets';
import gatheringReducer from './gathering';

export type StoreState = {
  building: BuildingStoreState;
  groups: GroupAdminStoreState;
  tags: TagStoreState;
};

const reducer = combineReducers({
  auth: authReducer,
  building: buildingReducer,
  residentGathering: residentGatheringReducer,
  perks: perksReducer,
  admins: adminReducer,
  groups: groupAdmin,
  tags: tag,
  groupEvents,
  assetManager: assetReducer,
  gathering: gatheringReducer
});
export default reducer;
