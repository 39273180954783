import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { CellProps, Column } from 'react-table';
import Breadcrumbs from '~/components/Breadcrumbs/Breadcrumbs';
import CobuTableAdvanced from '~/components/CobuTableAdvanced/CobuTableAdvanced';
import CobuTooltip from '~/components/CobuTooltip/CobuTooltip';
import Spinner from '~/components/Spinner/Spinner';
import useIsMounted from '~/hooks/isMounted';
import { GatheringFeedbackType } from '~/types/gatheringFeedback';
import classes from './GatheringFeedback.module.css';
import { GatheringType } from '~/types/gathering';
import { shortDateTimeFormatAlternate } from '~/helpers/dateTimeFormats';
import { getBuildingFromStore } from '~/helpers/reduxStoreHelpers';
import { fetchGatheringFeedback } from '~/api/building';
import { fetchOneGatheringRequest } from '~/api/residentGathering';
import _ from 'lodash';
import { withFullScreenLoader } from '~/hocs/withFullScreenLoader';
import { useFullScreenLoader } from '~/hooks/useFullScreenLoader';
import { ApiState, ViewState } from '~/types/common';
import moment from 'moment';
import { formatAverageRating } from '~/components/GatheringsTable/GatheringsTable';
import { ReactComponent as StarOn } from '~/utils/images/starOn.svg';
import { ReactComponent as StarOff } from '~/utils/images/starOff.svg';

const GatheringFeedback = () => {
  const { gatheringId } = useParams<{ gatheringId: string }>();
  const { buildingId } = useParams<{ buildingId: string }>();
  const [gatheringFeedback, setGatheringFeedback] = useState<
    GatheringFeedbackType[]
  >([]);
  const [feedbacksByDates, setfeedbacksByDates] = useState<
    Record<string, GatheringFeedbackType[]>
  >({});
  const [selectedDate, setselectedDate] = useState<string>();
  const [eventDates, setEventDates] = useState<string[]>([]);
  const [selectedGathering, setSelectedGathering] = useState<GatheringType>(
    {} as GatheringType
  );
  const [viewState, setviewState] = useState<ApiState>('LOADING');
  const { loader } = useFullScreenLoader();
  const isMounted = useIsMounted();
  const columns = useMemo(
    () =>
      [
        {
          Header: 'RESIDENT',
          accessor: 'creator'
        },
        {
          Header: 'EMAIL',
          accessor: 'creatorEmail'
        },
        {
          Header: 'Rating',
          accessor: 'generalRating'
        },
        {
          Header: 'FEEDBACK',
          Cell: (cell: CellProps<GatheringFeedbackType>) => (
            <React.Fragment>
              <p data-tip={cell.row.original.feedback} className='text-elipsis'>
                {cell.row.original.feedback}
              </p>
              <CobuTooltip />
            </React.Fragment>
          )
        }
      ] as Column<GatheringFeedbackType>[],
    []
  );

  useEffect(() => {
    const getData = async () => {
      setviewState('LOADING');
      loader.show();
      Promise.all([
        fetchGatheringFeedback(gatheringId),
        fetchOneGatheringRequest(gatheringId)
      ])
        .then((res) => {
          if (isMounted()) {
            const feedbacks = res[0].data;
            const gathering = res[1].data;

            const feedbacksKeyedByDates = _.groupBy(feedbacks, (feedback) => {
              return feedback.interestedDate;
            });

            setfeedbacksByDates(feedbacksKeyedByDates);

            const dates = Object.keys(feedbacksKeyedByDates).map((date) => {
              if (!date) return gathering.startTime;

              return date;
            });

            setEventDates(dates);
            setselectedDate(dates[0] ?? '');

            setGatheringFeedback(feedbacks);
            setSelectedGathering(gathering);
            setviewState('COMPLETED');
          }
        })
        .catch(console.error)
        .finally(() => {
          loader.hide();
          setviewState('FAILED');
        });
    };

    getData();
  }, [isMounted, gatheringId]);

  const selectedBuilding = getBuildingFromStore(buildingId);

  if (viewState == 'LOADING') return null;

  return (
    <div className='content-container'>
      <div className='margin-bottom-32'>
        <Breadcrumbs
          floatingBanner={{
            text: `${selectedBuilding.name}, ${shortDateTimeFormatAlternate(
              selectedGathering.startTime
            ).toLocaleLowerCase()}`
          }}
        />
        <h1 className={classes.title}>
          Event Feedback for <b>{selectedGathering.title}</b>
        </h1>
        {selectedGathering.scheduledEndTime && (
          <div className='flex-row align-center margin-right-16 margin-top-16 margin-bottom-8'>
            <p>
              {selectedGathering.recurringTypeName == 'One Time'
                ? 'Average'
                : 'Overall'}{' '}
              Rating -{' '}
            </p>
            <p className='margin-left-16 color-dark-blue'>
              {formatAverageRating(selectedGathering.averageRating)}
            </p>
            {selectedGathering.averageRating > 0 && (
              <div className={`margin-left-8 ${classes.starsContainer}`}>
                {[...Array(3)].map((item, index) => {
                  const rating = index + 1;
                  return Math.round(selectedGathering.averageRating) >=
                    rating ? (
                    <StarOn className={classes.star} />
                  ) : (
                    <StarOff className={classes.star} />
                  );
                })}
              </div>
            )}
          </div>
        )}
        {selectedGathering.recurringTypeName != 'One Time' && (
          <p className='margin-bottom-16'>
            * overall rating is the aggregate rating received for all the
            occurance of this recurring gathering
          </p>
        )}
        {selectedGathering.recurringTypeName != 'One Time' &&
          eventDates.length != 0 && (
            <div className='flex-row align-center'>
              <p className='margin-right-8'>Event Date</p>
              <select onChange={(e) => setselectedDate(e.target.value)}>
                <option disabled>Select Event Date</option>
                {eventDates.map((date) => (
                  <option value={date}>
                    {moment(date).format('dddd DD MMM YYYY')}
                  </option>
                ))}
              </select>
            </div>
          )}
        <div className='menu-bar'></div>
        <CobuTableAdvanced
          columns={columns}
          data={feedbacksByDates[selectedDate!] ?? []}
          noData={{ label: 'No Feedbacks' }}
          search={{
            searchField: 'feedback',
            placeholder: 'Search by feedback'
          }}
          actionsStyle={{ top: '148px' }}
        />
      </div>
    </div>
  );
};

export default withFullScreenLoader(GatheringFeedback);
