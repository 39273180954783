import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { connect, ResolveThunks } from 'react-redux';
import { toast } from 'react-toastify';

import './RSVPRegisterContainer.css';
import Logo from '~/utils/images/logo.svg';
import RSVPRegisterForm from '~/components/RSVPRegisterForm/RSVPRegisterForm';
import { signupRequest } from '~/api/auth';
import { saveGatheringId, saveUser } from '~/reducers/auth';
import { RSVPRegisterFormType } from '~/types/auth';
import { Mixpanel } from '~/services/MixpanelService';

interface DispatchProps {
  saveUser: typeof saveUser;
  saveGatheringId: typeof saveGatheringId;
}

type Props = ResolveThunks<DispatchProps>;

let gatheringId = '';

const RSVPRegisterContainer = (props: Props) => {
  const history = useHistory();
  const location = useLocation();
  const { saveUser, saveGatheringId } = props;

  useEffect(() => {
    const pathname = location.pathname;
    const lastSlashIndex = pathname.lastIndexOf('/');
    gatheringId = pathname.substring(lastSlashIndex + 1);
    saveGatheringId(gatheringId);
  }, [saveGatheringId, location.pathname]);

  const onSubmit = (data: RSVPRegisterFormType) => {
    signupRequest({
      ...data,
      verified: false,
      verifiedPhone: false,
      groupEvent: gatheringId
    })
      .then((res) => {
        if (res && res.status === 200) {
          saveUser(res.data.user);

          Mixpanel.track('did_signup');
          history.push('/rsvp-register/phone');
        }
      })
      .catch((error) => {
        if (error.message.includes('already exists')) {
          toast(error.message);
        }
      });
  };
  return (
    <div className='rsvp-register-container'>
      <img className='rsvp-register-logo' src={Logo} alt='cobu logo' />
      <div className='rsvp-register-text'>
        Fill the information to create an account
      </div>
      <div className='rsvp-form-container'>
        <RSVPRegisterForm onSubmit={onSubmit} />
      </div>
    </div>
  );
};

function mapDispatchToProps(): DispatchProps {
  return {
    saveUser,
    saveGatheringId
  };
}
export default connect(null, mapDispatchToProps())(RSVPRegisterContainer);
