import React, { useState } from 'react';
import Select, { ActionMeta } from 'react-select';
import { Colors } from '~/enums/Colors';
import { SelectType } from '~/enums/SelectType';
import { ISelectOption } from '~/helpers/interfaces';
import { SelectedOptionType } from '~/types/form';

type Props = {
  options: { label: string; value: string }[];
  onChange?: (selectedOption: SelectedOptionType | null) => void;
  placeholder?: string;
  defaultValue?: SelectedOptionType | null;
  isSearchable?: boolean;
  selectType: string;
  disabled?: boolean;
  isClearable?: boolean;
  noOptionsMessage?: string;
  isError?: boolean | null;
  multiValue: { label: string; value: string }[];
  setMultiValue: any;
  cobuPlusBuildings: Array<string>;
  singleSelect?: boolean;
  defaultOptions?: ISelectOption[];
};

const MultiSelect = (props: Props) => {
  const {
    options,
    placeholder,
    isSearchable,
    selectType,
    disabled,
    isClearable,
    noOptionsMessage,
    isError,
    multiValue,
    setMultiValue,
    cobuPlusBuildings,
    singleSelect,
    defaultOptions
  } = props;

  const [selectAll, setSelectAll] = useState(false);
  const [selectAllCobuPlus, setSelectAllCobuPlus] = useState(false);

  const getBorder = (isFocused: boolean) => {
    if (isFocused && selectType === SelectType.Filter) {
      return 'none';
    } else if (selectType != SelectType.Filter) {
      if (isError) {
        return '2px solid #ef4a4a';
      } else {
        return '1px solid rgba(39, 33, 99, 0.1)';
      }
    }
    return 'none';
  };

  const getBackgroundColor = (value: string, isFocused: boolean) => {
    if (isFocused) {
      return 'rgba(39,33,99,0.1)';
    }
    return 'white';
  };

  let allOptions = [];
  if (singleSelect) {
    allOptions = [...options];
  } else {
    if (selectAll) {
      allOptions = [...options];
    } else {
      if (selectAllCobuPlus) {
        allOptions = [
          ...(defaultOptions
            ? defaultOptions
            : [{ value: 'all', label: 'All Buildings' }]),
          ...options
        ];
      } else {
        allOptions = [
          ...(defaultOptions
            ? defaultOptions
            : [
                { value: 'all', label: 'All Buildings' },
                { value: 'allCobuPlus', label: 'All Cobu Plus Buildings' }
              ]),
          ...options
        ];
      }
    }
  }

  const findCobuPlusNames = () => {
    const cobuPlusBuildingNames = cobuPlusBuildings.map((cobuPlusBuilding) => {
      const buildingName = options.find((b) => {
        const buildingCode = b.value;
        return buildingCode === cobuPlusBuilding;
      });
      return { value: cobuPlusBuilding, label: buildingName?.label };
    });
    return cobuPlusBuildingNames.filter(
      (building) => building.label !== undefined
    );
  };

  const changeOnMultiSelect = (newValue: any, actionMeta: ActionMeta<any>) => {
    if (singleSelect) {
      setMultiValue([newValue]);
    } else {
      if (
        actionMeta.action === 'remove-value' ||
        actionMeta.action === 'clear'
      ) {
        setSelectAll(false);
        setSelectAllCobuPlus(false);
      }
      if (newValue) {
        const isAllIndex = newValue.findIndex(
          (item: SelectedOptionType) => item.value === 'all'
        );
        const isAllCobuPlusIndex = newValue.findIndex(
          (item: SelectedOptionType) => item.value === 'allCobuPlus'
        );
        if (isAllIndex >= 0) {
          setMultiValue(options);
          setSelectAll(true);
          return;
        }
        if (isAllCobuPlusIndex >= 0) {
          const cobuPlusBuildingOptions = findCobuPlusNames();
          setMultiValue(cobuPlusBuildingOptions);
          setSelectAllCobuPlus(true);
          return;
        }
        setMultiValue(newValue);
      }
    }
  };

  return (
    <div>
      <Select
        value={multiValue}
        options={allOptions}
        isMulti={!singleSelect}
        placeholder={placeholder}
        isSearchable={isSearchable}
        onChange={changeOnMultiSelect}
        closeMenuOnSelect={singleSelect}
        isClearable={isClearable}
        isDisabled={disabled}
        noOptionsMessage={() =>
          noOptionsMessage ? noOptionsMessage : 'No option'
        }
        styles={{
          option: (provided, state) => {
            return {
              fontSize:
                selectType === SelectType.Form ||
                selectType === SelectType.AutoAdjutForm
                  ? '16px'
                  : '12px',
              color: Colors.cobuDarkBlue,
              cursor: 'pointer',
              backgroundColor: getBackgroundColor(
                state.data.value,
                state.isFocused
              ),
              padding: 16,
              fontFamily: 'Roboto',
              fontStyle: 'normal'
            };
          },
          control: (base, state) => {
            return {
              ...base,
              //   minHeight: getHeight(),
              cursor: 'pointer',
              height: 50,
              alignItems: 'baseline',
              width:
                selectType === SelectType.Form ||
                selectType === SelectType.AutoAdjutForm
                  ? 'inherit'
                  : '180px',
              borderRadius: '8px',
              border: getBorder(state.isFocused),
              boxShadow: 'none',
              ...(selectType === SelectType.AutoAdjutForm
                ? { height: undefined }
                : {})
            };
          },
          valueContainer: (provided, state) => ({
            ...provided,
            height: 'inherit',
            alignItems: 'center',
            position: 'initial',
            overflow: selectType !== SelectType.Form ? 'visible' : 'hidden',
            fontFamily: 'Roboto',
            ...(selectType === SelectType.AutoAdjutForm
              ? { overflow: 'auto', maxHeight: window.innerHeight * 0.25 }
              : {})
          }),
          input: (provided, state) => ({
            ...provided
          }),
          indicatorSeparator: (state) => ({
            display: 'none'
          }),
          indicatorsContainer: (provided, state) => ({
            ...provided
            // height: getHeight()
          }),
          singleValue: (provided, state) => ({
            ...provided,
            alignItems: 'center',
            transform:
              selectType === SelectType.Table
                ? 'translateY(-170%)'
                : selectType === SelectType.Form ||
                  selectType === SelectType.AutoAdjutForm
                ? 'translateY(-10%)'
                : 'translateY(-90%)',
            position: 'inherit',
            color: 'rgba(39, 33, 99, 1)'
          })
        }}
      ></Select>
    </div>
  );
};

export default MultiSelect;
