import React, { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { FormField } from '~/components/FormField';
import Spinner from '~/components/Spinner/Spinner';
import { IBuildingKeyAttribute } from '~/types/building';
import { FormInputConfig } from '~/types/residentManagement';
import { useBuildingKeyAttribute } from './hooks';
import CobuConstants from '~/helpers/cobuConstants';
import { getDirtyValues } from '~/helpers/helpers';
import { filter } from 'lodash';
import Spacer from '~/components/Spacer/Spacer';

interface IProps {
  buildingId: string;
  onCancel: () => void;
  onEditBuilding: () => void;
}

export const EditBuildingAttributeForm: FC<IProps> = ({
  buildingId,
  onCancel
}) => {
  const {
    buildingKeyAttributes,
    loader,
    pmsAttributeFormConfig,
    assingnedAttributeFormConfig,
    unAssingnedAttributeFormConfig,
    watchAttributeUuidWithDisplayNone,
    postKeyAttributes
  } = useBuildingKeyAttribute(buildingId);

  const {
    register,
    handleSubmit,
    errors,
    control,
    reset,
    unregister,
    formState: { isDirty, dirtyFields }
  } = useForm<IBuildingKeyAttribute>({
    defaultValues: {}
  });

  useEffect(() => {
    if (buildingKeyAttributes) {
      reset(buildingKeyAttributes);
    }
  }, [buildingKeyAttributes]);

  const onSubmit = (formValues: IBuildingKeyAttribute) => {
    const dirtyValues = getDirtyValues(isDirty, dirtyFields, formValues);

    postKeyAttributes(dirtyValues, onCancel);
  };

  const generateField = (fieldConfig: FormInputConfig) => {
    return (
      <div key={fieldConfig.id}>
        <div
          className='item'
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between'
          }}
        >
          {!!fieldConfig?.label && (
            <label
              style={fieldConfig?.styles?.lableStyles}
              className={`${fieldConfig?.styles?.lableClassName} paragraph`}
              htmlFor='title'
            >
              {fieldConfig.label}
            </label>
          )}
          <FormField
            fieldConfig={{
              ...fieldConfig,
              styles: {
                inputStyles: {
                  width: '100%',
                  marginTop: 0
                },
                contentStyles: { width: '50%' }
              }
            }}
            errors={errors}
            register={register}
            control={control}
          />
        </div>
      </div>
    );
  };

  return (
    <div className='float-spinner-parent'>
      {loader && <Spinner float />}
      <form id='form-container' onSubmit={handleSubmit(onSubmit)}>
        <div>{pmsAttributeFormConfig.map((field) => generateField(field))}</div>
        {assingnedAttributeFormConfig && (
          <div className='margin-bottom-24'>
            <div className='heading4 margin-bottom-24'>Currently Assigned</div>
            <div
              style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}
            >
              {assingnedAttributeFormConfig.map((field) =>
                generateField(field)
              )}
            </div>
          </div>
        )}
        {unAssingnedAttributeFormConfig && (
          <div>
            <div className='heading4 margin-bottom-24'>Unassigned Keys</div>
            <div
              style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}
            >
              {unAssingnedAttributeFormConfig.map((field) =>
                generateField(field)
              )}
            </div>
          </div>
        )}
        {watchAttributeUuidWithDisplayNone && (
          <div style={{ display: 'none' }}>
            {watchAttributeUuidWithDisplayNone.map((field) =>
              generateField(field)
            )}
          </div>
        )}
        <Spacer height={20} />
        <div className='formButtons'>
          <button
            type='button'
            className='btn-standard btn-secondary margin-left-16'
            onClick={(e) => {
              e.preventDefault();

              onCancel();
            }}
          >
            {CobuConstants.createBuilding.cancel}
          </button>
          <input
            className={`btn-standard btn-primary margin-left-16 ${
              !isDirty ? 'disabled' : ''
            }`}
            type='submit'
            disabled={!isDirty}
          />
        </div>
      </form>
    </div>
  );
};
