import { useEffect, useState } from "react"
import { toast } from "react-toastify"
import { createBuildingSurvey, deactivateBuildingSurvey, getAllActiveSurveys } from "~/api/surveys"
import CobuConstants from "~/helpers/cobuConstants"
import { BuildingSurvey, CreateSurveyBody } from "~/types/surveys"


export default () => {

    const [activeSurveys, setActiveSurveys] = useState<BuildingSurvey[]>([])
    const [loadingConfig, setLoadingConfig] = useState<{ flag: boolean, comment?: string }>({
        flag: false,
        comment: ''
    })

    useEffect(() => {
        fetchSurveys()
    }, [])

    const fetchSurveys = async (refresh: boolean = false) => {
        setLoadingConfig({
            flag: true, comment: refresh ? CobuConstants.activeSurveys.refreshingActiveSurveys
                : CobuConstants.activeSurveys.fetchingActiveSurveys
        })
        try {
            const activeSurveyResp = await getAllActiveSurveys()
            setActiveSurveys(activeSurveyResp.data)
            setLoadingConfig({ flag: false })
        } catch (error) {
            setLoadingConfig({ flag: false })
            if (error) {
                toast(error?.message)
            }
        }
    }

    const deactivateSurvey = async (survey: BuildingSurvey) => {
        setLoadingConfig({ flag: true, comment: CobuConstants.activeSurveys.deactivatingSurvey })
        try {
            const updateResp = await deactivateBuildingSurvey(survey.uuid)
            setLoadingConfig({ flag: false })
            setActiveSurveys(activeSurveys.filter(surv => surv.uuid != survey.uuid))
            toast(CobuConstants.activeSurveys.deactivateSurveySuccess)
        } catch (error) {
            setLoadingConfig({ flag: false })
            if (error) {
                toast(error?.message)
            }
        }
    }

    const createSurvey = async (survey: CreateSurveyBody) => {
        setLoadingConfig({ flag: true, comment: CobuConstants.activeSurveys.createSurveyLoading })
        try {
            const createResp = await createBuildingSurvey(survey)
            fetchSurveys(true)
            toast(CobuConstants.activeSurveys.createSurveySuccess)
        } catch (error) {
            setLoadingConfig({ flag: false })
            if (error) {
                toast(error?.message)
            }
        }
    }

    return { activeSurveys, loadingConfig, fetchSurveys, deactivateSurvey, createSurvey }
}