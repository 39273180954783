import React, {  useState } from 'react';
import { connect, ResolveThunks } from 'react-redux';
import { saveUser } from '~/reducers/auth';
import { RootState } from '~/redux/store';
import LogoLarge from '~/components/LogoLarge/LogoLarge';
import ForgottenPasswordForm from '~/components/ForgottenPasswordForm/ForgottenPasswordForm';
import { sendResetPasswordEmail } from '~/api/auth';

import classes from './ForgottenPasswordContainer.module.css';
import { ReactComponent as ExclamationMark } from '~/utils/images/exclamation-mark.svg';

interface DispatchProps {
  saveUser: typeof saveUser;
}

interface IProps {
  isRSVPLogin: boolean;
}

type Props = IProps &
  ResolveThunks<DispatchProps> &
  ReturnType<typeof mapStateToProps>;

const ForgottenPasswordContainer = (props: Props) => {
  const onSubmit = (email: string) => {
    sendResetPasswordEmail(email).then((res) => {
      setShowSuccess(true);
    });
  };

  const [showSuccess, setShowSuccess] = useState(false);
  return (
    <div className={classes.passwordContainer}>
      <div className='display-flex'>
        <div className={classes.left}>
          <LogoLarge />
        </div>
        <div className={classes.right}>
          {showSuccess && (
            <div className={classes.successContainer}>
              <ExclamationMark fill='var(--iconInfoWarning)' />
              <span className={`margin-left-16 ${classes.info}`}>
                If we find your account in our databases, we will send you an
                email with instructions on how to reset your password.
              </span>
            </div>
          )}
          <ForgottenPasswordForm onSubmit={onSubmit} />
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state: RootState) {
  return {
    userId: state.auth.user
  };
}

function mapDispatchToProps(): DispatchProps {
  return {
    saveUser
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps()
)(ForgottenPasswordContainer);
