// helper functions for PerksContainer component
enum modalStatus {
  NEW_OFFER,
  GET_OFFER_LINK,
  NEW_MERCHANT,
  MERCHANT_CREATED_SUCCESS,
  EDIT_MERCHANT
}

function actionButtonDisabled(
  modalIndex: number,
  selectedMerchant: string,
  disableActionButton: boolean,
  createdProposal: string,
  newMerchantName: string,
  selectedCategory: string
) {
  return (
    (modalIndex === modalStatus.NEW_OFFER && selectedMerchant === '') ||
    (modalIndex === modalStatus.GET_OFFER_LINK && !createdProposal) ||
    (modalIndex === modalStatus.NEW_MERCHANT &&
      (newMerchantName === '' || selectedCategory === '')) ||
    (modalIndex === modalStatus.EDIT_MERCHANT &&
      newMerchantName === '' &&
      selectedCategory === '') ||
    disableActionButton
  );
}

export { actionButtonDisabled, modalStatus };
