import React from 'react';
import { FormField } from '~/components/FormField';
import Spinner from '~/components/Spinner/Spinner';
import { FormInputConfig } from '~/types/residentManagement';
import { ICreateReferralFormData } from './interfaces';
import useCreateReferralOffer from '~/hooks/building/referral/useCreateReferralOffer';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Breadcrumbs from '~/components/Breadcrumbs/Breadcrumbs';
import Spacer from '~/components/Spacer/Spacer';
import './CreateEditReferralForm.css';
import { Tabs } from '~/components/Tabs';
import { ReferralLandingPages } from '../constants';
import { UserType } from '~/hooks/building/referral/constants';

export const CreateEditReferralForm = () => {
  const { buildingId } = useParams<{ buildingId: string }>();

  const {
    loader,
    referralOffer,
    postReferralData,
    createReferralFormConfig,
    pageOptionBasedDefaultFormFileds,
    register,
    handleSubmit,
    errors,
    control,
    isDirty,
    selectedReferralOptionName,
    pageOptionBasedFormFileds,
    employeePageOptionBasedFormFileds,
    selectedUserType,
    setSelectedUserType
  } = useCreateReferralOffer(buildingId);

  const onSubmit = (formData: ICreateReferralFormData) => {
    if (!isDirty) {
      toast('Please edit the form');

      return;
    }

    postReferralData(formData);
  };

  const generateField = (fieldConfig: FormInputConfig) => {
    return (
      <div key={fieldConfig.id}>
        <div className='create-referral-item'>
          {!!fieldConfig?.label && (
            <label
              style={fieldConfig?.styles?.lableStyles}
              className={`${fieldConfig?.styles?.lableClassName}`}
              htmlFor='title'
            >
              {fieldConfig.label}
            </label>
          )}
          <FormField
            fieldConfig={fieldConfig}
            errors={errors}
            register={register}
            control={control}
          />
        </div>
      </div>
    );
  };

  return (
    <div className='container'>
      <Breadcrumbs />
      <div className='float-spinner-parent'>
        {loader && <Spinner float />}
        <Spacer height={24} />
        <div className='title bold'>
          {referralOffer ? 'Update Referral Offer:' : 'Create Referral Offer:'}
        </div>
        <Spacer height={24} />
        <form id='form-container' onSubmit={handleSubmit(onSubmit)}>
          <div className='create-referral-grid'>
            {createReferralFormConfig.map((field) => generateField(field))}
          </div>
          {selectedReferralOptionName === ReferralLandingPages.V1 && (
            <>
              <Spacer height={20} />
              <h2>{`Selected landing page option "${selectedReferralOptionName}" Form Fields:`}</h2>
              <Spacer height={20} />
              <div className='diagonal-user-type-section'>
                <div className='create-referral-grid'>
                  <Tabs.Container
                    isOnChangePreventDefault
                    onChange={(value) => {
                      setSelectedUserType(value as UserType);
                    }}
                    value={selectedUserType}
                  >
                    <Tabs.Option
                      selected={selectedUserType === 'user'}
                      key={'user'}
                      value={'user'}
                    >
                      User
                    </Tabs.Option>
                    <Tabs.Option
                      selected={selectedUserType === 'employee'}
                      key={'employee'}
                      value={'employee'}
                    >
                      Employee
                    </Tabs.Option>
                  </Tabs.Container>
                </div>
                <div className='diagonal-user-type-content'>
                  <div
                    style={{
                      ...(selectedUserType === 'user'
                        ? {}
                        : { display: 'none', width: 0 })
                    }}
                    className='create-referral-grid'
                  >
                    {pageOptionBasedFormFileds.map((field) =>
                      generateField(field)
                    )}
                  </div>
                  <div
                    style={{
                      ...(selectedUserType === 'employee'
                        ? {}
                        : { display: 'none', width: 0 })
                    }}
                    className='create-referral-grid'
                  >
                    {employeePageOptionBasedFormFileds.map((field) =>
                      generateField(field)
                    )}
                  </div>
                </div>
              </div>
              <h3>{'Default diagonal theme fields:'}</h3>
              <Spacer height={20} />
              <div className='create-referral-grid'>
                {pageOptionBasedDefaultFormFileds.map((field) =>
                  generateField(field)
                )}
              </div>
            </>
          )}
          <Spacer height={20} />
          <button
            className='btn-standard btn-primary margin-left-16 '
            type='submit'
          >
            {referralOffer ? 'Update' : 'Create'}
          </button>
        </form>
      </div>
    </div>
  );
};
