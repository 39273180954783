import React from 'react';
import { Column } from 'react-table';
import classes from './UploadRentRoll.module.css';
import CobuTable from '~/components/CobuTable/CobuTable';
import './style.css';

const matchingColumnLabels = [
  {
    label: 'FULL NAME',
    value: 'fullName'
  },
  {
    label: 'FIRST NAME',
    value: 'firstName'
  },
  {
    label: 'LAST NAME',
    value: 'lastName'
  },
  {
    label: 'BLDG-UNIT',
    value: 'unit'
  },
  {
    label: 'EMAIL',
    value: 'email'
  },
  {
    label: 'PHONE',
    value: 'phone'
  },
  {
    label: 'OCCUPANT TYPE',
    value: 'occupantType'
  },
  {
    label: 'MOVE IN',
    value: 'moveIn'
  },
  {
    label: 'MOVE OUT',
    value: 'moveOut'
  },
  {
    label: 'LEASE START',
    value: 'leaseStart'
  },
  {
    label: 'LEASE END',
    value: 'leaseEnd'
  }
];

const prepareEmptyColumns = ({
  selectedRow,
  maxColumns,
  onRowSelection,
  onColumnNameSelection
}: {
  selectedRow?: any;
  maxColumns: number;
  onRowSelection: (row: any) => void;
  onColumnNameSelection: React.ChangeEventHandler<HTMLSelectElement>;
}): Column<any>[] => {
  const columns = [
    {
      Header: 'Starting Row',
      disableSortBy: true,
      id: 'radio',
      accessor: (row: any) => {
        return (
          <div>
            <input
              value={row.id}
              onChange={() => onRowSelection(row)}
              type='radio'
              className='height-20 row-select'
              checked={row.id == selectedRow?.id}
            />
          </div>
        );
      }
    }
  ];

  for (let i = 1; i <= maxColumns; i++) {
    columns.push({
      // @ts-ignore
      Header: (
        <div>
          <select
            id={`field${i}`}
            name={`field${i}`}
            placeholder='Select'
            defaultValue={''}
            className='column-match'
            onChange={onColumnNameSelection}
          >
            <option value=''>Empty</option>
            {matchingColumnLabels.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </div>
      ),
      width: '100px',
      id: `field-${i}`,
      disableSortBy: true,
      accessor: (row: any) => {
        return <div className={classes.column}>{row[`field${i}`]}</div>;
      }
    });
  }

  return columns;
};

type ColumnMatchTableProps = {
  rows: any[];
  fieldMapping: any;
  selectedRow?: any;
  maxColumns: number;
  onRowSelection: (row: any) => void;
  onUpload: (fieldMapping: any) => void;
  onCancel: () => void;
  onColumnNameSelected: React.ChangeEventHandler<HTMLSelectElement>;
  buildingId: string;
  uploadActionTitle: string;
};

const ColumnMatchTable = (props: ColumnMatchTableProps) => {
  const { fieldMapping } = props;

  return (
    <div>
      <div className='margin-top-16 margin-bottom-8'>
        Match Upload File Content
      </div>
      <ul>
        <li>UPLOADED FILE</li>
      </ul>
      <div className='table-wrapper'>
        <CobuTable
          hideRightBorder
          columns={prepareEmptyColumns({
            maxColumns: props.maxColumns,
            onRowSelection: props.onRowSelection,
            selectedRow: props.selectedRow,
            onColumnNameSelection: props.onColumnNameSelected
          })}
          data={props.rows}
          sort={{ id: 'field-1', descending: false }}
          height={500}
        />
      </div>
      <div className='flex-row justify-end margin-top-24'>
        <button className='btn-standard btn-secondary' onClick={props.onCancel}>
          Cancel
        </button>
        <button
          className='btn-standard btn-primary margin-left-16'
          onClick={() => props.onUpload(fieldMapping)}
        >
          {props.uploadActionTitle}
        </button>
      </div>
    </div>
  );
};

export default ColumnMatchTable;
