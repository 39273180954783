import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { getJobsByQueue, retryJobAPI, retryQueueAPI } from "~/api/queue";
import CobuConstants from "~/helpers/cobuConstants";
import { Job } from "~/types/queues";


export default (queueId: string) => {
    const [jobs, setJobs] = useState<Job[]>([]);
    const [loadingConfig, setLoadingConfig] = useState<{ flag: boolean, comment?: string }>({
        flag: false,
        comment: ''
    });

    useEffect(() => {
        fetchJobs();
    }, [queueId]);

    const fetchJobs = async () => {
        setLoadingConfig({
            flag: true,
            comment: CobuConstants.queueManagement.fetchingJobs
        });
        try {
            const jobResp = await getJobsByQueue(queueId);
            const jobsData = jobResp.data;
            const sortedJobs = jobsData.sort((a: Job, b: Job) => {
                const order = {
                    'failed': 1,
                    'processing': 2,
                    'pending': 3,
                    'completed': 4
                };

                return order[b.status] - order[a.status]
            });

            setJobs(sortedJobs);
            setLoadingConfig({ flag: false });
        } catch (error) {
            setLoadingConfig({ flag: false });
            if (error) {
                toast(error?.message);
            }
        }
    };

    const retryJob = async (jobId: string) => {
        setLoadingConfig({
            flag: true,
            comment: CobuConstants.queueManagement.retryingJob
        });
        try {
            await retryJobAPI(jobId);
            fetchJobs(); // Refresh job list after retry
            toast(CobuConstants.queueManagement.retryJobSuccess);
        } catch (error) {
            setLoadingConfig({ flag: false });
            if (error) {
                toast(CobuConstants.queueManagement.retryJobError);
            }
        }
    };

    const retryAllJobs = async () => {
        setLoadingConfig({
            flag: true,
            comment: CobuConstants.queueManagement.retryingAllJob
        });
        try {
            await retryQueueAPI(queueId);
            fetchJobs(); // Refresh job list after retry
            toast(CobuConstants.queueManagement.retryJobSuccess);
        } catch (error) {
            setLoadingConfig({ flag: false });
            if (error) {
                toast(CobuConstants.queueManagement.retryJobError);
            }
        }
    };

    return { jobs, loadingConfig, fetchJobs, retryJob, retryAllJobs };
};