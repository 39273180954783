import { emperor } from '~/services';

export const fetchGroups = async (buildingIds: string[]) => {
  return await emperor.get(`group/`, {
    params: { active: true, building: buildingIds, sort: 'name ASC' }
  });
};

export const fetchGroupComments = async (groupId: string) => {
  return await emperor.get(`/admin/groupcomment/group/${groupId}`);
};
