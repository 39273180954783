import React, { useState } from "react"
import CobuConstants from "~/helpers/cobuConstants"
import WrappableText from "./WrappableText"

type IProps = {
    content: string,
    limit?: number
}


const CollapsibleText = ({ content, limit = 0 }: IProps) => {

    const [showAll, setShowAll] = useState(false);

    const showMore = () => setShowAll(true);
    const showLess = () => setShowAll(false);

    if (content.length <= limit || limit === 0) {
        return <WrappableText text={content} />
    }

    if (showAll) {
        return <div>
            <WrappableText text={content} />
            <div className='default-text underline' onClick={showLess}>{CobuConstants.general.less}</div>
        </div>
    }

    const toShow = content.substring(0, limit) + "...";
    return <div>
        <WrappableText text={toShow} />
        <div className='default-text underline' onClick={showMore}>{CobuConstants.general.more}</div>
    </div>
}

export default CollapsibleText