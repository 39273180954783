import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  fetchUserCountByBuilding,
  deactivateResidentByBuilding,
  deactivateBuilding
} from '~/api/building';
import Breadcrumbs from '~/components/Breadcrumbs/Breadcrumbs';
import Spinner from '~/components/Spinner/Spinner';

// @ts-ignore

import classes from './DeactivateBuildingContainer.module.css';
import { RootState } from '~/redux/store';
import { connect } from 'react-redux';
import { Roles } from '~/enums/Roles';

type IProps = ReturnType<typeof mapStateToProps>;

const DeactivateBuildingContainer = (props: IProps) => {
  const { userRole } = props;
  const { building } = useParams<{ building: string }>();
  const [userCount, setUserCount] = useState(0);

  useEffect(() => {
    fetchUserCountByBuilding(building)
      .then((res) => {
        setUserCount(res.data.residentsCount)
        setLoading(false);
        });
      });


  const [loading, setLoading] = useState(true);



  return (
    <div className={`content-container`}>
      <Breadcrumbs />
      <h1 className='h1-title'>Deactivate Building</h1>
      {loading && <Spinner />}

      {(userRole === Roles.Vcm || userRole === Roles.Superadmin) && (
            <div>
              <p className='small-text bold'>Step One:</p>
              <div className={`display-flex`}>
                <div className={classes.container}>
                  Active Users: {userCount}
                  
                <button
                  className='btn-primary btn-standard'
                  onClick={() => deactivateResidentByBuilding({building}).then((res) => {
                    toast("You just deactivated " + res.data.numberOfDeactivatedUsers + " user(s).");                      
                  }) }
                >
                  Deactivate All Users
                </button>
                </div>
              </div>
            <div className={`${classes.container}`}>
            <div>
              <p className='small-text bold'>Step Two:</p>
                                
              <button
                  className='btn-primary btn-standard'
                  onClick={() =>
                    deactivateBuilding({building}).then((res) => {
                      console.log(res);
                      toast("You just deactivated the building.");                      
                  })
                }
                >
                  Deactivate Building
                </button>
            </div>
            </div>    
            </div>
          )}
    </div>
  );
};

function mapStateToProps(state: RootState) {
  return {
    userRole: state.auth.userRole
  };
}

export default connect(mapStateToProps)(DeactivateBuildingContainer);