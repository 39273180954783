import { emperor } from "~/services";
import { AttributionDeeplink, AttributionDeeplinkCreateBody } from "~/types/deeplink";

export const getAllActiveDeeplinks = async (id: string): Promise<{ data: AttributionDeeplink[] }> => {
    return await emperor.get(`building/${id}/deeplinks`);
}

export const deactivateActiveDeeplink = async (id: string): Promise<{ data: any }> => {
    return await emperor.put(`deactivate-deeplink`, { id });
}

export const createAttributionDeeplink = async (body: AttributionDeeplinkCreateBody): Promise<{ data: any }> => {
    return await emperor.post(`create-deeplink`, body);
}