import React, { useEffect, useState } from 'react';
import { connect, ResolveThunks, useSelector } from 'react-redux';

import { useHistory, useParams } from 'react-router-dom';

import {
  deleteRSVPGatheringRequest,
  createRSVPGatheringRequest,
  updateRSVPGatheringRequest,
  getGatheringRSVPInfo,
  fetchOneGatheringRequest,
  getGatheringRsvps,
  getGatheringRsvpsForResident
} from '~/api/residentGathering';
import { fetchOneGatheringSuccess } from '~/reducers/residentGathering';
import { RootState } from '~/redux/store';
import RSVPGatheringDetail from '~/components/RSVPGatheringDetail/RSVPGatheringDetail';
import CalendarModal from '~/components/CalendarModal/CalendarModal';
import RSVPModal from '~/components/RSVPModal/RSVPModal';
import classes from './RSVPGatheringContainer.module.css';
import { GatheringRSVPInfo, GatheringType } from '~/types/gathering';
import Spinner from '~/components/Spinner/Spinner';
import NotFound from '~/components/NotFound/NotFound';
import { UserMin } from '~/types/user';
import { fetchOneUserRequest } from '~/api/user';
import { Mixpanel } from '~/services/MixpanelService';
import useIsMounted from '~/hooks/isMounted';
import { useQuery } from 'react-query';
import { fetchBuildingDetails } from '~/api/building';
import moment from 'moment';
import _ from 'lodash';
import { withFullScreenLoader } from '~/hocs/withFullScreenLoader';
import { useFullScreenLoader } from '~/hooks/useFullScreenLoader';
import { toast } from 'react-toastify';

interface DispatchProps {
  fetchOneGatheringSuccess: typeof fetchOneGatheringSuccess;
}

type Props = ResolveThunks<DispatchProps>;

const RSVPGatheringContainer = (props: Props) => {
  const [loading, setLoading] = useState(true);
  const [creator, setCreator] = useState({} as UserMin);
  const [showCalendarModal, setShowCalendarModal] = useState(false);
  const [showRSVPModal, setShowRSVPModal] = useState(false);
  const [isValidNumGuestInput, setIsValidNumGuestInput] = useState(true);
  const { gatheringId } = useParams<{ gatheringId: string }>();
  const [totalAttendees, settotalAttendees] = useState<number>(0);
  const [atCapacity, setatCapacity] = useState<boolean>(false);
  const [userNotAllowed, setUserNotAllowed] = useState(false);
  const [interestedDate, setinterestedDate] = useState<string>();
  const [selectedGathering, setselectedGathering] = useState<GatheringType>();
  const [rsvps, setrsvps] = useState<any>([]);
  const [rsvpsByDate, setrsvpsByDate] = useState<any>({});

  const { loader } = useFullScreenLoader();
  const [gathering, setGathering] = useState<GatheringType>();

  const user = useSelector((state: RootState) => state.auth.user);
  const { data: buildingDetailsRes, isSuccess: isBuildingDetailsSuccess } =
    useQuery('buildingDetails', () => fetchBuildingDetails(user.building));

  const history = useHistory();
  const isMounted = useIsMounted();

  const { fetchOneGatheringSuccess } = props;

  const onAttendButtonClick = () => {
    if (
      moment(interestedDate)
        .tz(gathering?.buildingTimezone!)
        .isBefore(moment().tz(gathering?.buildingTimezone!))
    ) {
      toast('Cannot RSVP to gathering, that is in past');
      return;
    }
    if (gathering?.isRSVP) {
      setShowRSVPModal(true);
    } else if (!hasUserRsvped()) {
      onCreateRSVP(0);
    } else {
      onDeleteRsvp();
    }
  };

  const getRsvps = () => {
    loader.show();
    getGatheringRsvpsForResident({ gatheringId: gatheringId })
      .then((res) => {
        const data = res.data;
        const rsvpGroupedByInterestedDate = _.groupBy(data, 'interestedDate');
        setrsvpsByDate(rsvpGroupedByInterestedDate);
        const totalAttendees = res.data.reduce(
          (total: number, currentValue: any) => {
            return total + currentValue.totalAttendees;
          },
          0
        );
        settotalAttendees(totalAttendees);
        setatCapacity(totalAttendees == gathering?.maxEventAttendees);
      })
      .finally(() => loader.hide());
  };

  const onCreateRSVP = (numberOfGuests: number) => {
    loader.show();
    createRSVPGatheringRequest({
      groupEvent: gathering?.uuid!,
      userForRsvp: user.uuid,
      totalAttendees: numberOfGuests + 1,
      interestedDate: interestedDate!
    })
      .then(() => {
        getRsvps();
        Mixpanel.track('rsvped_to_gathering', {
          $gathering_name: gathering?.title,
          $no_of_attendees: numberOfGuests + 1
        });
        setShowRSVPModal(false);
      })
      .catch((e) => {
        if (e.status === 400) {
          setIsValidNumGuestInput(false);
        }
      })
      .finally(() => {
        loader.hide();
      });
  };

  const onDeleteRsvp = () => {
    loader.show();
    deleteRSVPGatheringRequest(getUserRsvp()?.uuid)
      .then((res) => {
        getRsvps();
      })
      .finally(() => loader.hide());
    setShowRSVPModal(false);
  };

  const onChangeRSVPStatus = (numberOfGuests: number) => {
    loader.show();
    updateRSVPGatheringRequest({
      RSVPGatheringId: getUserRsvp()?.uuid,
      totalAttendees: numberOfGuests + 1
    })
      .then((res) => {
        if (res.data) {
          getRsvps();
        }
        if (res.status === 200) {
          setShowRSVPModal(false);
        }
      })
      .catch((e) => {
        if (e.status === 400) {
          setIsValidNumGuestInput(false);
        }
      })
      .finally(() => loader.hide());
  };

  const hasUserRsvped = () => {
    const rsvpsForDate: any[] = rsvpsByDate[interestedDate!] ?? [];

    const rsvp = rsvpsForDate?.find(
      (rsvp: any) => rsvp?.user?.uuid == user.uuid
    );

    return !!rsvp;
  };

  const getUserRsvp = () => {
    const rsvpsForDate: any[] = rsvpsByDate[interestedDate!] ?? [];

    const rsvp = rsvpsForDate?.find(
      (rsvp: any) => rsvp?.user?.uuid == user.uuid
    );

    return rsvp;
  };

  const userRsvp = getUserRsvp();

  const getGatheringForSelectedDate = () => {
    if (gathering?.recurringTypeName != 'One Time') {
      const gatheringForSelectedDate = gathering?.upcomingEvents?.find(
        (gathering) => {
          const date = moment(gathering.scheduledStartTime)
            .tz(gathering.buildingTimezone)
            .format('YYYY-MM-DD');

          if (date === interestedDate) return true;

          return false;
        }
      );

      return gatheringForSelectedDate ?? gathering;
    }

    return gathering;
  };

  const allGatherings = gathering ? gathering?.upcomingEvents : [];

  useEffect(() => {
    setselectedGathering(getGatheringForSelectedDate());
  }, [interestedDate]);

  useEffect(() => {
    async function getData() {
      const gathering = (await fetchOneGatheringRequest(gatheringId)).data;
      setGathering(gathering);
      getRsvps();

      Promise.all([fetchOneUserRequest(gathering.creator)])
        .then((res) => {
          if (isMounted()) {
            setCreator(res[0].data);
          }
        })
        .catch((err) => {
          if (err.status === 403) {
            if (isMounted()) setUserNotAllowed(true);
          }
        })
        .finally(() => {
          if (isMounted()) {
            setLoading(false);
          }
        });
    }
    if (user.verified && user.verifiedPhone) {
      getData();
    } else {
      history.push(`/rsvp/${gatheringId}`);
    }
  }, [user, isMounted, fetchOneGatheringSuccess, gatheringId, history]);

  useEffect(() => {
    if (!gathering) return;

    if (gathering.recurringTypeName != 'One Time') {
      const hasStarted = moment(gathering.scheduledStartTime).isBefore(
        moment().tz(gathering.buildingTimezone)
      );

      if (
        hasStarted &&
        Array.isArray(gathering.upcomingEvents) &&
        gathering.upcomingEvents[0]
      ) {
        setinterestedDate(
          moment(gathering.upcomingEvents[0]?.scheduledStartTime)
            .tz(gathering.buildingTimezone)
            .format('YYYY-MM-DD')
        );
      } else {
        moment(gathering.scheduledStartTime)
          .tz(gathering.buildingTimezone)
          .format('YYYY-MM-DD');
      }
    } else {
      setinterestedDate(
        moment(gathering.scheduledStartTime)
          .tz(gathering.buildingTimezone)
          .format('YYYY-MM-DD')
      );
    }
  }, [gathering]);

  if (!gathering) return null;

  return userNotAllowed ? (
    <NotFound />
  ) : (
    <div>
      {showCalendarModal && (
        <div className='overlay-with-modal'>
          <div
            className='gathering-overlay'
            onClick={() => {
              setShowCalendarModal(false);
            }}
          />
          <CalendarModal
            closeModal={() => setShowCalendarModal(false)}
            gathering={selectedGathering!}
          />
        </div>
      )}
      {showRSVPModal && (
        <div className='overlay-with-modal'>
          <div
            className='gathering-overlay'
            onClick={() => {
              setShowRSVPModal(false);
            }}
          />
          <RSVPModal
            closeModal={() => setShowRSVPModal(false)}
            createRSVP={onCreateRSVP}
            gathering={gathering}
            totalAttendees={totalAttendees}
            isAttending={hasUserRsvped()}
            deleteRSVP={onDeleteRsvp}
            changeRSVPStatus={onChangeRSVPStatus}
            selectedGuests={
              userRsvp?.totalAttendees &&
              typeof userRsvp?.totalAttendees == 'number' &&
              userRsvp?.totalAttendees > 0
                ? userRsvp?.totalAttendees - 1
                : 0
            }
            isValidNumGuestInput={isValidNumGuestInput}
            isAtCapacity={atCapacity}
          />
        </div>
      )}

      {loading ? (
        <Spinner />
      ) : (
        <div className={classes.rsvpGathHeader}>
          <div className={classes.rsvpGathImageContainer}>
            <img
              className={classes.gathImage}
              src={gathering?.image as string}
              alt='gath'
            />
          </div>
        </div>
      )}

      {!loading && isBuildingDetailsSuccess && (
        <div className={classes.rsvpGathContent}>
          <div className={classes.rsvpGathDetailContainer}>
            <RSVPGatheringDetail
              allGatherings={allGatherings}
              interestedDate={interestedDate}
              onInterestedDateChanged={setinterestedDate}
              creator={creator}
              building={buildingDetailsRes && buildingDetailsRes.data}
              gathering={selectedGathering ?? gathering}
              totalAttendees={totalAttendees}
              isAttending={hasUserRsvped()}
              onAttend={onAttendButtonClick}
              showCalendarModal={() => setShowCalendarModal(true)}
              isAtCapacity={atCapacity}
            />
          </div>
        </div>
      )}
    </div>
  );
};

function mapDispatchToProps(): DispatchProps {
  return {
    fetchOneGatheringSuccess
  };
}
export default connect(
  null,
  mapDispatchToProps()
)(withFullScreenLoader(RSVPGatheringContainer));
