import React from 'react';
import { NavLink } from 'react-router-dom';
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import routes from '~/routes/routes';

import { ReactComponent as RightArrow } from '~/utils/images/right-arrow.svg';
import classes from './Breadcrumbs.module.css';

interface IProps {
  indexesToHide?: number[];
  floatingBanner?: {
    text: string;
  };
}
const Breadcrumbs = (props: IProps) => {
  const breadcrumbs = useBreadcrumbs(routes);
  const { indexesToHide, floatingBanner } = props;
  return (
    <div className={classes.container}>
      <div className={classes.crumbContainer}>
        {breadcrumbs.map(({ match, breadcrumb }, i) => {
          return (
            <div
              key={match.url}
              className={
                indexesToHide && indexesToHide.includes(i)
                  ? `${classes.crumbContainer} ${classes.hide}`
                  : `${classes.crumbContainer}`
              }
            >
              <NavLink
                to={match.url}
                className={
                  breadcrumbs.length - 1 === i ? classes.selected : classes.link
                }
              >
                {breadcrumb}
              </NavLink>
              {breadcrumbs.length - 1 !== i && <RightArrow />}
            </div>
          );
        })}
      </div>
      {floatingBanner && (
        <div className={classes.banner}>
          <p className={classes.bannerText}>{floatingBanner.text}</p>
        </div>
      )}
    </div>
  );
};

export default Breadcrumbs;
