import React from 'react';
import { ReactComponent as NotFoundImage } from '~/utils/images/not-found.svg';
import classes from './NotFound.module.css';

const NotFound = () => {
  return (
    <div className={classes.container}>
      <NotFoundImage />
      <div className={classes.title}>404</div>
      <div className={classes.text}>Something’s missing!</div>
    </div>
  );
};

export default NotFound;
