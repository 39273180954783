import React from 'react';
import _ from 'lodash';
import './Tags.css';
import { FormikProps } from 'formik';
import { CreateGroupFromValues } from '../CreateGroupForm/CreateGroupForm';

const styles = {
  heading: {
    marginBottom: 0
  },
  errorMessage: {
    color: '#f44336',
    fontSize: '0.75rem',
    marginTop: 8,
    marginBottom: 10,
    fontWeight: 'default',
    fontFamily: 'Roboto'
  }
};

const handleClick = (
  type: string,
  index: number,
  tagId: number,
  { values, setFieldValue, setFieldTouched }: FormikProps<any>
) => {
  const tags = values[type];
  if (index >= 0) {
    tags.splice(index, 1);
  } else {
    tags.push(tagId);
  }
  setFieldValue(type, tags);
  setFieldTouched(type, true);
};

type TagsProps = FormikProps<CreateGroupFromValues> & {
  presentational: any;
  type: string;
  tagObjects: any[];
  form: any;
};

const Tags = (props: TagsProps) => {
  const { presentational, type = 'tags', tagObjects, form } = props;

  if (!tagObjects || !tagObjects.length) return null;

  if (presentational) {
    return (
      <div className='tags presentational-container'>
        <div className='presentational-container'>
          <p style={styles.heading}>
            {type.charAt(0).toUpperCase() + type.slice(1)}
          </p>
          {tagObjects?.map((tagObject) => (
            <button
              type='button'
              key={tagObject.uuid}
              className='active'
              style={{ cursor: 'default' }}
            >
              {tagObject.name}
            </button>
          ))}
        </div>
      </div>
    );
  }
  const { errors, touched, ...formikProps } = form;
  return (
    <div className='tags'>
      <p style={styles.heading}>
        {type.charAt(0).toUpperCase() + type.slice(1)}
      </p>
      {errors[type] && touched[type] ? (
        <p style={styles.errorMessage}>{errors[type]}</p>
      ) : null}
      {tagObjects.map((tagObject) => {
        const tagIndex = _.indexOf(formikProps.values[type], tagObject.uuid);
        return (
          <button
            type='button'
            key={tagObject.uuid}
            className={tagIndex >= 0 ? 'active' : 'inactive'}
            onClick={() =>
              handleClick(type, tagIndex, tagObject.uuid, formikProps)
            }
          >
            {tagObject.name}
          </button>
        );
      })}
    </div>
  );
};

export default Tags;
