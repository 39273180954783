import React from 'react';
import { useForm } from 'react-hook-form';
import classes from './PerkForm.module.css';
import { PerkPatch } from '~/types/perk';
import { useHistory, useParams } from 'react-router-dom';
import { MerchantCategories } from '~/enums/MerchantCategories';

interface IProps {
  perk: PerkPatch;
  onSubmit: (data: PerkPatch) => void;
}
export const PerkForm = (props: IProps) => {
  const { perk } = props;
  const history = useHistory();
  const { buildingId } = useParams<{ buildingId: string }>();
  const onSubmit = (data: PerkPatch) => {
    props.onSubmit(data);
  };

  const { register, handleSubmit, errors, formState, watch } = useForm<PerkPatch>({
    defaultValues: {
      uuid: perk?.uuid,
      name: perk?.name,
      offerType: perk?.offerType,
      customButtonLabel: perk?.customButtonLabel,
      contactSMSNumber: perk?.contactSMSNumber,
      contactPhone: perk?.contactPhone,
      contactEmail: perk?.contactEmail,
      description: perk?.description,
      redeemLink: perk?.redeemLink,
      merchant: perk?.merchant,
      status: perk?.status
    }
  });

  const { isSubmitting, isSubmitSuccessful } = formState;
  const watchOfferType = watch('offerType');

  return (
    <div className={classes.container}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={classes.formHeader}>
          <h1 className={classes.title}>Edit Offer</h1>
          <div className={classes.formButtons}>
            <button
              disabled={isSubmitting || isSubmitSuccessful}
              className='btn-standard btn-primary margin-left-16 weight-500'
              type='submit'
            >
              Save
            </button>
            <button
              className='btn-standard btn-seconday weight-500'
              type='button'
              onClick={() => {
                history.push(`/building/${buildingId}/perks`);
              }}
            >
              Cancel
            </button>
          </div>
        </div>
        <div className={classes.gridContainer}>
          <div className={classes.item}>
            <label className='margin-top-16' htmlFor='merchant'>
              Merchant Name*
            </label>
            <select
              name='merchant.uuid'
              ref={register}
              className={`${errors.merchant && 'border-red'} height-50`}
            >
              <option value={perk.merchant?.uuid}>{perk.merchant?.name}</option>
            </select>

            <label className='margin-top-16' htmlFor='title'>
              Name of Offer *
            </label>
            <input
              type='text'
              id='name'
              name='name'
              ref={register({
                required: 'This field is required'
              })}
              className={`${errors.name && 'border-red'}`}
            />

            <label className='margin-top-16' htmlFor='title'>
              Description of Business & Offer *
            </label>
            <textarea
              id='description'
              name='description'
              ref={register({
                required: 'This field is required'
              })}
              className={`${errors.description && 'border-red'}`}
            />
          </div>
          <div className={classes.item}>
            <label className='margin-top-16' htmlFor='group'>
              Choose category *
            </label>

            <select
              name='merchant.category'
              ref={register({
                required: 'This field is required'
              })}
              defaultValue={perk.merchant?.category?.toLocaleLowerCase()}
              className={`${errors.merchant && 'border-red'} height-50`}
            >
              {MerchantCategories.map((option: string) => {
                return (
                  <option value={option} key={option}>
                    {option}
                  </option>
                );
              })}
            </select>

            <label className='margin-top-16' htmlFor='title'>
              Offer Type *
            </label>
            <select id='offerType' name='offerType' className={`${errors.merchant && 'border-red'} height-50`} ref={register} >
              <option value="redeem">Redeem</option>
              <option value="learn">Learn More</option>
              <option value="custom">Custom</option>
              <option value="contact">Contact</option>
            </select>

            {watchOfferType == "custom" && (
               <div className={classes.item}>
                <label className='margin-top-16' htmlFor='customButtonLabel'>
              Custom Button Label
            </label>
            <input
              type='text'
              id='customButtonLabel'
              name='customButtonLabel'
              ref={register}
              className={`${errors.customButtonLabel && 'border-red'}`}
            />
              </div>
              )}
              
              {watchOfferType == "contact" && (
                <div>
                  <div className={classes.item}>
                    <label className='margin-top-16' htmlFor='contactEmail'>
                      Contact Email
                    </label>
                    <input
                    type='text'
                    id='contactEmail'
                    name='contactEmail'
                    ref={register({
                      required: 'This field is required'
                    })}
                      className={`${errors.contactEmail && 'border-red'}`}
                    />
                  </div>
                  <div className={classes.item}>
                  <label className='margin-top-16' htmlFor='contactPhone'>
                    Contact Phone
                  </label>
                  <input
                  type='text'
                  id='contactPhone'
                  name='contactPhone'
                  ref={register({
                    required: 'This field is required'
                  })}
                  className={`${errors.contactPhone && 'border-red'}`}
                  />
                </div>
                <div className={classes.item}>
                  <label className='margin-top-16' htmlFor='contactSMSNumber'>
                    Contact SMS Number
                  </label>
                  <input
                  type='text'
                  id='contactSMSNumber'
                  name='contactSMSNumber'
                  ref={register({
                    required: 'This field is required'
                  })}
                  className={`${errors.contactSMSNumber && 'border-red'}`}
                  />
                </div>
              </div>

              )}

            
            <label className='margin-top-16' htmlFor='locationString'>
              Redemption link *
            </label>
            <input
              type='text'
              id='redeemLink'
              name='redeemLink'
              ref={register({
                required: 'This field is required'
              })}
              className={`${errors.redeemLink && 'border-red'}`}
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default PerkForm;
