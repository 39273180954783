
import {
  FETCH_PROPOSEDGROUPS,
  PROPOSEDGROUPS_FETCHED,
  REJECTEDGROUPS_FETCHED,
  PROPOSEDGROUPS_UPDATED,
  PROPOSEDGROUPS_DELETED,
  CHOOSE_GROUP,
  CREATE_GROUP,
  GROUP_CREATED,
  GET_GROUPS,
  GROUPS_FETCHED,
  GROUP_UPDATED,
  GROUP_DELETED,
  OPEN_GROUP_EVENT_SNACKBAR,
} from '../actions/GroupActions';

interface SuggestingUser {
  uuid: string;
  email: string;
  firstName: string;
  lastName: string;
}

interface Building {
  uuid: string;
  name: string;
}

export interface Group {
  createdAt: string;
  updatedAt: string;
  uuid: string;
  approved: boolean;
  title: string;
  description: string;
  comment: string;
  rejected: boolean;
  rejectionReason: string;
  suggestingUser: SuggestingUser;
  building: Building;
}

export type GroupAdminStoreState = {
  groupProposals: Group[],
  rejectedGroups: Group[],
  createGroup?: Group,
  groupSelected?: Group,
  loading: boolean,
  groups: Group[],
  snackbarOpen: boolean,
  errorMessage: string
};

const initialState: GroupAdminStoreState = {
  groupProposals: [],
  rejectedGroups: [],
  createGroup: undefined,
  groupSelected: undefined,
  loading: true,
  groups: [],
  snackbarOpen: false,
  errorMessage: ''
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case FETCH_PROPOSEDGROUPS:
      return { ...state, loading: true };

    case PROPOSEDGROUPS_FETCHED: {
      return {
        ...state,
        groupProposals: action.payload,
        loading: false
      };
    }
    case REJECTEDGROUPS_FETCHED: {
      return {
        ...state,
        rejectedGroups: action.payload,
        loading: false
      };
    }

    case PROPOSEDGROUPS_UPDATED: {
      return {
        ...state,
        groupProposals: [
          ...state.groupProposals,
          action.payload,
          // [action.payload.uuid]: {
          //   rejected: action.payload.rejected,
          //   approved: action.payload.approved
          // }
        ],
        rejectedGroups: [
          ...state.rejectedGroups,
          action.payload,
          // [action.payload.uuid]: {
          //   ...action.payload
          // }
        ]
      };
    }

    case PROPOSEDGROUPS_DELETED: {
      return {
        ...state,
        rejectedGroups: [...state.rejectedGroups].filter(group => group.uuid !== action.payload.uuid)
      };
    }

    case CHOOSE_GROUP:
      return {
        ...state,
        groupSelected: action.payload
      };

    case CREATE_GROUP:
      return {
        ...state,
        loading: true
      };

    case GROUP_CREATED: {
      return {
        ...state,
        createGroup: action.payload,
        groups: [...state.groups, action.payload],
        loading: false
      };
    }

    case GET_GROUPS:
      return {
        ...state,
        loading: true
      };

    case GROUPS_FETCHED: {
      return {
        ...state,
        groups: action.payload,
        loading: false
      };
    }

    case GROUP_UPDATED:
      return {
        ...state,
        groups: [...state.groups, action.payload]
      };

    case GROUP_DELETED:
      const groups = { ...state.groups };
      return {
        ...state,
        groupSelected: {},
        groups: groups.filter(group => group.uuid == state.groupSelected?.uuid)
      };

    case OPEN_GROUP_EVENT_SNACKBAR:
      return {
        ...state,
        snackbarOpen: action.payload.snackbarOpen,
        errorMessage: action.payload.errorMessage
      };

    default:
      return state;
  }
};
