import React from 'react';
import { ReactComponent as ExclamationMark } from '~/utils/images/exclamation-mark.svg';

import './InvalidBlock.css';

interface IProps {
  text: string;
}
const InvalidBlock = (props: IProps) => {
  const { text } = props;
  return (
    <div>
      <div className='invalid-block-container'>
        <div className='exlamation-mark-container'>
          <ExclamationMark fill='var(--iconError)' />
        </div>
        <div className='invalid-block-text'>{text}</div>
      </div>
      <div className='invalid-block-text'>{text}</div>
      </div>
  );
};

export default InvalidBlock;
