import React, { useState } from 'react';
import classes from './Rating.module.css';
import { ReactComponent as StarOff } from '~/utils/images/starOff.svg';
import { ReactComponent as StarOn } from '~/utils/images/starOn.svg';

interface IProps {
  value: number;
  setValue: (value: number) => void;
  otherStyle: string;
}
const Rating = (props: IProps) => {
  const { value, setValue, otherStyle } = props;
  const [hover, setHover] = useState(value);
  const containerStyle =
    otherStyle && otherStyle === 'undefined'
      ? classes.container
      : classes.containerError;
  return (
    <div className={containerStyle}>
      {[...Array(5)].map((item, index) => {
        const givenRating = index + 1;
        return givenRating <= (hover || value) ? (
          <StarOn
            onMouseEnter={() => setHover(givenRating)}
            onMouseLeave={() => setHover(0)}
            onClick={() => setValue(givenRating)}
            className={classes.star}
            key={givenRating}
          />
        ) : (
          <StarOff
            onMouseEnter={() => setHover(givenRating)}
            onMouseLeave={() => setHover(0)}
            className={classes.star}
            key={givenRating}
          />
        );
      })}
    </div>
  );
};

export default Rating;
