import { useEffect } from 'react';

const RedirectComponent = () => {
  useEffect(() => {
    window.location.href = 'https://qrco.de/bbF2Tc';
  }, []);

  return null;
};

export default RedirectComponent;
