import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { patchFlyer } from '~/api/gatherings';
import { IFlyerFormData } from '~/containers/FlyerManagement/FlyerTable/EditFlyerForm/interfaces';

export default () => {
  const [loader, setLoader] = useState(false);

  const patchEventFlyer = async (
    flyerId: string,
    flyerData: IFlyerFormData,
    cb?: () => void
  ) => {
    try {
      setLoader(true);

      const formData = new FormData();

      formData.append('flyerUuid', flyerId);

      const isBgImageUrl = typeof flyerData.flyerBgImage === 'string';

      flyerData.flyerHeading &&
        formData.append('flyerHeading', flyerData.flyerHeading);

      flyerData.flyerDetails &&
        formData.append('flyerDetails', JSON.stringify(flyerData.flyerDetails));

      flyerData.flyerSubHeading &&
        formData.append('flyerSubHeading', flyerData.flyerSubHeading);

      flyerData.flyerDescription &&
        formData.append('flyerDescription', flyerData.flyerDescription);

      flyerData.flyerStatus &&
        formData.append('flyerStatus', flyerData.flyerStatus);

      flyerData.flyerTemplateUuid &&
        formData.append('flyerTemplateUuid', flyerData.flyerTemplateUuid);

      flyerData.flyerBgImage &&
        formData.append(
          isBgImageUrl ? 'flyerBgImageUrl' : 'flyerBgImage',
          flyerData.flyerBgImage
        );

      flyerData.flyerLogo && formData.append('flyerLogo', flyerData.flyerLogo);

      await patchFlyer(formData);

      setLoader(false);

      if (cb) {
        cb();
      }

      toast('Flyer Updated Successfully');
    } catch (e) {
      setLoader(false);
      toast(e.message || 'Failed to update flyer');
    }
  };

  return { loader, patchEventFlyer };
};
