import React, { LegacyRef, useRef, useState } from "react"
import { CellProps } from "react-table"
import Breadcrumbs from "~/components/Breadcrumbs/Breadcrumbs"
import CobuTableAdvanced from "~/components/CobuTableAdvanced/CobuTableAdvanced"
import Spacer from "~/components/Spacer/Spacer"
import Spinner from "~/components/Spinner/Spinner"
import CobuConstants from "~/helpers/cobuConstants"
import styles from './Deeplink.module.css'
import GenericModal from "~/components/GenericModal/GenericModal"
import { getCreateSurveyFormConfig } from "~/helpers/formHelpers"
import { Tabs } from "~/components/Tabs"
import { useParams } from "react-router-dom"
import { getBuildingFromStore } from "~/helpers/reduxStoreHelpers"
import { useDeeplink } from "~/hooks/deeplinks/useDeeplink"
import { AttributionDeeplink } from "~/types/deeplink"
import { qrCodeGeneratorURL } from "~/helpers/constants"
import CreateDeeplinkForm from "~/components/CreateDeeplinkForm/CreateDeeplinkForm"
import { ReactComponent as ChainLink } from '~/utils/images/link.svg';
import { toast } from "react-toastify"
import { saveAs } from 'file-saver';


const deeplinkTabs = [
    {
        label: 'GENERAL',
        value: 'general'
    },
    {
        label: 'GROUPS',
        value: 'groups'
    },
    {
        label: 'PERKS',
        value: 'perks'
    },
    {
        label: 'EVENTS',
        value: 'events'
    },
];


const Deeplinks = () => {

    /* Params */
    const { buildingId } = useParams<{ buildingId: string }>();
    const selectedBuilding = getBuildingFromStore(buildingId);

    const { activeDeepLinks, loadingConfig, deactivateDeepLink, createDeepLink } = useDeeplink(buildingId)
    const [showDeeplinkCreateModal, setShowDeeplinkCreateModal] = useState<boolean>(false)

    const [selectedFilter, setSelectedFilter] = useState<string>('general');

    const selectFilter = (value: string) => {
        if (!value) return;
        setSelectedFilter(value);
    };

    const getDeeplinkToShow = () => {
        if (activeDeepLinks) {
            return activeDeepLinks[selectedFilter] ?? []
        }
        return []
    }

    /* UI Components */

    const activeDeeplinksTableConfig = [
        {
            Header: 'Page',
            accessor: 'title',
            Cell: (cell: CellProps<AttributionDeeplink>) => {
                return <div
                    style={{ whiteSpace: 'normal' }}
                    className='default-text'>
                    {cell.row.original.title}
                </div>
            },
        },
        {
            Header: 'Link',
            accessor: 'link',
            Cell: (cell: CellProps<AttributionDeeplink>) => {
                return (
                    <div style={{ display: 'flex',justifyContent: 'center',alignItems: 'center'}}>
                        <div
                            style={{ whiteSpace: 'normal' }}
                            className='default-text'>
                            {cell.row.original.link}
                        </div>
                        <Spacer width={10} />
                        <div style={{  justifyContent: 'center',alignContent: 'center',paddingLeft: 10,paddingRight:20, height:80 }} onClick={
                            () => {
                                toast('Deeplink copied to clipboard.');
                                navigator.clipboard.writeText(
                                    cell.row.original.link
                                );
                            }
                        }>
                            <ChainLink className='margin-right-8' />
                        </div>
                    </div>
                )
            },
        },
        {
            Header: 'QR Code',
            Cell: (cell: CellProps<AttributionDeeplink>) => {
                return <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <img
                        className={styles.qrCode}
                        src={qrCodeGeneratorURL(cell.row.original.link)}
                        alt='Building code'
                    />
                    <Spacer width={5} />
                    <div style={{ justifyContent: 'center', alignContent: 'center', width: 50 }} onClick={
                        () => {
                            toast('Downloading QR....');
                            saveAs(qrCodeGeneratorURL(cell.row.original.link), 'image.jpg')
                           
                        }
                    }>
                        <ChainLink className='margin-right-8' />
                    </div>
                </div>
            },
        },
        {
            id: 'deactivate',
            Cell: (cell: CellProps<AttributionDeeplink>) => (
                <div
                    className={'more-actions-btn more-actions-btn-unselected'}
                    onClick={() => {
                        deactivateDeepLink(cell.row.original.uuid);
                    }}
                >
                    {CobuConstants.deeplink.deactivatelink}
                </div>
            )
        }
    ]

    const getCobuTableInstance = (data: AttributionDeeplink[], placeholder: string) => {
        return (
            <CobuTableAdvanced
                columns={activeDeeplinksTableConfig}
                data={data}
                noData={{
                    label: placeholder,
                    action: undefined,
                    image: undefined
                }}
                actions={[createDeeplinkAction()]}
                actionsStyle={{ top: '130px' }}
            />
        );
    };



    if (loadingConfig.flag) return <div className={styles.loadingContainer}>
        <Spinner />
        <h3 style={{ textAlign: 'center' }}>{loadingConfig.comment}</h3>
    </div>


    const createDeeplinkAction = () => {
        return (
            <button
                className='btn-primary btn-standard bold'
                onClick={() => {
                    setShowDeeplinkCreateModal(!showDeeplinkCreateModal)
                }}
            >
                {CobuConstants.deeplink.createDeeplink}
            </button>
        );
    };

    const createDeeplinkModal = () => {
        return showDeeplinkCreateModal ? <GenericModal
            showModal={showDeeplinkCreateModal}
            title={CobuConstants.deeplink.createDeeplink}
            content={
                <CreateDeeplinkForm
                    buildingId={buildingId}
                    cancel={() => { setShowDeeplinkCreateModal(!showDeeplinkCreateModal) }}
                    createDeeplink={(deeplink) => {
                        setShowDeeplinkCreateModal(!showDeeplinkCreateModal)
                        createDeepLink(deeplink)
                    }
                    }
                />
            }
            hideActionButton
            canceActionButton
            actionText={CobuConstants.residentManagement.createActionTitle}
            actionFunction={() => { }}
            onChange={() => { }}
        /> : null
    }

    return (
        <div className='content-container'>
            <Breadcrumbs />
            <Spacer height={20} />
            <h1>{CobuConstants.deeplink.title(selectedBuilding.name)}</h1>
            <Spacer height={20} />
            <Tabs.Container onChange={selectFilter} value={selectedFilter}>
                {deeplinkTabs.map((filter) => (
                    <Tabs.Option
                        selected={filter.value == selectedFilter}
                        key={filter.value}
                        value={filter.value}
                    >
                        {filter.label}
                    </Tabs.Option>
                ))}
            </Tabs.Container>
            <Spacer height={20} />
            {getCobuTableInstance(getDeeplinkToShow(), CobuConstants.deeplink.noActiveDeeplinks)}
            {createDeeplinkModal()}
        </div>
    )
}

export default Deeplinks