import { createSlice } from '@reduxjs/toolkit';
import { IEventWithFlyer } from '~/types/gathering';

interface IInitialState {
  eventsWithFlyer: IEventWithFlyer[];
}

const initialState: IInitialState = {
  eventsWithFlyer: []
};

const gatheringSlice = createSlice({
  name: 'gathering',
  initialState: initialState,
  reducers: {
    saveEventsWithFlyers: (state, action) => {
      state.eventsWithFlyer = action.payload;
    }
  }
});

export const { saveEventsWithFlyers } = gatheringSlice.actions;
export default gatheringSlice.reducer;
