import React from 'react';
import packageJson from '~/../package.json';

const Footer = () => {
  return (
    <div id='footer'>
      <p className='footer-content'>
        For support questions or feature requests, please email &nbsp;
        <a href='mailto:feedback@livecobu.com'>feedback@livecobu.com</a>
      </p>
      <div className='version'>Current App version: {packageJson.version}</div>
    </div>
  );
};

export default Footer;
