import React, { useEffect } from 'react';
import GroupsProposalsContainer from '../GroupsProposalsContainer/GroupsProposalsContainer';
import GroupsRejectedContainer from '../GroupsRejectedContainer/GroupsRejectedContainer';
import './GroupsContainer.css';
import { withFullScreenLoader } from '~/hocs/withFullScreenLoader';
import { useFullScreenLoader } from '~/hooks/useFullScreenLoader';
import { useCobuSelector } from '~/hooks/useCobuSelector';

const GroupsContainer = () => {
  const { loader } = useFullScreenLoader();
  const { loading } = useCobuSelector((state) => ({
    loading: state.groups.loading
  }));

  useEffect(() => {
    if (loading) {
      loader.show();
    } else {
      loader.hide();
    }
  }, [loading]);

  return (
    <div id='GroupsContainer' style={{ padding: '36px 0px' }}>
      <GroupsProposalsContainer />
      <GroupsRejectedContainer />
    </div>
  );
};

export default withFullScreenLoader(GroupsContainer);
