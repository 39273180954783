import React from 'react';
import classes from '~/containers/BuildingPmsIntegrationManagement/index.module.css';
import { Group } from '~/reducers/groupAdmin';

type RejectGroupProps = {
  proposedGroup: Group;
  handleChange: (value: any) => void;
  reasonText: string;
};

const RejectGroup = ({
  proposedGroup: {
    title,
    building,
    description,
    suggestingUser: { firstName, lastName, email }
  },
  handleChange,
  reasonText
}: RejectGroupProps) => (
  <div className='group-modal-content'>
    <div className={classes.gridContainer}>
      <div className={classes.item}>
        <div className='detail-content-container'>
          <div className={classes.gridContainer}>
            <div className={classes.item}>
              <p className='detail-label'>Title</p>
            </div>
            <div className={classes.item}>
              <p>{title}</p>
            </div>
            <div className={classes.item}>
              <p className='detail-label'>User</p>
            </div>
            <div className={classes.item}>
              <p>{`${firstName} ${lastName}`}</p>
            </div>
            <div className={classes.item}>
              <p className='detail-label'>Email</p>
            </div>
            <div className={classes.item}>
              <p>{email}</p>
            </div>
            <div className={classes.item}>
              <p className='detail-label'>Building</p>
            </div>
            <div className={classes.item}>
              <p>{building.name}</p>
            </div>
            <div className={classes.item}>
              <p className='detail-label'>Description</p>
            </div>
            <div className={classes.item}>
              <p>{description}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className={'item margin-top-24'}>
      <textarea
        id='rejection-reason-form'
        name='rejection-reason-form'
        value={reasonText}
        rows={3}
        onChange={(value) => handleChange(value)}
      />
    </div>
  </div>
);

export default RejectGroup;
