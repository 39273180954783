import React, { ChangeEvent, useState } from 'react';
import { useForm } from 'react-hook-form';

import { ReactComponent as Close } from '~/utils/images/close.svg';
import { GatheringType } from '~/types/gathering';
import classes from './RSVPModal.module.css';
import { ButtonAction } from '~/enums/ButtonAction';

interface IProps {
  closeModal: () => void;
  createRSVP: (numberOfGuests: number) => void;
  deleteRSVP: () => void;
  changeRSVPStatus: (numberOfGuests: number) => void;
  gathering: GatheringType;
  totalAttendees: number;
  isAttending: boolean;
  selectedGuests: number;
  isAtCapacity: boolean;
  isValidNumGuestInput: boolean;
}
const RSVPModal = (props: IProps) => {
  const {
    closeModal,
    createRSVP,
    gathering,
    totalAttendees,
    isAttending,
    deleteRSVP,
    changeRSVPStatus,
    selectedGuests,
    isAtCapacity,
    isValidNumGuestInput
  } = props;

  const { register, handleSubmit, errors } = useForm<{
    numberOfGuest: number;
  }>({
    defaultValues: {
      numberOfGuest: selectedGuests
    }
  });
  const [RSVPGuestNumber, setRSVPGuestNumber] = useState(selectedGuests);
  const [buttonType, setButtonType] = useState(ButtonAction.Create);

  const handleChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setRSVPGuestNumber(Number(event.target.value));
  };

  const getRSVPMaxGuests = () => {
    const numberOfAvailableGuestPlaces =
      gathering.maxEventAttendees - totalAttendees;
    const maxNumberOfGuests = numberOfAvailableGuestPlaces + selectedGuests;

    let maxGuests = 0;
    if (isAttending) {
      maxGuests =
        maxNumberOfGuests > gathering.maxGuests
          ? gathering.maxGuests + 1
          : maxNumberOfGuests + 1;
    } else {
      maxGuests =
        numberOfAvailableGuestPlaces > gathering.maxGuests
          ? gathering.maxGuests + 1
          : gathering.maxEventAttendees - totalAttendees;
    }

    return maxGuests;
  };

  const onSubmit = (data: { numberOfGuest: string }) => {
    if (buttonType === ButtonAction.Create) {
      createRSVP(Number(data.numberOfGuest));
    } else if (buttonType === ButtonAction.Delete) {
      deleteRSVP();
    } else if (buttonType === ButtonAction.Change) {
      changeRSVPStatus(Number(data.numberOfGuest));
    }
  };

  return (
    <div className={`modal`}>
      <div className={classes.rsvpCloseIconContainer}>
        <Close onClick={closeModal} />
      </div>
      <div className={`modal-content ${classes.contentWidth}`}>
        <p className='modal-title'>
          {isAttending || isAtCapacity
            ? 'Change number of guests'
            : 'Bringing any guest?'}
        </p>
        <form className='margin-top-24' onSubmit={handleSubmit(onSubmit)}>
          <div className='form-field-error-box error-text'>
            {((isAtCapacity && !isAttending) ||
              errors.numberOfGuest ||
              !isValidNumGuestInput) &&
              'Gathering is at the capacity.'}
          </div>
          <div>
            <select
              className={`input-field ${
                ((isAtCapacity && !isAttending) || errors.numberOfGuest) &&
                'error-border'
              } height-50`}
              name='numberOfGuest'
              ref={register({
                required: true
              })}
              disabled={(isAtCapacity && !isAttending) || !isValidNumGuestInput}
              onChange={handleChange}
            >
              {Array.from(Array(getRSVPMaxGuests()), (element, index) => {
                return (
                  <option key={index} value={index}>
                    {index} guests
                  </option>
                );
              })}
            </select>
          </div>
          <div className='modal-text color-light-grey-5'>
            You and {RSVPGuestNumber} guests?
          </div>
          {isAttending ? (
            <div className={classes.buttonContainer}>
              <button
                className={classes.cannotAttendButton}
                onClick={() => {
                  setButtonType(ButtonAction.Delete);
                }}
              >
                I cannot attend
              </button>
              <button
                className={`submit-button ${classes.saveButton}`}
                onClick={() => setButtonType(ButtonAction.Change)}
              >
                Save
              </button>
            </div>
          ) : (
            <button
              type='submit'
              className={`submit-button ${classes.rsvpButtonHeight}`}
              onClick={() => setButtonType(ButtonAction.Create)}
              disabled={(isAtCapacity && !isAttending) || !isValidNumGuestInput}
            >
              Complete RSVP
            </button>
          )}
        </form>
      </div>
    </div>
  );
};

export default RSVPModal;
