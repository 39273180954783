import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { getReviewAlgorithmResults } from "~/api/review";
import { Building } from "~/types/building";
import { GoogleInvitationSettings, ReviewAlgorithmResult } from "~/types/reviewReceived";



const useGoogleReviewAlgorithm = (settings: GoogleInvitationSettings, building: Partial<Building>) => {
    const [reviewAlgorithmResult, setReviewAlgorithmResult] = useState<ReviewAlgorithmResult[]>([]);
    const [loader, setLoader] = useState<boolean>(false);

    useEffect(() => {

        getGoogleReviewAlgorithmResult();
    }, [building, settings]);

    const getGoogleReviewAlgorithmResult = async () => {
        setLoader(true);
        try {
            const { uuid, reviewUrl, name, VCM } = building;
            const buildingData = { uuid, reviewUrl, name, VCM };
            const result = await getReviewAlgorithmResults(buildingData, settings);
            setReviewAlgorithmResult(result.data.selectedResidents ?? []);
        } catch (error) {
            console.log(error);
            toast(error?.message)
        } finally {
            setLoader(false);
        }
    };

    return { reviewAlgorithmResult, loader };
};

export default useGoogleReviewAlgorithm;
