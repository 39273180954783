import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  gatheringId: '',
  user: {
    uuid: '',
    email: '',
    profileImage: '',
    phone: '',
    verified: false,
    verifiedPhone: false,
    building: ''
  },
  userSettings: {
    gatheringActiveTabIndex: 0,
    perkActiveTabIndex: 0,
    reviewActiveTabIndex: 0
  },
  userRole: ''
};

const authSlice = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers: {
    saveGatheringId: (state, action) => {
      state.gatheringId = action.payload;
    },
    saveUser: (state, action) => {
      state.user = action.payload;
    },
    logoutSuccess: () => initialState,
    saveUserRole: (state, action) => {
      state.userRole = action.payload;
    },
    saveUserSettings: (state, action) => {
      state.userSettings = action.payload;
    }
  }
});

export const {
  saveUser,
  saveGatheringId,
  logoutSuccess,
  saveUserRole,
  saveUserSettings
} = authSlice.actions;
export default authSlice.reducer;
