import { useState } from "react"
import { toast } from "react-toastify"
import { getBuildingReports } from "~/api/reports"

const useReports = (building: string) => {
    const [report, setReport] = useState<any | null>(null)
    const [loading, setLoading] = useState<boolean>(false)

    const getReport = async (date: string) => {
        setLoading(true)
        try {
            const response = await getBuildingReports(building, date)
            setReport(response.data.context)
        } catch (error) {
            toast.error(error?.message || 'An error occurred while fetching the report.')
        } finally {
            setLoading(false)
        }
    }

    return { report, getReport, loading }
}

export default useReports;
