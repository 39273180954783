import React, { useRef, useState } from 'react';
import './MoreActionsGatherings.css';
import { useOnClickOutside } from '../../helpers/helpers';
import { useHistory } from 'react-router-dom';
import { GatheringType } from '~/types/gathering';
import GenericModal from '../GenericModal/GenericModal';
import {
  cancelRecurringGatheringRequest,
  deleteGatheringRequest
} from '~/api/building';
import { toast } from 'react-toastify';
import { connect, ResolveThunks } from 'react-redux';
import { deleteGatheringSuccess } from '~/reducers/building';
import { ReactComponent as ChainLink } from '~/utils/images/chain-link.svg';
import { Mixpanel } from '~/services/MixpanelService';
import { useFullScreenLoader } from '~/hooks/useFullScreenLoader';
import { withFullScreenLoader } from '~/hocs/withFullScreenLoader';
import { AxiosError } from 'axios';

interface DispatchProps {
  deleteGatheringSuccess: typeof deleteGatheringSuccess;
}

type MoreActionsProps = ResolveThunks<DispatchProps> & {
  gathering: GatheringType;
  getGatherings: () => void;
};

const MoreActionsGatherings = (props: MoreActionsProps) => {
  const [selected, setSelected] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showRSVPLinkModal, setShowRSVPLinkModal] = useState(false);
  const history = useHistory();
  const { gathering, deleteGatheringSuccess } = props;
  const ref = useRef<HTMLDivElement>(null);
  useOnClickOutside(ref, () => setSelected(false));
  const { loader } = useFullScreenLoader();

  const cancelGathering = () => {
    loader.show();

    cancelRecurringGatheringRequest(gathering.uuid)
      .then((res) => {
        Mixpanel.track('did_cancel_gathering', {
          $gathering_name: gathering.title
        });

        props.getGatherings();

        toast('Gathering Cancelled!');
      })
      .catch((e) => {
        const error = e as AxiosError;
        toast(
          error.message ?? 'Oh no! Something went wrong, please try again later'
        );
      })
      .finally(() => {
        setShowDeleteModal(false);
        loader.hide();
      });
  };

  const deleteGathering = () => {
    loader.show();
    deleteGatheringRequest(gathering.uuid)
      .then((res) => {
        deleteGatheringSuccess(res.data);

        Mixpanel.track('did_delete_gathering', {
          $gathering_name: gathering.title
        });

        toast('Gathering deleted!');
      })
      .catch((e) => {
        const error = e as AxiosError;
        toast(
          error.message ?? 'Oh no! Something went wrong, please try again later'
        );
      })
      .finally(() => {
        setShowDeleteModal(false);
        loader.hide();
      });
  };

  return (
    <div ref={ref}>
      <GenericModal
        showModal={showDeleteModal}
        title={'Delete Gathering?'}
        actionText={'Delete'}
        actionFunction={deleteGathering}
        onChange={(value: boolean) => {
          setShowDeleteModal(value);
        }}
        content={
          <div className='text-align-left'>
            Are you sure you want to delete {gathering.title}?
          </div>
        }
      />
      <GenericModal
        showModal={showRSVPLinkModal}
        onChange={(value: boolean) => {
          setShowRSVPLinkModal(value);
        }}
        title={'Get RSVP link'}
        actionText={'Copy link'}
        actionFunction={() => {
          navigator.clipboard.writeText(
            `${window.location.origin.toString()}/rsvp/${gathering.uuid}`
          );
          Mixpanel.track('did_copy_rsvp_link', {
            $gathering_name: gathering.title
          });
          toast('Link copied to clipboard.');
          setShowRSVPLinkModal(false);
        }}
        content={
          <div className='text-align-left'>
            <p className='margin-bottom-16'>
              Share this info with people you want in the building.
            </p>
            <p className='bold'>
              <ChainLink fill={'#FEC100'} className='margin-right-8' />
              {`${window.location.origin.toString()}/rsvp/${gathering.uuid}`}
            </p>
          </div>
        }
      />
      <div
        className={
          selected
            ? 'more-actions-btn more-actions-btn-selected'
            : 'more-actions-btn more-actions-btn-unselected'
        }
        onClick={() => setSelected(!selected)}
      >
        MORE ACTIONS
      </div>
      {selected && (
        <div className='more-actions-dropdown' style={{ right: '120px' }}>
          <div
            className='more-actions-option pointer'
            onClick={() =>
              history.push(
                `/building/${gathering.buildingId}/gathering/${gathering.uuid}/edit?recurring=${gathering.isRecurring}&eventDate=${gathering.scheduledStartTime}`
              )
            }
          >
            Edit
          </div>
          <div
            className='more-actions-option pointer'
            onClick={() => {
              setSelected(false);
              setShowDeleteModal(true);
            }}
          >
            Delete
          </div>
          <div
            className='more-actions-option pointer'
            onClick={() => {
              setSelected(false);
              setShowRSVPLinkModal(true);
            }}
          >
            Get RSVP link
          </div>
          {gathering.recurringTypeName != 'One Time' && (
            <div
              className='more-actions-option pointer'
              onClick={cancelGathering}
            >
              Cancel Future Gatherings
            </div>
          )}
        </div>
      )}
    </div>
  );
};
function mapDispatchToProps(): DispatchProps {
  return {
    deleteGatheringSuccess
  };
}
export default connect(
  null,
  mapDispatchToProps()
)(withFullScreenLoader(MoreActionsGatherings));
