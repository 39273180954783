import React from 'react';
import moment from 'moment';
import { Group } from '~/reducers/groupAdmin';

type ReviewRejectedGroupProps = {
  rejectedGroup: Group;
};

const ReviewRejectedGroup = (props: ReviewRejectedGroupProps) => {
  if (!props.rejectedGroup) return null;

  const {
    rejectedGroup: {
      createdAt,
      title,
      building,
      description,
      rejectionReason,
      suggestingUser: { firstName, lastName, email }
    }
  } = props;

  return (
    <div className='group-modal-content'>
      <div>
        <div>
          <div className='detail-content-container'>
            <div className='gridContainer'>
              <div className='item'>
                <p className='detail-label'>Group Title</p>
              </div>
              <div className='item'>
                <p>{title}</p>
              </div>
              <div className='item'>
                <p className='detail-label'>Rejection Date</p>
              </div>
              <div className='item'>
                <p>{moment(createdAt).format('MMM D, YYYY')}</p>
              </div>
              <div className='item'>
                <p className='detail-label'>User</p>
              </div>
              <div>
                <p>{`${firstName} ${lastName}`}</p>
              </div>
              <div className='item'>
                <p className='detail-label'>Email</p>
              </div>
              <div className='item'>
                <p>{email}</p>
              </div>
              <div className='item'>
                <p className='detail-label'>Building</p>
              </div>
              <div className='item'>
                <p>{building.name}</p>
              </div>
              <div className='item'>
                <p className='detail-label'>Description</p>
              </div>
              <div className='item'>
                <p>{description}</p>
              </div>
              <div className='item'>
                <p className='detail-label'>Reason</p>
              </div>
              <div className='item'>
                <p>
                  {rejectionReason ? (
                    rejectionReason
                  ) : (
                    <span>No Reason Provided</span>
                  )}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReviewRejectedGroup;
