import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { getBuildingName } from '~/api/buildingEntrataIntegration';
import { postReferralEntrataIntegration } from '~/api/referral';
import { postYardiCreds, verifyYardiCredentials } from '~/api/yardiIntegration';
// import { checkYardiRentRollAccess } from '~/api/yardiIntegration';
import { IYardiCredentialPMS } from '~/containers/Referral/interfaces';
import _ from 'lodash';

export default (buildingUuid: string) => {
  const [loader, setLoader] = useState(false);
  const [enableSumit, setEnableSubmit] = useState(false);
  const [buildingName, setBuildingName] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    getIntegrationBuildingName();
  }, []);

  const getIntegrationBuildingName = async () => {
    try {
      const buildingResponse = await getBuildingName(buildingUuid);
      setBuildingName(buildingResponse.data.name);
    } catch (e) {
      setError(e.message);
      setEnableSubmit(false);
      setLoader(false);
    }
  };

  const testYardiLeaseApi = async (yardiCreds: IYardiCredentialPMS) => {
    try {
      setLoader(true);
      setError('');

      const updatedYardiConfig = {
        ...yardiCreds,
        ...(yardiCreds.webServiceUrl
          ? {
              webServiceUrl: yardiCreds.webServiceUrl.replace(
                'http://',
                'https://'
              )
            }
          : {})
      };

      const res = await verifyYardiCredentials(
        _.omit(updatedYardiConfig, ['buildingUuid'])
      );

      setLoader(false);
      setEnableSubmit(true);

      toast(res.data.message || 'Credentials verified successfully!', {
        autoClose: 7000
      });
    } catch (e) {
      setLoader(false);

      toast(
        'Something went wrong. Please verify your credentials and try again.',
        {
          autoClose: 7000
        }
      );
    }
  };

  const postYardiPMSSetting = async (
    buldingId: string,
    yardiIntegration: IYardiCredentialPMS,
    callback?: () => void
  ) => {
    try {
      setLoader(true);

      const updatedYardiConfig = {
        ...yardiIntegration,
        ...(yardiIntegration.webServiceUrl
          ? {
              webServiceUrl: yardiIntegration.webServiceUrl.replace(
                'http://',
                'https://'
              )
            }
          : {})
      };

      const res = await postYardiCreds(buldingId, updatedYardiConfig);

      setLoader(false);
      setEnableSubmit(false);
      setSuccess(true);

      if (callback) {
        callback();
      }

      if (res?.data?.message) {
        toast(res.data.message, {
          autoClose: 7000
        });
      }
    } catch (e) {
      setLoader(false);
    }
  };

  const onFormUpdated = () => {
    setEnableSubmit(false);
  };

  const goBack = () => {
    setSuccess(false);
  };

  return {
    loader,
    enableSumit,
    error,
    buildingName,
    success,
    goBack,
    postYardiPMSSetting,
    testYardiLeaseApi,
    onFormUpdated
  };
};
