import React from 'react';
import { CommentFilterType } from '~/containers/Comments/Comments';
import { Roles } from '~/enums/Roles';
import { Building } from '~/types/building';
import { GatheringType } from '~/types/gathering';
import CommentSectionFilter from '../CommentSectionFilter/CommentSectionFilter';
import { GroupType } from '~/types/group';
import moment from 'moment';

interface IProps {
  selectedBuilding: Building;
  upcomingGatherings: GatheringType[];
  pastGatherings: GatheringType[];
  selectedGatheringId: string;
  gatheringDate?: string;
  selectedGroupId: string;
  userRole: string;
  selectFilter: (
    type: CommentFilterType,
    gatheringId: string,
    interestedDate?: string
  ) => void;
  showGatherings: boolean;
  handleGatheringFilterClick: () => void;
  showGroups: boolean;
  handleGroupFilterClick: () => void;
  groups: GroupType[];
}
const CommentSectionFilters = (props: IProps) => {
  const {
    selectedBuilding,
    upcomingGatherings,
    pastGatherings,
    selectedGatheringId,
    gatheringDate,
    selectedGroupId,
    selectFilter,
    showGatherings,
    handleGatheringFilterClick,
    showGroups,
    handleGroupFilterClick,
    groups
  } = props;

  return (
    <div>
      <CommentSectionFilter
        selectedFilter={selectedGatheringId}
        onClickAction={() => {
          selectFilter(CommentFilterType.BUILDING, '');
        }}
        filters={[
          {
            uuid: selectedBuilding.uuid,
            image: selectedBuilding.image,
            title: `${selectedBuilding.name}`
          }
        ]}
        sectionTitle='BUILDING CHAT'
        isBuildingChat
        isBuildingChatSelected={!selectedGatheringId && !selectedGroupId}
      />
      {upcomingGatherings.length > 0 && (
        <CommentSectionFilter
          selectedFilter={`${selectedGatheringId}${
            gatheringDate ? `?${gatheringDate}` : ''
          }`}
          onClickAction={(id: string, gathering: GatheringType) =>
            selectFilter(
              CommentFilterType.GATHERING,
              id,
              gathering?.isRecurring
                ? moment(gathering.scheduledStartTime)
                    .tz(gathering.buildingTimezone)
                    .format('YYYY-MM-DD')
                : ''
            )
          }
          filters={upcomingGatherings}
          showFilters={showGatherings}
          openFilterOptions={handleGatheringFilterClick}
          sectionTitle='UPCOMING GATHERINGS (MAX 20)'
          isBuildingChatSelected={false}
        />
      )}
      {pastGatherings.length > 0 && (
        <CommentSectionFilter
          selectedFilter={`${selectedGatheringId}${
            gatheringDate ? `?${gatheringDate}` : ''
          }`}
          onClickAction={(id: string, gathering: GatheringType) =>
            selectFilter(
              CommentFilterType.GATHERING,
              id,
              gathering?.isRecurring
                ? moment(gathering.scheduledStartTime)
                    .tz(gathering.buildingTimezone)
                    .format('YYYY-MM-DD')
                : ''
            )
          }
          filters={pastGatherings}
          showFilters={showGatherings}
          openFilterOptions={handleGatheringFilterClick}
          sectionTitle='PAST GATHERINGS (LAST 1 MONTH)'
          isBuildingChatSelected={false}
        />
      )}
      {groups.length > 0 && (
        <CommentSectionFilter
          selectedFilter={selectedGroupId}
          onClickAction={(id: string) =>
            selectFilter(CommentFilterType.GROUP, id)
          }
          filters={groups}
          showFilters={showGroups}
          openFilterOptions={handleGroupFilterClick}
          sectionTitle='GROUPS'
          isBuildingChatSelected={false}
        />
      )}
    </div>
  );
};

export default CommentSectionFilters;
