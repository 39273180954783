import React, { useState } from 'react';

import classes from './RadioButtons.module.css';
type Props = {
  value: boolean;
  onChange: (value: boolean) => void;
  isDisabled?: boolean;
};
const RadioButtons = (props: Props) => {
  const { value, onChange, isDisabled } = props;
  const [radioValue, setRadioValue] = useState(value);

  return (
    <div style={{ display: 'flex' }}>
      {[
        { name: 'Yes', value: true },
        { name: 'No', value: false }
      ].map((option) => (
        <div className={classes.radioButtonContainer} key={option.name}>
          <input
            name='isRSVP'
            type='radio'
            value={option.name}
            onChange={(e) => {
              setRadioValue(e.target.value === 'Yes');
              onChange(e.target.value === 'Yes');
            }}
            disabled={isDisabled}
            className='height-20'
            checked={option.value === radioValue}
          />
          <div className={classes.radioButtonLabel}>{option.name}</div>
        </div>
      ))}
    </div>
  );
};

export default RadioButtons;
