import { emperor } from '~/services';
import axios from 'axios';
import { MerchantPatch } from '~/types/merchant';
import { PerkPatch, PerkMerchantForm } from '~/types/perk';
import { imgixURL } from '~/helpers/constants';
import { convertKeyValueToFormData } from '~/helpers/helpers';
import { TimeStamp } from '~/types/common';

// perks

export const fetchPerksForBuilding = async (buildingId: string) => {
  return await emperor.get('/perk', {
    params: { building: buildingId, returnData: 'min' }
  });
};

export const fetchPerk = async (perkId: string) => {
  return await emperor.get(`perk/${perkId}`);
};

export const createPerk = async (data: PerkMerchantForm) => {
  return await emperor.post('/perk', data);
};

export const updatePerk = async (
  perkId: string,
  perk: PerkPatch & TimeStamp
) => {

  let body = {
    ...perk,
    status: perk.status,
    uuid: perk.uuid,
    merchant: perk?.merchant?.uuid,
    customButtonLabel:  perk?.customButtonLabel ?? ''
  }

  let perkObj = Object.keys(body)
  .filter((k) => body[k as keyof typeof body] != null)
  .reduce((a, k) => ({ ...a, [k]: body[k as keyof typeof body] }), {} as {[key: string]: any});

  return await emperor.patch(`perk/${perkId}`, perkObj);
};

export const createBuildingPerk = async (buildingId: string, perk: string) => {
  return await emperor.post(`/building/${buildingId}/buildingperk/create`, {
    perk,
    valid: true
  });
};

export const updateBuildingPerkValid = async (
  buildingPerkid: string,
  valid: boolean
) => {
  return await emperor.patch(`buildingPerk/${buildingPerkid}`, {
    valid
  });
};

// perk proposals

export const fetchPerksProposalsForBuilding = async (buildingId: string) => {
  return await emperor.get('/perkproposal', {
    params: { building: buildingId }
  });
};

export const fetchOnePerkProposal = async (perkProposalId: string) => {
  return emperor.get(`/perkproposal/${perkProposalId}`);
};

export const createPerkProposal = async (
  merchant: string,
  building: string
) => {
  return await emperor.post('/perkproposal', {
    merchant,
    building
  });
};

// merchants

export const fetchMerchant = async (merchantId: string) => {
  return await emperor.get(`merchant/${merchantId}`);
};

export const createMerchant = async (
  name: string,
  category: string,
  building: string
) => {
  const data = new FormData();
  const res = await axios({
    method: 'get',
    responseType: 'arraybuffer',
    url: imgixURL(name)
  });

  const image = new Blob([res.data], {
    type: 'image/png'
  });

  data.append('name', name.trim());
  data.append('category', category);
  data.append('building', building);
  data.append('image', image);

  return await emperor.post('/merchant', data);
};

export const updateMerchant = async (merchant: MerchantPatch) => {
  const merchantUpdate = convertKeyValueToFormData(merchant);

  if (merchant.name) {
    const imageRes = await axios({
      method: 'get',
      responseType: 'arraybuffer',
      url: imgixURL(merchant?.name!)
    });

    const image = new Blob([imageRes.data], {
      type: 'image/png'
    });

    merchantUpdate.append('image', image);
  }

  return await emperor.patch(`merchant/${merchant.uuid}`, merchantUpdate);
};
