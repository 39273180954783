import React, { useState } from 'react';
import { useMutation, useQuery } from 'react-query';

import { connect, ResolveThunks } from 'react-redux';
import { RootState } from '~/redux/store';

import Breadcrumbs from '~/components/Breadcrumbs/Breadcrumbs';
import MultiSelect from '~/components/MultiSelect/MultiSelect';
import ImageDropzone from '~/components/ImageDropzone/ImageDropzone';
import GenericModal from '~/components/GenericModal/GenericModal';
import Spinner from '~/components/Spinner/Spinner';
import Switch from 'react-ios-switch';

import { toast } from 'react-toastify';

import { SelectedOptionType } from '~/types/form';
import { SelectType } from '~/enums/SelectType';

import classes from './PostManagement.module.css';
import {
  createPostGroupComment,
  fetchCobuPlusBuildings
} from '~/api/postManagement';

interface DispatchProps {}

type Props = ResolveThunks<DispatchProps> & ReturnType<typeof mapStateToProps>;

const PostManagement = (props: Props) => {
  const { buildings } = props;

  const [message, setMessage] = useState('');
  const [image, setImage] = useState<File | string>('');
  const [multiValue, setMultiValue] = useState<SelectedOptionType[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [buildingsWithOutVCM, setBuildingsWithOutVCM] = useState<string[]>([]);
  const [isPinnedMessage, setIsPinnedMessage] = useState(false);
  const [isDisabledReplies, setIsDisabledReplies] = useState(false);

  const [showFormLoader, setShowFormLoader] = useState(false);

  const { data: cobuPlusBuildings, isLoading } = useQuery(
    ['cobu-plus-buildings'],
    () => fetchCobuPlusBuildings()
  );

  const getBuildingsForDropdown = () => {
    const buildingsForDropdown: SelectedOptionType[] = buildings.map(
      (building: any) => {
        return {
          value: building.uuid,
          label: building.name
        };
      }
    );
    return buildingsForDropdown;
  };

  const handleDrop = (acceptedFiles: File[]) => {
    setImage(acceptedFiles[0]);
  };

  const onCancel = () => {
    setMessage('');
    setImage('');
    setIsDisabledReplies(false);
    setIsPinnedMessage(false);
  };

  const createTemplateMutation = useMutation(createPostGroupComment, {
    onSuccess: (response) => {
      const countPostBuilding = response.data?.countBuildingsPostMessage;
      const listOfBuildingsWithoutVCM =
        response.data?.buildingWithOutVCMCommenter || null;

      if (countPostBuilding > 0) {
        const extraMessage =
          listOfBuildingsWithoutVCM && listOfBuildingsWithoutVCM.length > 0
            ? ' Check the list of buildings without VCM Commenter.'
            : '';
        toast(
          `Post for ${countPostBuilding} buildings were created successfully.${extraMessage}`
        );
      } else
        toast(
          `This message was not send to any building! Check the list of buildings without VCM Commenter`
        );

      setMessage('');
      setMultiValue([]);
      setImage('');
      setShowFormLoader(false);
      setIsPinnedMessage(false);
      setIsDisabledReplies(false);

      if (listOfBuildingsWithoutVCM && listOfBuildingsWithoutVCM.length > 0)
        setBuildingsWithOutVCM(listOfBuildingsWithoutVCM);
    },
    onError: (e: any) => {
      setShowFormLoader(false);
      toast(`There was an error creating the post: ${e.message || ''}`);
    }
  });

  const onSubmit = () => {
    setBuildingsWithOutVCM([]);
    const formData = new FormData();
    const buildingIds = multiValue.map((building) => building.value);

    formData.append(
      'postData',
      JSON.stringify({
        comment: message,
        buildingIds,
        isPinnedMessage,
        isDisabledReplies
      })
    );

    if (image) {
      formData.append('image', image);
    }

    setShowFormLoader(true);
    createTemplateMutation.mutate(formData);
  };

  const preSubmitButton = () => {
    if (!multiValue || multiValue.length <= 0) {
      toast('Buildings must be selected for posting');
      return;
    } else if (!message) {
      toast('You must specify a text for the post!');
      return;
    }
    setShowModal(true);
  };

  if (isLoading) {
    return <Spinner />;
  }
  return (
    <div className='content-container'>
      <div className='float-spinner-parent'>
        <form>
          {showFormLoader && <Spinner float />}
          <div>
            <Breadcrumbs />
            <div className={classes.container}>
              <h1 className='h1-title'>Post Management</h1>
              <MultiSelect
                options={getBuildingsForDropdown()}
                selectType={SelectType.AutoAdjutForm}
                multiValue={multiValue}
                setMultiValue={setMultiValue}
                placeholder='Select buildings...'
                cobuPlusBuildings={cobuPlusBuildings?.data || []}
              />
              <div style={{ marginTop: '20px', marginBottom: '20px' }}>
                <ImageDropzone image={image} handleDrop={handleDrop} />
              </div>
              <textarea
                id='message'
                name='message'
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                placeholder={'Message...'}
                maxLength={500}
              />
            </div>
            <div className={classes.bottomContainer}>
              <div className={classes.togglesContainer}>
                <div className={`display-flex`}>
                  <div className={classes.toggleContainer}>
                    <Switch
                      checked={isPinnedMessage}
                      onChange={(newSwitchState: boolean) => {
                        setIsPinnedMessage(newSwitchState);
                        if (!newSwitchState) {
                          setIsDisabledReplies(false);
                        }
                      }}
                      disabled={isLoading}
                    />
                    <div className='margin-left-16'>
                      <p>Pin post</p>
                    </div>
                  </div>
                </div>
                <div className={`display-flex`}>
                  <div className={classes.toggleContainer}>
                    <Switch
                      checked={isDisabledReplies}
                      onChange={(newSwitchState: boolean) => {
                        setIsDisabledReplies(newSwitchState);
                      }}
                      disabled={isLoading || !isPinnedMessage}
                    />
                    <div className='margin-left-16'>
                      <p>Disable replies</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className={classes.buttonsContainer}>
                <button
                  type='button'
                  className='btn-standard btn-primary margin-right-16'
                  onClick={preSubmitButton}
                >
                  Submit
                </button>
                <button
                  className='btn-standard btn-seconday weight-500'
                  type='button'
                  onClick={onCancel}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
      <GenericModal
        showModal={showModal}
        title=''
        applyModalStretch={true}
        content={
          <div style={{ margin: '0 10px', paddingTop: '40px' }}>
            <h3 className={classes.contentBodyh3}>
              Really want to post to all selected buildings? This action cannot
              be undone
            </h3>
          </div>
        }
        actionText={'Submit'}
        actionFunction={() => {
          onSubmit();
          setShowModal(false);
        }}
        onChange={() => {
          setMessage('');
          setShowModal(false);
        }}
      />
      {buildingsWithOutVCM && buildingsWithOutVCM.length > 0 && (
        <div className={classes.container}>
          <h3 className={classes.contentBodyh3}>
            Message cannot be sent to the following buildings because the VCM
            Commenter is not set:
          </h3>
          <ul>
            {buildingsWithOutVCM.map((building) => (
              <li key={building}>{building}</li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

function mapStateToProps(state: RootState) {
  return {
    buildings: state.building.buildings
  };
}

export default connect(mapStateToProps)(PostManagement);
