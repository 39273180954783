import {
  SortableContext,
  verticalListSortingStrategy
} from '@dnd-kit/sortable';
import React from 'react';

interface IProps {
  isDraggable: boolean;
  items: string[];
  children: React.ReactElement;
}

export const DragSortableContext = ({
  isDraggable,
  items,
  children
}: IProps) => {
  if (isDraggable) {
    return (
      <SortableContext items={items} strategy={verticalListSortingStrategy}>
        {children}
      </SortableContext>
    );
  }

  return children;
};
