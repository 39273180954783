import React, { FC, useEffect, useMemo, useState } from 'react';
import FlyerTable from './FlyerTable/FlyerTable';
import './FlyerManagement.css';
import Switch from 'react-ios-switch';
import Breadcrumbs from '~/components/Breadcrumbs/Breadcrumbs';
import Spacer from '~/components/Spacer/Spacer';

export const FlyerManagement: FC<any> = () => {
  const [showCompletedReports, setShowCompletedReports] = useState(false);

  return (
    <div className='container'>
      <Breadcrumbs />
      <Spacer height={20} />
      <div className='completed-flyer-switch'>
        <Switch
          checked={showCompletedReports}
          onChange={(currentSwitchState: boolean) => {
            setShowCompletedReports(currentSwitchState);
          }}
        />
        <div>Show Completed Flyers</div>
      </div>
      <FlyerTable showCompletedReports={showCompletedReports} />
    </div>
  );
};
