import { IMultiSelectOptionList } from '~/helpers/interfaces';

export const flyerTemplateOptions: IMultiSelectOptionList[] = [
  {
    value: 'includeInstagram',
    text: 'send instagram image?',
    isSelected: false
  }
];

export const pdfEmailContent = `
Hi team,
Please see attached for your custom event flyer as a result of your selection in the Gathering creation form.

Please use this flyer with the QR code & call to action to "RSVP on Cobu" so we can help you get the most accurate RSVP numbers.

Let us know if you have any questions!

- The Cobu Team`;

export const pdfWithInstaEmailContent = `Hi team,
Please see attached for your custom event flyer as a result of your selection in the Gathering creation form.

Please use this flyer with the QR code & call to action to "RSVP on Cobu" so we can help you get the most accurate RSVP numbers.

We have also added in an image that you can use on instagram if you are interested.

Let us know if you have any questions!

- The Cobu Team`;
