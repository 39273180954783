import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import {
  checkEntrataRentRollAccess,
  getBuildingName
} from '~/api/buildingEntrataIntegration';
import { postReferralEntrataIntegration } from '~/api/referral';
import { IReferralSettingEntrataPMS } from '~/containers/Referral/interfaces';

export default (id: string) => {
  const [loader, setLoader] = useState(false);
  const [enableSumit, setEnableSubmit] = useState(false);
  const [buildingName, setBuildingName] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    getIntegrationBuildingName();
  }, []);

  const getIntegrationBuildingName = async () => {
    try {
      const buildingResponse = await getBuildingName(id);
      setBuildingName(buildingResponse.data.name);
    } catch (e) {
      setError(e.message);
      setEnableSubmit(false);
      setLoader(false);
    }
  };

  const testEntrataLeaseApi = async (
    entrataConfig: IReferralSettingEntrataPMS
  ) => {
    try {
      setLoader(true);
      setError('');
      const leases = await checkEntrataRentRollAccess(entrataConfig);

      const enableSubmit = leases.data && leases.data.length >= 0;

      setLoader(false);
      setEnableSubmit(enableSubmit);
      toast('Credentials verified successfully!', { autoClose: 7000 });
    } catch (e) {
      setLoader(false);
    }
  };

  const postEntrataPMSSetting = async (
    buldingId: string,
    entrataIntegration: IReferralSettingEntrataPMS,
    callback?: () => void
  ) => {
    try {
      setLoader(true);
      await postReferralEntrataIntegration(buldingId, entrataIntegration, true);

      setLoader(false);
      setEnableSubmit(false);
      setSuccess(true);
      if (callback) {
        callback();
      }
    } catch (e) {
      setLoader(false);
    }
  };

  const onFormUpdated = () => {
    setEnableSubmit(false);
  };

  const goBack = () => {
    setSuccess(false);
  };

  return {
    loader,
    enableSumit,
    error,
    buildingName,
    success,
    goBack,
    postEntrataPMSSetting,
    testEntrataLeaseApi,
    onFormUpdated
  };
};
