import React, { useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Logo from '~/utils/images/logo.svg';
import Spinner from '~/components/Spinner/Spinner';
import { FormField } from '~/components/FormField';
import useAddEntrataConfig from '~/hooks/useAddEntrataConfig';
import { getObjectDiff } from '~/helpers/helpers';
import { Colors } from '~/enums/Colors';
import { IReferralSettingEntrataPMS } from '../Referral/interfaces';
import { entrataPMSFormConfig } from './constants';
import classes from './AddEntrataConfig.module.css';
import { FormInputConfig } from '~/types/residentManagement';

const AddEntrataConfig: React.FC = () => {
  const { buildingId } = useParams<{ buildingId: string }>();
  const previousFormData = useRef<IReferralSettingEntrataPMS | null>(null);

  const {
    loader,
    error,
    buildingName,
    success,
    postEntrataPMSSetting,
    enableSumit,
    testEntrataLeaseApi,
    onFormUpdated,
    goBack
  } = useAddEntrataConfig(buildingId);

  const {
    register,
    handleSubmit,
    errors,
    control,
    watch,
    reset,
    formState: { isDirty }
  } = useForm<IReferralSettingEntrataPMS>();

  const allFields = watch();

  useEffect(() => {
    if (previousFormData.current && allFields) {
      const isDirtyAfterFormSubmit = getObjectDiff(
        previousFormData.current,
        allFields
      );
      if (Object.keys(isDirtyAfterFormSubmit).length) {
        onFormUpdated();
      }
    }
  }, [allFields, onFormUpdated]);

  const generateField = (fieldConfig: FormInputConfig) => (
    <div key={fieldConfig.id} className={classes.item}>
      {fieldConfig.label && (
        <label
          style={fieldConfig.styles?.lableStyles}
          className={fieldConfig.styles?.lableClassName}
          htmlFor={fieldConfig.id}
        >
          {fieldConfig.label}
        </label>
      )}
      <FormField
        fieldConfig={fieldConfig}
        errors={errors}
        register={register}
        control={control}
      />
    </div>
  );

  const onSubmit = (formData: IReferralSettingEntrataPMS) => {
    if (!isDirty) {
      toast('Please edit the form before submitting.');
      return;
    }

    previousFormData.current = formData;
    testEntrataLeaseApi(formData);
  };

  const submitUserApi = () => {
    postEntrataPMSSetting(buildingId, allFields, () => reset());
  };

  const handleClose = () => {
    goBack();
  };

  return (
    <div className={`${classes.container} float-spinner-parent`}>
      {loader && <Spinner float />}
      <div className={classes.header}>
        <img className={classes.logo} src={Logo} alt='Cobu' />
      </div>
      <div className={classes.content}>
        {success ? (
          <div className={classes.containersuccess}>
            <h1 className={classes.heading}>
              Entrata API Integration Configured
            </h1>
            <p className={classes.paragraph}>
              Thank you for configuring your Entrata information with us.
            </p>
            <button className={classes.button} onClick={handleClose}>
              Go Back
            </button>
          </div>
        ) : (
          <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
            <h1>Please add your Entrata API User information</h1>
            <h3>{buildingName}</h3>
            <div className={classes.linkContainer}>
              <a
                target='_blank'
                rel='noopener noreferrer'
                href='https://docs.google.com/document/d/1Xhq-8HxniqjYSkMZhEnvmi4DhdYI62q1Ow2Y-h75ZYI/edit'
              >
                Integration Set Up Instructions
              </a>
            </div>
            <div>{entrataPMSFormConfig.map(generateField)}</div>
            <div>
              <a
                target='_blank'
                rel='noopener noreferrer'
                href='mailto:customersuccess@livecobu.com?subject=Entrata%20Integration%20Help%20Needed'
              >
                Need help?
              </a>
            </div>
            {error && (
              <h3 style={{ color: Colors.cobuRed }}>{`Error: ${error}`}</h3>
            )}
            <button
              className='btn-standard btn-primary'
              type='submit'
              disabled={!isDirty}
            >
              Test Settings
            </button>
            <button
              className='btn-standard btn-secondary'
              type='button'
              onClick={submitUserApi}
              disabled={!enableSumit}
            >
              Submit
            </button>
          </form>
        )}
      </div>
    </div>
  );
};

export default AddEntrataConfig;
