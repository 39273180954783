import { IBuildingAddress } from '~/types/building';
import { FormInputConfig } from '~/types/residentManagement';

export const buildingAddressFormConfig: FormInputConfig[] = [
  {
    id: 'city' as keyof IBuildingAddress,
    isRequired: true,
    placeholder: 'eg: Chicago',
    label: 'Enter city:'
  },
  {
    id: 'state' as keyof IBuildingAddress,
    isRequired: true,
    placeholder: 'eg: Illinois',
    label: 'Enter state:'
  },
  {
    id: 'postalcode' as keyof IBuildingAddress,
    isRequired: true,
    placeholder: 'eg: 60614',
    label: 'Enter postal code:'
  }
];
