import axios from 'axios';
import bugsnag from 'bugsnag-js';
import _ from 'lodash';
import Cookies from 'universal-cookie';

import { StoreDispatch } from '~/redux/store';

const bugsnagClient = bugsnag('8822370330acd587983bc49c6f829cd4');

const url = process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_BACKEND_ENDPOINT!
    : process.env.REACT_APP_LOCAL_ENDPOINT!;

export const SET_GROUP_EVENT_LOADING = 'SET_GROUP_EVENT_LOADING';
export const OPEN_GROUP_EVENT_SNACKBAR = 'OPEN_GROUP_EVENT_SNACKBAR';
export const GET_GROUP_EVENTS = 'GET_GROUP_EVENTS';
export const CREATE_GROUP_EVENT = 'CREATE_GROUP_EVENT';
export const UPDATE_GROUP_EVENT = 'UPDATE_GROUP_EVENT';
export const DELETE_GROUP_EVENT = 'DELETE_GROUP_EVENT';

export const getGroupEvents = (buildingId: string) => (
  dispatch: StoreDispatch
) => {
  const cookies = new Cookies();
  const token = cookies.get('token');

  dispatch({
    type: SET_GROUP_EVENT_LOADING,
    payload: true
  });

  return axios({
    method: 'GET',
    url: `${url}/groupevent`,
    headers: { token },
    params: {
      building: [buildingId],
      sort: 'startTime DESC',
      cancelled: false
    }
  })
    .then((groupEventResponse) => {
      dispatch({
        type: GET_GROUP_EVENTS,
        payload: {
          groupEvents: groupEventResponse.data
        }
      });
      dispatch({
        type: SET_GROUP_EVENT_LOADING,
        payload: false
      });
    })
    .catch((error) => {
      bugsnagClient.notify(error);
    });
};

export const updateGroupEvent = (groupEventId: string, groupEvent: any) => (
  dispatch: StoreDispatch
) => {
  const cookies = new Cookies();
  const token = cookies.get('token');

  return axios({
    method: 'PATCH',
    url: `${url}/groupevent/${groupEventId}`,
    headers: { token },
    data: groupEvent
  })
    .then((response) => {
      dispatch({
        type: UPDATE_GROUP_EVENT,
        payload: response.data
      });
    })
    .catch((error) => {
      bugsnagClient.notify(error);
      dispatch({
        type: OPEN_GROUP_EVENT_SNACKBAR,
        payload: {
          snackbarOpen: true,
          errorMessage: error.response.data.message
        }
      });
    });
};
