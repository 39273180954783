import _ from 'lodash';
import {
  GET_GROUP_EVENTS,
  UPDATE_GROUP_EVENT,
} from '../actions/GroupEventActions';

const initialState = {
  groupEvents: {},
  loading: false,
  snackbarOpen: false,
  errorMessage: '',
};

export default (state = initialState, action: any) => {
  const { payload } = action;

  switch (action.type) {
    case GET_GROUP_EVENTS: {
      const groupEvents = _.mapKeys(payload.groupEvents, 'uuid');
      return { ...state, groupEvents, loading: false };
    }
    case UPDATE_GROUP_EVENT:
      return {
        ...state,
        groupEvents: { ...state.groupEvents, [payload.uuid]: payload },
      };
    default:
      return state;
  }
};
