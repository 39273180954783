import React from 'react';
import { Column } from 'react-table';
import moment from 'moment';
import { useHistory, useParams } from 'react-router-dom';

import CobuTable from '../CobuTable/CobuTable';
import { ReactComponent as Community } from '~/utils/images/community.svg';
import { ResidentScoreType } from '~/types/resident';
import { Mixpanel } from '~/services/MixpanelService';
import { useSelector } from 'react-redux';
import { RootState } from '~/redux/store';
import { buildingSelector } from '~/redux/selectors';
import UsernameDisplay from '../UsernameDisplay/UsernameDisplay';

interface IProps {
  data: ResidentScoreType[];
  openResidentRemoveModal: (value: string) => void;
  sendInvite: (user: string) => void;
}

const ResidentsTable = (props: IProps) => {
  const { buildingId } = useParams<{ buildingId: string }>();
  const building = useSelector((state: RootState) =>
    buildingSelector(state, buildingId)
  );
  const history = useHistory();
  const { data, openResidentRemoveModal, sendInvite } = props;
  const tableData = React.useMemo(() => data, [data]);
  const columns = React.useMemo(
    () =>
      [
        {
          Header: 'USER NAME',
          accessor: (row: ResidentScoreType) => {
            return (
              <UsernameDisplay
                firstName={row.user.firstName}
                lastName={row.user.lastName}
                email={row.user.email}
                profileImage={row.user.profileImage}
              />
            );
          }
        },
        {
          Header: 'PHONE',
          accessor: (row: ResidentScoreType) => {
            return (
              <div
                style={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap'
                }}
              >
                {row.user.phone}
              </div>
            );
          }
        },
        {
          Header: 'SCORE',
          accessor: (row: ResidentScoreType) => {
            return (
              <div
                style={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap'
                }}
              >
                {row.score}
              </div>
            );
          }
        },
        {
          Header: '# OF POSTS',
          accessor: (row: ResidentScoreType) => {
            return (
              <div
                style={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap'
                }}
              >
                {row.numberOfComments}
              </div>
            );
          }
        },
        {
          Header: '# OF RSVP',
          accessor: (row: ResidentScoreType) => {
            return (
              <div
                style={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap'
                }}
              >
                {row.numberOfRSVPs}
              </div>
            );
          }
        },
        {
          Header: 'LAST ACTIVITY',
          accessor: (row: ResidentScoreType) => {
            return (
              <div
                style={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap'
                }}
              >
                {moment
                  .parseZone(row.user.lastActivity)
                  .format('MM/DD/YYYY - hh:mm A')}
              </div>
            );
          }
        },
        {
          Header: 'SMS Opt-in',
          accessor: (row: ResidentScoreType) => {
            return (
              <div
                style={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap'
                }}
              >
                {row.user.optedInSMSMessages ? 'Yes' : 'No'}
              </div>
            );
          }
        },
        {
          id: 'send-invite',
          accessor: (row: ResidentScoreType) => {
            return (
              <div
                className={`table-button pointer`}
                onClick={() => {
                  Mixpanel.track('send_review_invite', {
                    email: row.user.email,
                    firstName: row.user.firstName,
                    lastName: row.user.lastName,
                    ...(building && { buildingName: building.name })
                  });
                  sendInvite(row.user.uuid);
                }}
              >
                SEND INVITE
              </div>
            );
          }
        },
        {
          id: 'remove',
          accessor: (row: ResidentScoreType) => {
            return (
              <div
                className={`table-button pointer`}
                onClick={() => {
                  Mixpanel.track('review_invitee_removed', {
                    email: row.user.email,
                    firstName: row.user.firstName,
                    lastName: row.user.lastName,
                    ...(building && { buildingName: building.name })
                  });
                  openResidentRemoveModal(row.user.uuid);
                }}
              >
                REMOVE
              </div>
            );
          }
        }
      ] as Column<ResidentScoreType>[],
    [history, openResidentRemoveModal, data]
  );

  return (
    <div>
      {tableData.length > 0 && (
        <div style={{ display: 'flex' }}>
          <CobuTable
            columns={[...columns]}
            data={tableData}
            sort={{ id: 'SCORE', descending: false }}
          />
        </div>
      )}
      {tableData.length <= 0 && (
        <div className='noData'>
          <Community />
          <h2>No residents!</h2>
        </div>
      )}
    </div>
  );
};

export default ResidentsTable;
