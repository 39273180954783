import {
  applyMiddleware,
  configureStore,
  getDefaultMiddleware
} from '@reduxjs/toolkit';
import localforage from 'localforage';
import { persistReducer } from 'redux-persist';
import logger from 'redux-logger';

import reducer from '../reducers';

const persistConfig = {
  key: 'root',
  storage: localforage,
  blacklist: ['perks', 'assetManager']
};

const persistedReducer = persistReducer(persistConfig, reducer);

const devMiddleWare = process.env.NODE_ENV !== 'production' ? [logger] : [];

const store = configureStore({
  reducer: persistedReducer,
  middleware: [
    ...devMiddleWare,
    ...getDefaultMiddleware({ immutableCheck: false, serializableCheck: false })
  ],
  devTools: process.env.NODE_ENV !== 'production'
});

export type RootState = ReturnType<typeof store.getState>;

export type StoreDispatch = typeof store.dispatch;

export default store;
