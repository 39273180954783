import React from "react"

const WrappableText = ({ text }: { text: string }) => {
    return <div
        style={{ whiteSpace: 'normal' }}
        className='default-text'>
        {text}
    </div>
}

export default WrappableText