import React from 'react';

import classes from './CircularProgressBar.module.css';

interface Props {
  value: number;
  color: string;
}
const CircularProgressBar = (props: Props) => {
  const { value, color } = props;
  const sqSize = 50;
  const strokeWidth = 5;
  const radius = (sqSize - strokeWidth) / 2;
  const viewBox = `0 0 ${sqSize} ${sqSize}`;
  const dashArray = radius * Math.PI * 2;
  const dashOffset = dashArray - (dashArray * value) / 100;
  return (
    <svg width={50} height={50} viewBox={viewBox}>
      <circle
        className={classes.circleBackground}
        cx={sqSize / 2}
        cy={sqSize / 2}
        r={radius}
        strokeWidth={`${strokeWidth}px`}
      />
      <circle
        className={classes.circleProgress}
        cx={sqSize / 2}
        cy={sqSize / 2}
        r={radius}
        strokeWidth={`${strokeWidth}px`}
        // Start progress marker at 12 O'Clock
        transform={`rotate(-90 ${sqSize / 2} ${sqSize / 2})`}
        style={{
          strokeDasharray: dashArray,
          strokeDashoffset: dashOffset,
          stroke: color
        }}
      />
      <text
        className={classes.circleText}
        x='50%'
        y='50%'
        dy='.3em'
        textAnchor='middle'
      >
        {`${value}%`}
      </text>
    </svg>
  );
};

export default CircularProgressBar;
