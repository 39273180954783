import axios from 'axios';
import bugsnag from 'bugsnag-js';

import { OPEN_GROUP_EVENT_SNACKBAR } from './GroupActions';
import Cookies from "universal-cookie";
import { StoreDispatch } from '~/redux/store';

const bugsnagClient = bugsnag('8822370330acd587983bc49c6f829cd4');

const url = process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_BACKEND_ENDPOINT!
    : process.env.REACT_APP_LOCAL_ENDPOINT!;

export const GET_GROUP_EVENT_COMMENTS = 'GET_GROUP_EVENT_COMMENTS';
export const SET_GROUP_EVENT_COMMENT_LOADING = 'SET_GROUP_EVENT_COMMENT_LOADING';
export const UPDATE_GROUP_EVENT_COMMENT = 'UPDATE_GROUP_EVENT_COMMENT';

export const createGroupEventComment = (comment: string, scheduled: boolean) => (dispatch: StoreDispatch) => {
  const cookies = new Cookies();
  const token = cookies.get('token');
  
  axios({
    url: scheduled ? `${url}/scheduledgroupeventcomment` : `${url}/groupeventcomment`,
    method: 'POST',
    headers: { token },
    data: comment,
  }).catch((error) => {
    dispatch({
      type: OPEN_GROUP_EVENT_SNACKBAR,
      payload: {
        snackbarOpen: true,
        errorMessage: error.response.status === 404 ? "Creator email or group provided didn't match any data in the building"
          : error.response.data.message,
      },
    });
  });
};
