import axios from 'axios';
import { toast } from 'react-toastify';
import Cookies from 'universal-cookie';

type Configuration = {
  url: string;
  data?: Object;
};

export function createApiService(configuration: Configuration) {
  const cookies = new Cookies();
  const instance = axios.create({
    baseURL: configuration.url,
    data: configuration.data
  });

  instance.interceptors.request.use((req) => {
    const token = cookies.get('token');
    if (token) {
      req.headers = {
        Authorization: `Bearer ${token}`
      };
    }

    return req;
  });

  return {
    get: instance.get,
    post: instance.post,
    patch: instance.patch,
    delete: instance.delete
  };
}

export default function createService(configuration: Configuration) {
  const cookies = new Cookies();
  const instance = axios.create({
    baseURL: configuration.url,
    data: configuration.data
  });

  instance.interceptors.request.use((req) => {
    const token = cookies.get('token');
    if (token) {
      req.headers = { token };
    }

    if (req?.data) {
      delete req.data.createdAt;
      delete req.data.updatedAt;
    }

    req.headers['client'] = 'PM_DASHBOARD';
    return req;
  });

  instance.interceptors.response.use(
    (res) => {
      if (res.data.token) {
        cookies.set('token', res.data.token, { path: '/', httpOnly: false });
      }
      return res;
    },
    function (error) {
      let message = '';
      let status = error?.response?.status;

      if (error?.response?.data?.message) {
        message = error?.response?.data?.message;
      }

      if (status) {
        if (status === 401) {
          cookies.remove('token');
          if (window.location.pathname !== '/login') {
            window.location.href = '/login';
          }
        }

        if (status === 500) {
          toast(message || 'Something went wrong. Please try again.');
        }
      } else {
        status = 999;
      }

      const customError: Object = { status: status, message: message };
      throw customError;
    }
  );
  return {
    get: instance.get,
    post: instance.post,
    patch: instance.patch,
    delete: instance.delete,
    put: instance.put
  };
}
