import React, { CSSProperties } from 'react';

type LabelProps = {
  title: string;
  style?: CSSProperties;
};

const Label = (props: LabelProps) => (
  <p
    style={{
      padding: '4px 8px',
      backgroundColor: 'green',
      fontSize: '12px',
      borderRadius: '3px',
      color: 'white',
      fontWeight: 500,
      letterSpacing: 0.8,
      width: 'fit-content'
    }}
    title='Live'
  >
    {props.title}
  </p>
);

export default Label;
