import { api } from "~/services";

export const getBuildingReports = async (
    building: string,
    date: string
) => {
    return await api.get('report/get-report', {
        params: {
            building,
            date
        }
    });
};
