import React, { useState } from 'react';
import ReactCodeInput from 'react-code-input';

import './RegisterVerificationForm.css';

interface IProps {
  onSubmit: (code: string) => void;
}

export const LoginForm = (props: IProps) => {
  const [code, setCode] = useState('');
  const onChange = (data: string) => {
    if (data) {
      setCode(data);
    }
  };

  const onConfirm = () => {
    props.onSubmit(code);
  };
  return (
    <div className='reg-ver-form'>
      <ReactCodeInput
        name=''
        fields={4}
        inputMode='tel'
        type='number'
        onChange={onChange}
        className='reg-ver-form-input'
      />
      <button
        type='submit'
        className='submit-button reg-ver-form-button'
        onClick={onConfirm}
      >
        Confirm
      </button>
    </div>
  );
};

export default LoginForm;
