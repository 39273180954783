import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { RootState } from '~/redux/store';
import { getBuildingFromStore } from '~/helpers/reduxStoreHelpers';
import Breadcrumbs from '~/components/Breadcrumbs/Breadcrumbs';

import GroupCard from '~/components/GroupCard/GroupCard';

import { toast } from 'react-toastify';
import { fetchGroups } from '~/api/groups';
import classes from './Groups.module.css';
import { GroupType } from '~/types/group';
import { updateGroup } from '~/api/group';
import { useFullScreenLoader } from '~/hooks/useFullScreenLoader';
import { withFullScreenLoader } from '~/hocs/withFullScreenLoader';
import { CreateGroupFromValues } from '~/components/CreateGroupForm/CreateGroupForm';
import { getGroupsFromUserBuildings } from '~/actions/GroupActions';
import EditGroupDetails from '~/components/EditGroupDetails';
import GenericModal from '~/components/GenericModal/GenericModal';
import { useCobuDispatch } from '~/hooks/useCobuDispatch';

type Props = ReturnType<typeof mapStateToProps>;
const Groups = () => {
  const [loading, setLoading] = useState(true);
  const [groups, setGroups] = useState<GroupType[]>([]);
  const [selectedGroup, setSelectedGroup] = useState<GroupType>();
  const { buildingId } = useParams<{ buildingId: string }>();
  const selectedBuilding = getBuildingFromStore(buildingId);
  const { loader } = useFullScreenLoader();

  const closeModal = () => {
    setSelectedGroup(undefined);
  };

  const doFetchGroups = async () => {
    try {
      loader.show();
      const res = await fetchGroups([selectedBuilding.uuid]);
      const sortedData = res.data.sort(
        (a: any, b: any) => b.meta.groupMemberCount - a.meta.groupMemberCount
      );
      setGroups(sortedData);
      setSelectedGroup(undefined);
    } catch (error) {
      console.error(error);
      toast('There was an error fetching groups, please try again');
    }
    loader.hide();
  };

  const onUpdateGroup = (values: CreateGroupFromValues) => {
    if (!selectedGroup) return;

    loader.show();

    const group = selectedGroup;

    const createGroupFormData = new FormData();

    createGroupFormData.append('name', values.title.trim());
    createGroupFormData.append('building', values.building);
    createGroupFormData.append('description', values.description);
    createGroupFormData.append('active', values.active.toString());
    createGroupFormData.append('featured', values.featured.toString());
    createGroupFormData.append('isDefault', values.isDefault.toString());

    if (values.image?.path) {
      createGroupFormData.append('image', values.image);
    } else if (selectedGroup.imageUrl) {
      createGroupFormData.append('imageUrl', selectedGroup.imageUrl);
    }

    updateGroup(group.uuid, createGroupFormData).finally(() => {
      loader.hide();
      setSelectedGroup(undefined);
      doFetchGroups();
    });
  };

  useEffect(() => {
    doFetchGroups();
  }, []);

  return (
    <div className='content-container'>
      <div>
        <Breadcrumbs />
        <p className='h1-title'>
          <strong>{selectedBuilding.name}</strong> Groups
        </p>
        <div>
          <ul className={classes.groupList}>
            <li>
              <span>Groups ({groups.length})</span>
            </li>
          </ul>
        </div>
        <div className={classes.contentGroups}>
          {groups &&
            groups.map((group, index: number) => {
              return (
                <div className={classes.groupContainer} key={index}>
                  <GroupCard
                    key={group.uuid}
                    group={group}
                    onGroupSelected={setSelectedGroup}
                  />
                </div>
              );
            })}
        </div>

        <GenericModal
          title='EDIT GROUP DETAILS'
          content={
            <EditGroupDetails
              onConfirm={onUpdateGroup}
              groupSelected={selectedGroup}
              options={{
                hideBuildingField: true,
                hideInterestsField: true
              }}
              Action={
                <button type='submit' className='btn-standard btn-primary'>
                  Save Changes
                </button>
              }
            />
          }
          cancelOverride='Close'
          showModal={!!selectedGroup}
          onChange={closeModal}
          hideActionButton={true}
          actionButtonDisabled={true}
          enableOverflowScrolls={true}
        />
      </div>
    </div>
  );
};

function mapStateToProps(state: RootState) {
  return {
    currentUserRole: state.auth.userRole
  };
}

function mapDispatchToProps() {
  return {};
}

export default connect(
  mapStateToProps,
  mapDispatchToProps()
)(withFullScreenLoader(Groups));
