import React, { useState } from "react";
import CobuConstants from "~/helpers/cobuConstants";
import { GoogleReview } from "~/types/reviewReceived";
import { AttributionUser } from "~/types/user";
import Spacer from "../Spacer/Spacer";
import { toast } from "react-toastify";
import CustomUserDropDown from "../CustomDropDown/CustomDropDown";
import { ReviewType, removalReasonsForResidentScoreList, reviewReasonsOfCobu, reviewReasonsOfNotCobu } from "~/helpers/constants";
import CheckboxList from "../CheckboxList/CheckboxList";
import { removeResidentFormResidentsTabRequest } from "~/api/building";
import GenericModal from "../GenericModal/GenericModal";
import styles from './Attribution.module.css'

type AttributionFormProps = {
    review: GoogleReview | null;
    users: AttributionUser[];
    attribute: (review: any) => void;
    onSelectUser?: (user: AttributionUser) => void;
};

const AttributionForm: React.FC<AttributionFormProps> = ({
    review,
    users,
    attribute,
    onSelectUser,
}: AttributionFormProps) => {

    const [selectedUser, setSelectedUser] = useState<AttributionUser | null>(null);
    const [notes, setNotes] = useState<string>('');
    const [selectedMode, setSelectedMode] = useState<string>('');
    const [selectedReason, setSelectedReason] = useState('');
    const [showResidentRemoveModal, setShowResidentRemoveModal] = useState(false);

    // Handles user selection in the dropdown
    const handleUserSelection = (user: AttributionUser) => {
        onSelectUser?.(user);
        setSelectedUser(prevSelectedUser => (prevSelectedUser?.uuid === user.uuid ? null : user));
    };

    // Handles the attribution of the review to the selected user
    const handleAttributeReview = (cobu: boolean) => {
        if (selectedUser) {
            attribute({ ...review, userId: selectedUser.uuid, userName: review?.contributor_name, cobu, notes, ...selectedUser.reviewinvitationtype && {type: selectedUser.reviewinvitationtype?.uuid} });
        } else {
            toast(CobuConstants.googleReview.userNotSelectedWarning);
        }
    };

    const handleAttribution = () => {
        if (selectedMode) {
            switch (selectedMode) {
                case ReviewType.cobu:
                    handleAttributeReview(true)
                    break
                case ReviewType.notCobu:
                    if (selectedUser) {
                        setShowResidentRemoveModal(true);
                    } else {
                        toast(CobuConstants.googleReview.userNotSelectedWarning);
                    }
                    break
                case ReviewType.unknown:
                    attribute({ ...review })
                    break
                default:
                    break
            }
        } else {
            toast(CobuConstants.googleReview.modeNotSelectedWarning);
        }
    }


    const showNotes = () => {
        return selectedMode === ReviewType.cobu ?
            <div className={`${styles.notesContainer}`}>
                <p className='default-text weight-500 font-16 margin-right-8'>
                    {CobuConstants.googleReview.reviewNotes}
                </p>
                <textarea
                    value={notes}
                    placeholder={CobuConstants.googleReview.reviewNotesPlaceholder}
                    onChange={(val) => setNotes(val.currentTarget.value)}
                ></textarea>
            </div> : null
    }

    return (
        <div>
            {/* Render the CustomUserDropDown for user selection */}
            <Spacer height={20} />
            <div>
                <Spacer height={50} />
                <CustomUserDropDown
                    users={users}
                    onSelect={handleUserSelection}
                />
            </div>

            {/* Display the selected user details */}
            <div>
                <Spacer height={50} />
                <div>

                    <p className='default-text weight-500 font-16 margin-right-8'>
                        {CobuConstants.googleReview.selectedUser}
                    </p>

                    <Spacer height={10} />

                    {selectedUser ?
                        <div className={`twoRow center-align ${styles.selecteduser}`} >
                            <div className={`flexRow center-align`}>
                                <img
                                    className='avatar margin-right-8'
                                    alt='avatar'
                                    src={selectedUser.profileImage}
                                />
                                <p className='default-text weight-500 font-16 margin-right-8'>
                                    {`${selectedUser.firstName} ${selectedUser.lastName}`}
                                </p>
                            </div>
                            <p className='default-text weight-500 font-16'>
                                {selectedUser.email}
                            </p>
                            <button
                                className={'btn-standard btn-secondary'}
                                style={{
                                    backgroundColor: 'lightgray'
                                }}
                                onClick={() => {
                                    onSelectUser?.(selectedUser);
                                    setSelectedUser(null)
                                }}
                            >
                                {CobuConstants.googleReview.deselect}
                            </button>
                        </div>
                        :
                        <div className={styles.userPlaceholderContainer}>
                            <p className='default-text font-14 margin-right-8'>
                                {CobuConstants.googleReview.cobuUserNotSelected}
                            </p>
                        </div>
                    }
                </div>
            </div>


            {/* Buttons for review attribution */}
            <Spacer height={50} />
            <div>
                <p className='default-text weight-500 font-16 margin-right-8'>
                    {CobuConstants.googleReview.credit}
                </p>
                <Spacer height={20} />
                <p className='default-text weight-500 font-16 margin-right-8'>
                    {CobuConstants.googleReview.notCobu}
                </p>
                <Spacer height={10} />
                <CheckboxList
                    highlight
                    options={reviewReasonsOfNotCobu}
                    selectedOption={selectedMode}
                    setSelectedOption={setSelectedMode}
                />
                <Spacer height={30} />
                <p className='default-text weight-500 font-16 margin-right-8'>
                    {CobuConstants.googleReview.attribute}
                </p>
                <Spacer height={10} />
                <CheckboxList
                    highlight
                    options={reviewReasonsOfCobu}
                    selectedOption={selectedMode}
                    setSelectedOption={setSelectedMode}
                />
                <Spacer height={20} />
                {showNotes()}
            </div>



            <div className="formHeader">
                <div>
                </div>
                <div>
                    <button
                        className='btn-standard btn-secondary margin-left-16'
                        onClick={() => {
                            setSelectedMode('')
                            if (selectedUser) {
                                onSelectUser?.(selectedUser);
                            }
                            setSelectedUser(null)
                        }}
                    >
                        {CobuConstants.googleReview.cancel}
                    </button>
                    <button
                        className='btn-standard btn-primary margin-left-16'
                        onClick={() => handleAttribution()}
                    >
                        {CobuConstants.googleReview.save}
                    </button>
                </div>
            </div>
            <GenericModal
                showModal={!!showResidentRemoveModal}
                title={'Remove Resident from Invite List?'}
                content={
                    <CheckboxList
                        options={
                            removalReasonsForResidentScoreList
                        }
                        setSelectedOption={(value) => {
                            setSelectedReason(value);
                        }}
                        selectedOption={selectedReason}
                    />
                }
                actionText='Remove resident'
                onChange={() => {
                    setShowResidentRemoveModal(false);
                    setSelectedReason('');
                }}
                actionButtonDisabled={!selectedReason}
                actionFunction={async () => {
                    setSelectedReason('');
                    setShowResidentRemoveModal(false);
                    handleAttributeReview(false)
                    removeResidentFormResidentsTabRequest({
                        user: selectedUser?.uuid ?? '',
                        removalReason: selectedReason,
                        bypassCheck: true,
                        removalTable: 'RESIDENT_REVIEW_INVITATION'
                    }).then((res) => { });
                }}
            />
        </div>
    );
};

export default AttributionForm;
