import moment from "moment";
import React from "react"
import { CellProps } from "react-table";
import Breadcrumbs from "~/components/Breadcrumbs/Breadcrumbs";
import CobuTableAdvanced from "~/components/CobuTableAdvanced/CobuTableAdvanced";
import Spinner from "~/components/Spinner/Spinner";
import CobuConstants from "~/helpers/cobuConstants";
import usePinnedPosts from "~/hooks/post/usePinnedPosts";
import { PinPost } from "~/types/postComment";
import styles from './PinnedPostManagement.module.css'
import Spacer from "~/components/Spacer/Spacer";
import { useHistory } from "react-router-dom";


const PinnedPostManagement = () => {

    const { pinPosts, loadingConfig, unPinComment } = usePinnedPosts()
    const history = useHistory();

    /* UI Components */

    const pinnedPostTableConfig = [
        {
            Header: 'BUILDING',
            accessor: 'building.name'
        },
        {
            Header: 'CONTENTS',
            accessor: 'contents',
            Cell: (cell: CellProps<PinPost>) => {
                return <div
                    style={{ whiteSpace: 'normal' }}
                    className='default-text'>
                    {cell.row.original.contents}
                </div>
            },
        },
        {
            Header: 'CREATED',
            accessor: 'createdAt',
            Cell: (cell: CellProps<PinPost>) => (
                <div>
                    {moment(cell.row.original.createdAt)
                        .local()
                        .format('MM/DD/YYYY')}
                </div>
            )
        },
        {
            id: 'pin/unpin',
            Cell: (cell: CellProps<PinPost>) => (
                <div
                    className={'more-actions-btn more-actions-btn-unselected'}
                    onClick={() => { unPinComment(cell.row.original) }}>
                    {CobuConstants.pinnedPostManagement.removePin}
                </div>
            )
        }
    ]

    const getCobuTableInstance = (data: PinPost[], placeholder: string) => {
        return (
            <CobuTableAdvanced
                columns={pinnedPostTableConfig}
                data={data}
                noData={{
                    label: placeholder,
                    action: undefined,
                    image: undefined
                }}
                actions={[createPostAction()]}
                actionsStyle={{ top: '130px' }}
            />
        );
    };

    const createPostAction = () => {
        return (
            <button
                className='btn-primary btn-standard bold'
                onClick={() => {
                    history.push(`/building/pinned-post-management/post-management`);
                }}
            >
                {CobuConstants.pinnedPostManagement.createPost}
            </button>
        );
    };

    if (loadingConfig.flag) return <div className={styles.loadingContainer}>
        <Spinner />
        <h3 style={{ textAlign: 'center' }}>{loadingConfig.comment}</h3>
    </div>

    return (
        <div className='content-container'>
            <Breadcrumbs />
            <Spacer height={20} />
            <h1>{CobuConstants.pinnedPostManagement.title}</h1>
            <Spacer height={20} />
            {getCobuTableInstance(pinPosts, CobuConstants.pinnedPostManagement.noPinnedPosts)}
        </div>
    )
}

export default PinnedPostManagement;