import React, { CSSProperties } from 'react';
import classes from './HorizontalList.module.css';
import { LineItem, Widget, WidgetComponent } from '~/types/layout';
import { getWidgetComponent } from '../../components';

type HorizontalListProps = Widget & {};

export const HorizontalList = (props: HorizontalListProps) => {
  return (
    <div>
      {props.header?.lineItems?.map((lineItem) => (
        <div style={lineItem.style}>{lineItem.text}</div>
      ))}
      <div
        style={{
          display: 'flex',
          flexDirection: 'row'
        }}
        className={`margin-top-16 margin-bottom-16 ${classes.componentWrapper}`}
      >
        {props.components?.map((comp) => {
          if (!comp) return null

          const Component = getWidgetComponent(comp) as any;

          return <Component {...comp} />;
        })}
      </div>
    </div>
  );
};
