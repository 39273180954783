import React, { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import {
  fetchBuildingDashboardConfig,
  fetchBuildingDetails,
  fetchBuildingStats,
  fetchCommentSentimentStats,
  updateBuilding,
  updateBuildingUnits
} from '~/api/building';
import Breadcrumbs from '~/components/Breadcrumbs/Breadcrumbs';
import StatsBox from '~/components/StatsBox/StatsBox';
import { BuildingStats } from '~/types/building';
import { connect } from 'react-redux';
import { RootState } from '~/redux/store';
import { ReactComponent as Bullet } from '~/utils/images/bullet.svg';
import classes from './Dashboard.module.css';
import {
  IBuildingConfigColumn,
  configureDashboardData
} from '~/helpers/configureDashboardData';
import Spinner from '~/components/Spinner/Spinner';
import { Mixpanel } from '../../services/MixpanelService';
import { getBuildingFromStore } from '~/helpers/reduxStoreHelpers';
import { Roles } from '~/enums/Roles';
import { toast } from 'react-toastify';
import { configureCommentSentimentData } from '~/helpers/configureCommentSentimentData';
import { useBuildingKeyAttribute } from '../EditBuildingContainer/EditBuildingKeys/hooks';

type Props = ReturnType<typeof mapStateToProps>;

const Stats = (props: Props) => {
  const { buildingId } = useParams<{ buildingId: string }>();
  const building = getBuildingFromStore(buildingId);
  const { userRole } = props;
  const queryClient = useQueryClient();
  const { buildingKeyAttributes } = useBuildingKeyAttribute(buildingId);

  const {
    data: buildingDetailsRes,
    isSuccess: isBuildingDetailsSuccess,
    isLoading: isBuildingDetailsLoading,
    isError: isBuildingDetailsError
  } = useQuery(
    ['buildingDetails', buildingId],
    () => fetchBuildingDetails(buildingId),
    {
      refetchOnWindowFocus: false
    }
  );
  let buildingUserOptions: any = [];
  if (isBuildingDetailsSuccess) {
    const activeResidents = buildingDetailsRes?.data.residents.filter(
      (resident: { deactivated: boolean }) => !resident.deactivated
    );
    buildingUserOptions = activeResidents.map((resident: { email: string }) => {
      return { value: resident.email, label: resident.email };
    });
  }

  const {
    data: buildingDashboardRes,
    isLoading: isBuildingDashboardLoading,
    isError: isBuildingDashboardError
  } = useQuery(
    ['buildingDashboardConfig', buildingId],
    () => fetchBuildingDashboardConfig(buildingId),
    {
      refetchOnWindowFocus: false
    }
  );

  const showSentimentData = () => {
    return (
      (userRole === Roles.Pm &&
        buildingDashboardRes?.data.config.showSentimentDataToPM) ||
      userRole === Roles.Vcm ||
      userRole === Roles.Superadmin
    );
  };

  const {
    data: statsRes,
    isLoading: isBuildingStatsLoading,
    isError: isBuildingStatsError
  } = useQuery(
    ['buildingStats', buildingId],
    () => fetchBuildingStats(buildingId),
    {
      refetchOnWindowFocus: false
    }
  );
  const stats = statsRes?.data;

  const commentSentimentQuery = useQuery(
    ['commentSentiment', buildingId],
    () => fetchCommentSentimentStats(buildingId),
    {
      refetchOnWindowFocus: false,
      enabled: !isBuildingDashboardLoading && showSentimentData()
    }
  );

  if (
    isBuildingDetailsError ||
    isBuildingDashboardError ||
    isBuildingStatsError ||
    commentSentimentQuery.isError
  ) {
    toast('Something went wrong. Please try again.');
  }

  const updateStats = async (totalOccupiedUnits: number) => {
    try {
      let resp = await updateBuildingUnits({ totalOccupiedUnits }, buildingId);
      const copyStats = stats;
      queryClient.setQueryData(['buildingStats', buildingId], {
        data: {
          ...copyStats,
          totalOccupiedUnits: resp.data.totalOccupiedUnits
        }
      });
      toast('Occupied units have been updated!');
    } catch (error) {
      console.log(error);
      toast('Something went wrong! Please try again later.');
    }
  };
  useEffect(() => {
    Mixpanel.track('did_open_building_dashboard', {
      $building_name: building.name
    });
  });

  const changeStats = (email: string) => {
    let copyStats: BuildingStats = { ...stats };
    if (userRole === Roles.Superadmin || userRole === Roles.Vcm)
      copyStats.VCMCommenter = email;
    else copyStats.PMCommenter = email;

    queryClient.setQueryData(['buildingStats', buildingId], {
      data: copyStats
    });
  };

  const renderConfigColumn = (data: IBuildingConfigColumn) => {
    return (
      <div key={data.title} className={`${classes.column}  margin-right-40`}>
        {data.sibling && (
          <div className='margin-bottom-16'>
            {data.sibling.map((e) => renderConfigColumn(e))}
          </div>
        )}
        <div className={classes.subtitleContainer}>
          <Bullet fill={data.color} />
          <div className={classes.subtitle}>{data.title}</div>
        </div>
        {Object.values(data.cards)
          .filter(
            (card) =>
              card.selector !== 'neighborhoodOffers' ||
              buildingDashboardRes?.data.config.showPerks
          )
          .map((card) => {
            return (
              <StatsBox
                key={card.selector}
                title={card.title}
                description={card.description}
                statsValue={card.statsValue}
                color={data.color}
                footerLinkText={card.footerLinkText}
                footerLink={card.footerLink}
                renderCustomFooter={card.renderCustomFooter}
                titleLink={card.titleLink}
                action={card.action}
                building={buildingDetailsRes?.data}
                currentUserRole={userRole}
                changeStats={changeStats}
                show={card.show}
                showActions={card.showAction}
                buildingUserOptions={buildingUserOptions}
                updateOccupiedUnits={updateStats}
                styles={card.styles}
              />
            );
          })}
      </div>
    );
  };

  const googleDriveLink = useMemo(() => {
    if (!buildingKeyAttributes) {
      return null;
    }

    const googleDriveAttribute =
      buildingKeyAttributes?.attributedBuildingKeyValue.find((atttribute) =>
        atttribute.name.includes('Google Drive')
      );

    if (googleDriveAttribute) {
      return googleDriveAttribute.value;
    }

    return null;
  }, [buildingKeyAttributes]);

  const showGoogleDriveAnchor =
    userRole === Roles.Vcm || userRole === Roles.Superadmin;

  return (
    <div>
      {!isBuildingDashboardLoading &&
      !isBuildingDetailsLoading &&
      !isBuildingStatsLoading &&
      !commentSentimentQuery.isLoading ? (
        <div className='content-container'>
          <Breadcrumbs />
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between'
            }}
          >
            <div className='margin-top-24'>
              <h2>
                {building.name}
                {''}
              </h2>
            </div>
            {showGoogleDriveAnchor && googleDriveLink && (
              <div className='margin-right-40'>
                <a href={googleDriveLink} target='_blank'>
                  Google Drive Link
                </a>
              </div>
            )}
          </div>
          {showSentimentData() && (
            <div className={`?${classes.subtitleContainer} margin-top-32`}>
              <div className={classes.subtitle}>SENTIMENT VIEWS</div>
            </div>
          )}
          <div>
            <div>
              <div className={classes.container}>
                {commentSentimentQuery.isSuccess &&
                  configureCommentSentimentData(
                    commentSentimentQuery.data.data,
                    buildingId
                  ).map((data) => (
                    <div
                      key={data.title}
                      className={`${classes.column}  margin-right-40`}
                    >
                      <StatsBox
                        title={data.title}
                        description='All comments in 30 days'
                        statsValue={data.statsValue}
                        color={data.color}
                        footerLinkText='See Comments'
                        building={building}
                        currentUserRole={userRole}
                        show={showSentimentData()}
                        isSentiment
                        footerLink={data.footerLink}
                      />
                    </div>
                  ))}
              </div>
            </div>
            <div className={classes.container}>
              {stats &&
                buildingDashboardRes &&
                configureDashboardData(
                  stats,
                  buildingDetailsRes?.data,
                  userRole,
                  buildingDashboardRes?.data.config
                ).map(renderConfigColumn)}
            </div>
          </div>
        </div>
      ) : (
        <div className={classes.spinner}>
          <Spinner />
        </div>
      )}
    </div>
  );
};

function mapStateToProps(state: RootState) {
  return {
    userRole: state.auth.userRole
  };
}

export default connect(mapStateToProps)(Stats);
