import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { ICommunityLead, getReferralLeads } from '~/api/referral';

export default (buildigId: string) => {
  const [loading, setLoading] = useState(false);
  const [referralLeads, setReferralLead] = useState<ICommunityLead[] | null>(
    null
  );

  const fetchCommunityLeads = async () => {
    try {
      setLoading(true);
      const res = await getReferralLeads(buildigId);

      setReferralLead(res.data);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      toast(e.message || 'Failed to fetch community leads');
    }
  };

  useEffect(() => {
    fetchCommunityLeads();
  }, []);

  return { loading, referralLeads };
};
