import React, { useMemo, useState } from 'react';
import { Field, Formik, FormikProps } from 'formik';
import { Building } from '~/types/building';
import { Group } from '~/reducers/groupAdmin';
import { Colors } from '~/enums/Colors';
import * as Yup from 'yup';
import './style.css';
import {
  YardiBuildingIntegration,
  YardiCredential,
  YardiProperty
} from '~/api/yardiIntegration';
import CobuTable from '~/components/CobuTable/CobuTable';
import CobuTableAdvanced from '~/components/CobuTableAdvanced/CobuTableAdvanced';
import { CellProps } from 'react-table';
import _, { property, values } from 'lodash';
import { Integrations } from '@sentry/core';
import { EntrataIntegratedBuilding } from '~/api/buildingEntrataIntegration';
import { useParams } from 'react-router-dom';

export interface AddNewYardiIntegrationFormValues {
  building: string;
  yardiPropertyId: string;
  credentials: string;
}

const validationSchema = Yup.object().shape({
  building: Yup.string().required('Building is missing or is invalid'),
  yardiPropertyId: Yup.string().required(
    '̦Yardi Property ID is missing or is invalid'
  )
});

export interface AddNewYardiIntegrationFormValuesErrors
  extends AddNewYardiIntegrationFormValues {}

type AddYardiIntegrationFormProps = {
  entrataIntegrations: EntrataIntegratedBuilding[];
  yardiIntegrations: YardiBuildingIntegration[];
  selectedIntegration?: YardiBuildingIntegration;
  credentials: YardiCredential[];
  buildings: Building[];
  onFormSubmit: (values: AddNewYardiIntegrationFormValues) => void;
  onClose: () => void;
  properties: YardiProperty[];
};

const AddYardiIntegrationForm = (props: AddYardiIntegrationFormProps) => {
  const { onFormSubmit, properties } = props;
  const [searchInput, setsearchInput] = useState<string>('');
  const [selectedCredential, setselectedCredential] = useState<string>('');
  const [selectedYardiProperty, setselectedYardiProperty] =
    useState<string>('');
  const { buildingId } = useParams<{ buildingId: string }>();

  const filteredProperties = useMemo(() => {
    return properties.filter((property) => {
      const searchInputMatch =
        property.MarketingName?.toString()
          .toLowerCase()
          .includes(searchInput) ||
        property.Code?.toString().toLowerCase().includes(searchInput);

      const selectedCredentialMatch =
        property.credentials.name == selectedCredential;

      if (!!selectedCredential) {
        return selectedCredentialMatch && searchInputMatch;
      } else {
        return searchInputMatch;
      }
    });
  }, [searchInput, selectedCredential, props.credentials, properties]);

  const entrataIntegratedBuildings = useMemo(
    () => _.keyBy(props.entrataIntegrations, 'buildingId'),
    props.entrataIntegrations
  );

  const integratedYardiProperties = useMemo(
    () => _.keyBy(props.yardiIntegrations, 'yardi_property_id'),
    props.entrataIntegrations
  );

  const buildings = Object.values(props.buildings);

  const getYardiPropertyFromCode = (code: string) => {
    return properties.find((property) => property.Code == code);
  };

  return (
    <Formik
      initialValues={{
        building: buildingId ?? props.selectedIntegration?.buildingId ?? '',
        yardiPropertyId: props.selectedIntegration?.yardi_property_id ?? '',
        credentials: props.selectedIntegration?.credentials?.uuid ?? ''
      }}
      validationSchema={validationSchema}
      onSubmit={onFormSubmit}
    >
      {(formikProps: FormikProps<AddNewYardiIntegrationFormValues>) => {
        const {
          values,
          errors,
          touched,
          setFieldValue,
          handleSubmit,
          setFieldTouched,
          handleChange
        } = formikProps;

        return (
          <form className='group-form' onSubmit={handleSubmit}>
            <div>
              <div className='margin-top-16'>
                <p className='color-dark-blue font-16' id='building-label'>
                  <b>Building on Cobu</b>
                </p>
                <select
                  name='building'
                  disabled={!!buildingId || !!props.selectedIntegration}
                  value={values.building}
                  onChange={handleChange}
                  className='margin-top-8 building-dropdown-select'
                  style={{ width: '100%' }}
                >
                  <option value='' disabled>
                    Select a Building
                  </option>
                  {buildings.map((building) => (
                    <option
                      disabled={
                        !!entrataIntegratedBuildings &&
                        !!entrataIntegratedBuildings[building.uuid]
                      }
                      value={building.uuid}
                      key={building.uuid}
                    >
                      {building.name}{' '}
                      {!!entrataIntegratedBuildings &&
                      !!entrataIntegratedBuildings[building.uuid]
                        ? '(Integrated with Entrata)'
                        : ''}
                    </option>
                  ))}
                </select>
                <p
                  className='margin-top-24 color-dark-blue font-16'
                  id='building-label'
                >
                  <b>Select property on Yardi</b>
                </p>
                <div className='flex-row align-center justify-between margin-bottom-8'>
                  <input
                    placeholder='Search by name or ID'
                    style={{ width: '300px' }}
                    value={searchInput}
                    onChange={(e) => setsearchInput(e.target.value)}
                  />
                  <div>OR</div>
                  <div className='flex-row align-center'>
                    <p>Filter by credentials</p>
                    <select
                      className='margin-left-8'
                      placeholder='Filter by credentials'
                      value={selectedCredential}
                      onChange={(e) => setselectedCredential(e.target.value)}
                    >
                      <option value=''>All</option>
                      {props.credentials?.map((credential) => (
                        <option
                          selected={credential.name == selectedCredential}
                          value={credential.name}
                        >
                          {credential.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <CobuTableAdvanced
                  data={filteredProperties}
                  noData={{ label: 'No properties found' }}
                  height={300}
                  columns={[
                    {
                      id: 'actions',
                      Header: '',
                      Cell: (cell: CellProps<YardiProperty>) => {
                        const code = cell.row.original.Code;
                        const isAlreadyIntegrated =
                          !!integratedYardiProperties &&
                          !!integratedYardiProperties[code];

                        if (isAlreadyIntegrated) {
                          return <p></p>;
                        }

                        return (
                          <input
                            value={cell.row.original.Code}
                            type='radio'
                            className='height-20 row-select'
                            disabled={isAlreadyIntegrated}
                            checked={
                              cell.row.original.Code == values.yardiPropertyId
                            }
                            onChange={(e) => {
                              setFieldValue('yardiPropertyId', e.target.value);
                              setFieldValue(
                                'credentials',
                                cell.row.original.credentialsId
                              );
                            }}
                          />
                        );
                      }
                    },
                    {
                      id: 'name',
                      Header: 'Property Name',
                      Cell: (cell: CellProps<YardiProperty>) => {
                        const code = cell.row.original.Code;
                        const isAlreadyIntegrated =
                          !!integratedYardiProperties &&
                          !!integratedYardiProperties[code];

                        return (
                          <div>
                            <div
                              className={isAlreadyIntegrated && 'color-danger'}
                            >
                              {cell.row.original.MarketingName}
                            </div>
                            {isAlreadyIntegrated && (
                              <p>
                                Integrated with '
                                {integratedYardiProperties[code].buildingName}'
                              </p>
                            )}
                          </div>
                        );
                      }
                    },
                    {
                      id: 'id',
                      Header: 'Yardi Property ID',
                      accessor: 'Code'
                    },
                    {
                      id: 'credentialsName',
                      Header: 'Credentials',
                      accessor: 'credentials.name'
                    }
                  ]}
                ></CobuTableAdvanced>
                <p className='margin-top-16'>
                  Selected Yardi Property -{' '}
                  <b>
                    {getYardiPropertyFromCode(values.yardiPropertyId)
                      ?.MarketingName ?? 'None'}{' '}
                  </b>
                </p>
                {/* <select
                  name='yardiPropertyId'
                  value={values.yardiPropertyId}
                  onChange={handleChange}
                  className='margin-top-8'
                  style={{ width: '100%' }}
                >
                  <option value='' disabled>
                    Select a active property
                  </option>
                  {properties.map((property) => (
                    <option value={property.Code} key={property.Code}>
                      {property.MarketingName}
                    </option>
                  ))}
                </select> */}
              </div>
            </div>
            <div id='buttons-wrapper'>
              <button
                type='submit'
                className='btn-standard btn-secondary margin-right-16'
                onClick={(e) => {
                  e.preventDefault();
                  props.onClose();
                }}
              >
                Cancel
              </button>
              <button type='submit' className='btn-standard btn-primary'>
                Save
              </button>
            </div>
          </form>
        );
      }}
    </Formik>
  );
};

export default AddYardiIntegrationForm;
