import React from 'react';

import { addToCalendar, createBlob } from '~/helpers/addToCalendar';
import { ReactComponent as RightArrow } from '~/utils/images/right-arrow.svg';
import { CalendarTypes } from '~/enums/CalendarTypes';
import { GatheringType } from '~/types/gathering';
import './CalendarButton.css';
import { Mixpanel } from '~/services/MixpanelService';

interface IProps {
  Icon: React.SVGProps<SVGSVGElement>;
  text: string;
  type: CalendarTypes;
  gathering: GatheringType;
}
const CalendarButton = (props: IProps) => {
  const { Icon, text, type, gathering } = props;

  const handleCalendarButtonClick = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    e.preventDefault();
    const url = e.currentTarget.getAttribute('href');
    if (url) {
      if (url.startsWith('BEGIN')) {
        createBlob(url);
      } else {
        window.open(url, '_blank');
      }
    }

    Mixpanel.track('added_to_calendar', {
      $gathering_name: gathering.title,
      $calendar_destination: text
    });
  };

  return (
    <a
      className='calendar-button'
      href={addToCalendar(type, gathering)}
      onClick={handleCalendarButtonClick}
    >
      {Icon}
      <div>{text}</div>
      <RightArrow fill='none' />
    </a>
  );
};

export default CalendarButton;
