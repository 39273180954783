import React, { useState } from 'react';
import { connect, ResolveThunks } from 'react-redux';
import { useHistory } from 'react-router-dom';

import RegisterPhoneForm from '~/components/RegisterPhoneFom/RegisterPhoneForm';

import './RegisterPhoneContainer.css';
import { saveUser } from '~/reducers/auth';
import { sendCode, updateUser } from '~/api/auth';
import { RootState } from '~/redux/store';
import InvalidBlock from '~/components/InvalidBlock/InvalidBlock';

interface DispatchProps {
  saveUser: typeof saveUser;
}

type Props = ResolveThunks<DispatchProps> & ReturnType<typeof mapStateToProps>;

const RegisterPhoneContainer = (props: Props) => {
  const [invalidPhone, setInvalidPhone] = useState(false);
  const history = useHistory();
  const { saveUser, user } = props;

  const invalidText =
    'The phone number you entered seems to be invalid. Update it and try again.';

  const hideInvalidBlock = () => {
    setInvalidPhone(false);
  };

  const onConfirm = (data: string) => {
    const phone = data.replace(/[()-]/g, '').replace(' ', '');
    updateUser({ userId: user.uuid, data: { phone } })
      .then((res) => {
        if (res && res.status === 200) {
          saveUser({
            ...res.data,
            building: res.data.building.uuid
          });
          sendCode(user.uuid);
          history.push('/rsvp-register/verification');
        }
      })
      .catch(() => {
        setInvalidPhone(true);
      });
  };

  return (
    <div className='register-phone-container'>
      <div className='reg-phone-content-container'>
        {invalidPhone ? (
          <InvalidBlock text={invalidText} />
        ) : (
          <div className='empty-invalid-block-container'></div>
        )}
        <div className='reg-phone-title-container'>
          <div>Insert Your Phone Number</div>
        </div>
        <div className='reg-phone-text-container'>
          We need to verify your account by sending a code via text messaging.
          Cobu will never send you texts without your permission, and we will
          never share or sell your phone number to anyone.
        </div>
        <RegisterPhoneForm onSubmit={onConfirm} onChange={hideInvalidBlock} />
      </div>
    </div>
  );
};

function mapStateToProps(state: RootState) {
  return {
    user: state.auth.user
  };
}

function mapDispatchToProps(): DispatchProps {
  return {
    saveUser
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps()
)(RegisterPhoneContainer);
