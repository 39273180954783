import { createSlice } from "@reduxjs/toolkit";
import { ResidentManagementState } from "~/types/residentManagement";

export const initialState: ResidentManagementState = {
    activeResidents: [],
    deactivatedResidents: [],
    loading: true,
    loadingString: '',
    isActive: true,
    showCreateModal: false,
    selectedIds: {
        activeSelectedIds: [],
        deactivatedSelectedIds: []
    }
}

const residentSlice = createSlice({
    name: "resident",
    reducers: {
        loader: (state, action) => {
            state.loading = true
            state.loadingString = action.payload.loadingString ?? state.loadingString
        },
        hideLoader: (state) => {
            state.loading = false
        },
        setResidents: (state, action) => {
            state.activeResidents = action.payload.activeResidents
            state.deactivatedResidents = action.payload.deactivatedResidents
            state.loading = false
        },
        toggleActive: (state) => {
            state.isActive = !state.isActive
        },
        updateSelection: (state, action) => {
            state.selectedIds = action.payload
        },
        toggleUserCreateModel:(state) => {
            state.showCreateModal = !state.showCreateModal
        },
        resetState: (state) => {
            state.selectedIds = { activeSelectedIds: [], deactivatedSelectedIds: [] }
            state.isActive = true
            state.showCreateModal =false
        },
    },
    initialState: initialState
});

export const {
    loader,
    hideLoader,
    setResidents,
    toggleActive,
    updateSelection,
    toggleUserCreateModel,
    resetState
} = residentSlice.actions

export default residentSlice.reducer

