import React from "react";
import { useHistory, useParams } from "react-router-dom";
import Breadcrumbs from "~/components/Breadcrumbs/Breadcrumbs";
import useGoogleReviews from "~/hooks/googlereviews/useGoogleReviews";
import styles from './GoogleReview.module.css'
import Spinner from "~/components/Spinner/Spinner";
import { GoogleReview } from "~/types/reviewReceived";
import { CellProps } from "react-table";
import CobuTableAdvanced from "~/components/CobuTableAdvanced/CobuTableAdvanced";
import Spacer from "~/components/Spacer/Spacer";
import CobuConstants from "~/helpers/cobuConstants";
import { getBuildingFromStore } from "~/helpers/reduxStoreHelpers";
import CollapsibleText from "~/components/CollapsibleText/CollapsibleText";
import moment from "moment";

const GoogleReviewManagement = ({ }) => {

    const { buildingId } = useParams<{ buildingId: string }>();
    const selectedBuilding = getBuildingFromStore(buildingId);
    const history = useHistory()
    const { googleReviews, loadingConfig } = useGoogleReviews(buildingId)

    /* UI Logic */
    const googleReviewTableConfig = [
        {
            Header: 'NAME',
            accessor: 'contributor_name',
            Cell: (cell: CellProps<GoogleReview>) => {
                return <div className="flexRow center-align">
                    <img
                        className='avatar margin-right-8'
                        alt='avatar'
                        referrerPolicy="no-referrer"
                        src={cell.row.original.contributor_thumbnail}
                    />
                    <div>
                        < p
                            className='default-text weight-500 font-16'>
                            {cell.row.original.contributor_name}
                        </p>
                        <a
                            href={cell.row.original.contributor_link}
                            target="_blank"
                            className='default-text font-14'>
                            {CobuConstants.googleReview.viewGoogleProfile}
                        </a>
                    </div>
                </div>
            },
        },
        {
            Header: 'REVIEW',
            accessor: 'review_snippet',
            Cell: (cell: CellProps<GoogleReview>) => {
                return <CollapsibleText content={cell.row.original.review_snippet} limit={150} />
            },
        },
        {
            Header: 'RATING',
            accessor: 'review_rating'
        },
        {
            Header: 'DATE FOUND',
            accessor: 'date_found',
            Cell: (cell: CellProps<GoogleReview>) => (
                <div>
                    {moment(cell.row.original.date_found)
                        .local()
                        .format('MM/DD/YYYY')}
                </div>
            )
        },
        {
            id: 'attribute',
            Cell: (cell: CellProps<GoogleReview>) => (
                <div
                    className={'more-actions-btn more-actions-btn-unselected'}
                    onClick={() => {
                        history.push(`/building/google-review-summary/${buildingId}/${cell.row.original.uuid}`)
                    }}>
                    {CobuConstants.googleReview.assign}
                </div>
            )
        }

    ]

    const getCobuTableInstance = (data: GoogleReview[], placeholder: string) => {
        return (
            <CobuTableAdvanced
                columns={googleReviewTableConfig}
                data={data}
                noData={{
                    label: placeholder,
                    action: undefined,
                    image: undefined
                }}
            />
        );
    };

    if (loadingConfig.flag) return <div className={styles.loadingContainer}>
        <Spinner />
        <h3 style={{ textAlign: 'center' }}>{loadingConfig.comment}</h3>
    </div>

    return (
        <div className='content-container'>
            <Breadcrumbs />
            <Spacer height={20} />
            <h1>{CobuConstants.googleReview.googleReviewTitle(selectedBuilding.name)}</h1>
            <Spacer height={20} />
            {getCobuTableInstance(googleReviews, CobuConstants.googleReview.noReviews)}
        </div>
    )
}

export default GoogleReviewManagement