import { Language } from './interfaces';

export const en: Language = {
  user: {},
  post: {},
  survey: {},
  review: {},
  building: {
    multiplePropertiesMergedIntoOneBuilding:
      'If there are multiple properties merged into one building. Provide each individual property’s information',
    googleFeatureIdNeeded:
      'In rare occurrences, the Google feature id is needed.',
    additionalGoogleReviewURLs: 'Additional Google Review URLs',
    featureIds: 'Feature IDs',
    addNewFeatureId: 'Add a new Feature ID',
    addNewGoogleReviewURL: 'Add a new Google Review URL'
  },
  common: {}
};
