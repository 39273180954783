import React, { useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import {
  getBuildingKeyAttributes,
  postBuildingKeyAttributes
} from '~/api/building';
import { IBuildingKeyAttribute } from '~/types/building';
import { FormInputConfig } from '~/types/residentManagement';
import { isValidUrl, toTitleCase } from '~/utils/stringUtils';

export const useBuildingKeyAttribute = (buildingUuid: string) => {
  const [buildingKeyAttributes, setBuildingKeyAttributes] =
    useState<IBuildingKeyAttribute | null>(null);

  const [loader, setLoader] = useState(false);

  const fetchBuildingKeyAttributes = async () => {
    try {
      setLoader(true);
      const { data } = await getBuildingKeyAttributes(buildingUuid);

      setBuildingKeyAttributes(data);
      setLoader(false);
    } catch (e) {
      setLoader(false);
    }
  };

  useEffect(() => {
    fetchBuildingKeyAttributes();
  }, []);

  const postKeyAttributes = async (
    keyAttributes: Partial<IBuildingKeyAttribute>,
    onCancel: () => void
  ) => {
    try {
      setLoader(true);
      await postBuildingKeyAttributes(buildingUuid, keyAttributes);

      setLoader(false);

      toast('Updated attributes successfully!');
      if (onCancel) {
        onCancel();
      }
    } catch (e) {
      setLoader(false);
    }
  };

  const pmsAttributeFormConfig: FormInputConfig[] = useMemo(
    () => [
      {
        id: 'pms.buildingPMS',
        label: 'Property Management System',
        inputType: 'select',
        selectOptions: buildingKeyAttributes?.pms?.options.map((option) => ({
          label: option,
          value: option
        })),
        isRequired: false,
        styles: {
          inputStyles: { backgroundColor: 'red', width: '100%' },
          contentStyles: { backgroundColor: 'green', width: '50%' }
        }
      }
    ],
    [buildingKeyAttributes]
  );

  const assingnedAttributeFormConfig: FormInputConfig[] | null = useMemo(() => {
    if (!buildingKeyAttributes?.attributedBuildingKeyValue.length) {
      return null;
    }

    return buildingKeyAttributes?.attributedBuildingKeyValue.map(
      (attibutedKeyValue, index) => {
        const { name, options } = attibutedKeyValue;

        let values = '';

        if (options && options.length) {
          const isUrl = isValidUrl(options[0]);

          if (isUrl) {
            values = `${options[0].split('//')[0]}//${
              options[0].split('//')[1].split('/')[0]
            }`;
          } else {
            values = options.join(', ');
          }
        }

        return {
          id: `attributedBuildingKeyValue[${index}].value`,
          label: toTitleCase(name),
          inputType: 'text',
          isRequired: false,
          ...(options && options.length
            ? {
                sibling: (
                  <div
                    style={{ fontStyle: 'italic' }}
                  >{`values: ${values}`}</div>
                )
              }
            : {})
        };
      }
    );
  }, [buildingKeyAttributes]);

  const unAssingnedAttributeFormConfig: FormInputConfig[] | null =
    useMemo(() => {
      if (!buildingKeyAttributes?.unAttributedBuildingKeys.length) {
        return null;
      }

      return buildingKeyAttributes?.unAttributedBuildingKeys.map(
        (unAttibutedKeyValue, index) => {
          const { name, options } = unAttibutedKeyValue;

          let values = '';

          if (options && options.length) {
            const isUrl = isValidUrl(options[0]);

            if (isUrl) {
              values = `${options[0].split('//')[0]}//${
                options[0].split('//')[1].split('/')[0]
              }`;
            } else {
              values = options.join(', ');
            }
          }

          return {
            id: `unAttributedBuildingKeys[${index}].value`,
            label: toTitleCase(name),
            inputType: 'text',
            isRequired: false,
            ...(options && options.length
              ? {
                  sibling: (
                    <div
                      style={{ fontStyle: 'italic' }}
                    >{`values: ${values}`}</div>
                  )
                }
              : {})
          };
        }
      );
    }, [buildingKeyAttributes]);

  const watchAttributeUuidWithDisplayNone: FormInputConfig[] | null =
    useMemo(() => {
      if (
        !buildingKeyAttributes?.unAttributedBuildingKeys.length &&
        !buildingKeyAttributes?.attributedBuildingKeyValue.length
      ) {
        return null;
      }

      const watchers: FormInputConfig[] = [];

      if (buildingKeyAttributes?.unAttributedBuildingKeys) {
        buildingKeyAttributes.unAttributedBuildingKeys.forEach(
          (attributeKeyValue, index) => {
            watchers.push({
              id: `unAttributedBuildingKeys[${index}].uuid`,
              inputType: 'text',
              displayNone: true,
              isRequired: false
            });
          }
        );
      }

      if (buildingKeyAttributes?.attributedBuildingKeyValue) {
        buildingKeyAttributes.attributedBuildingKeyValue.forEach(
          (attributeKeyValue, index) => {
            watchers.push({
              id: `attributedBuildingKeyValue[${index}].uuid`,
              inputType: 'text',
              displayNone: true,
              isRequired: false
            });
          }
        );
      }

      return watchers;
    }, [buildingKeyAttributes]);

  return {
    buildingKeyAttributes,
    loader,
    pmsAttributeFormConfig,
    assingnedAttributeFormConfig,
    unAssingnedAttributeFormConfig,
    watchAttributeUuidWithDisplayNone,
    postKeyAttributes
  };
};
