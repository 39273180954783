import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { createAttributionDeeplink, deactivateActiveDeeplink, getAllActiveDeeplinks } from "~/api/deeplinks";
import CobuConstants from "~/helpers/cobuConstants";
import { AttributionDeeplink, AttributionDeeplinkCreateBody } from "~/types/deeplink";



export const useDeeplink = (buildingId: string) => {
    const [activeDeepLinks, setActiveDeepLinks] = useState<{ [key: string]: AttributionDeeplink[] } | null>(null);
    const [loadingConfig, setLoadingConfig] = useState<{ flag: boolean; comment?: string }>({
        flag: false,
        comment: '',
    });

    useEffect(() => {
        fetchDeepLinks()
    }, []);

    const fetchDeepLinks = async (refresh: boolean = false) => {
        setLoadingConfig({
            flag: true,
            comment: refresh ? CobuConstants.deeplink.refreshingActiveDeeplinks : CobuConstants.deeplink.fetchingActiveDeeplinks,
        });
        try {
            const activeDeepLinksResp = await getAllActiveDeeplinks(buildingId);
            setActiveDeepLinks(categorizeDeeplinks(activeDeepLinksResp.data));
            setLoadingConfig({ flag: false });
        } catch (error) {
            setLoadingConfig({ flag: false });
            if (error) {
                toast(error?.message);
            }
        }
    };

    const categorizeDeeplinks = (pages: AttributionDeeplink[]): { [key: string]: AttributionDeeplink[] } => {
        return pages.reduce((acc, page) => {
            if (!acc[page.type]) {
                acc[page.type] = [];
            }
            acc[page.type].push(page);
            return acc;
        }, {} as { [key: string]: AttributionDeeplink[] });
    }

    const deactivateDeepLink = async (deepLinkId: string) => {
        setLoadingConfig({ flag: true, comment: CobuConstants.deeplink.deactivatingDeeplink });
        try {
            await deactivateActiveDeeplink(deepLinkId);
            fetchDeepLinks(true);
            toast(CobuConstants.deeplink.deactivateDeeplinkSuccess);
        } catch (error) {
            setLoadingConfig({ flag: false });
            if (error) {
                toast(error?.message);
            }
        }
    };

    const createDeepLink = async (deepLink: AttributionDeeplinkCreateBody) => {
        setLoadingConfig({ flag: true, comment: CobuConstants.deeplink.createDeeplinkLoading });
        try {
          await createAttributionDeeplink(deepLink);
          fetchDeepLinks(true);
          toast(CobuConstants.deeplink.createDeeplinkSuccess);
        } catch (error) {
          setLoadingConfig({ flag: false });
          if (error) {
            toast(error?.message);
          }
        }
    };



    return { activeDeepLinks, loadingConfig, fetchDeepLinks, deactivateDeepLink, createDeepLink };
};
