import React, { useState, useRef } from 'react';
import { connect, ResolveThunks } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Logo from '~/utils/images/logo.svg';
import './Header.css';

import ProfileDropdown from '~/containers/ProfileDropdown/ProfileDropdown';
import localforage from 'localforage';
import { RootState } from '~/redux/store';
import { logout } from '~/api/auth';
import { logoutSuccess } from '~/reducers/auth';
import { useOnClickOutside } from '~/helpers/helpers';
import { Roles } from '~/enums/Roles';
import { CreateMenu } from './CreateMenu/CreateMenu';
interface DispatchProps {
  logoutSuccess: typeof logoutSuccess;
}

type Props = ReturnType<typeof mapStateToProps> & ResolveThunks<DispatchProps>;

const Header = (props: Props) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const history = useHistory();
  const { logoutSuccess, profileImage, userRole } = props;

  const ref = useRef<HTMLDivElement>(null);
  useOnClickOutside(ref, () => setShowDropdown(false));

  const onLogout = () => {
    logout().then(() => {
      setShowDropdown(false);
      localforage.clear();
      logoutSuccess();
    });
    history.push('/login');
  };

  const showCreateCTA = userRole === Roles.Vcm || userRole === Roles.Superadmin;

  return (
    <div id='header'>
      <img
        className='logo pointer'
        src={Logo}
        alt='Cobu'
        onClick={() => history.push('/building')}
      />
      <div className='header-nav-menu'>
        {showCreateCTA && (
          <div className='menu-item'>
            <CreateMenu />
          </div>
        )}
        <div className='position-relative' ref={ref}>
          <img
            src={profileImage}
            alt='Profile'
            className='profile-image pointer'
            onClick={() => setShowDropdown(!showDropdown)}
          />
          {showDropdown && <ProfileDropdown email='' onLogout={onLogout} />}
        </div>
      </div>
    </div>
  );
};
function mapDispatchToProps(): DispatchProps {
  return {
    logoutSuccess
  };
}
function mapStateToProps(state: RootState) {
  return {
    profileImage: state.auth.user.profileImage,
    userRole: state.auth.userRole
  };
}
export default connect(mapStateToProps, mapDispatchToProps())(Header);
