import { AssignmentTagAccessor } from '~/types/assets';

export enum ASSIGNMENT_TAGS {
  all = 'All',
  unassigned = 'Unassigned'
}

export const defaultTagAssignments = [
  { uuid: '1', [AssignmentTagAccessor.tagName]: ASSIGNMENT_TAGS.all },
  { uuid: '2', [AssignmentTagAccessor.tagName]: ASSIGNMENT_TAGS.unassigned }
];
