import React from 'react';
import { Field, Formik, FormikProps } from 'formik';
import { Colors } from '~/enums/Colors';
import * as Yup from 'yup';
import './style.css';
import { toast } from 'react-toastify';

export interface AddYardiCredentialFormValues {
  name: string;
  username: string;
  password: string;
  database: string;
  serverName: string;
  webServiceUrl: string;
}

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .required('"Name" field is missing or is invalid')
    .min(5, 'The "Name" field should contain atleast 5 characters'),
  webServiceUrl: Yup.string()
    .required('"Web Service Url" is missing or is invalid')
    .min(1, 'The "Web Service Url" should contain atleast 1 characters'),
  database: Yup.string()
    .required('"Database" field is missing or is invalid')
    .min(1, 'The "Database" should contain atleast 1 characters'),
  serverName: Yup.string()
    .required('"Server Name" is missing or is invalid')
    .min(1, 'The "Server Name" should contain atleast 1 characters'),
  username: Yup.string()
    .required('Username is missing or is invalid')
    .min(1, 'The username should contain atleast 1 characters'),
  password: Yup.string()
    .required('Password is required')
    .min(1, 'The password should contain atleast 1 characters')
});

export interface AddYardiCredentialFormValuesErrors
  extends AddYardiCredentialFormValues {}

type AddYardiCredentialsFormProps = {
  onFormSubmit: (values: AddYardiCredentialFormValues) => void;
  onClose: () => void;
};

const checkWebServiceUrl = (webServiceUrl: string) => {
  new URL(webServiceUrl);

  if (!webServiceUrl.includes('http'))
    throw Error('Invalid web service url. "http" missing form the url');

  if (!webServiceUrl.includes('.com'))
    throw Error('Invalid web service url. ".com" missing form the url');
};

const AddYardiCredentialsForm = (props: AddYardiCredentialsFormProps) => {
  const { onFormSubmit } = props;

  const onSubmit = (values: AddYardiCredentialFormValues) => {
    try {
      checkWebServiceUrl(values?.webServiceUrl);

      onFormSubmit(values);
    } catch (error) {
      toast(error.message);
      console.error(error);
    }
  };

  return (
    <Formik
      initialValues={{
        username: '',
        password: '',
        webServiceUrl: '',
        database: '',
        serverName: '',
        name: ''
      }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {(formikProps: FormikProps<AddYardiCredentialFormValues>) => {
        const {
          values,
          errors,
          touched,
          setFieldValue,
          handleSubmit,
          setFieldTouched,
          handleChange
        } = formikProps;

        return (
          <form className='group-form' onSubmit={handleSubmit}>
            <div>
              <div
                className='margin-top-16'
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '45%'
                }}
              >
                <label>Name</label>
                <input
                  id='name'
                  name='name'
                  onChange={handleChange}
                  onBlur={() => setFieldTouched('name', true, false)}
                  value={values.name}
                />
                {touched.name && Boolean(errors.name) && (
                  <p style={{ color: Colors.cobuRed }}>{errors.name}</p>
                )}
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div
                  className='margin-top-16'
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '45%'
                  }}
                >
                  <label>Username</label>
                  <input
                    id='username'
                    name='username'
                    onChange={handleChange}
                    onBlur={() => setFieldTouched('username', true, false)}
                    value={values.username}
                  />
                  {touched.username && Boolean(errors.username) && (
                    <p style={{ color: Colors.cobuRed }}>{errors.username}</p>
                  )}
                </div>
                <div
                  className='margin-top-16'
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '45%'
                  }}
                >
                  <label>Password</label>
                  <input
                    id='password'
                    name='password'
                    onChange={handleChange}
                    onBlur={() => setFieldTouched('password', true, false)}
                    value={values.password}
                  />
                  {touched.password && Boolean(errors.password) && (
                    <p style={{ color: Colors.cobuRed }}>{errors.password}</p>
                  )}
                </div>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div
                  className='margin-top-16'
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '45%'
                  }}
                >
                  <label>Database</label>
                  <input
                    id='database'
                    name='database'
                    onChange={handleChange}
                    onBlur={() => setFieldTouched('database', true, false)}
                    value={values.database}
                  />
                  {touched.database && Boolean(errors.database) && (
                    <p style={{ color: Colors.cobuRed }}>{errors.database}</p>
                  )}
                </div>
                <div
                  className='margin-top-16'
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '45%'
                  }}
                >
                  <label>Server Name</label>
                  <input
                    id='serverName'
                    name='serverName'
                    onChange={handleChange}
                    onBlur={() => setFieldTouched('serverName', true, false)}
                    value={values.serverName}
                  />
                  {touched.serverName && Boolean(errors.serverName) && (
                    <p style={{ color: Colors.cobuRed }}>{errors.serverName}</p>
                  )}
                </div>
              </div>
              <div
                className='margin-top-16'
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '45%'
                }}
              >
                <label>Web Service URL</label>
                <input
                  id='webServiceUrl'
                  name='webServiceUrl'
                  onChange={handleChange}
                  onBlur={() => setFieldTouched('webServiceUrl', true, false)}
                  value={values.webServiceUrl}
                />
                {touched.webServiceUrl && Boolean(errors.webServiceUrl) && (
                  <p style={{ color: Colors.cobuRed }}>
                    {errors.webServiceUrl}
                  </p>
                )}
              </div>
            </div>
            <div id='buttons-wrapper'>
              <button
                type='submit'
                className='btn-standard btn-secondary margin-right-16'
                onClick={(e) => {
                  e.preventDefault();
                  props.onClose();
                }}
              >
                Cancel
              </button>
              <button type='submit' className='btn-standard btn-primary'>
                Save
              </button>
            </div>
          </form>
        );
      }}
    </Formik>
  );
};

export default AddYardiCredentialsForm;
