import React, { FC, LegacyRef, useEffect, useMemo, useRef } from 'react';
import './FlyerTable.css';
import { IEventWithFlyer, IGatheringFlyer } from '~/types/gathering';
import './FlyerTable.css';
import { ReactComponent as Community } from '~/utils/images/community.svg';
import useFlyerTableData from '~/hooks/building/flyer/useFlyerTableData';
import GenericModal from '~/components/GenericModal/GenericModal';
import { EditFlyerForm } from './EditFlyerForm/EditFlyerForm';
import { EmailFlyerForm } from './EmailFylerForm/EmailFlyerForm';
import { ISelectOption } from '~/helpers/interfaces';
import Spinner from '~/components/Spinner/Spinner';
import CobuTableAdvanced from '~/components/CobuTableAdvanced/CobuTableAdvanced';

interface IProps {
  showCompletedReports: boolean;
}

const FlyerTable: FC<IProps> = ({ showCompletedReports }) => {
  const {
    columns,
    editFlyerData,
    onEditFlyerCancel,
    emailFlyerLoader,
    emailFlyerData,
    onEmailFlyerSubmit,
    onEmailModalCancel,
    flyerTemplatOptions,
    templateSelectionOption,
    setTemplateSelectionOption,
    pdfFlyerHtmlContent,
    instaFlyerHtmlContent,
    pdfIFrameRef,
    instaIFrameRef,
    loading,
    downloadAttachments,
    eventsWithFlyer,
    fetchEventsWithFlyers
  } = useFlyerTableData();

  const inputRef = useRef<{ clearSearchInput: () => void }>(null);

  useEffect(() => {
    inputRef?.current?.clearSearchInput();
  }, [showCompletedReports]);

  const filteredFlyers = useMemo(() => {
    return eventsWithFlyer?.filter((eventWithFlyers) => {
      if (showCompletedReports) {
        return eventWithFlyers.flyerStatus === 'completed';
      }

      return eventWithFlyers.flyerStatus !== 'completed';
    });
  }, [eventsWithFlyer, showCompletedReports]);

  const flyerTableMemo = useMemo(() => {
    return (
      <CobuTableAdvanced
        enableDeferredSearch
        centerAlignedHeaderAndContent
        ref={inputRef}
        noData={{
          label: 'No Data',
          action: undefined,
          image: undefined
        }}
        search={{
          searchField: 'buildingName',
          placeholder: 'Building Name'
        }}
        columns={columns}
        data={filteredFlyers}
        sort={{
          id: 'startTime',
          descending: true
        }}
        actionsStyle={{ top: '125px', right: '15vw' }}
      />
    );
  }, [filteredFlyers]);

  if (filteredFlyers.length <= 0) {
    return (
      <div>
        <Community />
        <h2>No Flyers found!</h2>
      </div>
    );
  }

  const isPdfFlyer = editFlyerData?.flyer?.flyerTemplateType === 'PDF Flyer';

  return (
    <div>
      {loading && <Spinner />}
      {flyerTableMemo}
      <GenericModal
        showModal={!!editFlyerData.flyer}
        title={`Edit ${isPdfFlyer ? 'PDF' : 'Instagram'} Flyer`}
        content={
          editFlyerData.flyer ? (
            <EditFlyerForm
              flyerTemplatOptions={flyerTemplatOptions as ISelectOption[]}
              flyerData={editFlyerData.flyer as IGatheringFlyer}
              eventData={editFlyerData.event as IEventWithFlyer}
              onCancel={onEditFlyerCancel}
              fetchEventsWithFlyers={fetchEventsWithFlyers}
            />
          ) : null
        }
        enableOverflowScrolls
        hideActionButton
        canceActionButton
        actionText={'Submit'}
        actionFunction={() => {}}
        onChange={() => {}}
      />
      <GenericModal
        showModal={!!emailFlyerData.event}
        title={'Email flyers ON-SITE'}
        enableOverflowScrolls
        content={
          emailFlyerData.event ? (
            <EmailFlyerForm
              templateSelectionOption={templateSelectionOption}
              setTemplateSelectionOption={setTemplateSelectionOption}
              loader={emailFlyerLoader}
              eventData={emailFlyerData.event as IEventWithFlyer}
              flyerGroupEmails={emailFlyerData.flyerGroupEmails}
              onSubmitForm={onEmailFlyerSubmit}
              onCancel={onEmailModalCancel}
              downloadAttachments={downloadAttachments}
            />
          ) : null
        }
        hideActionButton
        canceActionButton
        actionText={'Submit'}
        actionFunction={() => {}}
        onChange={() => {}}
      />
      {pdfFlyerHtmlContent && (
        <iframe
          id='pdfIFrameRef'
          ref={pdfIFrameRef as LegacyRef<HTMLIFrameElement>}
          style={{ width: '100%', height: '1056px', visibility: 'hidden' }}
          srcDoc={pdfFlyerHtmlContent}
        />
      )}
      {instaFlyerHtmlContent && (
        <iframe
          id='instaIFrameRef'
          ref={instaIFrameRef as LegacyRef<HTMLIFrameElement>}
          style={{ width: '1080px', height: '1080px', visibility: 'hidden' }}
          srcDoc={instaFlyerHtmlContent}
        />
      )}
    </div>
  );
};

export default FlyerTable;
