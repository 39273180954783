import momentTimezone from 'moment-timezone';
import React from 'react';
import Breadcrumbs from '~/components/Breadcrumbs/Breadcrumbs';
import CobuTableAdvanced from '~/components/CobuTableAdvanced/CobuTableAdvanced';
import Spinner from '~/components/Spinner/Spinner';
import CheckBox from '~/components/CheckBox/CheckBox';
import Spacer from '~/components/Spacer/Spacer';
import { UpcomingEvent } from '~/types/gathering';
import useUpcomingEvents from '~/hooks/events/useUpcomingEvents';
import CobuConstants from '~/helpers/cobuConstants';
import Switch from 'react-ios-switch';
import styles from './EventManagement.module.css';
import Label from '~/components/Label';
import TextDelimeter from '~/components/TextDelimeter/TextDelimeter';
import { Link } from 'react-router-dom';
import { withFullScreenLoader } from '~/hocs/withFullScreenLoader';
import { useFullScreenLoader } from '~/hooks/useFullScreenLoader';

const EventManagement = () => {
  const { upcomingEvents, loadingConfig, toggleDataForEvent } =
    useUpcomingEvents();
  const { loader } = useFullScreenLoader();

  //UI Logics

  const renderStartTime = (cell: UpcomingEvent) => {
    const startTime = momentTimezone
      .tz(cell.scheduledStartTime, cell.buildingTimezone)
      .format('MM/DD/YYYY - hh:mm A');
    return <div>{startTime}</div>;
  };

  const renderRSVPCell = (cell: UpcomingEvent) => {
    return cell.isRSVP ? (
      <CheckBox
        index={0}
        option={{ text: '', value: '' }}
        selectedOption={''}
        setSelectedOption={() => {}}
      />
    ) : null;
  };

  const renderAutoRemainderSwitch = (cell: UpcomingEvent) => {
    return (
      <div>
        <Switch
          checked={cell.reqAutoReminder}
          onChange={async () => {
            loader.show();
            await toggleDataForEvent(cell);
            loader.hide();
          }}
        />
      </div>
    );
  };

  const upcomingEventTableConfig = [
    {
      Header: 'TITLE',
      accessor: (cell: UpcomingEvent) => {
        return (
          <div>
            <Link to={`/building/${cell.buildingId}/${cell.uuid}/rsvp`}>
              <TextDelimeter
                textElement={cell.title}
                maxDelimiter={80}
                moreMessage='See more'
                lessMessage='See less'
                contentStyle={{
                  fontSize: '18px',
                  color: 'rgb(39, 33, 99)',
                  fontWeight: 500,
                  lineHeight: 1.4
                }}
              />
              {cell.isRecurring && (
                <div>
                  <Label title='Recurring' />
                </div>
              )}
            </Link>
          </div>
        );
      }
    },
    {
      Header: 'BUILDING',
      accessor: (cell: UpcomingEvent) => (
        <Link to={`/building/${cell.buildingId}`}>{cell.buildingName}</Link>
      )
    },
    { Header: 'GROUP', accessor: 'groupName' },
    {
      Header: 'CREATOR',
      accessor: (cell: UpcomingEvent) => `${cell.creator?.displayName}`
    },
    { Header: 'CREATOR EMAIL', accessor: 'creator.email' },
    { Header: 'START TIME', accessor: renderStartTime },
    { Header: 'RSVP', accessor: renderRSVPCell },
    {
      Header: 'REMAINING RSVPs',
      accessor: (cell: UpcomingEvent) =>
        cell.maxEventAttendees
          ? `${cell.maxEventAttendees - Number(cell.rsvps)}`
          : '∞'
    },
    { Header: 'AUTO REMINDER', accessor: renderAutoRemainderSwitch }
  ];

  const getCobuTableInstance = (data: UpcomingEvent[], placeholder: string) => (
    <CobuTableAdvanced
      columns={upcomingEventTableConfig}
      data={data}
      noData={{ label: placeholder, action: undefined, image: undefined }}
      actionsStyle={{ top: '130px' }}
    />
  );

  if (loadingConfig.flag) {
    return (
      <div className={styles.loadingContainer}>
        <Spinner />
        <h3 style={{ textAlign: 'center' }}>{loadingConfig.comment}</h3>
      </div>
    );
  }

  return (
    <div className='content-container'>
      <Breadcrumbs />
      <Spacer height={20} />
      <h1>{CobuConstants.eventManagement.title}</h1>
      <h4>{`This table showcases all events scheduled to occur greater than or equal to three days, till next 1 month, from the current date. `}</h4>
      <Spacer height={20} />
      {getCobuTableInstance(
        upcomingEvents,
        CobuConstants.pinnedPostManagement.noPinnedPosts
      )}
    </div>
  );
};

export default withFullScreenLoader(EventManagement);
