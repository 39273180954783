import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { connect, ResolveThunks } from 'react-redux';

import { RootState } from '~/redux/store';

import './RegisterSuccessContainer.css';
import { sendInstallLink } from '~/api/auth';
import { fetchOneGatheringSuccess } from '~/reducers/residentGathering';
import { fetchOneGatheringRequest } from '~/api/residentGathering';

interface DispatchProps {
  fetchOneGatheringSuccess: typeof fetchOneGatheringSuccess;
}

type Props = ResolveThunks<DispatchProps> & ReturnType<typeof mapStateToProps>;

const RegisterSuccessContainer = (props: Props) => {
  const history = useHistory();
  const { gatheringId, user, fetchOneGatheringSuccess, gathering } = props;

  useEffect(() => {
    const fetchData = () => {
      fetchOneGatheringRequest(gatheringId).then(async (res) => {
        await fetchOneGatheringSuccess(res.data);
      });
    };
    fetchData();
  }, [fetchOneGatheringSuccess, gatheringId]);

  const sendSMS = () => {
    sendInstallLink({
      user: user.uuid,
      building: gathering.buildingId
    }).then(() => {
      history.push(`/rsvp/gathering/${gatheringId}`);
    });
  };

  const onSkip = () => {
    history.push(`/rsvp/gathering/${gatheringId}`);
  };

  return (
    <div className='reg-suc-container'>
      <div className='reg-suc-title'>
        Thanks for signing up for Cobu.{' '}
        <span role='img' aria-label='tada'>
          🎉
        </span>
      </div>
      <div className='reg-suc-text'>
        The Cobu mobile app helps you engage with your neighbors, join interest
        groups, swap household items and participate in fun events together.
      </div>
      <div className='reg-suc-text'>
        Don't miss out on the latest happenings at
      </div>
      <div className='reg-suc-building'>{gathering.buildingName}</div>
      <div className='reg-suc-text'>
        Tap on the link below to get Cobu for your mobile device.
      </div>
      <div className='submit-button reg-sec-button' onClick={sendSMS}>
        Get install link as SMS
      </div>
      <div className='reg-sec-skip' onClick={onSkip}>
        SKIP
      </div>
    </div>
  );
};

function mapStateToProps(state: RootState) {
  return {
    gatheringId: state.auth.gatheringId,
    user: state.auth.user,
    gathering: state.residentGathering.gathering
  };
}

function mapDispatchToProps(): DispatchProps {
  return {
    fetchOneGatheringSuccess
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps()
)(RegisterSuccessContainer);
