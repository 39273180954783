import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { GoogleReview, GoogleReviewDetailState } from "~/types/reviewReceived";
import { AttributionUser } from "~/types/user";

// Define the initial state
export const initialState: GoogleReviewDetailState = {
    review: null,
    loading: false,
    loadingString: "",
    residents: [],
    activeResident: null
}

// Create a slice for managing Google reviews state
const googleReviewSlice = createSlice({
    name: "googleReviews",
    initialState,
    reducers: {
        // Reducer to handle showing loader
        loader: (state, action: PayloadAction<{ loadingString?: string }>) => {
            state.loading = true;
            state.loadingString = action.payload.loadingString ?? state.loadingString;
        },

        // Reducer to hide loader
        hideLoader: (state) => {
            state.loading = false;
        },

        // Reducer to set Google review details
        setGoogleReviewDetails: (state, action: PayloadAction<{ review: GoogleReview, residents: AttributionUser[] }>) => {
            state.review = action.payload.review;
            state.residents = action.payload.residents;
            state.loading = false;
        },

        // Reducer to set the active resident
        setActiveResident: (state, action: PayloadAction<{ activeResident: AttributionUser }>) => {
            const resident = action.payload.activeResident;

            // Toggle activeResident based on current state
            state.activeResident = (state.activeResident && state.activeResident.uuid === resident.uuid) ? null : resident;

            // Update 'selected' flag for residents based on activeResident
            state.residents = state.residents.map(u => ({
                ...u,
                selected: state.activeResident ? u.uuid === state.activeResident.uuid : false
            }));
        }
    }
});

// Extract action creators and reducer
export const {
    loader,
    hideLoader,
    setGoogleReviewDetails,
    setActiveResident,
} = googleReviewSlice.actions;

export default googleReviewSlice.reducer;
