import React, { FC } from 'react';
import { PacmanLoader } from 'react-spinners';

import classes from './Spinner.module.css';

interface IProps {
  float?: boolean;
}

const Spinner: FC<IProps> = ({ float = false }) => {
  //TODO color in enum
  return (
    <div className={`${classes.spinner} ${float ? classes.floatSpinner : ''}`}>
      <PacmanLoader color={'#fec100'} />
    </div>
  );
};

export default Spinner;
