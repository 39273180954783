import React from 'react';
import { connect } from 'react-redux';

import './ProfileDropdown.css';

import { RootState } from '~/redux/store';

interface IProps {
  onLogout: () => void;
}

type Props = IProps & ReturnType<typeof mapStateToProps>;

const ProfileDropdown = (props: Props) => {
  const { onLogout } = props;

  return (
    <div className='dropdown-content'>
      <p className='info-text'>Logged in as:</p>
      <p className='user'>{props.email}</p>
      <p className='log-out pointer' onClick={onLogout}>
        Log out
      </p>
    </div>
  );
};

function mapStateToProps(state: RootState) {
  return {
    email: state.auth.user.email
  };
}

export default connect(mapStateToProps, null)(ProfileDropdown);
