import React, { useEffect, useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import {
  CreateResidentFormType,
  ResidentInputFieldConfig
} from '~/types/residentManagement';
import './CreateResidentForm.css';
import CobuConstants from '~/helpers/cobuConstants';
import Spacer from '../Spacer/Spacer';
import Checkbox from '../CheckBox/CheckBox';
import { toast } from 'react-toastify';
import Switch from 'react-ios-switch';
import { CobuCheckbox } from '../CobuCheckbox';

type IProps = {
  createResident: (r: CreateResidentFormType) => void;
  cancel: () => void;
  formConfig: ResidentInputFieldConfig[];
  edit?: boolean;
  initialData?: any;
};

const CreateResidentForm = ({
  createResident,
  cancel,
  formConfig,
  edit,
  initialData
}: IProps) => {
  const { register, handleSubmit, errors, formState, setValue, watch } =
    useForm<CreateResidentFormType>({
      defaultValues: edit ? { ...initialData, password: '      ' } : {}
    });

  const [tncConfig, setTncConfig] = useState(
    initialData
      ? {
          status: initialData.acceptedTermsAndConditions,
          displayStatus: `${initialData.acceptedTermsAndConditions}`
        }
      : {}
  );

  const [doNotDeactivate, setdoNotDeactivate] = useState<boolean>(
    initialData?.doNotDeactivate ?? false
  );

  const [showFullName, setShowFullName] = useState(
    initialData ? initialData.showFullName : false
  );

  const onSubmit: SubmitHandler<CreateResidentFormType> = async (resident) => {
    //create
    if (!edit) {
      createResident({ ...resident, doNotDeactivate });
      return;
    }

    //edit
    let isTncUpdated =
      !initialData.acceptedTermsAndConditions &&
      tncConfig.displayStatus === 'true';
    let tncData = isTncUpdated ? { acceptedTermsAndConditions: true } : {};

    let finalData = {
      ...resident,
      ...tncData,
      showFullName,
      doNotDeactivate
    };
    createResident(finalData);
  };

  let firstName = watch('firstName');
  let lastName = watch('lastName');

  useEffect(() => {
    setValue(
      'displayName',
      showFullName
        ? `${firstName} ${lastName}`
        : `${firstName} ${lastName?.charAt(0)}.`
    );
  }, [firstName, lastName]);

  const generateField = (fieldConfig: ResidentInputFieldConfig) => {
    let error = errors[`${fieldConfig.id}` as keyof CreateResidentFormType];
    return (
      <div key={fieldConfig.id}>
        <div className='item'>
          <label htmlFor='title'>{fieldConfig.label}</label>
          <input
            type='text'
            id={fieldConfig.id}
            name={fieldConfig.id}
            readOnly={fieldConfig.readOnly}
            ref={register({
              required: true,
              pattern: fieldConfig.pattern,
              maxLength: fieldConfig.maxLength,
              minLength: fieldConfig.minLength
            })}
            //hack to allow empty password if not edited..else minimum 6 characters
            onFocus={(event) => {
              if (fieldConfig.id === 'password' && edit) {
                let value = event.target.value;
                if (value === '      ') {
                  event.target.value = '';
                }
              }
            }}
            onBlur={(event) => {
              if (fieldConfig.id === 'password' && edit) {
                let value = event.target.value;
                if (!value.trim()) {
                  event.target.value = '      ';
                }
              }
            }}
            className={`${fieldConfig.readOnly && 'nonEditable'} ${
              error && 'border-red'
            }`}
          />
          {error && error.type === 'required' && (
            <span className='errorTitle span' role='alert'>
              {CobuConstants.createResident.requiredError}
            </span>
          )}
          {error && fieldConfig.pattern && error.type === 'pattern' && (
            <span className='errorTitle span' role='alert'>
              {fieldConfig.patternErrorMsg}
            </span>
          )}
          {error && fieldConfig.maxLengthError && error.type === 'maxLength' && (
            <span className='errorTitle span' role='alert'>
              {fieldConfig.maxLengthError}
            </span>
          )}
          {error && fieldConfig.maxLengthError && error.type === 'minLength' && (
            <span className='errorTitle span' role='alert'>
              {fieldConfig.minLengthError}
            </span>
          )}
        </div>
        {edit && fieldConfig.id === 'firstName' ? (
          <div>
            <div className='list'>
              <p className='rowTextStyle'>
                {CobuConstants.createResident.showFullName}
              </p>
              <Switch
                className={'switchStyle'}
                checked={showFullName}
                onChange={(newSwitchState: boolean) => {
                  setShowFullName(!showFullName);
                  setValue(
                    'displayName',
                    !showFullName
                      ? `${firstName} ${lastName}`
                      : `${firstName} ${lastName?.charAt(0)}.`
                  );
                }}
              />
            </div>
          </div>
        ) : null}
      </div>
    );
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='gridContainer'>
          {formConfig.map((field) => generateField(field))}
        </div>
        <Spacer height={20} />
        <div>
          <CobuCheckbox
            title={CobuConstants.createResident.doNotDeactivate}
            checked={doNotDeactivate}
            onChange={setdoNotDeactivate}
          />
        </div>
        {edit && (
          <div>
            <Checkbox
              defaultSetting
              index={0}
              option={{
                //@ts-ignore
                text: (
                  <div className='flexRow'>
                    <p className='bold'>{CobuConstants.createResident.tnc}</p>,{' '}
                    {`${CobuConstants.createResident.acceptTnc}`}{' '}
                  </div>
                ),
                value: tncConfig?.displayStatus ?? ''
              }}
              selectedOption={'true'}
              setSelectedOption={function (value: string): void {
                if (!initialData.acceptedTermsAndConditions) {
                  setTncConfig({
                    ...tncConfig,
                    displayStatus:
                      tncConfig.displayStatus === 'true' ? 'false' : 'true'
                  });
                }
              }}
            />
          </div>
        )}
        <div className='formButtons'>
          <button className='btn-standard btn-secondary' onClick={cancel}>
            {CobuConstants.createResident.cancel}
          </button>
          <input
            className='btn-standard btn-primary margin-left-16 '
            type='submit'
          />
        </div>
      </form>
    </div>
  );
};

export default CreateResidentForm;
