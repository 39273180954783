
import React from 'react';

import { ReactComponent as CheckboxUnselected } from '~/utils/images/checkbox-unchecked.svg';
import { ReactComponent as CheckboxSelected } from '~/utils/images/checkbox-checked.svg';
import { ReactComponent as CheckboxSelectedGreen } from '~/utils/images/checkbox-checked-green.svg';

interface IProps {
  index: number
  option: { text: string; value: string };
  selectedOption: string;
  defaultSetting?: boolean;
  setSelectedOption: (value: string) => void;
  highlight?: boolean
}
const Checkbox = (props: IProps) => {
  const { option, setSelectedOption, defaultSetting, selectedOption, index } = props;

  return (
    <div>
      <div
        key={index}
        className='checkbox-row'
        onClick={() => setSelectedOption(option.value)}
      >
        {selectedOption === option.value ? (
          (defaultSetting ? <CheckboxSelectedGreen /> : <CheckboxSelected />)
        ) : (
          <CheckboxUnselected />
        )}
        {props.highlight ? <p className='default-text weight-500 font-14 margin-right-8 margin-left-16'>{option.text}</p> :
          <p className='margin-left-16'>{option.text}</p>
        }
      </div>
    </div>
  );
};
export default Checkbox;