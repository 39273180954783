import React, { FC, useRef } from 'react';
import { useOwnerGroupAsssets } from '~/hooks/assets/useAssetManagement';
import CobuTableAdvanced from '../CobuTableAdvanced/CobuTableAdvanced';
import {
  AssetAccessors,
  AssignmentTagAccessor,
  IOwnerGroupAsset,
  IOwnerGroupProperty
} from '~/types/assets';
import './AssetMangementTable.css';
import Spinner from '../Spinner/Spinner';
import { Roles } from '~/enums/Roles';

interface IProps {
  showSearchHeader?: boolean;
  ownerId?: string;
  heading: string;
  isFromOwnerGroup?: boolean;
}

export const AssetManagementTable: FC<IProps> = ({
  showSearchHeader = false,
  ownerId,
  isFromOwnerGroup,
  heading
}) => {
  const {
    assignmentTags,
    ownerGroupWiseAssets,
    onAssignmentTag,
    assetManagementTableConfig,
    loading,
    selectedOwnerGroupProperties,
    selectedAssignmentTag,
    isVCMorSuperAdmin
  } = useOwnerGroupAsssets(ownerId, isFromOwnerGroup);

  const inputRef = useRef<{ clearSearchInput: () => void }>(null);

  if (loading) {
    return <Spinner />;
  }

  const clearSearchInput = () => {
    if (inputRef?.current?.clearSearchInput) {
      inputRef?.current?.clearSearchInput();
    }
  };

  const columnData = ownerId
    ? (selectedOwnerGroupProperties as IOwnerGroupProperty[])
    : (ownerGroupWiseAssets as IOwnerGroupAsset[]);

  const showHeader = showSearchHeader && isVCMorSuperAdmin;

  return (
    <>
      <div className='tableHeader'>
        <div className='tableHeader'>
          <div className='title bold tableTitle'>{heading}</div>
          {showHeader && (
            <div className='tableHeader assignment-tags'>
              {assignmentTags &&
                assignmentTags.map((assignmentTag) => {
                  return (
                    <button
                      className={`btn-standard btn-secondary ${
                        selectedAssignmentTag?.uuid === assignmentTag.uuid
                          ? 'switchSelected'
                          : 'defaultSwitch'
                      }`}
                      onClick={() => {
                        onAssignmentTag(assignmentTag);

                        clearSearchInput();
                      }}
                    >
                      {assignmentTag[AssignmentTagAccessor.tagName]}
                    </button>
                  );
                })}
            </div>
          )}
        </div>
        {/* {showHeader && (
          <div className='tableHeader'>
            <button
              className={`btn-standard btn-secondary ${
                isActiveBuildings ? 'switchSelected' : 'defaultSwitch'
              }`}
              onClick={() => {
                toggleBuildingActiveState(true);
                clearSearchInput();
              }}
            >
              ACTIVE
            </button>
            <button
              className={`btn-standard btn-secondary  ${
                isActiveBuildings ? 'defaultSwitch' : 'switchSelected'
              }`}
              onClick={() => {
                toggleBuildingActiveState(false);
                clearSearchInput();
              }}
            >
              INACTIVE
            </button>
          </div>
        )} */}
      </div>
      {!!columnData && (
        <CobuTableAdvanced
          {...(isVCMorSuperAdmin
            ? { containerStyle: { position: 'relative' } }
            : {})}
          actionsStyle={
            isVCMorSuperAdmin
              ? {
                  top: '-80px',
                  right: '0px'
                }
              : {
                  position: 'static',
                  justifyContent: 'flex-end',
                  marginBottom: '10px'
                }
          }
          columns={[...assetManagementTableConfig]}
          data={columnData}
          noData={{
            label: 'No Data',
            action: undefined,
            image: undefined
          }}
          {...(showSearchHeader
            ? {
                search: {
                  searchField: AssetAccessors.buildingName,
                  placeholder: 'Search by property'
                }
              }
            : {})}
          showPagination
          cellTextAlign='center'
          ref={inputRef}
        />
      )}
    </>
  );
};
