import createService, { createApiService } from './createService';
const url = process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_BACKEND_ENDPOINT!
    : process.env.REACT_APP_LOCAL_ENDPOINT!;
const COBU_API_URL = process.env.REACT_APP_COBU_API_ENDPOINT!

export const emperor = createService({
  url
});

export const api = createApiService({
  url: COBU_API_URL
})
