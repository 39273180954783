import Cookies from 'universal-cookie';

import { emperor } from '~/services';
import { PasswordResetFormType } from '~/types/auth';


export type AuthData = {
  email: string;
  password: string;
};

export const loginRequest = async (loginData: AuthData) => {
  const data = new FormData();
  data.append('email', loginData.email);
  data.append('password', loginData.password);
  return await emperor.post('/auth/login', data);
};

export const sendResetPasswordEmail = async (email: string) => {
  const data = new FormData();
  data.append('email', email);
  return await emperor.post('/user/password/send_reset', data);
};

export const resetPasswordRequest = async (data: PasswordResetFormType) => {
  const formData = new FormData();
  formData.append('password', data.password);
  formData.append('tokenId', 'data.tokenId');
  formData.append('token', 'data.token');
  return await emperor.post('user/password/reset', formData);
};

export const logout = async () => {
  const cookies = new Cookies();
  await cookies.remove('token', { path: '/' });
};

export const signupRequest = async (data: {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  roomNumber: string;
  verified: boolean;
  verifiedPhone: boolean;
  groupEvent: string;
}) => {
  return await emperor.post('/auth/rsvp/signup', data);
};

export const updateUser = async (updateData: {
  userId: string;
  data: { phone: string };
}) => {
  return await emperor.patch(`/user/${updateData.userId}`, updateData.data);
};

export const sendCode = async (userId: string) => {
  return await emperor.get(`/auth/getcode/${userId}`);
};

export const verifyCode = async (data: { userId: string; code: string }) => {
  return await emperor.post('/auth/verify', data);
};

export const getUserRoleRequest = async (userId: string) => {
  return await emperor.get(`/v2/user/role/${userId}`);
};

export const sendInstallLink = async (data: {
  user: string;
  building: string;
}) => {
  return await emperor.post(`/user/send-install-link`, data);
};
