import React, { FC } from 'react';
import { useBuildingTileFrom } from './hooks';
import Spinner from '~/components/Spinner/Spinner';
import { FormInputConfig } from '~/types/residentManagement';
import { FormField } from '~/components/FormField';
import { useForm } from 'react-hook-form';
import { IBuildingTileForm } from './interfaces';
import GenericModal from '~/components/GenericModal/GenericModal';
import Spacer from '~/components/Spacer/Spacer';

interface IProps {
  buildingId: string;
  selectedTile?: IBuildingTileForm;
  onFormSubmit: () => void;
  onCancel: () => void;
  showFormModal: boolean;
}

export const BuildingTileForm: FC<IProps> = ({
  selectedTile,
  onFormSubmit,
  buildingId,
  onCancel,
  showFormModal
}) => {
  const { loader, onSubmit, formConfig } = useBuildingTileFrom(
    buildingId,
    selectedTile,
    onFormSubmit
  );

  const { register, handleSubmit, errors, control, reset } =
    useForm<IBuildingTileForm>({
      defaultValues: selectedTile ?? {}
    });

  const generateField = (fieldConfig: FormInputConfig) => {
    return (
      <div key={fieldConfig.id}>
        <div className='item'>
          {!!fieldConfig?.label && (
            <label
              style={fieldConfig?.styles?.lableStyles}
              className={`${fieldConfig?.styles?.lableClassName}`}
              htmlFor='title'
            >
              {fieldConfig.label}
            </label>
          )}
          <FormField
            fieldConfig={fieldConfig}
            errors={errors}
            register={register}
            control={control}
          />
        </div>
      </div>
    );
  };

  const renderForm = () => {
    return (
      <div className='float-spinner-parent'>
        {loader && <Spinner float />}
        <form id='form-container' onSubmit={handleSubmit(onSubmit)}>
          <div>{formConfig.map((field) => generateField(field))}</div>
          <Spacer height={20} />
          <div className='formButtons'>
            {!!selectedTile && (
              <button
                className='btn-standard btn-secondary'
                onClick={(event) => {
                  event.preventDefault();
                  reset();
                }}
              >
                {'Reset'}
              </button>
            )}
            <button
              className='btn-standard btn-secondary margin-left-16'
              onClick={(event) => {
                event.preventDefault();

                onCancel();
              }}
            >
              {'Cancel'}
            </button>
            <button
              className='btn-standard btn-primary margin-left-16 '
              type='submit'
            >
              {selectedTile ? 'Update' : 'Save'}
            </button>
          </div>
        </form>
      </div>
    );
  };

  return (
    <GenericModal
      showModal={showFormModal}
      title={'Configure a Tile'}
      enableOverflowScrolls
      content={renderForm()}
      hideActionButton
      canceActionButton
      actionText={''}
      actionFunction={() => {}}
      onChange={() => {}}
    />
  );
};
