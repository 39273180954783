import React, { FC } from 'react';
import './AutoGrowFormField.css';
import { FormInputConfig } from '~/types/residentManagement';
import { FormField } from '../FormField';
import { ReactComponent as TrashIcon } from '~/utils/images/trash.svg';
import { ReactComponent as AddIcon } from '~/utils/images/addPlus.svg';
import {
  Control,
  FieldErrors,
  FieldName,
  Ref,
  RegisterOptions,
  useFieldArray
} from 'react-hook-form';

type IProps = {
  rowConfig: FormInputConfig[];
  control: Control<any>;
  errors?: FieldErrors<any>;
  register(rules?: RegisterOptions): (ref: Ref | null) => void;
  register(name: string, rules?: RegisterOptions): void;
  formFieldName: string;
  addNewRowText?: string;
  enableDelete?: boolean;
};

export const AutoGrowFormField: FC<IProps> = ({
  rowConfig,
  errors,
  control,
  register,
  formFieldName,
  addNewRowText,
  enableDelete = true
}) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: formFieldName
  });

  const onDeleRow = (index: number) => {
    remove(index);
  };

  const onAddNewField = () => {
    append({ building: '', url: '' });
  };

  const renderDeleteIcon = (index: number) => (
    <div id='trash-icon' onClick={() => onDeleRow(index)}>
      <TrashIcon />
    </div>
  );

  return (
    <>
      {fields.map((field, index) => {
        return (
          <div
            key={field.id}
            className='grid auto-grow-container'
            style={{
              gridTemplateColumns: `repeat(${
                rowConfig.filter((config) => !config.displayNone).length
              }, 1fr) 25px`
            }}
          >
            {rowConfig.map((fieldConfig, i) => {
              return (
                <div
                  key={i}
                  className='item'
                  style={fieldConfig.displayNone ? { display: 'none' } : {}}
                >
                  <FormField
                    nameIndex={index}
                    namePreFix={formFieldName}
                    fieldConfig={fieldConfig}
                    errors={errors?.[formFieldName]?.[index]}
                    register={register}
                    control={control}
                    defaultValue={field[fieldConfig.id]}
                  />
                </div>
              );
            })}
            {enableDelete && renderDeleteIcon(index)}
          </div>
        );
      })}
      {!!addNewRowText && (
        <div className='row-container'>
          <div
            className={
              addNewRowText
                ? 'row-container add-content paragraph'
                : 'row-container btn-primary'
            }
            onClick={onAddNewField}
          >
            {addNewRowText || (
              <>
                <AddIcon /> Add
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};
