export const MerchantCategories = [
  'Restaurant',
  'Entertainment',
  'Pet Care Service',
  'Cleaning Service',
  'Health & Wellness Service',
  'Fitness Provider',
  'Child Care Service',
  'Car Wash Service',
  'Laundry',
  'Handyman Service',
  'Car Sharing',
  'Home',
  'Suggestion Box',
  'Dry Cleaning',
  'Hotels and Travel',
  'Shopping',
  'Food',
  'Salon'
];
