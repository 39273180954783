import React, { } from "react"
import Breadcrumbs from "~/components/Breadcrumbs/Breadcrumbs"
import Spacer from "~/components/Spacer/Spacer"
import CobuConstants from "~/helpers/cobuConstants"
import YearMonthPicker from "../YearMonthPicker"
import MonthlyReport from "~/components/BuildingReport/BuildingReport"
import { useParams } from "react-router-dom"
import useReports from "~/hooks/reports/useReports"
import Spinner from "~/components/Spinner/Spinner"
import { getBuildingFromStore } from "~/helpers/reduxStoreHelpers"


const Reports = ({ }) => {

    const { buildingId } = useParams<{ buildingId: string }>();
    const selectedBuilding = getBuildingFromStore(buildingId);
    const { report, loading, getReport } = useReports(buildingId)


    const selectDate = (date: string) => {
        getReport(date)
    }

    return (
        <div className='content-container'>
            <Breadcrumbs />
            <Spacer height={20} />
            <h1>{CobuConstants.buildingReports.title} for {selectedBuilding.name}</h1>
            <Spacer height={40} />
            <div style={{ display: "flex", flex: 1, flexDirection: "row" }}>
                <div style={{ flex: 1 }}>
                    <YearMonthPicker selectDate={selectDate} />
                </div>
                <div style={{ flex: 3, borderWidth: 1, borderColor: "gray", borderStyle: 'solid', padding: '10px', marginBottom: '10px', borderRadius: '10px' }}>
                    {/* @ts-ignore */}
                    {
                        loading ? <Spinner /> : (report ? <MonthlyReport {...report} /> : <div style={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center' }}><h3>No Report Available for selected month</h3></div>)
                    }
                </div>
            </div>

        </div>
    )
}

export default Reports
