import axios from 'axios';
import bugsnag from 'bugsnag-js';
import Cookies from 'universal-cookie';

import { OPEN_GROUP_EVENT_SNACKBAR } from './GroupActions';
import { StoreDispatch } from '~/redux/store';

const url = process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_BACKEND_ENDPOINT!
    : process.env.REACT_APP_LOCAL_ENDPOINT!;

const bugsnagClient = bugsnag('8822370330acd587983bc49c6f829cd4');

export const GET_GROUP_COMMENTS = 'GET_GROUP_COMMENTS';
export const SET_GROUP_COMMENT_LOADING = 'SET_GROUP_COMMENT_LOADING';
export const SET_SCHEDULED_GROUP_COMMENTS = 'SET_SCHEDULED_GROUP_COMMENTS';
export const DELETE_SCHEDULED_GROUP_COMMENTS = 'DELETE_SCHEDULED_GROUP_COMMENTS';
export const UPDATE_SCHEDULED_GROUP_COMMENTS = 'UPDATE_SCHEDULED_GROUP_COMMENTS';
export const UPDATE_GROUP_COMMENT = 'UPDATE_GROUP_COMMENT';
export const GET_BUILDING_COMMENTS = 'GET_BUILDING_COMMENTS';
export const UPDATE_BUILDING_CHAT_COMMENT = 'UPDATE_BUILDING_CHAT_COMMENT';


export const updateScheduledGroupComment = (comment: string, uuid: string) => (dispatch: StoreDispatch) => {
  const cookies = new Cookies();
  const token = cookies.get('token');

  axios({
    url: `${url}/scheduledgroupcomment/${uuid}`,
    method: 'PATCH',
    headers: { token },
    data: comment,
  })
    .then((response) => {
      dispatch({
        type: UPDATE_SCHEDULED_GROUP_COMMENTS,
        payload: response.data,
      });
    })
    .catch((error) => {
      alert(
        error.response.status === 404
          ? "Creator email provided didn't match any data in the building"
          : 'An error occured',
      );
      bugsnagClient.notify(error);
    });
};

export const createGroupComment = (comment: string, scheduled: boolean) => async (dispatch: StoreDispatch) => {
  const cookies = new Cookies();
  const token = cookies.get('token');

  return axios({
    url: scheduled
      ? `${url}/scheduledgroupcomment`
      : `${url}/groupcomment`,
    method: 'POST',
    headers: { token },
    data: comment,
  }).catch((error) => {
    dispatch({
      type: OPEN_GROUP_EVENT_SNACKBAR,
      payload: {
        snackbarOpen: true,
        errorMessage:
          error.response.status === 404
            ? "Creator email or group provided didn't match any data in the building"
            : error.response.data.message,
      },
    });
  });
};