import React from 'react';

import { ReactComponent as CheckboxUnselected } from '~/utils/images/checkbox-unchecked.svg';
import { ReactComponent as CheckboxSelected } from '~/utils/images/checkbox-checked.svg';
// import './MultiSelectCheckBox.css';

interface CobuCheckboxProps {
  checked: boolean;
  onChange: (checked: boolean) => void;
  title?: string;
}

export const CobuCheckbox = (props: CobuCheckboxProps) => {
  return (
    <div
      className='checkbox-row'
      onClick={() => props.onChange(!props.checked)}
    >
      {props.checked ? <CheckboxSelected /> : <CheckboxUnselected />}
      {props.title && <p className='margin-left-16'>{props.title}</p>}
    </div>
  );
};
