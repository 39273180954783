import React, { useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { AssetGoogleReviewsTable } from '~/components/AssetGoogleReviewsTable/AssetGoogleReviewsTable';
import { AssetManagementTable } from '~/components/AssetMangementTable/AssetMangementTable';
import './AssetGoogleReview.css';
import Breadcrumbs from '~/components/Breadcrumbs/Breadcrumbs';
import Spacer from '~/components/Spacer/Spacer';
import { Roles } from '~/enums/Roles';
import { useSelector } from 'react-redux';
import { RootState } from '~/redux/store';

export const AssetGoogleReview = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const ownerGroupId = queryParams.get('ownerGroupId') as string;
  const ownerGroupName = queryParams.get('ownerGroupName') as string;
  const isOwnerGroup = queryParams.get('isOwnerGroup') as string;

  const [isActiveBuildings, setIsActiveBuildings] = useState(true);

  const toggleBuildingActiveState = (isActive: boolean) => {
    setIsActiveBuildings(isActive);
  };

  const role = useSelector((state: RootState) => {
    return state.auth.userRole;
  });

  if (!ownerGroupId) {
    return null;
  }

  const decodedOwnerName = decodeURIComponent(ownerGroupName);

  const isVCMorSuperAdmin = role === Roles.Superadmin || role === Roles.Vcm;

  return (
    <div className='container'>
      <Breadcrumbs />
      {/* {isVCMorSuperAdmin && (
        <div className='tableHeader'>
          <button
            className={`btn-standard btn-secondary ${
              isActiveBuildings ? 'switchSelected' : 'defaultSwitch'
            }`}
            onClick={() => toggleBuildingActiveState(true)}
          >
            ACTIVE
          </button>
          <button
            className={`btn-standard btn-secondary  ${
              isActiveBuildings ? 'defaultSwitch' : 'switchSelected'
            }`}
            onClick={() => toggleBuildingActiveState(false)}
          >
            INACTIVE
          </button>
        </div>
      )} */}
      <AssetManagementTable
        ownerId={ownerGroupId}
        isFromOwnerGroup={isOwnerGroup === 'true'}
        heading={`${decodedOwnerName} | Overview`}
        showSearchHeader
      />
      <Spacer height={20} />
      <AssetGoogleReviewsTable
        heading={`${decodedOwnerName} | Google Reviews`}
        ownerId={ownerGroupId}
        isFromOwnerGroup={isOwnerGroup === 'true'}
      />
    </div>
  );
};
