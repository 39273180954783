import { Building } from '~/types/building';

export const formatBuildingData = (building: Building): Building => {
  const reportEmails = JSON.parse(JSON.stringify(building?.reportEmails));

  if (building.hasOwnProperty('reportEmails')) {
    if (!!building?.reportEmails && typeof building.reportEmails === 'string') {
      building.reportEmails = building.reportEmails
        ?.slice(1, -1)
        .split(',')
        .join('\n');

      if (!building.propertyEmails || building.propertyEmails.length <= 0) {
        building.propertyEmails = reportEmails
          ?.slice(1, -1)
          .split(',')
          .map((email: string) => ({ email, type: '' }));
      }
    } else if (
      !!building?.reportEmails &&
      Array.isArray(building?.reportEmails)
    ) {
      building.reportEmails = building?.reportEmails.join('\n');

      if (!building.propertyEmails || building.propertyEmails.length <= 0) {
        building.propertyEmails = reportEmails.map((email: string) => ({
          email,
          type: ''
        }));
      }
    }
  }

  return building;
};
