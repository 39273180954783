import React from 'react';
import Checkbox from '../CheckBox/CheckBox';

interface IProps {
  options: { text: string; value: string }[];
  selectedOption: string;
  defaultSetting?: boolean;
  setSelectedOption: (value: string) => void;
  highlight?: boolean
}
const CheckboxList = (props: IProps) => {
  const { options, setSelectedOption, defaultSetting, selectedOption, highlight } = props;

  return (
    <div>
      {options.map((option, index) => (
        <Checkbox index={index}
          option={option}
          key={`${index}`}
          selectedOption={selectedOption}
          defaultSetting={defaultSetting}
          highlight={highlight}
          setSelectedOption={setSelectedOption} />
      ))}
    </div>
  );
};
export default CheckboxList;
