import axios from 'axios';
import bugsnag from 'bugsnag-js';
import Cookies from 'universal-cookie';
import { StoreDispatch } from '~/redux/store';

const bugsnagClient = bugsnag('8822370330acd587983bc49c6f829cd4');

const url =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_BACKEND_ENDPOINT!
    : process.env.REACT_APP_LOCAL_ENDPOINT!;

export const FETCHING_BUILDINGS = 'FETCHING_BUILDINGS';
export const BUILDINGS_FETCHED = 'BUILDINGS_FETCHED';

export const getBuildingsSimple = () => (dispatch: StoreDispatch) => {
  const cookies = new Cookies();
  dispatch({
    type: FETCHING_BUILDINGS
  });

  axios({
    method: 'GET',
    url: `${url}/v2/user/buildings`,
    headers: { token: cookies.get('token') }
  })
    .then((response) => {
      dispatch({
        type: BUILDINGS_FETCHED,
        payload: response.data
      });
    })
    .catch((error) => {
      bugsnagClient.notify(error);
    });
};
