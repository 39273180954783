import React, { useEffect, useState, useRef, CSSProperties } from 'react';
import classes from './GenericModal.module.css';
import { useOnClickOutside } from '~/helpers/helpers';

interface IProps {
  showModal: boolean;
  title: string | React.ReactChild;
  content: React.ReactNode;
  actionText?: string;
  width?: string;
  actionFunction?: () => void;
  actionButtonDisabled?: boolean;
  onChange: (arg: boolean) => void;
  cancelOverride?: string;
  actionButtonsInHeader?: boolean;
  hideActionButton?: boolean;
  applyModalStretch?: boolean;
  canceActionButton?: boolean;
  enableOverflowScrolls?: boolean;
  dismissOnTapOutside?: boolean;
  titleStyle?: CSSProperties;
}

const GenericModal = (props: IProps) => {
  const {
    showModal,
    actionText,
    actionFunction,
    actionButtonDisabled,
    content,
    title,
    width,
    onChange,
    cancelOverride,
    actionButtonsInHeader,
    hideActionButton,
    applyModalStretch,
    canceActionButton,
    dismissOnTapOutside = true,
    enableOverflowScrolls = false,
    titleStyle
  } = props;

  const [show, setShow] = useState(showModal);

  const ref = useRef<HTMLDivElement>(null);
  useOnClickOutside(ref, () => {
    if (dismissOnTapOutside) setShow(false);
  });

  const freezeBodyScroll = () => {
    document.getElementsByTagName('body')[0].style.overflow = 'hidden';
  };

  const unFreezeBodyScroll = () => {
    document.getElementsByTagName('body')[0].style.overflow = 'initial';
  };

  useEffect(() => {
    if (showModal) {
      freezeBodyScroll();
    } else {
      unFreezeBodyScroll();
    }

    setShow(showModal);
  }, [showModal]);

  useEffect(() => {
    return () => {
      unFreezeBodyScroll();
    };
  }, []);

  return (
    <div
      className={show ? classes.overlay : classes.hide}
      onClick={(e) => {
        onChange(false);
      }}
      ref={ref}
    >
      <div
        className={`${
          applyModalStretch ? classes.modalStretch : classes.modal
        } ${classes.modalContainer} ${
          enableOverflowScrolls && classes.overflowAutoScroll
        }`}
        style={width ? { width: width } : {}}
        onClick={(e) => {
          // Prevent the modal from closing when clicking inside it
          e.stopPropagation();
        }}
      >
        {actionButtonsInHeader ? (
          <div>
            <div className={classes.headerContainer}>
              {title && (
                <p style={titleStyle} className={classes.modalTitle}>
                  {title}
                </p>
              )}
              <div className={classes.buttons}>
                <button
                  className='btn-standard btn-secondary'
                  onClick={() => {
                    onChange(false);
                  }}
                >
                  {cancelOverride || 'Cancel'}
                </button>
                {!hideActionButton && (
                  <button
                    className='btn-standard btn-primary margin-left-16 '
                    onClick={() => actionFunction && actionFunction()}
                    disabled={actionButtonDisabled}
                  >
                    {actionText}
                  </button>
                )}
              </div>
            </div>
            {content}
          </div>
        ) : (
          <div>
            <p style={titleStyle} className={classes.modalTitle}>
              {title}
            </p>
            {content}
            {(!hideActionButton || !canceActionButton) && (
              <div
                className={
                  applyModalStretch ? classes.buttonsStretch : classes.buttons
                }
              >
                {!canceActionButton && (
                  <button
                    className='btn-standard btn-secondary'
                    onClick={() => {
                      onChange(false);
                    }}
                  >
                    {cancelOverride || 'Cancel'}
                  </button>
                )}
                {!hideActionButton && (
                  <button
                    className='btn-standard btn-primary margin-left-16 '
                    onClick={() => actionFunction && actionFunction()}
                    disabled={actionButtonDisabled}
                  >
                    {actionText}
                  </button>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default GenericModal;
