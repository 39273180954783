import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState
} from 'react';
import { Column } from 'react-table';
import CobuTable from '~/components/CobuTable/CobuTable';
import { getNestedField } from '~/helpers/helpers';
import { ReactComponent as Search } from '~/utils/images/search.svg';
import { ReactComponent as Community } from '~/utils/images/community.svg';
import classes from './CobuTableAdvanced.module.css';

interface IProps {
  columns: Column<any>[];
  data: {}[];
  actions?: React.ReactNode[];
  height?: number;
  actionsStyle?: React.CSSProperties;
  containerStyle?: React.CSSProperties;
  search?: {
    searchField?: string;
    searchFields?: string[];
    placeholder: string;
    showFull?: boolean;
  };
  noData: {
    label: string;
    action?: React.ReactNode;
    image?: React.SVGProps<SVGSVGElement>;
  };
  sort?: {
    id: string;
    descending: boolean;
  };
  hideHeader?: boolean;
  mini?: boolean;
  showPagination?: boolean;
  paginationPageSize?: number;
  cellTextAlign?:
    | 'center'
    | 'end'
    | 'justify'
    | 'left'
    | 'match-parent'
    | 'right'
    | 'start';
  columnsWithoutSort?: string[];
  rowProps?: {
    onMouseEnter: (value: any) => void;
    onMouseLeave: (value: any) => void;
  };
  ignoreDefaultSortBehaviour?: boolean;
  centerAlignedHeaderAndContent?: boolean;
  enableDeferredSearch?: boolean;
}

const CobuTableAdvanced = forwardRef((props: IProps, ref) => {
  const {
    data,
    columns,
    actions,
    actionsStyle,
    noData,
    search,
    sort,
    showPagination,
    paginationPageSize,
    cellTextAlign,
    containerStyle,
    centerAlignedHeaderAndContent = false,
    enableDeferredSearch = false
  } = props;
  const [searchValue, setSearchValue] = useState('');

  const [showSearchField, setShowSearchField] = useState(false);
  const [realData, setRealData] = useState(data);

  useEffect(() => {
    if (
      search &&
      search.searchFields &&
      search.searchFields.length > 0 &&
      data.length > 0
    ) {
      setRealData(
        data.filter((d: Object) => {
          return search.searchFields?.some((field) => {
            return getNestedField(d, field)[0]
              .toLocaleLowerCase()
              .includes(searchValue.toLocaleLowerCase());
          });
        })
      );
    } else if (search && search.searchField && data.length > 0) {
      setRealData(
        data.filter((d: Object) => {
          return getNestedField(d, search.searchField!)[0]
            .toLocaleLowerCase()
            .includes(searchValue.toLocaleLowerCase());
        })
      );
    } else {
      setRealData(data);
    }
  }, [searchValue, search, data]);

  useEffect(() => {
    if (!enableDeferredSearch) {
      setRealData(data);
    }
  }, [data]);

  const clearSearchInput = () => {
    setSearchValue('');
    setShowSearchField(false);
  };

  useImperativeHandle(
    ref,
    () => {
      return {
        clearSearchInput
      };
    },
    []
  );

  return (
    <div style={containerStyle}>
      <div style={actionsStyle} className={classes.searchPosition}>
        {search && search.searchFields && search.searchFields.length > 0 ? (
          search.showFull ? (
            <div
              className={`search-expanded pointer`}
              style={{}}
              onClick={clearSearchInput}
            >
              <Search onClick={clearSearchInput} className='search-icon' />
              <input
                className={`${classes.searchInput}`}
                placeholder={`Search by ${
                  search?.placeholder || search?.searchFields.join(', ')
                }`}
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                onClick={(e) => e.stopPropagation()}
              />
            </div>
          ) : (
            <div>
              {showSearchField ? (
                <div
                  className='search-expanded pointer'
                  onClick={clearSearchInput}
                >
                  <Search onClick={clearSearchInput} className='search-icon' />
                  <input
                    className='search-input'
                    placeholder={`Search by ${
                      search?.placeholder || search?.searchFields.join(', ')
                    }`}
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                    onClick={(e) => e.stopPropagation()}
                  />
                </div>
              ) : (
                <div
                  className='search pointer'
                  onClick={() => setShowSearchField(true)}
                >
                  <Search />
                </div>
              )}
            </div>
          )
        ) : (
          search &&
          search.searchField && (
            <div>
              {showSearchField ? (
                <div
                  className='search-expanded pointer'
                  onClick={clearSearchInput}
                >
                  <Search onClick={clearSearchInput} className='search-icon' />
                  <input
                    className='search-input'
                    placeholder={`Search by ${
                      search?.placeholder || search?.searchField
                    }`}
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                    onClick={(e) => e.stopPropagation()}
                  />
                </div>
              ) : (
                <div
                  className='search pointer'
                  onClick={() => setShowSearchField(true)}
                >
                  <Search />
                </div>
              )}
            </div>
          )
        )}
        {actions &&
          actions.map((action: React.ReactNode, index) => (
            <div className={`margin-left-16`} key={index}>
              {action}
            </div>
          ))}
      </div>

      {realData.length > 0 && (
        <CobuTable
          showPagination={showPagination}
          paginationPageSize={paginationPageSize}
          height={props.height}
          hideHeader={props.hideHeader}
          data={realData}
          columns={columns}
          mini={props.mini}
          sort={sort}
          cellTextAlign={cellTextAlign}
          rowProps={props.rowProps}
          ignoreDefaultSortBehaviour={props.ignoreDefaultSortBehaviour}
          centerAlignedHeaderAndContent={centerAlignedHeaderAndContent}
        />
      )}
      {realData.length <= 0 && (
        <div className={classes.noData}>
          {noData.image || <Community />}
          <h2 className={`margin-top-24 ${classes.titleLabel}`}>
            {noData.label}
          </h2>
          {noData.action}
        </div>
      )}
    </div>
  );
});

export default CobuTableAdvanced;
