import React from 'react';
import { Group } from '~/reducers/groupAdmin';
import CobuTable from '../CobuTable/CobuTable';
import './GroupsProposals.css';

type GroupsProposalsProps = {
  loading: boolean;
  groupProposals: Group[];
  handleCreate: (row: any) => void;
  handleReject: (row: any) => void;
};

const GroupsProposals = (props: GroupsProposalsProps) => {
  const { loading, groupProposals, handleCreate, handleReject } = props;

  const GroupProposalsArray = React.useMemo(
    () => [
      {
        Header: 'Suggesting User',
        id: 'suggesting',
        disableSortBy: true,
        columns: [
          {
            Header: 'Name',
            id: 'name',
            disableSortBy: true,
            accessor: ({ suggestingUser: user }: { suggestingUser: any }) => (
              <div
                title={`${user.firstName} ${user.lastName}`}
                className='suggestingUser'
              >
                {`${user.firstName} ${user.lastName}`}
              </div>
            )
          },
          {
            Header: 'Email',
            id: 'email',
            disableSortBy: true,
            accessor: ({ suggestingUser: user }: { suggestingUser: any }) => (
              <div title={user.email} className='suggestingUser'>
                {user.email}
              </div>
            )
          }
        ]
      },
      {
        Header: 'Proposal Details',
        id: 'proposed',
        disableSortBy: true,
        columns: [
          {
            Header: 'Title',
            id: 'title',
            disableSortBy: true,
            accessor: (row: any) => (
              <div title={row.title} className='proposedGroupTitle'>
                {row.title}
              </div>
            )
          },
          {
            Header: 'Building',
            id: 'buildingname',
            disableSortBy: true,
            accessor: (row: any) => (
              <div className='tableColumn'>{row.building.name}</div>
            )
          }
        ]
      },
      {
        Header: 'Additional Info',
        id: 'additional',
        disableSortBy: true,
        columns: [
          {
            Header: 'Description',
            id: 'description',
            width: 250,
            disableSortBy: true,
            accessor: (row: any) => (
              <div className='proposedGroupDescription'>{row.description}</div>
            )
          },
          {
            Header: 'Actions',
            id: 'proposed_actions',
            width: 200,
            disableSortBy: true,
            Cell: ({ row }: any) => (
              <div className='actionsColumn'>
                <button
                  className='btn-small btn-primary'
                  onClick={() => handleCreate(row as any)}
                >
                  Approve
                </button>
                <button
                  className='btn-small margin-left-16'
                  onClick={() => handleReject(row as any)}
                >
                  Reject
                </button>
              </div>
            )
          }
        ]
      }
    ],
    [handleCreate, handleReject]
  );

  if (
    !Array.isArray(groupProposals) ||
    groupProposals?.length == 0 ||
    loading
  ) {
    return null;
  }

  const groups = groupProposals?.filter(
    (group: any) =>
      group.suggestingUser &&
      group.building &&
      !group.approved &&
      !group.rejected
  );

  return (
    <>
      <div>
        <h2>Group Proposals</h2>
        <div id='proposed-groups'>
          <CobuTable data={groups} columns={GroupProposalsArray} />
        </div>
      </div>
    </>
  );
};

export default GroupsProposals;
