/* Component for adding horizonatl and vertical spacings */

import React from 'react';

type IProps = {
  height?: number;
  width?: number;
};

const Spacer = (props: IProps) => {
  const { height = 0, width = 0 } = props;
  return <div style={{ height: height, width: width }}></div>;
};

export default Spacer;
