import { AttributionUser, UserMin } from "./user";

export type ReviewReceivedType = {
  building: string;
  createdAt: string;
  reviewdate: string;
  reviewlink: string;
  reviewname: string;
  reviewnotes: string;
  reviewrating: number;
  reviewtext: string;
  updatedAt: string;
  user: {
    firstName: string;
    lastName: string;
    email: string;
    profileImage: string;
    uuid: string;
  };
  uuid: string;
};


export type GoogleReviewSummaryType = {
  name: string,
  uuid: string,
  count: number
}

export type GoogleReview = {
  uuid: string
  url: string
  review_snippet: string
  review_rating: number
  review_likes: number
  review_details: any
  raw_results: any
  has_been_reviewed: boolean
  external_id: string
  date_found: string
  contributor_thumbnail: string
  contributor_number_reviews: string
  contributor_name: string
  contributor_local_guide: boolean
  contributor_link: string
  contributor_id: string
  cobu_review: boolean
  building: string,
  images: string[]
}

export type GoogleReviewDetailState = {
  review: GoogleReview | null,
  activeResident: AttributionUser | null,
  loading: boolean,
  loadingString: string,
  residents: AttributionUser[],
}

export type AttributeReviewType = {
  userId: string,
  userName: string,
  uuid: string,
  isCobu?: boolean,
  notes?: string
  type?: string
}

export type GoogleInvitationSettings = {
  uuid: string;
  building?: string;
  minsentimentscore: number;
  hourofday: number;
  recentactivitydaythreshold: number;
  oldactivitymonththreshold: number;
  activeuserdaythreshold: number;
  reviewquotadivisor: number;
  maxreviewrequestsperday: number;
  invitationsentwithindays: number;
  eligibleresidentactivitydays: number;
  eligibleresidentcreateddatedays: number;
  eligiblegroupcommentsmonths: number;
  allowednegitivecomments: number;
  multiplierrecenetcomments: number;
  multiplieroldcomments: number;
  multiplierrecentgreatcomments: number;
  multiplieroldgreatcomments: number;
  multiplierrecentrsvps: number;
  multiplieroldrsvps: number;
}

export type ReviewAlgorithmResult = {
  firstName: string;
  lastName: string;
  phone: string;
  building: string;
  buildingVCM: number;
  buildingName: string;
  reviewUrl: string;
  userId: string;
  score: number;
  recentComments: number;
  oldComments: number;
  recentGreatComments: number;
  oldGreatComments: number;
  recentRsvps: number;
  oldRsvps: number;
  residentScore: number;
  negativeComments: number;
  isSMS: boolean
}


export enum ReviewAlgorithmResultAccessor {
  firstName = 'firstName',
  lastName = 'lastName',
  recentComments = 'recentComments',
  oldComments = 'oldComments',
  recentGreatComments = 'recentGreatComments',
  oldGreatComments = 'oldGreatComments',
  recentRsvps = 'recentRsvps',
  oldRsvps = 'oldRsvps',
  residentScore = 'residentScore',
  negativeComments = 'negativeComments',
  score = 'score',
}