function termsURL(): string {
  return 'https://intercom.help/cobu/en/collections/2503886-member-agreement-privacy-policy-and-code-of-conduct';
}

function imgixURL(name: string): string {
  return `https://assets.imgix.net/~text?fm=png&txt-size=100&txt-fit=max&w=800&txt-font=Avenir+Next+Condensed,Bold&txt=${encodeURIComponent(
    name
  )}&txt-pad=30&txt-color=fff&h=575&txt-align=center,middle`;
}

function qrCodeGeneratorURL(data: string) {
  return `https://api.qrserver.com/v1/create-qr-code/?data=${data}&amp;size=100x100`;
}

const defaultProfilePicture =
  'https://s3.amazonaws.com/doorbell-test1/c222370d196a41282aad2672b3c37e31_profile.png';

const removalReasonsForHideOrDeleteComment = [
  {
    value: 'ANTI_PM',
    text: 'Anti-PM post (e.g. "the f****king building management sucks").'
  },
  {
    value: 'COC_VIOLATION',
    text:
      'Code of Conduct Violation (e.g. "Bob you and the other liberals in this building need to get a life").'
  },
  {
    value: 'COMPLAINT',
    text:
      'Complaint or negative post (e.g. "The pool is green today what gives?").'
  },
  { value: 'NONE', text: 'None of the above (e.g. cleanup of old VCM posts).' }
];

export enum ReviewType {
  cobu = 'cobu',
  notCobu = 'not-cobu',
  unknown = 'unknown'
}
const reviewReasonsOfCobu = [
  {
    value: ReviewType.cobu,
    text:
      'Matched the writer to a Cobu user, And we are  taking credit for the review.'
  },
]

const reviewReasonsOfNotCobu = [
  {
    value: ReviewType.unknown,
    text: `Can't match the writer with a Cobu user`
  },
  {
    value: ReviewType.notCobu,
    text:
      'Matched the writer to a Cobu user, but we are not taking credit for the review.'
  },

];

const possibleFilters = [12, 24, 36, 48, 168];

const defaultTimeFilter = 0;

const removalReasonsForResidentScoreList = [
  { value: 'HIGH_SCORE', text: 'Score calculation - score is too high' },
  { value: 'INACCURATE_DATA', text: 'Actitvity data is inaccurate' },
  { value: 'ALREADY_REVIEWED', text: 'Resident already left a review' },
  {
    value: 'NOT_WANT_TO_BE_CONTACTED',
    text: 'Resident does not want to be contacted'
  },
  { value: 'OTHER', text: 'Other' }
];

const removalReasonsForResidentsWithRIList = [
  {
    value: 'NOT_WANT_TO_BE_CONTACTED',
    text: 'Resident does not want to be contacted'
  },
  { value: 'OTHER', text: 'Other' }
];
const limitPerPage = 15;

export {
  termsURL,
  imgixURL,
  qrCodeGeneratorURL,
  defaultProfilePicture,
  defaultTimeFilter,
  removalReasonsForHideOrDeleteComment,
  possibleFilters,
  removalReasonsForResidentScoreList,
  removalReasonsForResidentsWithRIList,
  limitPerPage,
  reviewReasonsOfCobu,
  reviewReasonsOfNotCobu
};
