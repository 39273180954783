import React from "react";
import CobuConstants from "~/helpers/cobuConstants";
import { AttributionUser } from "~/types/user";
import CobuTableAdvanced from "../CobuTableAdvanced/CobuTableAdvanced";
import { CellProps } from "react-table";
import WrappableText from "../CollapsibleText/WrappableText";
import moment from "moment";
import Checkbox from "../CheckBox/CheckBox";
import classes from './CustomDropDown.module.css'

type IProps = {
    users: AttributionUser[];
    onSelect: ((user: AttributionUser) => void);
};

const CustomUserDropDown = ({ users, onSelect }: IProps) => {

    // Configuration for the table columns
    const googleReviewTableConfig = [
        {
            Header: 'RESIDENT',
            accessor: 'name',
            Cell: (cell: CellProps<AttributionUser>) => (
                <div className="flexRow center-align">
                    <img
                        className='avatar margin-right-8'
                        alt='avatar'
                        src={cell.row.original.profileImage}
                    />
                    <p className='default-text weight-500 font-16'>
                        {`${cell.row.original.fullName}`}
                    </p>
                </div>
            ),
        },
        {
            Header: 'EMAIL',
            accessor: 'email',
            Cell: (cell: CellProps<AttributionUser>) => (
                <WrappableText text={cell.row.original.email} />
            )
        },
        {
            Header: 'INVITED',
            Cell: (cell: CellProps<AttributionUser>) => (
                <div className={`${classes.invitecell}`} style={{ backgroundColor: cell.row.original.reviewinvitationtype?.button.background }}>
                    <p className='default-text weight-500 font-16' style={{ color: cell.row.original.reviewinvitationtype?.button.text, textAlign: 'center' }}>{cell.row.original.reviewinvitationtype ? cell.row.original.reviewinvitationtype.name : ''}</p>
                </div>
            )
        },
        {
            Header: 'DATE',
            accessor: 'createdAt',
            Cell: (cell: CellProps<AttributionUser>) => (
                <div>
                    {cell.row.original.reviewInvitationDate ? moment(cell.row.original.reviewInvitationDate)
                        .local()
                        .format('MM/DD/YYYY') : ''}
                </div>
            )
        },
        {
            Header: 'REMINDER',
            Cell: (cell: CellProps<AttributionUser>) => (
                <div>
                    {cell.row.original.details?.reminderDate ? moment(cell.row.original.details?.reminderDate)
                        .local()
                        .format('MM/DD/YYYY') : ''}
                </div>
            )
        },
        {
            Header: 'CURRENT',
            accessor: 'deactivated',
            Cell: (cell: CellProps<AttributionUser>) => (
                <div className='center-aligned '>
                    {cell.row.original.deactivated ? null : (
                        <Checkbox
                            index={0}
                            option={{
                                text: "",
                                value: ""
                            }}
                            selectedOption={""}
                            setSelectedOption={() => { }}
                        />
                    )}
                </div>
            )
        },
        {
            Header: '',
            accessor: 'select',
            Cell: (cell: CellProps<AttributionUser>) => {
                let user = cell.row.original;
                return (
                    <button
                        className={user.selected ? 'btn-standard btn-secondary' : 'btn-standard btn-primary margin-left-16'}
                        onClick={() => {
                            onSelect(user);
                        }}
                    >
                        {user.selected ? CobuConstants.googleReview.deselect : CobuConstants.googleReview.select}
                    </button>
                );
            },
        },
    ];

    // Function to generate the CobuTableAdvanced instance
    const getCobuTableInstance = (data: AttributionUser[], placeholder: string) => (
        <CobuTableAdvanced
            columns={googleReviewTableConfig}
            data={data}
            mini
            height={350}
            noData={{
                label: placeholder,
                action: undefined,
                image: undefined
            }}
            search={{
                searchFields: ['fullName', 'email'],
                placeholder: 'Name or Email',
                showFull: true
            }}
            actionsStyle={{ top: '220px', right: '120px' }}
        />
    );

    return (
        <div>
            {getCobuTableInstance(users, CobuConstants.googleReview.noresidents)}
        </div>
    );
};

export default CustomUserDropDown;
