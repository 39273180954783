import React, { FC, useState } from 'react';
import CobuConstants from '~/helpers/cobuConstants';
import { EditBuildingAttributeForm } from './EditBuildingAttributeForm';
import GenericModal from '~/components/GenericModal/GenericModal';

interface IProps {
  buildingId: string;
  onEditBuilding: () => Promise<void>;
  showEditSetting?: boolean;
}

export const EditBuildingKeys: FC<IProps> = ({
  buildingId,
  onEditBuilding,
  showEditSetting = false
}) => {
  const [showEditBuildingForm, setShowEditBuildingForm] =
    useState(showEditSetting);

  const toggleShowBuildingForm = () => {
    setShowEditBuildingForm((prevState) => !prevState);
  };

  return (
    <>
      <button
        className='btn-standard btn-primary'
        onClick={toggleShowBuildingForm}
      >
        Edit Building Keys
      </button>
      <GenericModal
        showModal={showEditBuildingForm}
        title={'Edit Attributes'}
        enableOverflowScrolls
        content={
          showEditBuildingForm ? (
            <EditBuildingAttributeForm
              buildingId={buildingId}
              onCancel={toggleShowBuildingForm}
              onEditBuilding={onEditBuilding}
            />
          ) : null
        }
        hideActionButton
        canceActionButton
        actionText={CobuConstants.createBuilding.editActionTitle}
        actionFunction={() => {}}
        onChange={() => {}}
      />
    </>
  );
};
