import { emperor } from "~/services";
import { CreateSurveyBody } from "~/types/surveys";
import { CreateToolTipBody } from "~/types/tooltip";

export const getAllActiveTooltips = async () => {
    return await emperor.get('tooltip');
}

export const deactivateTooltips = async (surveyID: string) => {
    return await emperor.patch(`tooltip/deactivate`, { uuid: surveyID });
}

export const createBuildingTooltip = async (survey: CreateToolTipBody) => {
    return await emperor.post('tooltip',survey);
}