import React, { useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Breadcrumbs from '~/components/Breadcrumbs/Breadcrumbs';
import { FormField } from '~/components/FormField';
import Spinner from '~/components/Spinner/Spinner';
import useBuildingAddress from '~/hooks/building/useBuildingAddress';
import { IBuildingAddress } from '~/types/building';
import { FormInputConfig } from '~/types/residentManagement';
import { buildingAddressFormConfig } from './constants';
import './EditBuildingAddress.css';

export const EditBuildingAddress = () => {
  const {
    register,
    handleSubmit,
    errors,
    control,
    reset,
    formState: { isDirty, dirtyFields }
  } = useForm<IBuildingAddress>({
    defaultValues: {}
  });
  const { buildingId } = useParams<{ buildingId: string }>();

  const { loader, address, onPutAddress } = useBuildingAddress(buildingId);

  useEffect(() => {
    if (address) {
      reset(address);
    }
  }, [address]);

  const generateField = (fieldConfig: FormInputConfig) => {
    return (
      <div key={fieldConfig.id}>
        <div className='item'>
          {!!fieldConfig?.label && (
            <label
              style={fieldConfig?.styles?.lableStyles}
              className={`${fieldConfig?.styles?.lableClassName}`}
              htmlFor='title'
            >
              {fieldConfig.label}
            </label>
          )}
          <FormField
            fieldConfig={fieldConfig}
            errors={errors}
            register={register}
            control={control}
          />
        </div>
      </div>
    );
  };

  const onSubmit: SubmitHandler<IBuildingAddress> = (buildingAddress) => {
    if (!isDirty) {
      toast('Please edit the form');

      return;
    }

    onPutAddress(buildingAddress);
  };
  return (
    <div className='float-spinner-parent container'>
      {loader && <Spinner float />}
      <Breadcrumbs />
      <form id='form-container' onSubmit={handleSubmit(onSubmit)}>
        <div className='grid'>
          {buildingAddressFormConfig.map((field) => generateField(field))}
        </div>
        <input
          className='btn-standard btn-primary margin-top-32'
          type='submit'
        />
      </form>
    </div>
  );
};
