import { Colors } from '~/enums/Colors';

function configureCommentSentimentData(
  sentiment: {
    positive: number;
    negative: number;
    neutral: number;
  },
  buildingId: string
) {
  const sentimentData: {
    selector: string;
    title: string;
    color: string;
    description?: string;
    statsValue: string;
    footerLinkText?: string;
    footerLink?: string;
  }[] = [
    {
      selector: 'positiveSentiment',
      title: 'Positive',
      color: Colors.cobuYellow,
      statsValue: sentiment.positive.toString(),
      footerLink: `/building/${buildingId}/sentiment/positive`
    },
    {
      selector: 'negativeSentiment',
      title: 'Moderated',
      color: Colors.cobuRed,
      statsValue: sentiment.negative.toString(),
      footerLink: `/building/${buildingId}/sentiment/negative`
    },
    {
      selector: 'neutralSentiment',
      title: 'Neutral',
      color: Colors.cobuTurquoise,
      statsValue: sentiment.neutral.toString(),
      footerLink: `/building/${buildingId}/sentiment/neutral`
    }
  ];
  return sentimentData;
}

export { configureCommentSentimentData };
