import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAssignmentTagsRequest,
  getOwnerGroupAssetsRequest,
  getOwnerGroupGoogleReviewRequest
} from '~/api/assets';
import { RootState } from '~/redux/store';
import {
  AssetAccessors,
  AssetGoogleReviewAccessor,
  AssignmentTagAccessor,
  IAssignmentTag,
  IOwnerGroupAsset,
  IOwnerGroupGoogleRevew
} from '~/types/assets';
import { CellProps } from 'react-table';
import CobuConstants from '~/helpers/cobuConstants';
import { toTitleCase, truncateString } from '~/utils/stringUtils';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Roles } from '~/enums/Roles';
import { setOwnerGroupProperties } from '~/reducers/assets';
import moment from 'moment';
import Axios, { CancelToken } from 'axios';
import { StatusIndicator } from '~/components/StatusIndicator/StatusIndicator';
import { ASSIGNMENT_TAGS, defaultTagAssignments } from '~/enums/Assets';

export const useOwnerGroupAsssets = (
  ownerId?: string,
  isFromOwnerGroup?: boolean
) => {
  //hooks
  const history = useHistory();
  const dispatch = useDispatch();

  const [assignmentTags, setAssignmentTags] = useState<null | IAssignmentTag[]>(
    null
  );
  const [selectedAssignmentTag, setSelectedAssignmentTag] =
    useState<IAssignmentTag | null>(defaultTagAssignments[0]);
  const [loading, setLoading] = useState(false);
  const role = useSelector((state: RootState) => {
    return state.auth.userRole;
  });
  const [ownerGroupWiseAssets, setOwnerGroupWiseAssets] = useState<
    IOwnerGroupAsset[] | null
  >(null);
  const selectedOwnerGroupProperties = useSelector(
    (state: RootState) => state.assetManager.selectedOwnerGroupProperties
  );

  const isVCMorSuperAdmin = role === Roles.Superadmin || role === Roles.Vcm;

  const getOwnerAssets = async (cancelToken?: CancelToken) => {
    try {
      setLoading(true);
      const res = await getOwnerGroupAssetsRequest({}, cancelToken);
      setLoading(false);

      setOwnerGroupWiseAssets(res.data);
    } catch (e) {
      setLoading(false);
      if (e.message) {
        toast(e.message);
      }
    }
  };

  const getOwnerGrpProperties = async (cancelToken?: CancelToken) => {
    try {
      setLoading(true);
      const res = await getOwnerGroupAssetsRequest(
        { ownerGroupId: ownerId },
        cancelToken
      );
      setLoading(false);

      dispatch(setOwnerGroupProperties(res.data));
    } catch (e) {
      setLoading(false);
      if (e.message) {
        toast(e.message);
      }
    }
  };

  const getPmGrpProperties = async (cancelToken?: CancelToken) => {
    try {
      setLoading(true);
      const res = await getOwnerGroupAssetsRequest(
        { propertyMngGroupId: ownerId },
        cancelToken
      );
      setLoading(false);

      dispatch(setOwnerGroupProperties(res.data));
    } catch (e) {
      setLoading(false);
      if (e.message) {
        toast(e.message);
      }
    }
  };

  const getAssignmentTags = async (cancelToken?: CancelToken) => {
    try {
      const res = await getAssignmentTagsRequest(cancelToken);

      setAssignmentTags([...defaultTagAssignments, ...res.data]);
    } catch (e) {
      if (e.message) {
        toast(e.message);
      }
    }
  };

  useEffect(() => {
    const ownerSource = Axios.CancelToken.source();
    const tagSource = Axios.CancelToken.source();

    //home page -> asset overview page
    if (!ownerId) {
      getOwnerAssets(ownerSource.token);
      if (isVCMorSuperAdmin) {
        getAssignmentTags(tagSource.token);
      }

      return;
    }

    //google review -> owner base property view
    if (!selectedOwnerGroupProperties) {
      if (isFromOwnerGroup) {
        getOwnerGrpProperties(ownerSource.token);
      } else {
        getPmGrpProperties(ownerSource.token);
      }
    }

    return () => {
      ownerSource.cancel('Request canceled by cleanup');
    };
  }, []);

  const onOwnerGroup = (
    ownerType: AssetAccessors.pmOwnerGroup | AssetAccessors.ownerGroup,
    ownerId: string,
    ownerName: string
  ) => {
    if (!ownerGroupWiseAssets) {
      return;
    }

    const ownerProperties = ownerGroupWiseAssets.filter(
      (ownerAssets: IOwnerGroupAsset) => {
        if (ownerType === AssetAccessors.pmOwnerGroup) {
          return ownerAssets[AssetAccessors.pmOwnerGroup]?.uuid === ownerId;
        }

        return ownerAssets[AssetAccessors.ownerGroup]?.uuid === ownerId;
      }
    );

    dispatch(setOwnerGroupProperties(ownerProperties));

    history.push(
      CobuConstants.navUrls.assetGoogleReviewLink(
        ownerId,
        ownerName,
        ownerType === AssetAccessors.ownerGroup
      )
    );
  };

  const onAssignmentTag = (assignmentTag: IAssignmentTag) => {
    setSelectedAssignmentTag(assignmentTag);
  };

  const isNotPmRoleAndOwnerGroup = role !== Roles.Pm && !ownerId;

  const assetManagementTableConfig = useMemo(() => {
    return [
      {
        Header: 'PROPERTY',
        accessor: AssetAccessors.buildingName,
        Cell: (cell: CellProps<IOwnerGroupAsset>) => {
          return (
            <a
              href={CobuConstants.navUrls.buildingDashboardLink(
                cell.row.original[AssetAccessors.buildingId]
              )}
              className='default-text'
            >
              <div style={{ whiteSpace: 'normal' }} className='default-text'>
                {cell.row.original[AssetAccessors.buildingName]}
              </div>
            </a>
          );
        }
      },
      {
        Header: 'PROPERTY IMAGE',
        accessor: AssetAccessors.buildingImage,
        Cell: (cell: CellProps<IOwnerGroupAsset>) => {
          return (
            <img
              style={{ width: 100, height: 60, borderRadius: 10 }}
              alt='avatar'
              src={cell.row.original[AssetAccessors.buildingImage]}
            />
          );
        }
      },
      ...(isNotPmRoleAndOwnerGroup
        ? [
            {
              Header: 'OWNER GROUP',
              accessor: AssetAccessors.ownerGroup,
              Cell: (cell: CellProps<IOwnerGroupAsset>) => {
                return (
                  <div
                    onClick={(e) => {
                      if (cell.row.original[AssetAccessors.ownerGroup]?.uuid) {
                        onOwnerGroup(
                          AssetAccessors.ownerGroup,
                          cell.row.original[AssetAccessors.ownerGroup]
                            ?.uuid as string,
                          cell.row.original[AssetAccessors.ownerGroup]
                            ?.name as string
                        );
                      }
                    }}
                    className={`default-text cursor-pointer ${
                      cell.row.original[AssetAccessors.ownerGroup]?.uuid
                        ? 'underline'
                        : ''
                    }`}
                  >
                    {toTitleCase(
                      truncateString(
                        cell.row.original[AssetAccessors.ownerGroup]?.name ||
                          '--'
                      )
                    )}
                  </div>
                );
              }
            },
            {
              Header: 'PROPERTY MNG GROUP',
              accessor: AssetAccessors.pmOwnerGroup,
              Cell: (cell: CellProps<IOwnerGroupAsset>) => {
                return (
                  <div
                    onClick={(e) => {
                      if (
                        cell.row.original[AssetAccessors.pmOwnerGroup]?.uuid
                      ) {
                        onOwnerGroup(
                          AssetAccessors.pmOwnerGroup,
                          cell.row.original[AssetAccessors.pmOwnerGroup]
                            ?.uuid as string,
                          cell.row.original[AssetAccessors.pmOwnerGroup]
                            ?.name as string
                        );
                      }
                    }}
                    className={`default-text cursor-pointer ${
                      cell.row.original[AssetAccessors.pmOwnerGroup]?.uuid
                        ? 'underline'
                        : ''
                    } `}
                  >
                    {toTitleCase(
                      truncateString(
                        cell.row.original[AssetAccessors.pmOwnerGroup]?.name ||
                          '--'
                      )
                    )}
                  </div>
                );
              }
            }
          ]
        : []),
      {
        Header: 'AVG. RATING',
        accessor: AssetAccessors.avgReviewRating,
        Cell: (cell: CellProps<IOwnerGroupAsset>) =>
          cell.value ? Number(cell.value).toFixed(1) : '--'
      },
      {
        Header: 'ADOPTION',
        accessor: AssetAccessors.adoptionRate,
        Cell: (cell: CellProps<IOwnerGroupAsset>) => {
          const currentDate = moment();

          const isBuildingLiveMoreThan3Months = cell.row.original[
            AssetAccessors.buildingGoLive
          ]
            ? currentDate.diff(
                moment(cell.row.original[AssetAccessors.buildingGoLive]),
                'months'
              )
            : false;

          return cell.value ? (
            <StatusIndicator
              indicatorFunctions={{
                ...(isBuildingLiveMoreThan3Months
                  ? { green: (val) => val >= 53 }
                  : {})
              }}
              value={cell.value}
            >
              {`${cell.value}%`}
            </StatusIndicator>
          ) : (
            '--'
          );
        }
      },
      {
        Header: 'ACTIVE RATE',
        accessor: AssetAccessors.activeRate,
        Cell: (cell: CellProps<IOwnerGroupAsset>) => {
          const currentDate = moment();

          const isBuildingLiveMoreThan3Months = cell.row.original[
            AssetAccessors.buildingGoLive
          ]
            ? currentDate.diff(
                moment(cell.row.original[AssetAccessors.buildingGoLive]),
                'months'
              )
            : false;
          return cell.value ? (
            <StatusIndicator
              indicatorFunctions={{
                ...(isBuildingLiveMoreThan3Months
                  ? { green: (val) => val >= 53 }
                  : {})
              }}
              value={cell.value}
            >
              {`${cell.value}%`}
            </StatusIndicator>
          ) : (
            '--'
          );
        }
      },
      {
        Header: 'PM EVENTS LAST 30 DAYS',
        accessor: AssetAccessors.pmEventsLast30days,
        Cell: (cell: CellProps<IOwnerGroupAsset>) => {
          return cell.value ? (
            <StatusIndicator
              indicatorFunctions={{
                red: (val) => val <= 0,
                green: (val) => val >= 1
              }}
              value={cell.value}
            >
              {cell.value}
            </StatusIndicator>
          ) : (
            '--'
          );
        }
      },
      {
        Header: 'EVENTS LAST 60 DAYS',
        accessor: AssetAccessors.eventsLast60days,
        Cell: (cell: CellProps<IOwnerGroupAsset>) => {
          return cell.value ? (
            <StatusIndicator
              indicatorFunctions={{
                red: (val) => val <= 0,
                yellow: (val) => val === 1,
                green: (val) => val >= 2
              }}
              value={cell.value}
            >
              {cell.value}
            </StatusIndicator>
          ) : (
            '--'
          );
        }
      },
      {
        Header: 'REVIEW LAST 60 DAYS',
        accessor: AssetAccessors.allReviewCount60Days,
        Cell: (cell: CellProps<IOwnerGroupAsset>) => cell.value || '--'
      },
      {
        Header: 'COBU REVIEW LAST 60 DAYS',
        accessor: AssetAccessors.cobuReviewCount60Days,
        Cell: (cell: CellProps<IOwnerGroupAsset>) =>
          cell.value
            ? `${cell.value}\xa0\xa0\xa0${(
                (Number(cell.value) /
                  Number(
                    cell.row.original[AssetAccessors.allReviewCount60Days]
                  )) *
                100
              ).toFixed(0)}%`
            : '--'
      },
      {
        Header: 'UNASSIGNED REVIEWS',
        accessor: AssetAccessors.unassignedReviews60Days,
        Cell: (cell: CellProps<IOwnerGroupAsset>) => cell.value || '--'
      },
      ...(isVCMorSuperAdmin
        ? [
            {
              Header: 'TAG NAME',
              accessor: AssetAccessors.tagName,
              Cell: (cell: CellProps<IOwnerGroupAsset>) => cell.value || '--'
            }
          ]
        : [])
    ];
  }, [ownerGroupWiseAssets]);

  const filteredAssets = useMemo(() => {
    return (
      isFromOwnerGroup ? selectedOwnerGroupProperties : ownerGroupWiseAssets
    )?.filter((ownerAsset) => {
      if (selectedAssignmentTag) {
        if (
          selectedAssignmentTag[AssignmentTagAccessor.tagName] ===
          ASSIGNMENT_TAGS.all
        ) {
          return true;
        }

        if (
          selectedAssignmentTag[AssignmentTagAccessor.tagName] ===
          ASSIGNMENT_TAGS.unassigned
        ) {
          return !ownerAsset[AssetAccessors.tagName];
        }

        return (
          ownerAsset[AssetAccessors.tagUserId] === selectedAssignmentTag.uuid
        );
      }

      return true;
    });
  }, [
    selectedAssignmentTag,
    ownerGroupWiseAssets,
    selectedOwnerGroupProperties
  ]);

  return {
    ownerGroupWiseAssets: filteredAssets,
    assignmentTags,
    assetManagementTableConfig,
    onAssignmentTag,
    loading,
    selectedOwnerGroupProperties: filteredAssets,
    selectedAssignmentTag,
    isVCMorSuperAdmin
  };
};

export const useOwnerGroupGoogleReviews = (
  ownerId: string,
  isFromOwnerGroup?: boolean
) => {
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [ownerPropertiesGoogleReviews, setOwnerPropertiesGoogleReviews] =
    useState<null | IOwnerGroupGoogleRevew[]>(null);

  const fetchOwnerPropertiesGoogleReview = async (
    cancelToken?: CancelToken
  ) => {
    try {
      setLoading(true);
      const res = await getOwnerGroupGoogleReviewRequest(
        { ownerGroupId: ownerId },
        cancelToken
      );
      setLoading(false);

      setOwnerPropertiesGoogleReviews(res.data);
    } catch (e) {
      if (e.message) {
        toast(e.message);
      }
    }
  };

  const fetchPmPropertiesGoogleReview = async (cancelToken?: CancelToken) => {
    try {
      setLoading(true);
      const res = await getOwnerGroupGoogleReviewRequest(
        { propertyMngGroupId: ownerId },
        cancelToken
      );
      setLoading(false);

      setOwnerPropertiesGoogleReviews(res.data);
    } catch (e) {
      if (e.message) {
        toast(e.message);
      }
    }
  };

  useEffect(() => {
    const source = Axios.CancelToken.source();

    if (isFromOwnerGroup) {
      fetchOwnerPropertiesGoogleReview(source.token);
    } else {
      fetchPmPropertiesGoogleReview(source.token);
    }

    return () => {
      source.cancel('Request canceled by cleanup');
    };
  }, []);

  const googleReviewTableConfig = useMemo(
    () => [
      {
        Header: 'PROPERTY MNG GROUP',
        accessor: AssetGoogleReviewAccessor.pmOwnerGroup,
        Cell: (cell: CellProps<IOwnerGroupGoogleRevew>) =>
          truncateString(
            cell.row.original[AssetAccessors.pmOwnerGroup]?.name || '--'
          )
      },
      {
        Header: 'PROPERTY',
        accessor: AssetGoogleReviewAccessor.buildingName,
        Cell: (cell: CellProps<IOwnerGroupAsset>) => (
          <div style={{ whiteSpace: 'normal' }} className='default-text'>
            {cell.row.original[AssetAccessors.buildingName]}
          </div>
        )
      },

      {
        Header: 'GOOGLE STAR RATING',
        accessor: AssetGoogleReviewAccessor.avgReviewRating,
        Cell: (cell: CellProps<IOwnerGroupGoogleRevew>) =>
          cell.value ? Number(cell.value).toFixed(1) : '--'
      },
      {
        Header: 'COBU REVIEWS START DATE',
        accessor: AssetGoogleReviewAccessor.startReviewOutreach,
        Cell: (cell: CellProps<IOwnerGroupGoogleRevew>) => {
          if (cell.value) {
            return truncateString(moment(cell.value).format('MMM YYYY'));
          }

          return (
            <button
              onClick={() => {
                history.push(
                  `/building/${
                    cell.row.original[AssetGoogleReviewAccessor.buildingId]
                  }/edit?showEditSetting=true`
                );
              }}
              className='btn btn-standard btn-primary'
            >
              Add Outreach Date
            </button>
          );
        }
      },
      {
        Header: 'COBU REVIEWS MONTHS LIVE',
        accessor: '',
        Cell: (cell: CellProps<IOwnerGroupGoogleRevew>) => {
          if (
            !cell.row.original[AssetGoogleReviewAccessor.startReviewOutreach]
          ) {
            return '--';
          }

          const currentDate = moment();
          const googleReviewStartDate = moment(
            cell.row.original[AssetGoogleReviewAccessor.startReviewOutreach]
          );

          const monthsDifference = currentDate.diff(
            googleReviewStartDate,
            'months'
          );

          return monthsDifference;
        }
      },
      {
        Header: '#COBU REVIEWS*',
        accessor: AssetGoogleReviewAccessor.cobuReviews,
        Cell: (cell: CellProps<IOwnerGroupGoogleRevew>) => cell.value || '--'
      },
      {
        Header: '#NON COBU REVIEWS*',
        accessor: '',
        Cell: (cell: CellProps<IOwnerGroupGoogleRevew>) =>
          Number(
            cell.row.original[AssetGoogleReviewAccessor.totalGoogleReviews]
          ) -
            Number(cell.row.original[AssetGoogleReviewAccessor.cobuReviews]) ||
          '--'
      },
      {
        Header: '#TOTAL REVIEWS*',
        accessor: AssetGoogleReviewAccessor.totalGoogleReviews,
        Cell: (cell: CellProps<IOwnerGroupGoogleRevew>) => cell.value || '--'
      },
      {
        Header: 'COBU REVIEWS AS % OF TOTAL',
        accessor: '',
        Cell: (cell: CellProps<IOwnerGroupGoogleRevew>) => {
          if (
            cell.row.original[AssetGoogleReviewAccessor.cobuReviews] &&
            cell.row.original[AssetGoogleReviewAccessor.totalGoogleReviews]
          ) {
            const percentage = (
              (Number(
                cell.row.original[AssetGoogleReviewAccessor.cobuReviews]
              ) /
                Number(
                  cell.row.original[
                    AssetGoogleReviewAccessor.totalGoogleReviews
                  ]
                )) *
              100
            ).toFixed(1);

            return (
              <StatusIndicator
                value={percentage}
                indicatorFunctions={{ green: (val) => val >= 20 }}
              >
                {`${percentage}%`}
              </StatusIndicator>
            );
          } else {
            return '--';
          }
        }
      },
      {
        Header: 'COBU REVIEW STAR RATING',
        accessor: AssetGoogleReviewAccessor.cobuReviewStarRating,
        Cell: (cell: CellProps<IOwnerGroupGoogleRevew>) => {
          return cell.value ? (
            <StatusIndicator
              value={Number(cell.value).toFixed(1)}
              indicatorFunctions={{
                red: (val) => val < 4.0,
                yellow: (val) => val >= 4 && val < 4.5,
                green: (val) => val >= 4.5
              }}
            >
              {Number(cell.value).toFixed(1)}
            </StatusIndicator>
          ) : (
            '--'
          );
        }
      },
      {
        Header: 'NON COBU REVIEW STAR RATING',
        accessor: AssetGoogleReviewAccessor.nonCobuReviewStarRating,
        Cell: (cell: CellProps<IOwnerGroupGoogleRevew>) => {
          return cell.value ? (
            <StatusIndicator
              value={Number(cell.value).toFixed(1)}
              indicatorFunctions={{
                red: (val) => val < 4.0,
                yellow: (val) => val >= 4 && val < 4.5,
                green: (val) => val >= 4.5
              }}
            >
              {Number(cell.value).toFixed(1)}
            </StatusIndicator>
          ) : (
            '--'
          );
        }
      },
      {
        Header: 'COBU REVIEW % W/COMMENTS',
        accessor: AssetGoogleReviewAccessor.allReviewsWithSnippet,
        Cell: (cell: CellProps<IOwnerGroupGoogleRevew>) => {
          if (
            Number(cell.row.original[AssetGoogleReviewAccessor.cobuReviews]) &&
            Number(
              cell.row.original[
                AssetGoogleReviewAccessor.cobuReviewsWithSnippet
              ]
            )
          ) {
            const percentage = (
              (Number(
                cell.row.original[
                  AssetGoogleReviewAccessor.cobuReviewsWithSnippet
                ]
              ) /
                Number(
                  cell.row.original[AssetGoogleReviewAccessor.cobuReviews]
                )) *
              100
            ).toFixed(1);

            return `${percentage}%`;
          } else {
            return '--';
          }
        }
      },
      {
        Header: 'NON COBU REVIEW % W/COMMENTS',
        accessor: '',
        Cell: (cell: CellProps<IOwnerGroupGoogleRevew>) => {
          if (
            Number(
              cell.row.original[AssetGoogleReviewAccessor.totalGoogleReviews]
            ) -
              Number(
                cell.row.original[AssetGoogleReviewAccessor.cobuReviews]
              ) &&
            Number(
              cell.row.original[
                AssetGoogleReviewAccessor.nonCobuReviewsWithSnippet
              ]
            )
          ) {
            const percentage = (
              (Number(
                cell.row.original[
                  AssetGoogleReviewAccessor.nonCobuReviewsWithSnippet
                ]
              ) /
                (Number(
                  cell.row.original[
                    AssetGoogleReviewAccessor.totalGoogleReviews
                  ]
                ) -
                  Number(
                    cell.row.original[AssetGoogleReviewAccessor.cobuReviews]
                  ))) *
              100
            ).toFixed(1);

            return `${percentage}%`;
          } else {
            return '--';
          }
        }
      }
    ],
    []
  );

  return {
    loading,
    ownerPropertiesGoogleReviews,
    googleReviewTableConfig
  };
};
