import React from 'react';
import Logo from '~/utils/images/logo.svg';

const LogoLarge = () => {
  return (
    <div className='cobu-container'>
      <img className='cobu-logo' src={Logo} alt='cobu logo' />
      <div className='cobu-text login-text'>
        Helps you meet your neighbors and build community where you live.
      </div>
    </div>
  );
};

export default LogoLarge;
