import React, { BaseSyntheticEvent, CSSProperties, ReactElement } from 'react';

import './style.css';

export type TabOption = { value: string; label: string };

type OptionProps = {
  value: string;
  children: any;
  selected?: boolean;
};

export const Option = (props: OptionProps) => {
  return (
    <button
      key={props.value}
      value={props.value}
      className={props.selected ? 'tab-selected' : ''}
      // onClick={() => {
      //   props.onChange(option);
      // }}
    >
      {props.children}
    </button>
  );
};

type TabsProps = {
  value?: string;
  onChange: (value: string) => void;
  children: ReactElement[];
  isOnChangePreventDefault?: boolean;
  styles?: CSSProperties;
};

export const Container = (props: TabsProps) => {
  return (
    <div
      style={props.styles}
      className='tabs-container pointer'
      onClick={(e: BaseSyntheticEvent<any>) => {
        if (props.isOnChangePreventDefault) {
          e.preventDefault();
        }

        props.onChange(e.target?.value as string);
      }}
    >
      {props.children}
    </div>
  );
};

export const Tabs = { Container, Option };
