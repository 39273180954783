import { emperor } from '~/services';
import { BuildingEntrataIntegration } from '~/types/buildingEntrataIntegration';
import { convertKeyValueToFormData } from '~/helpers/helpers';
import EntrataLeaseJsonToCSV from '~/services/EntrataLeaseJsonToCSV';
import { toast } from 'react-toastify';
import { BuildingDetails } from '~/types/building';
import { IReferralSettingEntrataPMS } from '~/containers/Referral/interfaces';
import { AxiosResponse } from 'axios';

export interface EntrataIntegratedBuilding {
  buildingId: string;
  integrationId: string;
  buildingName: string;
  domain: string;
  username: string;
  password: string;
  propertyId: string;
  ownerGroup: string;
  pmOwnerGroup: string;
  lastSyncStatus: 'SUCCESS' | 'FAILED' | 'SYNCING';
  lastSyncTime: Date;
  totalRecordsSynced: number;
  default: boolean;
  propertyOwnerGroup: string;
}

interface AddNewEntrataIntegrationPayload {
  domain: string;
  propertyId: string;
  username: string;
  password: string;
  building: string;
}

export const getEntrataIntegratedBuildings = async () => {
  return emperor.get<EntrataIntegratedBuilding[]>('/entrata/integrations');
};

export const addNewEntrataIntegration = async (payload: FormData) => {
  return await emperor.put(`/entrata-integration/buildings`, payload);
};

export const syncPmsIntegrationRentroll = async (
  integrationType: string,
  payload: { buildings: string[] }
) => {
  return await emperor.post(`/sync-rentroll/${integrationType}`, payload);
};

// BuildingEntrataIntegration
export const updateBuildingEntrataIntegration = async (
  buildingEntrataIntegration: BuildingEntrataIntegration
) => {
  const data = convertKeyValueToFormData(buildingEntrataIntegration);
  return emperor.patch(
    `buildingEntrataIntegration/${buildingEntrataIntegration.uuid}`,
    data
  );
};

export const deleteEntrataBuildingIntegration = async (
  integrationId: string
) => {
  return emperor.delete(`integrationbuildingentrata/${integrationId}`);
};

export const fetchBuildingEntrataIntegrationDetails = async (
  buildingId: string
) => {
  return emperor.get(`buildingEntrataIntegration/${buildingId}`);
};

export const fetchBuildingYardiIntegrationDetails = async (
  buildingId: string
) => {
  return emperor.get(`buildingYardiIntegration/${buildingId}`);
};

export const checkEntrataRentRollAccess = async (
  entrataConfig: IReferralSettingEntrataPMS
): Promise<AxiosResponse<any[]>> => {
  return await emperor.get('entrata/auth-rentroll-access', {
    params: entrataConfig
  });
};

export const getBuildingName = async (
  id: string
): Promise<AxiosResponse<any>> => {
  return await emperor.get(`public/building/${id}`);
};

export const getEntrataLeadList = async (buildingId: string) => {
  const entrataData = await emperor.get(`entrata/rentroll/${buildingId}`);

  if (entrataData.status === 200) {
    const buildingName = entrataData?.data?.buildingInfo.name;
    const buildingLink = entrataData?.data?.buildingInfo.link;
    const buildingCode = entrataData?.data?.buildingInfo.code;

    const { leases } = entrataData?.data;

    const csvStr = EntrataLeaseJsonToCSV(
      { lease: leases },
      buildingName,
      buildingId,
      buildingLink,
      buildingCode
    );

    // download CSV
    const hiddenElement = document.createElement('a');
    hiddenElement.href = `data:text/csv;charset=utf-8,${encodeURI(csvStr)}`;
    hiddenElement.target = '_blank';
    hiddenElement.download = `${buildingName}.csv`;
    hiddenElement.click();
  } else {
    toast('There was an error trying to download the entrata data!');
  }
};
