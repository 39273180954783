import moment from "moment"
import React, { useState } from "react"
import { CellProps } from "react-table"
import Breadcrumbs from "~/components/Breadcrumbs/Breadcrumbs"
import CobuTableAdvanced from "~/components/CobuTableAdvanced/CobuTableAdvanced"
import Spacer from "~/components/Spacer/Spacer"
import Spinner from "~/components/Spinner/Spinner"
import CobuConstants from "~/helpers/cobuConstants"
import useSurveys from "~/hooks/surveys/useSurveys"
import { BuildingSurvey } from "~/types/surveys"
import styles from './ActiveSurvey.module.css'
import GenericModal from "~/components/GenericModal/GenericModal"
import CreateSurveyForm from "~/components/CreateSurveyForm/CreateSurveyForm"
import { getCreateSurveyFormConfig } from "~/helpers/formHelpers"


const ActiveSurvey = () => {

    const { activeSurveys, loadingConfig, deactivateSurvey, createSurvey } = useSurveys()
    const [showSurveyCreateModal, setShowSurveyCreateModal] = useState<boolean>(false)

    /* UI Components */

    const activeSurveysTableConfig = [
        {
            Header: 'BUILDING',
            accessor: 'building.name'
        },
        {
            Header: 'DESCRIPTION',
            accessor: 'description',
            Cell: (cell: CellProps<BuildingSurvey>) => {
                return <div
                    style={{ whiteSpace: 'normal' }}
                    className='default-text'>
                    {cell.row.original.description}
                </div>
            },
        },
        {
            Header: 'TITLE',
            accessor: 'title',
            Cell: (cell: CellProps<BuildingSurvey>) => {
                return <div
                    style={{ whiteSpace: 'normal' }}
                    className='default-text'>
                    {cell.row.original.title}
                </div>
            },
        },
        {
            Header: 'SURVEY LINK',
            Cell: (cell: CellProps<BuildingSurvey>) => {

                return <a
                    href={cell.row.original.surveyURL}
                    target="_blank"
                    className='default-text'>
                    {CobuConstants.activeSurveys.viewSurvey}
                </a>
            }
        },
        {
            Header: 'CALL TO ACTION',
            accessor: 'callToAction'
        },
        {
            Header: 'CREATED',
            accessor: 'createdAt',
            Cell: (cell: CellProps<BuildingSurvey>) => (
                <div>
                    {moment(cell.row.original.createdAt)
                        .local()
                        .format('MM/DD/YYYY')}
                </div>
            )
        },
        {
            id: 'deactivate',
            Cell: (cell: CellProps<BuildingSurvey>) => (
                <div
                    className={'more-actions-btn more-actions-btn-unselected'}
                    onClick={() => { deactivateSurvey(cell.row.original) }}>
                    {CobuConstants.activeSurveys.deactivateSurvey}
                </div>
            )
        }
    ]

    const getCobuTableInstance = (data: BuildingSurvey[], placeholder: string) => {
        return (
            <CobuTableAdvanced
                columns={activeSurveysTableConfig}
                data={data}
                noData={{
                    label: placeholder,
                    action: undefined,
                    image: undefined
                }}
                actions={[createSurveyAction()]}
                actionsStyle={{ top: '130px' }}
            />
        );
    };

    const createSurveyAction = () => {
        return (
            <button
                className='btn-primary btn-standard bold'
                onClick={() => {
                    setShowSurveyCreateModal(!showSurveyCreateModal)
                }}
            >
                {CobuConstants.activeSurveys.createSurvey}
            </button>
        );
    };

    if (loadingConfig.flag) return <div className={styles.loadingContainer}>
        <Spinner />
        <h3 style={{ textAlign: 'center' }}>{loadingConfig.comment}</h3>
    </div>

    const createSurveyModal = () => {
        return showSurveyCreateModal ? <GenericModal
            showModal={showSurveyCreateModal}
            title={CobuConstants.activeSurveys.createSurvey}
            content={
                <CreateSurveyForm
                    cancel={() => { setShowSurveyCreateModal(!showSurveyCreateModal) }}
                    createSurvey={(survey) => {
                        setShowSurveyCreateModal(!showSurveyCreateModal)
                        createSurvey(survey)
                    }
                    }
                    formConfig={getCreateSurveyFormConfig()}
                />
            }
            hideActionButton
            canceActionButton
            actionText={CobuConstants.residentManagement.createActionTitle}
            actionFunction={() => { }}
            onChange={() => { }}
        /> : null
    }

    return (
        <div className='content-container'>
            <Breadcrumbs />
            <Spacer height={20} />
            <h1>{CobuConstants.activeSurveys.title}</h1>
            <Spacer height={20} />
            {getCobuTableInstance(activeSurveys, CobuConstants.activeSurveys.noActiveSurveys)}
            {createSurveyModal()}
        </div>
    )
}

export default ActiveSurvey