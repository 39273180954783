import moment from 'moment';

import { CalendarTypes } from '~/enums/CalendarTypes';
import { GatheringType } from '~/types/gathering';

function addToCalendar(type: CalendarTypes, gathering: GatheringType) {
  const startDateTime = moment(gathering.scheduledStartTime)
    .tz(gathering.buildingTimezone)
    .utc(true);
  const endDateTime = gathering.scheduledEndTime
    ? moment(gathering.scheduledEndTime)
        .tz(gathering.buildingTimezone)
        .utc(true)
    : startDateTime.clone().add(2, 'hours');
  const duration = endDateTime.diff(startDateTime, 'hours');

  const event = {
    description: gathering.description,
    duration,
    endDatetime: endDateTime,
    location: gathering.locationString,
    startDatetime: startDateTime,
    title: gathering.title,
    timezone: gathering.buildingTimezone
  };

  const formatICalOutlookDate = (date: moment.Moment) =>
    date && date.format('YYYYMMDDTHHmmssZ').toString().replace('+00:00', 'Z');

  const formatGoogleYahooDate = (date: moment.Moment) =>
    date && date.parseZone().format('YYYYMMDDTHHmmssZ');

  const escapeICSDescription = (description: string) =>
    description.replace(/(\r?\n|<br ?\/?>)/g, '\\n');
  if (type === CalendarTypes.Google) {
    return `https://calendar.google.com/calendar/render?action=TEMPLATE&dates=${formatGoogleYahooDate(
      event.startDatetime
    )}/${formatGoogleYahooDate(event.endDatetime)}${
      event.timezone && `&ctz=${event.timezone}`
    }&location=${event.location}&text=${event.title}&details=${
      event.description
    }`;
  } else if (type === CalendarTypes.Yahoo) {
    return `https://calendar.yahoo.com/?v=60&view=d&type=20&title=${event.title}&st=${event.startDatetime}&dur=${event.duration}
        )}&desc=${event.description}&in_loc=${event.location}`;
  } else if (CalendarTypes.Outlook || CalendarTypes.ICal) {
    const formatStartTime = formatICalOutlookDate(event.startDatetime);
    const formatEndTime = formatICalOutlookDate(event.endDatetime);
    let content = [
      'BEGIN:VCALENDAR',
      'VERSION:2.0',
      'BEGIN:VEVENT',
      `URL:${document.URL}`,
      'METHOD:PUBLISH',
      event.timezone === ''
        ? `DTSTART:${formatStartTime}`
        : `DTSTART;TZID=${event.timezone}:${formatStartTime}`,
      event.timezone === ''
        ? `DTEND:${formatEndTime}`
        : `DTEND;TZID=${event.timezone}:${formatEndTime}`,
      `SUMMARY:${event.title}`,
      `DESCRIPTION:${escapeICSDescription(event.description)}`,
      `LOCATION:${event.location}`,
      'END:VEVENT',
      'END:VCALENDAR'
    ].join('\n');
    return content;
  }
}

function createBlob(url: any) {
  const blob = new Blob([url], { type: 'text/calendar;charset=utf-8' });

  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.setAttribute('download', `gathering.ics`);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}
export { addToCalendar, createBlob };
