import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { emperor } from '~/services';
import { SelectedOptionType } from '~/types/form';

const useCreateDeeplinkForm = (buildingId: string) => {
    const [typeMultiValue, setTypeMultiValue] = useState<SelectedOptionType[]>([]);
    const [itemMultiValue, setItemMultiValue] = useState<SelectedOptionType[]>([]);
    const [items, setItems] = useState<{} | null>(null);

    useEffect(() => {
        if(typeMultiValue[0]){
            setItemMultiValue([])
        }
    }, [typeMultiValue]);
    

    useEffect(() => {
        getItems();
    }, []);

    
    const getItems = async () => {
        try {
            let resp = await emperor.get(`building/${buildingId}/summary-items`);
            setItems(resp.data);
        } catch (error) {
            console.error(error);
        }
    };

    const getDeeplinkTypesForDropdown = () => {
        return [
            { value: 'groups', label: 'Group' },
            { value: 'perks', label: 'Perk' },
            { value: 'events', label: 'Event' }
        ];
    };

    const getItemsForDropdown = () => {
        //@ts-ignore
        let x = items ? items[typeMultiValue[0]?.value] || [] : [];
        switch (typeMultiValue[0]?.value) {
            case 'groups':
                return x.map((y: any) => ({ value: y.uuid, label: y.name.toUpperCase() }));
            case 'perks':
                return x.map((y: any) => ({ value: y.perk.uuid, label: y.perk.name.toUpperCase() }));
            case 'events':
                return x.map((y: any) => ({ value: y.uuid, label: y.title.toUpperCase() }));
            default:
                return [];
        }
    };

    const getCreateDeeplinkPayload = () => {
        if(typeMultiValue.length === 0){
            toast('Please select a valid type')
            return null
        }

        if(itemMultiValue.length === 0){
            toast(`Please select a valid ${typeMultiValue[0].label}`)
            return null
        }
        //@ts-ignore
        let title = `${itemMultiValue[0]?.label ?? ''} - ${typeMultiValue[0]?.label ?? ''} - Page`;
        return {
            title,
            type: typeMultiValue[0]?.value,
            metadata: {
                uuid: itemMultiValue[0]?.value,
            },
            building: buildingId
        }
    }

    return {
        items,
        typeMultiValue,
        setTypeMultiValue,
        itemMultiValue,
        setItemMultiValue,
        getDeeplinkTypesForDropdown,
        getItemsForDropdown,
        getCreateDeeplinkPayload
    };
};

export default useCreateDeeplinkForm;
