import React from 'react';
import CalendarButton from '../CalendarButton/CalendarButton';
import Close from '~/utils/images/close.svg';
import { ReactComponent as GoogleIcon } from '~/utils/images/google-calendar.svg';
import { ReactComponent as OutlookIcon } from '~/utils/images/outlook-calendar.svg';
import { ReactComponent as YahooIcon } from '~/utils/images/yahoo-calendar.svg';
import { ReactComponent as AppleIcon } from '~/utils/images/apple-calendar.svg';
import classes from './CalendarModal.module.css';
import { CalendarTypes } from '~/enums/CalendarTypes';
import { GatheringType } from '~/types/gathering';

interface IProps {
  closeModal: () => void;
  gathering: GatheringType;
}
const CalendarModal = (props: IProps) => {
  const { closeModal, gathering } = props;

  return (
    <div className='modal'>
      <div className={classes.closeIconContainer}>
        <img className='pointer' alt='close' src={Close} onClick={closeModal} />
      </div>
      <div className={`modal-content ${classes.contentWidth}`}>
        {
          <div>
            <p className='modal-title'>Add this event to your calendar</p>
          </div>
        }
        <div className={`margin-top-24 ${classes.calendarButtonContainer}`}>
          <CalendarButton
            Icon={<GoogleIcon />}
            text={'Google calendar'}
            type={CalendarTypes.Google}
            gathering={gathering}
          />
          <CalendarButton
            Icon={<OutlookIcon />}
            text={'Outlook calendar'}
            type={CalendarTypes.Outlook}
            gathering={gathering}
          />
          <CalendarButton
            Icon={<YahooIcon />}
            text={'Yahoo calendar'}
            type={CalendarTypes.Yahoo}
            gathering={gathering}
          />
          <CalendarButton
            Icon={<AppleIcon />}
            text={'Apple calendar'}
            type={CalendarTypes.ICal}
            gathering={gathering}
          />
        </div>
        <p className='modal-title'>Don't have cobu app?</p>
        <p className='modal-text color-light-grey-5'>
          Download the app to be on track with the newest events in your
          building.
        </p>
      </div>
    </div>
  );
};

export default CalendarModal;
