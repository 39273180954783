import React, { useEffect } from 'react';
import { ReviewReceivedType } from '~/types/reviewReceived';
import RatingDisplay from '../RatingDisplay/RatingDisplay';
import UsernameDisplay from '../UsernameDisplay/UsernameDisplay';
import classes from './ReviewReceivedDetails.module.css';
import moment from 'moment';

interface IProps {
  showModal: boolean;
  closeModal: () => void;
  review: ReviewReceivedType;
  openEditMode: () => void;
}

const ReviewReceivedDetails = (props: IProps) => {
  const { showModal, review, closeModal, openEditMode } = props;

  return (
    <div className={showModal ? classes.overlay : classes.hide}>
      <div className={classes.modal}>
        <div className={`margin-bottom-32 space-between`}>
          <div className='modal-title'>Review details</div>
          <div className={classes.bottonContainer}>
            <div
              className={`${classes.button} ${classes.editButton} pointer`}
              onClick={openEditMode}
            >
              Edit
            </div>
            <div
              className={`${classes.button} ${classes.cancelButton} pointer`}
              onClick={() => closeModal()}
            >
              Cancel
            </div>
          </div>
        </div>
        <div className='space-between align-items'>
          {review.user && (
            <UsernameDisplay
              firstName={review.user.firstName}
              lastName={review.user.lastName}
              email={review.user.email}
              profileImage={review.user.profileImage}
            />
          )}
          <RatingDisplay value={review.reviewrating} />
        </div>
        <div className={`margin-top-24 ${classes.textStyle}`}>
          Date of Review: {moment.parseZone(review.reviewdate).format('MM/DD/YYYY')}
        </div>
        <div className={`margin-top-8 font-18 ${classes.lineHeight}`}>
          {review.reviewtext}
        </div>
        <div className={`margin-top-24 ${classes.textStyle}`}>Review Note</div>
        <div className={`margin-top-8 font-18 ${classes.lineHeight}`}>
          {review.reviewnotes}
        </div>
      </div>
    </div>
  );
};

export default ReviewReceivedDetails;
