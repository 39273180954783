import React from 'react';
import { useForm } from 'react-hook-form';
import { uriRegex } from '~/helpers/helpers';
import { PerkMerchantForm, PerkProposal } from '~/types/perk';
import classes from './VendorForm.module.css';

type Props = {
  perkProposal: PerkProposal;
  onCreate: (data: {
    name: string;
    description: string;
    offerType: string;
    redeemLink: string;
  }) => void;
  enableSubmitButton: (
    isValid: boolean,
    isSubmitting: boolean,
    isSubmitSuccessful: boolean
  ) => void;
};

const VendorForm = (props: Props) => {
  const { perkProposal, onCreate, enableSubmitButton } = props;
  const {
    register,
    handleSubmit,
    errors,
    formState: { isValid, isSubmitting, isSubmitSuccessful }
  } = useForm<PerkMerchantForm>({
    defaultValues: {
      name: '',
      description: '',
      offerType: '',
      redeemLink: ''
    },
    mode: 'onChange'
  });
  const submitHandler = (data: {
    name: string;
    description: string;
    offerType: string;
    redeemLink: string;
  }) => {
    onCreate(data);
  };

  return (
    <div className={classes.formContainer}>
      <form id='vendor-form' onSubmit={handleSubmit(submitHandler)}>
        <div className={classes.formHeader}>
          <h1 className={classes.title}>
            Please add details about your service
          </h1>
        </div>
        <div className={classes.gridContainer}>
          <div className={classes.item}>
            <label className='margin-top-16' htmlFor='title'>
              Building name
            </label>
            <input
              type='text'
              id='building'
              name='building'
              defaultValue={perkProposal.buildingName}
              disabled={true}
              className={classes.prefilled}
            />
            <label className='margin-top-16' htmlFor='title'>
              Merchant
            </label>
            <input
              type='text'
              id='merchant'
              name='merchant'
              defaultValue={perkProposal.merchantName}
              className={classes.prefilled}
              disabled={true}
            />
            <label className='margin-top-16' htmlFor='title'>
              Name of Offer *
            </label>
            <input
              type='text'
              id='name'
              name='name'
              placeholder={'For example: 15% off your first service'}
              ref={register({
                required: 'This field is required',
                maxLength: 50
              })}
              onChange={() =>
                enableSubmitButton(isValid, isSubmitting, isSubmitSuccessful)
              }
            />
            {errors.name && (
              <span className='form-error'>
                This field is required and cannot exceed 50 characters
              </span>
            )}

            <label className='margin-top-16' htmlFor='title'>
              Offer Type *
            </label>
            <select id='offerType' name='offerType' className={`${errors.merchant && 'border-red'} height-50`} ref={register}>
              <option value="redeem">Redeem</option>
              <option value="learn">Learn More</option>
            </select>
            
            <label className='margin-top-16' htmlFor='title'>
              Redemption Info *
            </label>
            <input
              type='text'
              id='redeemLink'
              name='redeemLink'
              placeholder={'Add a redemption URL or website link.'}
              ref={register({
                required: 'This field is required',
                pattern: {
                  value: uriRegex(),
                  message: 'Please enter a valid URL'
                }
              })}
              onChange={() =>
                enableSubmitButton(isValid, isSubmitting, isSubmitSuccessful)
              }
            />
            {errors.redeemLink && (
              <span className='form-error'>
                This field is required and it must be a valid URL
              </span>
            )}
            <label className='margin-top-16' htmlFor='title'>
              Description of Business & Offer *
            </label>
            <textarea
              id='description'
              name='description'
              placeholder={'Add a description'}
              ref={register({
                required: 'This field is required'
              })}
              onChange={() =>
                enableSubmitButton(isValid, isSubmitting, isSubmitSuccessful)
              }
              className={classes.vendorFormTextarea}
            />
            {errors.description && (
              <span className='form-error'>This field is required</span>
            )}
          </div>
        </div>
      </form>
    </div>
  );
};

export default VendorForm;
