import React, { Component } from 'react';
import { Field } from 'formik';
import _ from 'lodash';
import ImageDropzone from '../ImageDropzone/ImageDropzone';
import { Colors } from '~/enums/Colors';

class ScheduleCommentForm extends Component {
  constructor(props) {
    super(props);
    this.state = { postNow: true };
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
  }

  componentDidMount() {
    const {
      values: { building },
      getGroups,
      group
    } = this.props;
    if (building) {
      getGroups([building], true, true);
    }
    if (building && !group) {
      this.state.postNow = false;
    }
  }

  getGroupSelectPlaceholder = (values, buildingGroups) => {
    if (!values.building) {
      return 'Select building first';
    }
    if (!buildingGroups.length) {
      return 'No groups for this building';
    }
    return 'Select Group';
  };

  getGroupEventSelectPlaceholder = (values, groupGroupEvents) => {
    if (!values.group) {
      return 'Select group first';
    }
    if (!groupGroupEvents.length) {
      return 'No gatherings for this group';
    }
    return 'Select Gathering';
  };

  handleChange = (name, setFieldValue) => (event) => {
    const { getGroups } = this.props;
    if (name === 'building') {
      // get active groups and residents in these buildings
      getGroups([event.target.value], true, true);
    }
    setFieldValue(name, event.target.value, true);
  };

  handleCheckboxChange = (setFieldValue) => (event) => {
    this.setState({
      postNow: event.target.checked
    });
    setFieldValue('postTime', '');
  };

  render() {
    const {
      values,
      errors,
      touched,
      setFieldValue,
      setFieldTouched,
      selectOptions: { buildings, groups, groupEvents },
      handleSubmit,
      formType
    } = this.props;
    const { postNow } = this.state;
    const buildingArr = Object.values(buildings);
    const groupArr = Object.values(groups);
    const groupEventArr = Object.values(groupEvents);

    const groupsInBuilding = groupArr.filter(
      (group) => group.building.uuid === values.building
    );
    const groupEventsInGroup = groupEventArr.filter(
      (groupEvent) => groupEvent.group.uuid === values.group
    );
    groupsInBuilding.unshift({ uuid: 'null', name: 'CHAT' });

    return (
      <form id='group-event-form' onSubmit={handleSubmit}>
        <div className='half-form-width'>
          <label className='select-label' htmlFor='building-select'>
            Building
          </label>
          <select
            id='building-select'
            value={values.building}
            onChange={this.handleChange('building', setFieldValue)}
            onBlur={() => setFieldTouched('building', true, false)}
            disabled={formType === 'update' || this.props.editMode}
          >
            <option value='' disabled>
              Select Building
            </option>
            {_.map(buildingArr, (building) => (
              <option key={building.uuid} value={building.uuid}>
                {building.name}
              </option>
            ))}
          </select>
          {Boolean(errors.building) && touched.building && (
            <p style={{ color: Colors.cobuRed }}>
              {touched.building ? errors.building : ''}
            </p>
          )}
        </div>
        <div className='half-form-width'>
          <label className='select-label' htmlFor='group-select'>
            Group
          </label>
          <select
            id='group-select'
            value={values.group}
            disabled={
              !values.building ||
              !groupsInBuilding.length ||
              this.props.editMode
            }
            onChange={this.handleChange('group', setFieldValue)}
            onBlur={() => setFieldTouched('group', true, false)}
          >
            <option value='' disabled>
              {this.getGroupSelectPlaceholder(values, groupsInBuilding)}
            </option>
            {_.map(groupsInBuilding, (group) => (
              <option key={group.uuid} value={group.uuid}>
                {group.name}
              </option>
            ))}
          </select>
          {Boolean(errors.group) && touched.group && (
            <p style={{ color: Colors.cobuRed }}>
              {touched.group ? errors.group : ''}
            </p>
          )}
        </div>
        {
          <div className='half-form-width'>
            <label className='select-label' htmlFor='group-event-select'>
              Gathering
            </label>
            <select
              id='group-event-select'
              value={values.groupEvent}
              disabled={
                !values.building || !values.group || !groupEventsInGroup.length
              }
              onChange={this.handleChange('groupEvent', setFieldValue)}
              onBlur={() => setFieldTouched('groupEvent', true, false)}
            >
              <option value='' disabled>
                {this.getGroupEventSelectPlaceholder(
                  values,
                  groupEventsInGroup
                )}
              </option>
              {_.map(groupEventsInGroup, (groupEvent) => (
                <option key={groupEvent.uuid} value={groupEvent.uuid}>
                  {groupEvent.title}
                </option>
              ))}
            </select>
            {Boolean(errors.groupEvent) && touched.groupEvent && (
              <p style={{ color: Colors.cobuRed }}>
                {touched.groupEvent ? errors.groupEvent : ''}
              </p>
            )}
          </div>
        }
        <div className='half-form-width'>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <label>
              {values.building ? "Creator's Email" : 'Select building first'}
            </label>
            <input
              id='creatorEmail'
              name='creatorEmail'
              value={values.creatorEmail}
              type='email'
              disabled={formType === 'update' || !values.building}
              onChange={this.handleChange('creatorEmail', setFieldValue)}
              onBlur={() => setFieldTouched('creatorEmail', true, false)}
            />
            {Boolean(errors.creatorEmail) && touched.creatorEmail && (
              <p style={{ color: Colors.cobuRed }}>
                {touched.creatorEmail ? errors.creatorEmail : ''}
              </p>
            )}
          </div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <label>Comment Contents</label>
          <input
            id='contents'
            name='contents'
            value={values.contents}
            onChange={this.handleChange('contents', setFieldValue)}
            onBlur={() => setFieldTouched('contents', true, false)}
            fullWidth
            multiline
          />
          {Boolean(errors.contents) && touched.contents && (
            <p style={{ color: Colors.cobuRed }}>
              {touched.contents ? errors.contents : ''}
            </p>
          )}
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center'
            }}
          >
            <input
              type='checkbox'
              checked={postNow}
              onChange={this.handleCheckboxChange(setFieldValue)}
              onBlur={() => setFieldTouched('postTime', true, false)}
            />
            <label>Post now</label>

            {!postNow && (
              <div className='margin-left-16'>
                <label>Date & Time of Posting</label>
                <input
                  className='margin-left-8'
                  type='datetime-local'
                  value={postNow ? null : values.postTime}
                  onChange={(e) => {
                    this.handleChange(
                      'postTime',
                      setFieldValue('postTime', e.target.value, true)
                    );
                  }}
                />
                {Boolean(errors.postTime) && touched.postTime && (
                  <p style={{ color: Colors.cobuRed }}>
                    {touched.postTime ? errors.postTime : ''}
                  </p>
                )}
              </div>
            )}
          </div>
        </div>
        <div className='img-button-container'>
          <Field
            name='image'
            component={(formikProps) => (
              <ImageDropzone
                image={values?.image}
                handleDrop={(files) => {
                  setFieldValue('image', files[0], true);
                  setFieldTouched('image', true, false);
                }}
                {...formikProps}
              />
            )}
          />
        </div>
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end'
          }}
        >
          <button
            className='btn-primary btn-standard'
            type='submit'
            variant='contained'
            color='primary'
          >
            Submit
          </button>
        </div>
      </form>
    );
  }
}

export default ScheduleCommentForm;
