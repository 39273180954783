import { createSlice } from '@reduxjs/toolkit';
import { GatheringType } from '~/types/gathering';

const gatheringSlice = createSlice({
  name: 'gathering',
  initialState: {
    gathering: { ...({} as GatheringType), image: '' }
  },
  reducers: {
    fetchOneGatheringSuccess: (state, action) => {
      state.gathering = action.payload;
    }
  }
});
export const { fetchOneGatheringSuccess } = gatheringSlice.actions;
export default gatheringSlice.reducer;
