import React from "react";
import Spinner from "../Spinner/Spinner";
import CobuTable from "../CobuTable/CobuTable";
import { Building } from "~/types/building";
import { GoogleInvitationSettings } from "~/types/reviewReceived";
import CobuConstants from "~/helpers/cobuConstants";
import { getReviewAlgorithmTableConfig } from "./ReviewAlgorithmTableConfig";
import useReviewAlgorithm from "~/hooks/googlereviews/useReviewAlgorithm";
import CobuTableAdvanced from "../CobuTableAdvanced/CobuTableAdvanced";
import Spacer from "../Spacer/Spacer";

type Props = {
    settings: GoogleInvitationSettings;
    building: Partial<Building>;
    onCancel: () => void;
}

const ReviewAlgorithmResults: React.FC<Props> = ({ settings, building, onCancel }) => {
    const { loader, reviewAlgorithmResult } = useReviewAlgorithm(settings, building);

    return (
        <div className='float-spinner-parent'>
            {loader && <Spinner float />}
            {reviewAlgorithmResult && reviewAlgorithmResult.length ? <p className='default-text weight-500 font-16'>{`No of Eligible Residents  - ${reviewAlgorithmResult.length}`}</p> : ''}
            <Spacer height={10} />
            {reviewAlgorithmResult ? (
                <CobuTableAdvanced columns={getReviewAlgorithmTableConfig()} data={reviewAlgorithmResult} noData={{
                    label: CobuConstants.editGoogleReviewSettings.reviewResultsPlaceHolder,
                    action: undefined,
                    image: undefined
                }} />
            ) : null}
            <button type="button" className='btn-standard btn-secondary' onClick={onCancel}>
                {CobuConstants.editGoogleReviewSettings.buttonText.dismiss}
            </button>
        </div>
    );
};

export default ReviewAlgorithmResults;