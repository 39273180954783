import React, { useState } from 'react';
import { connect, ResolveThunks } from 'react-redux';
import { saveUser } from '~/reducers/auth';
import { RootState } from '~/redux/store';
import { useHistory } from 'react-router-dom';

import classes from './ResetPasswordContainer.module.css';
import LogoLarge from '~/components/LogoLarge/LogoLarge';
import ResetPasswordForm from '~/components/ResetPasswordForm/ResetPasswordForm';
import { resetPasswordRequest } from '~/api/auth';
import { PasswordResetFormType } from '~/types/auth';

interface DispatchProps {
  saveUser: typeof saveUser;
}

interface IProps {
  isRSVPLogin: boolean;
}

type Props = IProps &
  ResolveThunks<DispatchProps> &
  ReturnType<typeof mapStateToProps>;

const ResetPasswordContainer = (props: Props) => {
  const history = useHistory();
  const [showSuccess, setShowSuccess] = useState(false);
  const onSubmit = (data: PasswordResetFormType) => {
    resetPasswordRequest(data).then((res) => {
      setShowSuccess(true);
    });
  };

  return (
    <div className={`${classes.passwordContainer}`}>
      <div className='display-flex'>
        {!showSuccess && (
          <React.Fragment>
            <div className={classes.left}>
              <LogoLarge />
            </div>
            <div className={classes.right}>
              <ResetPasswordForm onSubmit={onSubmit} />
            </div>{' '}
          </React.Fragment>
        )}
        {showSuccess && (
          <div className={classes.successContainer}>
            <h1>
              Password updated{' '}
              <span role='img' aria-label='Celebration'>
                🎉
              </span>{' '}
            </h1>
            <p className={classes.text}>
              Your password has been changeg successfully! Use your new password
              to log in.
            </p>
            <button
              className={`btn-primary btn-large ${classes.login}`}
              onClick={() => history.push('/login')}
            >
              Log In to Cobu
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

function mapStateToProps(state: RootState) {
  return {
    userId: state.auth.user
  };
}

function mapDispatchToProps(): DispatchProps {
  return {
    saveUser
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps()
)(ResetPasswordContainer);
