import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchBuildingDetailsById } from '~/api/building';
import {
  fetchBuildingDetails,
  fetchBuildingDetailsSuccess
} from '~/reducers/building';
import { RootState } from '~/redux/store';
import { Building } from '~/types/building';

export default (buildingId: string, isRefetch = false) => {
  const dispatch = useDispatch();
  const buildingDetails: null | Partial<Building> = useSelector(
    (state: RootState) => {
      return state.building.selectedBuilding;
    }
  );

  const getBuildingDetails = async () => {
    try {
      dispatch(fetchBuildingDetails());
      const res = await fetchBuildingDetailsById(buildingId);

      dispatch(fetchBuildingDetailsSuccess(res.data));
    } catch (e) {}
  };

  useEffect(() => {
    if (isRefetch) {
      getBuildingDetails();

      return;
    }

    if (
      !buildingDetails ||
      (buildingDetails && (buildingDetails as Building).uuid != buildingId)
    ) {
      getBuildingDetails();
    }
  }, []);

  return buildingDetails;
};
