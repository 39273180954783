import React from 'react';
import { ReactComponent as Pencil } from '~/utils/images/pencil.svg';
//import { ReactComponent as LongRightArrow } from '~/utils/images/long-right-arrow.svg';
import TextDelimeter from '~/components/TextDelimeter/TextDelimeter';
import classes from './GroupCard.module.css';
import { GroupType } from '~/types/group';
import _ from 'lodash';
import { Colors } from '~/enums/Colors';

interface IProps {
  group: GroupType;
  onGroupSelected: (group: GroupType) => void;
}
const GroupCard = (props: IProps) => {
  const { group } = props;

  return (
    <>
      {group.isDefault && (
        <div className='bullet margin-left-16 margin-top-16'>DEFAULT</div>
      )}
      <div className={classes.card}>
        <div className={classes.container}>
          <div className={classes.containerImg}>
            <div
              className={classes.img}
              style={{ backgroundImage: `url( ${group.imageUrl})` }}
            />
          </div>
          <div className={classes.content}>
            <div className='space-between'>
              <h2 className={classes.contentTittleh2}>{group.name}</h2>
              <Pencil
                className={classes.pencil}
                onClick={() => {
                  props.onGroupSelected(group);
                }}
              />
            </div>
            <div className={classes.contentBody}>
              <h3 className={classes.contentBodyh3}>Group Description</h3>
              <TextDelimeter
                textElement={group.description}
                maxDelimiter={250}
                moreMessage='See more'
                lessMessage='See less'
              />
            </div>
            <div className={classes.contentLinks}>
              <div className={classes.contentLink}>
                <p>Members</p>
                <section className={classes.contentLinksRight}>
                  <p>{group.meta.groupMemberCount}</p>
                  {/* <LongRightArrow /> When the logic is available un comment this arrow*/}
                </section>
              </div>
              <div className={classes.contentLink}>
                <p>Comments</p>
                <section className={classes.contentLinksRight}>
                  <p>{group.meta.totalCommentCount}</p>
                  {/* <LongRightArrow /> When the logic is available un comment this arrow*/}
                </section>
              </div>
              <div className={classes.contentLink}>
                <p>Gatherings</p>
                <section className={classes.contentLinksRight}>
                  <p>{group.meta.upcomingGroupEventCount}</p>
                  {/* <LongRightArrow /> When the logic is available un comment this arrow*/}
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GroupCard;
