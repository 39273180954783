import React, { useEffect, useMemo, useState } from 'react';
import { connect, ResolveThunks } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { CellProps, Column } from 'react-table';
import { toast } from 'react-toastify';
import { fetchBuildingMerchants } from '~/api/building';
import { fetchBuildingDashboardConfig } from '~/api/building';

import {
  fetchPerksForBuilding,
  fetchPerksProposalsForBuilding,
  updatePerk
} from '~/api/perks';
import Breadcrumbs from '~/components/Breadcrumbs/Breadcrumbs';
import CobuTableAdvanced from '~/components/CobuTableAdvanced/CobuTableAdvanced';
import CobuTooltip from '~/components/CobuTooltip/CobuTooltip';
import buttonStyle from '~/components/GatheringsTable/GatheringTable.styles';
import MoreActions from '~/components/MoreActions/MoreActions';
import Spinner from '~/components/Spinner/Spinner';
import { composePerkProposalLink } from '~/helpers/helpers';
import {
  fetchPerksSuccess,
  updateBuildingPerkSuccess,
  updatePerkSuccess
} from '~/reducers/perks';
import { saveUserSettings } from '~/reducers/auth';
import { RootState } from '~/redux/store';
import { BuildingPerk } from '~/types/buildingPerk';
import { Merchant, MerchantPatch } from '~/types/merchant';
import { Perk } from '~/types/perk';
import classes from './PerksContainer.module.css';
import { ReactComponent as ChainLink } from '~/utils/images/chain-link.svg';
import { ReactComponent as NoOffersImage } from '~/utils/images/no-offers.svg';
import CobuSelect from '~/components/CobuSelect/CobuSelect';
import CobuSwitcher from '~/components/CobuSwitcher/CobuSwitcher';
import MerchantForm from '~/components/MerchantForm/MerchantForm';
import PerksModal from './PerksModal';
import { PerkStatus } from '~/enums/PerkStatus';
import { getBuildingFromStore } from '~/helpers/reduxStoreHelpers';
import { modalStatus } from './PerksUtils';
import { SelectType } from '~/enums/SelectType';

type Props = ResolveThunks<DispatchProps> & ReturnType<typeof mapStateToProps>;
type selectedType = { value: string; label: string } | null;

interface DispatchProps {
  fetchPerksSuccess: typeof fetchPerksSuccess;
  updateBuildingPerkSuccess: typeof updateBuildingPerkSuccess;
  updatePerkSuccess: typeof updatePerkSuccess;
  saveUserSettings: typeof saveUserSettings;
}

const PerksContainer = (props: Props) => {
  const {
    NEW_OFFER,
    GET_OFFER_LINK,
    NEW_MERCHANT,
    MERCHANT_CREATED_SUCCESS,
    EDIT_MERCHANT
  } = modalStatus;

  const [buildingMerchants, setBuildingMerchants] = useState<Merchant[]>([]);
  const [perkProposals, setPerkProposals] = useState<BuildingPerk[]>([]);
  const [
    selectedBuildingPerk,
    setSelectedBuildingPerk
  ] = useState<BuildingPerk | null>(null);
  const [loading, setLoading] = useState(true);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showCreateOfferModal, setShowCreateOfferModal] = useState(false);
  const [modalIndex, setModalIndex] = useState(NEW_OFFER);
  const [selectedMerchant, setSelectedMerchant] = useState<string>('');
  const [createdProposal, setCreatedProposal] = useState<string>('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [newMerchantName, setNewMerchantName] = useState('');
  const [showPerks, setShowPerks] = useState(true);
  const [merchantForEdit, setMerchantForEdit] = useState<MerchantPatch>(
    {} as MerchantPatch
  );

  const [isActionSuccessed, setIsActionSuccessed] = useState(true);
  const { buildingId } = useParams<{ buildingId: string }>();
  const history = useHistory();
  const {
    buildingPerks,
    fetchPerksSuccess,
    updatePerkSuccess,
    userSettings,
    saveUserSettings
  } = props;

  //#region column definitions

  const pendingPerksColumns = useMemo(
    () =>
      [
        {
          Header: 'MERCHANT NAME',
          accessor: 'merchantName',
          Cell: (cell: CellProps<BuildingPerk>) => (
            <div
              className={`pointer ${classes.merchantCell}`}
              style={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap'
              }}
              onClick={() => {
                const row = cell.row.original;
                setShowCreateOfferModal(true);
                setModalIndex(EDIT_MERCHANT);
                setMerchantForEdit({
                  uuid: row.merchantUuid ? row.merchantUuid : '',
                  name: row.merchantName ? row.merchantName : '',
                  category: row.merchantCategory ? row.merchantCategory : ''
                });
              }}
            >
              {cell.row.original.merchantName}
            </div>
          )
        },
        {
          Header: 'OFFER NAME',
          accessor: 'perk.name'
        },
        {
          Header: 'OFFER DESCRIPTION',
          accessor: 'perk.description',
          Cell: (cell: CellProps<BuildingPerk>) => (
            <React.Fragment>
              <p data-tip={cell.value} className={classes.description}>
                {cell.value}
              </p>
              <CobuTooltip />
            </React.Fragment>
          )
        },
        {
          id: 'activate',
          Cell: (cell: CellProps<BuildingPerk>) => (
            <div
              style={buttonStyle as React.CSSProperties}
              className={`pointer`}
              onClick={() => {
                setLoading(true);

                updatePerk(cell.row.original.perk.uuid, {
                  ...cell.row.original.perk,
                  status: PerkStatus.Active
                })
                  .then((res) => {
                    setLoading(false);
                    updatePerkSuccess(res.data);
                  })
                  .catch(() => {
                    setLoading(false);
                  });
              }}
            >
              ACTIVATE
            </div>
          )
        },
        {
          id: 'more',
          Cell: (cell: CellProps<BuildingPerk>) => (
            <MoreActions
              options={[
                {
                  label: 'Edit',
                  action: () => {
                    history.push(
                      `${history.location.pathname}/${cell.row.original.perk.uuid}/edit`
                    );
                  }
                },
                {
                  label: 'Delete',
                  action: () => {
                    setSelectedBuildingPerk(cell.row.original);
                    setShowDeleteModal(true);
                  }
                }
              ]}
            />
          )
        }
      ] as Column<Perk>[],
    [history, updatePerkSuccess, EDIT_MERCHANT]
  );

  const activePerksColumns = useMemo(
    () =>
      [
        {
          Header: 'MERCHANT NAME',
          accessor: 'merchantName',
          Cell: (cell: CellProps<BuildingPerk>) => (
            <div
              className={`pointer ${classes.merchantCell}`}
              style={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap'
              }}
              onClick={() => {
                const row = cell.row.original;
                setShowCreateOfferModal(true);
                setModalIndex(EDIT_MERCHANT);
                setMerchantForEdit({
                  uuid: row.merchantUuid ? row.merchantUuid : '',
                  name: row.merchantName ? row.merchantName : '',
                  category: row.merchantCategory ? row.merchantCategory : ''
                });
              }}
            >
              {cell.row.original.merchantName}
            </div>
          )
        },
        {
          Header: 'OFFER NAME',
          accessor: 'perk.name'
        },
        {
          Header: 'OFFER DESCRIPTION',
          accessor: 'perk.description',
          Cell: (cell: CellProps<BuildingPerk>) => (
            <React.Fragment>
              <p data-tip={cell.value} className={classes.description}>
                {cell.value}
              </p>
              <CobuTooltip />
            </React.Fragment>
          )
        },
        {
          id: 'more',
          Cell: (cell: CellProps<BuildingPerk>) => (
            <MoreActions
              options={[
                {
                  label: 'Edit',
                  action: () => {
                    history.push(
                      `${history.location.pathname}/${cell.row.original.perk.uuid}/edit`
                    );
                  }
                },
                {
                  label: 'Deactivate',
                  action: () => {
                    setLoading(true);

                    const original = cell.row.original;
                    updatePerk(original.perk.uuid, {
                      uuid: original.perk.uuid,
                      status: PerkStatus.Inactive
                    })
                      .then((res) => {
                        setLoading(false);
                        updatePerkSuccess(res.data);
                      })
                      .catch(() => {
                        setLoading(false);
                      });
                  }
                },
                {
                  label: 'Delete',
                  action: () => {
                    setSelectedBuildingPerk(cell.row.original);
                    setShowDeleteModal(true);
                  }
                }
              ]}
            />
          )
        }
      ] as Column<Perk>[],
    [history, updatePerkSuccess, EDIT_MERCHANT]
  );

  const proposalColumns = useMemo(
    () =>
      [
        {
          Header: 'MERCHANT NAME',
          accessor: 'merchant.name'
        },
        {
          Header: 'MERCHANT CATEGORY',
          accessor: 'merchant.category',
          Cell: (cell: CellProps<Perk>) => <p>{cell.value}</p>
        },
        {
          accessor: 'uuid',
          Cell: (cell: CellProps<Perk>) => (
            <a
              href={composePerkProposalLink(cell.row.original.uuid)}
              rel='noopener noreferrer'
              target='_blank'
            >
              {composePerkProposalLink(cell.row.original.uuid)}
            </a>
          )
        },
        {
          id: 'Copy',
          Cell: (cell: CellProps<Perk>) => (
            <div
              style={buttonStyle as React.CSSProperties}
              className={`pointer`}
              onClick={() => {
                navigator.clipboard.writeText(
                  composePerkProposalLink(cell.row.original.uuid)
                );
                toast('Link copied to clipboard.');
              }}
            >
              COPY LINK
            </div>
          )
        }
      ] as Column<Perk>[],
    []
  );

  //#endregion

  const createOfferAction = () => {
    return (
      <button
        className='btn-primary btn-standard bold'
        onClick={() => setShowCreateOfferModal(true)}
      >
        Create Offer
      </button>
    );
  };
  const selectedBuilding = getBuildingFromStore(buildingId);

  const selectOptions = buildingMerchants.map((merchant: Merchant) => {
    return { value: merchant.uuid, label: merchant.name };
  });

  const changeMerchantName = (name: string) => {
    setNewMerchantName(name);
  };

  const changeMerchantCategory = (category: string) => {
    setSelectedCategory(category);
  };

  useEffect(() => {
    const getData = async () => {
      Promise.all([
        fetchPerksForBuilding(buildingId),
        fetchPerksProposalsForBuilding(buildingId),
        fetchBuildingMerchants(buildingId)
      ])
        .then((res) => {
          fetchPerksSuccess(res[0].data);
          setPerkProposals(res[1].data);
          setBuildingMerchants(res[2].data);
        })
        .finally(() => setLoading(false));
    };
    fetchBuildingDashboardConfig(buildingId).then((res) => {
      if (!res.data.config || (res.data.config && !res.data.config.showPerks)) {
        setShowPerks(false);
      }

      if (!showDeleteModal) {
        getData();
      }
    });
  }, [buildingId, fetchPerksSuccess, isActionSuccessed, showDeleteModal]);

  let offerModalTitle = '',
    actionTextModal = '',
    modalContent;

  switch (modalIndex) {
    case NEW_OFFER:
      offerModalTitle = 'Create new Offer';
      actionTextModal = 'Next';
      modalContent = (
        <div>
          <label>Choose a Merchant</label>
          <CobuSelect
            options={[
              {
                label: 'Create a new Merchant',
                value: 'null'
              },
              ...selectOptions
            ]}
            onChange={(selectedOption: selectedType) => {
              setSelectedMerchant(selectedOption?.value || '');
              if (selectedOption?.value === 'null')
                setTimeout(() => setModalIndex(NEW_MERCHANT), 400);
            }}
            placeholder='Select a Merchant or create a new one'
            selectType={SelectType.Form}
          />
          <p className='modal-small-text'>
            Please remember you will need to activate the Offer after the
            service provider enters the details.
          </p>
        </div>
      );
      break;
    case GET_OFFER_LINK:
      offerModalTitle = 'Get Offer link';
      actionTextModal = 'Copy link';
      modalContent = (
        <div className='text-align-left'>
          <p className='margin-bottom-16'>Share this link with the vendor.</p>
          {!createdProposal ? (
            <p className='bold'>
              <ChainLink fill={'#FEC100'} className='margin-right-8' />
              loading link...
            </p>
          ) : (
            <>
              <ChainLink fill={'#FEC100'} className='margin-right-8' />
              <a
                className='bold'
                target='_blank'
                rel='noopener noreferrer'
                href={composePerkProposalLink(createdProposal)}
              >
                {composePerkProposalLink(createdProposal)}
              </a>
            </>
          )}
        </div>
      );
      break;
    case NEW_MERCHANT:
      offerModalTitle = 'Create new Merchant';
      actionTextModal = 'Save';
      modalContent = (
        <MerchantForm
          merchant={{ name: '', category: '' }}
          onChangeName={changeMerchantName}
          onChangeCategory={changeMerchantCategory}
          isEdit={false}
        />
      );
      break;
    case MERCHANT_CREATED_SUCCESS:
      offerModalTitle = 'Merchant Created';
      actionTextModal = 'Continue';
      modalContent = (
        <p className='margin-bottom-16'>
          Press "Continue" to create a new offer, or "Cancel" to return to
          offers.
        </p>
      );
      break;
    case EDIT_MERCHANT:
      offerModalTitle = 'Edit Merchant';
      actionTextModal = 'Save';
      modalContent = (
        <MerchantForm
          merchant={merchantForEdit}
          onChangeName={(name) => setNewMerchantName(name)}
          onChangeCategory={(category) => setSelectedCategory(category)}
          isEdit={true}
        />
      );
      break;
    default:
      offerModalTitle = 'Create new Offer';
      actionTextModal = 'Next';
      break;
  }

  return !showPerks ? (
    <h1>Not Found</h1>
  ) : (
    <div className='content-container'>
      <PerksModal
        showCreateOfferModal={showCreateOfferModal}
        offerModalTitle={offerModalTitle}
        modalIndex={modalIndex}
        selectedMerchant={selectedMerchant}
        createdProposal={createdProposal}
        newMerchantName={newMerchantName}
        selectedCategory={selectedCategory}
        actionTextModal={actionTextModal}
        setModalIndex={setModalIndex}
        setCreatedProposal={setCreatedProposal}
        composePerkProposalLink={composePerkProposalLink}
        buildingId={buildingId}
        setPerkProposals={setPerkProposals}
        perkProposals={perkProposals}
        setShowCreateOfferModal={setShowCreateOfferModal}
        setSelectedMerchant={setSelectedMerchant}
        setSelectedCategory={setSelectedCategory}
        setNewMerchantName={setNewMerchantName}
        setBuildingMerchants={setBuildingMerchants}
        merchantForEdit={merchantForEdit}
        modalContent={modalContent}
        showDeleteModal={showDeleteModal}
        selectedBuildingPerk={selectedBuildingPerk}
        setShowDeleteModal={setShowDeleteModal}
        actionSuccess={(value) => setIsActionSuccessed(value)}
      />
      {loading && <Spinner />}
      {!loading && (
        <React.Fragment>
          <Breadcrumbs />
          <h1 className={classes.title}>
            Neighborhood Offers for <b>{selectedBuilding.name}</b>
          </h1>
          <CobuSwitcher
            optionsLabels={[
              PerkStatus.Pending,
              PerkStatus.Active,
              PerkStatus.Inactive
            ]}
            onClickCallback={(index: number) => {
              saveUserSettings({
                ...userSettings,
                perkActiveTabIndex: index
              });
            }}
            defaultSelected={
              (userSettings && userSettings.perkActiveTabIndex) || 0
            }
            content={[
              <div>
                <div className='margin-bottom-32'>
                  <h3 className={classes.tableTitle}>
                    Offers needing your review
                  </h3>
                  <CobuTableAdvanced
                    data={buildingPerks.filter(
                      (bp: BuildingPerk) =>
                        bp.perk.status === PerkStatus.Pending
                    )}
                    columns={pendingPerksColumns}
                    noData={{
                      label: 'No offers needing activation',
                      image: <NoOffersImage />
                    }}
                    search={{
                      searchField: 'perk.name',
                      placeholder: 'Offer Name'
                    }}
                    actions={[createOfferAction()]}
                  />
                </div>

                <div className='margin-bottom-32'>
                  <h3 className={classes.tableTitle}>
                    Offers waiting for Merchant to complete
                  </h3>
                  <CobuTableAdvanced
                    data={perkProposals}
                    columns={proposalColumns}
                    noData={{
                      label: 'No Offer Proposals',
                      image: <NoOffersImage />,
                      action: createOfferAction()
                    }}
                  />
                </div>
              </div>,
              <CobuTableAdvanced
                data={buildingPerks.filter(
                  (bp: BuildingPerk) => bp.perk.status === PerkStatus.Active
                )}
                columns={activePerksColumns}
                noData={{
                  label: 'No Active Offers',
                  image: <NoOffersImage />
                }}
                search={{
                  searchField: 'perk.name',
                  placeholder: 'Offer Name'
                }}
              />,
              <CobuTableAdvanced
                data={buildingPerks.filter(
                  (bp: BuildingPerk) => bp.perk.status === PerkStatus.Inactive
                )}
                columns={pendingPerksColumns}
                noData={{
                  label: 'No Inactive Offers',
                  image: <NoOffersImage />
                }}
                search={{
                  searchField: 'perk.name',
                  placeholder: 'Offer Name'
                }}
              />
            ]}
          />
        </React.Fragment>
      )}
    </div>
  );
};

function mapStateToProps(state: RootState) {
  return {
    buildings: state.building.buildings,
    buildingPerks: state.perks.buildingPerks,
    userSettings: state.auth.userSettings
  };
}

function mapDispatchToProps(): DispatchProps {
  return {
    fetchPerksSuccess,
    updateBuildingPerkSuccess,
    updatePerkSuccess,
    saveUserSettings
  };
}

export default connect(mapStateToProps, mapDispatchToProps())(PerksContainer);
