import React from 'react';
import { CommentFilterType } from '~/containers/Comments/Comments';

import { ReactComponent as DownArrow } from '~/utils/images/arrow-down.svg';
import { ReactComponent as UpArrow } from '~/utils/images/arrow-up.svg';
import classes from './CommentSectionFilter.module.css';
import moment from 'moment';
import { GatheringType } from '~/types/gathering';

interface IProps {
  sectionTitle: string;
  filters: any;
  showFilters?: boolean;
  selectedFilter?: string;
  isBuildingChat?: boolean;
  onClickAction: (id: string, filter: any) => void;
  openFilterOptions?: () => void;
  isBuildingChatSelected: boolean;
}

const CommentSectionFilter = (props: IProps) => {
  const {
    filters,
    onClickAction,
    showFilters,
    selectedFilter,
    sectionTitle,
    openFilterOptions,
    isBuildingChat,
    isBuildingChatSelected
  } = props;

  const getTitleForEvent = (filter: GatheringType) => {
    return (
      <div className='flex'>
        {filter.title}
        <div style={{ fontSize: '11px', fontWeight: 'normal' }}>
          {moment(filter.scheduledStartTime)
            .tz(filter.buildingTimezone)
            .format('ddd, DD MMM, YYYY')}
        </div>
      </div>
    );
  };

  return (
    <div className={classes.sectionFilterContainer}>
      <div
        className={`font-14 color-light-grey-5 bold margin-top-24 ${classes.singleSectionFilter}`}
        onClick={openFilterOptions}
      >
        <div>{sectionTitle}</div>
        {!isBuildingChat && (showFilters ? <UpArrow /> : <DownArrow />)}
      </div>
      {(showFilters || isBuildingChat) && (
        <div className={`margin-top-4 ${classes.filterOptionsContainer}`}>
          {filters.map((filter: any) => {
            const filterForGatherings =
              filter.scheduledStartTime && filter.isRecurring
                ? `${filter.uuid}?${moment(filter.scheduledStartTime)
                    .tz(filter.buildingTimezone)
                    .format('YYYY-MM-DD')}`
                : filter.uuid;

            return (
              <div
                key={filter.uuid}
                className={`margin-top-4 font-18 weight-500 cursor-pointer ${
                  classes.singleSectionFilterOption
                } ${
                  (selectedFilter === filterForGatherings ||
                    isBuildingChatSelected) &&
                  classes.singleSectionFilterOptionSelected
                } `}
                onClick={() => {
                  onClickAction(filter.uuid, filter);
                }}
              >
                <div className={classes.imgTitleContainer}>
                  {filter.image || filter.imageUrl ? (
                    <img
                      className={`margin-right-8 ${classes.avatar}`}
                      alt='avatar'
                      src={filter.image || filter.imageUrl}
                    />
                  ) : (
                    <div className={`margin-right-8 ${classes.avatar}`} />
                  )}
                  <div>
                    {filter.title && filter.scheduledStartTime
                      ? getTitleForEvent(filter)
                      : filter.name}
                  </div>
                </div>
                {!isBuildingChat && (
                  <div
                    className={`${classes.commentsCount} ${
                      selectedFilter && classes.commentsCountSelected
                    }`}
                  >
                    {filter.countComments ?? filter?.meta?.activeCommentCount}
                  </div>
                )}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default CommentSectionFilter;
