import React, { FC } from 'react';
import Spinner from '../Spinner/Spinner';
import classes from './ModalFloatLoader.module.scss';

interface IProps {
  loading: boolean;
}

export const ModalFloatLoader: FC<IProps> = ({ loading }) => {
  if (!loading) {
    return null;
  }

  return <div className={classes.modal}>{loading && <Spinner float />}</div>;
};
