import moment from 'moment';
import React from 'react';
import { Comment } from '~/types/comment';

interface Props {
  comment: Comment;
  parent: boolean;
}

const prettifyTime = (time: string) => {
  return moment(time).fromNow().includes('second') === false &&
    moment(time).fromNow().includes('minute') === false &&
    moment(time).fromNow().includes('hour') === false &&
    moment(time).fromNow().includes('day') === false
    ? moment(time).format('ll')
    : moment(time).fromNow();
};

const BasicComment = ({ comment, parent }: Props) => {
  return (
    <div className={comment.parent ? 'comment-child' : 'comment'}>
      <img
        className='avatar margin-right-8'
        alt='avatar'
        src={comment.user.profileImage}
      />
      {comment.children && comment.children.length > 0 && (
        <div className='line' />
      )}
      <div>
        <div className='checkbox-row'>
          <p className='comments-container-txt margin-bottom-4'>
            {comment.user.displayName}
          </p>
          {comment.removed && (
            <p className='removed-label checkbox-text margin-left-8 warning-background'>
              {`Comment has been DELETED by ${comment.removedBy || 'ADMIN'}`}
            </p>
          )}
          {comment.hidden && (
            <p className='removed-label checkbox-text margin-left-8 warning-background'>
              {`Comment has been HIDDEN by ${comment.hiddenBy || 'ADMIN'}`}
            </p>
          )}
        </div>
        <p
          className={
            comment.removed || comment.hidden
              ? 'comments-container-content-hidden'
              : 'comments-container-content'
          }
        >
          {comment.images?.length ? (
            <>
              {comment.contents && <p>{comment.contents}</p>}
              <img
                src={comment.images[0].image}
                className={comment.removed || comment.hidden ? 'bw' : ''}
                alt='comment img'
                width='300'
                style={{ borderRadius: 10 }}
              />
            </>
          ) : (
            comment.contents
          )}
        </p>
        <p className='comment-time'>{prettifyTime(comment.createdAt)}</p>
      </div>
    </div>
  );
};

export default BasicComment;
