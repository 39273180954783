import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import {
  fetchBuildingDashboardConfig,
  getBuildingAddress,
  getBuildingHelloData,
  postEnableHelloData
} from '~/api/building';
import { IEnableHelloDataForm } from '~/containers/EnableHelloData/interfaces';
import { BuildingDashboardConfigResponse } from '~/types/building';
import { IBuildingHelloData } from '~/types/helloData';

export default (buildingId: string) => {
  const [loader, setLoader] = useState(false);
  const [isAddressPresent, setIsAddressPresent] = useState(false);
  const [buildingConfig, setBuildingConfig] =
    useState<BuildingDashboardConfigResponse | null>(null);
  const [buildingHelloData, setBuildingHelloData] =
    useState<null | IBuildingHelloData>(null);

  const getAddress = async () => {
    try {
      setLoader(true);
      const res = await getBuildingAddress(buildingId);
      setIsAddressPresent(!!res.data);
      setLoader(false);
    } catch (e) {
      setLoader(false);
    }
  };

  const fetchBuildingConfig = async () => {
    try {
      setLoader(true);
      const res = await fetchBuildingDashboardConfig(buildingId);

      setBuildingConfig(res.data);
      setLoader(false);
    } catch (e) {
      setLoader(false);

      toast('Failed to fetch building config');
    }
  };

  const fetchBuildingHelloData = async () => {
    try {
      setLoader(true);

      const res = await getBuildingHelloData(buildingId);

      setBuildingHelloData(res.data);

      setLoader(false);
    } catch (e) {
      setLoader(false);
    }
  };

  const onSubmitForm = async (enableHelloData: boolean) => {
    try {
      setLoader(true);

      await postEnableHelloData(buildingId, enableHelloData);
      await fetchBuildingHelloData();

      setLoader(false);
    } catch (e) {
      setLoader(false);
    }
  };

  useEffect(() => {
    Promise.allSettled([fetchBuildingHelloData()]).then(() => {
      fetchBuildingConfig();
      getAddress();
    });
  }, []);

  return {
    loader,
    isAddressPresent,
    buildingConfig,
    buildingHelloData,
    onSubmitForm
  };
};
