import React, { FC } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import CobuConstants from '~/helpers/cobuConstants';
import { Building } from '~/types/building';
import './EditBuildingReviewSettings.css';
import { FormInputConfig } from '~/types/residentManagement';
import Spinner from '../Spinner/Spinner';
import { FormField } from '../FormField';
import { GoogleInvitationSettings } from '~/types/reviewReceived';
import useGoogleReviewBuildingSettings from '~/hooks/googlereviews/useGoogleReviewBuildingSettings';

interface IProps {

  defaultData?: GoogleInvitationSettings | undefined;
  formConfig: FormInputConfig[];
  onCancel: () => void;
  onEditBuilding?: () => Promise<void>;
  building: Building;
  showPreview: any
}

export const EditBuildingReviewSettings: FC<IProps> = ({
  defaultData = {},
  formConfig,
  onCancel,
  showPreview,
  building }) => {

  const {
    register,
    handleSubmit,
    errors,
    getValues,
    control } = useForm<GoogleInvitationSettings>({
      defaultValues: defaultData
    });


  const { loader, updateGoogleReviewSettings, } = useGoogleReviewBuildingSettings(building)

  const generateField = (fieldConfig: FormInputConfig) => {
    return (
      <div key={fieldConfig.id}>
        <div className='item'>
          {!!fieldConfig?.label && (
            <label
              style={fieldConfig?.styles?.lableStyles}
              className={`${fieldConfig?.styles?.lableClassName}`}
              htmlFor='title'
            >
              {fieldConfig.label}
            </label>
          )}
          <FormField
            fieldConfig={fieldConfig as FormInputConfig}
            errors={errors}
            register={register}
            control={control}
          />
        </div>
      </div>
    );
  };

  const onSubmit: SubmitHandler<GoogleInvitationSettings> = (settings) => {
    updateGoogleReviewSettings(settings)
  };

  return (
    <div className='float-spinner-parent'>
      {loader && <Spinner float />}
      <form id='form-container' onSubmit={handleSubmit(onSubmit)}>
        <div className='grid'>
          {formConfig.map((field) => generateField(field))}
        </div>
        <div className='twoRow'>
          <button type="button" className='btn-standard btn-secondary' onClick={() => {
            let settings = getValues()
            showPreview(settings)
          }}>
            Preview Results
          </button>
          <div>
            <button type="button" className='btn-standard btn-secondary' onClick={onCancel}>
              {CobuConstants.createBuilding.cancel}
            </button>
            <input
              className='btn-standard btn-primary margin-left-16 '
              type='submit'
            />
          </div>
        </div>
      </form>
    </div>
  );
};
