import React, { useEffect, useState, useRef } from 'react';
import classes from './GenericModalWithOutButtons.module.css';
import { useOnClickOutside } from '~/helpers/helpers';

interface IProps {
  showModal: boolean;
  title: string;
  content: React.ReactNode;
  onChange: (arg: boolean) => void;
}

const GenericModalWithOutButtons = (props: IProps) => {
  const { showModal, content, title, onChange } = props;
  const [show, setShow] = useState(showModal);
  const ref = useRef<HTMLDivElement>(null);
  useOnClickOutside(ref, () => setShow(false));

  useEffect(() => {
    setShow(showModal);
  }, [showModal]);

  return (
    <div
      className={show ? classes.overlay : classes.hide}
      onClick={(e) => {
        onChange(false);
      }}
      ref={ref}
    >
      <div
        className={classes.modal}
        onClick={(e) => {
          // Prevent the modal from closing when clicking inside it
          e.stopPropagation();
        }}
      >
        <div className={classes.headerContainer}>
          <p className={classes.modalTitle}>{title}</p>
        </div>
        {content}
      </div>
    </div>
  );
};

export default GenericModalWithOutButtons;
