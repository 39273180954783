import React, { useState } from 'react';
import { Column } from 'react-table';
import classes from './TenantMatching.module.css';
import CobuTable from '~/components/CobuTable/CobuTable';
import { TenantInfo } from '../RentRoll/TenantRentRollTable';
import { ResidentUIModel } from '~/types/residentManagement';
import CobuTableAdvanced from '~/components/CobuTableAdvanced/CobuTableAdvanced';
import './style.css';
import { Colors } from '~/enums/Colors';

type OnSearchInput = (index: number, input: string) => void;
type OnTenantSelected = (resident: ResidentUIModel, tenant: TenantInfo) => void;
type SearchResults = Record<number, TenantInfo[]>;
type UserTenantMatches = Record<string, TenantInfo>;

const getColumnsForTenantsDetailsTable = ({
  selectedResident,
  userTenantMatches,
  onTenantSelected
}: {
  selectedResident: ResidentUIModel;
  userTenantMatches: UserTenantMatches;
  onTenantSelected: OnTenantSelected;
}) => {
  return [
    {
      Header: '',
      disableSortBy: true,
      id: 'radio',
      accessor: (tenant: any) => {
        return (
          <div>
            <button
              className='btn btn-small btn-primary'
              onClick={() => onTenantSelected(selectedResident, tenant)}
            >
              Match
            </button>
          </div>
        );
      }
    },
    {
      Header: 'Tenant Name',
      disableSortBy: true,
      id: 'name',
      accessor: (row: TenantInfo) => {
        return (
          <div>
            {row.firstName} {row.lastName}
          </div>
        );
      }
    },
    {
      Header: 'Tenant Email',
      disableSortBy: true,
      accessor: 'email',
      id: 'email'
    },
    {
      Header: 'Tenant Phone',
      disableSortBy: true,
      accessor: 'phone',
      id: 'phone'
    },
    {
      Header: 'Unit No.',
      disableSortBy: true,
      accessor: 'unit',
      id: 'unit'
    }
  ];
};

const getColumns = ({
  onSearchInput,
  onInputFocus,
  searchResults,
  onTenantSelected,
  userTenantMatches,
  viewTenantsDetailsClicked
}: {
  onSearchInput: OnSearchInput;
  onInputFocus: (index: number) => void;
  searchResults: SearchResults;
  onTenantSelected: OnTenantSelected;
  userTenantMatches: UserTenantMatches;
  viewTenantsDetailsClicked: (index: number) => void;
}): Column<any>[] => {
  const columns = [
    {
      Header: 'Name',
      disableSortBy: true,
      id: 'name',
      accessor: (row: any) => {
        return (
          <div>
            {row.firstName} {row.lastName}
          </div>
        );
      }
    },
    {
      Header: 'Email',
      disableSortBy: true,
      accessor: 'email',
      id: 'email'
    },
    {
      Header: 'Phone',
      disableSortBy: true,
      accessor: 'phone',
      id: 'phone'
    },
    {
      Header: 'Unit No.',
      disableSortBy: true,
      accessor: 'roomNumber',
      id: 'roomNumber'
    },
    {
      Header: 'Search',
      disableSortBy: true,
      accessor: (row: any, index: number) => (
        <div className={`flex ${classes.column}`}>
          <input
            onChange={(e) => onSearchInput(index, e.target.value)}
            onFocus={() => onInputFocus(index)}
            placeholder='Text to search'
          />
        </div>
      )
    },
    {
      Header: 'Match',
      disableSortBy: true,
      accessor: (row: any, index: number) => {
        if (
          Array.isArray(searchResults[index]) &&
          searchResults[index]?.length > 0
        ) {
          return (
            <div id='tenant-matching-table'>
              <CobuTable
                columns={getColumnsForTenantsDetailsTable({
                  selectedResident: row,
                  userTenantMatches: userTenantMatches,
                  onTenantSelected: onTenantSelected
                })}
                data={searchResults[index] ?? []}
                sort={{ id: 'name', descending: false }}
              />
            </div>
          );
        } else if (
          searchResults.hasOwnProperty(index) &&
          Array.isArray(searchResults[index]) &&
          searchResults[index].length == 0
        ) {
          return (
            <p className='margin-left-24' style={{ color: Colors.cobuRed }}>
              No matching tenant found
            </p>
          );
        }

        return null;
      }
    }
  ];

  return columns;
};

type TenantMatchingTableProps = {
  rows?: ResidentUIModel[];
  onSearchInput: OnSearchInput;
  onInputFocus: (index: number) => void;
  searchResults: SearchResults;
  onTenantSelected: OnTenantSelected;
  userTenantMatches: UserTenantMatches;
  totalUsers: number;
  // selectedRow?: any;
};

const TenantMatchingTable = (props: TenantMatchingTableProps) => {
  const [
    selectedIndexToViewMatchingTenants,
    setselectedIndexToViewMatchingTenants
  ] = useState<number>(-1);

  const openTenantsDetailsList = (index: number) =>
    setselectedIndexToViewMatchingTenants(index);

  const closeTenantsDetailsList = () =>
    setselectedIndexToViewMatchingTenants(-1);

  return (
    <div>
      <div id='users-table' className='table-wrapper'>
        <div
          className='margin-bottom-8'
          style={{ fontSize: '14px', color: Colors.cobuRed }}
        >
          total users missing tenant info - <b>{props.rows?.length ?? 0}</b> out
          of <b>{props.totalUsers}</b>
        </div>
        <CobuTableAdvanced
          noData={{
            label:
              Array.isArray(props.rows) && props.rows?.length == 0
                ? 'No user found with missing linked tenant'
                : ''
          }}
          columns={getColumns({
            onSearchInput: props.onSearchInput,
            onInputFocus: props.onInputFocus,
            searchResults: props.searchResults,
            onTenantSelected: props.onTenantSelected,
            userTenantMatches: props.userTenantMatches,
            viewTenantsDetailsClicked: openTenantsDetailsList
          })}
          data={props.rows ?? []}
          sort={{ id: 'name', descending: false }}
        />
      </div>
    </div>
  );
};

export default TenantMatchingTable;
