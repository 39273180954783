import React from 'react';
import Spacer from '../Spacer/Spacer';

interface User {
  name: string;
  profileImage: string;
}

interface Content {
  user: User;
  contents: string;
  children?: Content[];
}

interface Event {
  title: string;
  groupName: string;
  startDate: string;
  rsvpCount: number;
  averageScore: number;
}

interface Group {
  name: string;
  memberCount: number;
}

interface Props {
  buildingName: string;
  startDate: string;
  endDate: string;
  liveDate: string;
  totalUsers: number;
  adoptionRate: number;
  totalActiveUsers: number;
  activeRate: number;
  greatContent?: Content[];
  topEvents?: Event[];
  eventTotal: number;
  rsvpTotal: number;
  topGroups?: Group[];
  totalGroups: number;
  bestPractices: {
    content: string;
    description: string;
    heading: string;
    image: string;
  }[];
  reviews?: {
    reviewDate: string;
    reviewLink: string;
    reviewRating: number;
    reviewText: string;
    reviewerName: string;
  }[];
}

const MonthlyReport: React.FC<Props> = ({
  buildingName,
  startDate,
  endDate,
  liveDate,
  totalUsers,
  adoptionRate,
  totalActiveUsers,
  activeRate,
  greatContent,
  topEvents,
  eventTotal,
  rsvpTotal,
  topGroups,
  totalGroups,
  bestPractices,
  reviews
}) => {
  return (
    <>
      <div>
        <div
          style={{ display: 'flex', backgroundColor: '#01b5d1', padding: 10 }}
        >
          <img
            src='https://cobu-api-public.s3.amazonaws.com/cobulogocropped.png'
            alt='Cobu Logo'
            style={{ height: 50 }}
          />
          <div style={{ float: 'right', width: '80%' }}>
            <div style={{ fontWeight: 800, textAlign: 'right' }}>
              Monthly Report for {buildingName}
            </div>
            <div style={{ textAlign: 'right', fontWeight: 200 }}>
              {startDate} - {endDate}
            </div>
            <div style={{ textAlign: 'right', fontWeight: 200 }}>
              Live since: {liveDate}
            </div>
          </div>
        </div>
      </div>
      <Spacer height={20} />
      {!!bestPractices && bestPractices.length > 0 && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '20px 0',
            paddingBottom: '20px'
          }}
        >
          <div style={{ fontWeight: 800, fontSize: 18 }}>Best Practices</div>
          {bestPractices.map((bestPractice) => {
            const { image, heading, content, description } = bestPractice;

            return (
              <div
                style={{
                  padding: '12px',
                  borderRadius: '10px',
                  backgroundColor: 'rgba(242, 242, 246, 1)'
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flex: 1,
                    flexDirection: 'row',
                    gap: '0 20px',
                    alignItems: 'center'
                  }}
                >
                  <img src={image} width={'30px'} height={'30px'} />
                  <div style={{ fontWeight: 800, fontSize: 14 }}>{heading}</div>
                  <div style={{ flex: 1 }}>{content}</div>
                </div>
                {!!description && (
                  <div style={{ margin: '10px 0 0 0' }}>{description}</div>
                )}
              </div>
            );
          })}
        </div>
      )}
      {!!reviews && (
        <div>
          <div style={{ fontWeight: 800, fontSize: 18 }}>
            Cobu Generated Google Reviews
          </div>
          <Spacer height={4} />
          <div style={{ fontWeight: 800, fontSize: 14 }}>
            {`This month, Cobu generated the following 4 and 5 star review(s) for
            ${buildingName}`}
          </div>
          <Spacer height={20} />
          <div style={{ display: 'flex', flexDirection: 'row', flex: 1 }}>
            <div style={{ flex: 2, fontWeight: 800, fontSize: 16 }}>Name</div>
            <div style={{ flex: 1, fontWeight: 800, fontSize: 16 }}>Date</div>
            <div style={{ flex: 1, fontWeight: 800, fontSize: 16 }}>Rating</div>
            <div style={{ flex: 5, fontWeight: 800, fontSize: 16 }}>Review</div>
            <div style={{ flex: 1, fontWeight: 800, fontSize: 16 }}>Link</div>
          </div>
          <Spacer height={8} />
          <div
            style={{
              display: 'flex',
              gap: '10px 0',
              flexDirection: 'column'
            }}
          >
            {reviews.map((review) => {
              const {
                reviewDate,
                reviewLink,
                reviewRating,
                reviewText,
                reviewerName
              } = review;

              return (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    flex: 1,
                    gap: '10px 0'
                  }}
                >
                  <div style={{ flex: 2, fontSize: 16 }}>{reviewerName}</div>
                  <div style={{ flex: 1, fontSize: 16 }}>{reviewDate}</div>
                  <div style={{ flex: 1, fontSize: 16 }}>{reviewRating}</div>
                  <div style={{ flex: 5, fontSize: 16 }}>{reviewText}</div>
                  <div style={{ flex: 1, fontSize: 16 }}>
                    <a href={reviewLink}>link</a>
                  </div>
                </div>
              );
            })}
          </div>
          <Spacer height={30} />
        </div>
      )}
      <div>
        <div style={{ fontWeight: 800, fontSize: 18 }}>
          Adoption and Activity
        </div>
      </div>
      <Spacer height={20} />
      <div style={{ borderBottom: '1px solid #e1e1e1' }}>
        <div style={{ display: 'flex' }}>
          <div style={{ width: '50%' }}>
            <div style={{ fontWeight: 600, fontSize: 14 }}>
              Total Cobu Users
            </div>
            <div style={{ color: 'gray', fontSize: '10px' }}>
              Registered and active over the last 6 months
            </div>
            <div
              style={{ fontSize: '20px', fontWeight: 400, paddingTop: '8px' }}
            >
              {totalUsers}
            </div>
          </div>
          <div style={{ width: '50%' }}>
            <div style={{ fontWeight: 600, fontSize: 14 }}>Adoption Rate</div>
            <div style={{ color: 'gray', fontSize: '10px' }}>
              Users as a percentage of occupied units
            </div>
            <div
              style={{ fontSize: '20px', fontWeight: 400, paddingTop: '8px' }}
            >
              {adoptionRate}%
            </div>
          </div>
        </div>
      </div>
      <Spacer height={20} />
      <div style={{ borderBottom: '1px solid #e1e1e1' }}>
        <div style={{ display: 'flex' }}>
          <div style={{ width: '50%' }}>
            <div style={{ fontWeight: 600, fontSize: 14 }}>
              Total Active Users
            </div>
            <div style={{ color: 'gray', fontSize: '10px' }}>
              Active in the last 30 days
            </div>
            <div
              style={{ fontSize: '20px', fontWeight: 400, paddingTop: '8px' }}
            >
              {totalActiveUsers}
            </div>
          </div>
          <div style={{ width: '50%' }}>
            <div style={{ fontWeight: 600, fontSize: 14 }}>Active Rate</div>
            <div style={{ color: 'gray', fontSize: '10px' }}>
              Active users as a percentage of total users
            </div>
            <div
              style={{ fontSize: '20px', fontWeight: 400, paddingTop: '8px' }}
            >
              {activeRate}%
            </div>
          </div>
        </div>
      </div>
      <Spacer height={20} />
      <div style={{ borderBottom: '1px solid #e1e1e1' }}>
        <div style={{ fontWeight: 600, fontSize: 16 }}>
          National Portfolio Average
        </div>
        <Spacer height={10} />
        <div style={{ display: 'flex' }}>
          <div style={{ width: '50%' }}>
            <div style={{ fontWeight: 600, fontSize: 14 }}>Adoption Rate</div>
            <div
              style={{ fontSize: '20px', fontWeight: 400, paddingTop: '8px' }}
            >
              52%
            </div>
          </div>
          <div style={{ width: '50%' }}>
            <div style={{ fontWeight: 600, fontSize: 14 }}>Active Rate</div>
            <div
              style={{ fontSize: '20px', fontWeight: 400, paddingTop: '8px' }}
            >
              57%
            </div>
          </div>
        </div>
      </div>
      <Spacer height={20} />
      {greatContent && greatContent.length > 0 && (
        <>
          <div style={{ fontWeight: 600, fontSize: 14 }}>Great Content</div>
          <Spacer height={20} />
          {greatContent.map((content, index) => (
            <>
              <div
                key={index}
                style={{ display: 'flex', marginBottom: '20px' }}
              >
                <div>
                  <img
                    src={content.user.profileImage}
                    alt='Profile'
                    style={{ width: '50px', borderRadius: '50%' }}
                  />
                </div>
                <Spacer width={20} />
                <div>
                  <div
                    style={{
                      fontWeight: 600,
                      fontSize: 14,
                      marginBottom: '8px'
                    }}
                  >
                    {content.user.name}
                  </div>
                  <div style={{ color: '#272163', fontSize: 14 }}>
                    {content.contents}
                  </div>
                  <Spacer height={20} />
                  {content.children &&
                    content.children.map((childContent, childIndex) => (
                      <>
                        <div
                          key={childIndex}
                          style={{ display: 'flex', marginLeft: '5%' }}
                        >
                          <div>
                            <img
                              src={childContent.user.profileImage}
                              alt='Profile'
                              style={{ width: '50px', borderRadius: '50%' }}
                            />
                          </div>
                          <Spacer width={20} />
                          <div>
                            <div
                              style={{
                                fontWeight: 600,
                                fontSize: 14,
                                marginBottom: '8px'
                              }}
                            >
                              {childContent.user.name}
                            </div>
                            <div style={{ color: '#272163', fontSize: 14 }}>
                              {childContent.contents}
                            </div>
                          </div>
                        </div>
                        <Spacer height={10} />
                      </>
                    ))}
                </div>
              </div>
              <Spacer height={20} />
            </>
          ))}
        </>
      )}

      <div>
        <div style={{ fontWeight: 600, fontSize: 14 }}>Event Summary</div>
        <div style={{ margin: '10px 0' }}>
          {topEvents && topEvents.length > 0 ? (
            <>
              <div>
                This month had {eventTotal} event(s) with {rsvpTotal} rsvps
              </div>
              <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                <thead>
                  <tr style={{ borderBottom: '1px solid #e1e1e1' }}>
                    <th
                      style={{
                        padding: '8px 0',
                        textAlign: 'left',
                        fontWeight: 'bold',
                        color: 'gray',
                        fontSize: 12
                      }}
                    >
                      Top Events
                    </th>
                    <th
                      style={{
                        padding: '8px 0',
                        textAlign: 'left',
                        fontWeight: 'bold',
                        color: 'gray',
                        fontSize: 12
                      }}
                    >
                      Group
                    </th>
                    <th
                      style={{
                        padding: '8px 0',
                        textAlign: 'left',
                        fontWeight: 'bold',
                        color: 'gray',
                        fontSize: 12
                      }}
                    >
                      Date
                    </th>
                    <th
                      style={{
                        padding: '8px 0',
                        textAlign: 'left',
                        fontWeight: 'bold',
                        color: 'gray',
                        fontSize: 12
                      }}
                    >
                      RSVPs
                    </th>
                    <th
                      style={{
                        padding: '8px 0',
                        textAlign: 'left',
                        fontWeight: 'bold',
                        color: 'gray',
                        fontSize: 12
                      }}
                    >
                      Rating
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {topEvents.map((event, index) => (
                    <tr
                      key={index}
                      style={{ borderBottom: '1px solid #e1e1e1' }}
                    >
                      <td
                        style={{
                          padding: '8px 0',
                          textAlign: 'left',
                          fontSize: 14
                        }}
                      >
                        {event.title}
                      </td>
                      <td
                        style={{
                          padding: '8px 0',
                          textAlign: 'left',
                          fontSize: 14
                        }}
                      >
                        {event.groupName}
                      </td>
                      <td
                        style={{
                          padding: '8px 0',
                          textAlign: 'left',
                          fontSize: 14
                        }}
                      >
                        {event.startDate}
                      </td>
                      <td
                        style={{
                          padding: '8px 0',
                          textAlign: 'left',
                          fontSize: 14
                        }}
                      >
                        {event.rsvpCount}
                      </td>
                      <td
                        style={{
                          padding: '8px 0',
                          textAlign: 'left',
                          fontSize: 14
                        }}
                      >
                        {event.averageScore}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
          ) : (
            <div>
              No events were added to Cobu last month. Add events to Cobu so
              residents can RSVP, receive reminders, and can provide valuable
              feedback.
            </div>
          )}
        </div>
      </div>
      <Spacer height={25} />
      <div>
        <div style={{ fontWeight: 600, fontSize: 14 }}>
          {totalGroups} Total Groups (includes Marketplace & Building groups
          that are open to all residents)
        </div>
        {topGroups && topGroups.length > 0 && (
          <>
            <Spacer height={10} />
            <div style={{ fontWeight: 500, fontSize: 14 }}>
              Highest Performing Groups
            </div>
            <>
              <Spacer height={10} />
              <div style={{ borderBottom: '1px solid #e1e1e1' }}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginBottom: '4px'
                  }}
                >
                  <div
                    style={{
                      flex: '1',
                      fontWeight: 'bold',
                      color: 'gray',
                      fontSize: 12
                    }}
                  >
                    Name
                  </div>
                  <div
                    style={{
                      flex: '1',
                      fontWeight: 'bold',
                      color: 'gray',
                      fontSize: 12
                    }}
                  >
                    Members
                  </div>
                </div>
              </div>
              {topGroups.map((group, index) => (
                <div
                  key={index}
                  style={{
                    borderBottom: '1px solid #e1e1e1',
                    display: 'flex',
                    justifyContent: 'space-between',
                    paddingTop: '10px',
                    paddingBottom: '10px'
                  }}
                >
                  <div style={{ flex: '1', fontSize: 14 }}>{group.name}</div>
                  <div style={{ flex: '1', fontSize: 14 }}>
                    {group.memberCount}
                  </div>
                </div>
              ))}
            </>
          </>
        )}
      </div>

      <Spacer height={20} />
      <div>
        <div style={{ fontWeight: 800, fontSize: 18 }}>Best Practices</div>
        <ul>
          <li style={{ color: 'black', fontSize: 14 }}>
            Add your monthly events in the{' '}
            <a href='https://app.livecobu.com'>
              Cobu Property Management Dashboard
            </a>
          </li>
          <li style={{ color: 'black', fontSize: 14 }}>
            Use ‘RSVP on Cobu’ messaging to promote all events with QR
            code/magic link/building code
          </li>
          <li style={{ color: 'black', fontSize: 14 }}>
            Include ‘New Resident Welcome Flyer’ in move-in packets
          </li>
          <li style={{ color: 'black', fontSize: 14 }}>
            Send Cobu Team monthly email lists (or integrate with Entrata)
          </li>
        </ul>
      </div>
      <Spacer height={20} />
      <div>
        <div style={{ fontWeight: 800, fontSize: 18 }}>Event Ideas</div>
        <Spacer height={5} />
        <div style={{ fontSize: 14 }}>
          Need event ideas? See{' '}
          <a href='https://sites.google.com/livecobu.com/event-inspirations/event-checklist'>
            ideas
          </a>{' '}
          for the entire year
        </div>
      </div>
      <Spacer height={20} />
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column'
        }}
      >
        <div style={{ fontWeight: 500, fontSize: 14 }}>
          See more detailed data in the Cobu dashboard!
        </div>
        <button
          style={{
            backgroundColor: '#01b5d1',
            color: 'white',
            padding: '10px'
          }}
        >
          Go to Dashboard
        </button>
        <div style={{ color: 'gray', fontSize: 14 }}>
          Have questions or would like to learn about Cobu Plus? Reach out to us{' '}
          <a href='mailto:customersuccess@livecobu.com'>here</a>
        </div>
        <Spacer height={20} />
      </div>
    </>
  );
};

export default MonthlyReport;
