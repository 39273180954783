export enum AssetAccessors {
  buildingId = 'buildingId',
  buildingName = 'buildingName',
  isBuildingActive = 'isBuildingActive',
  tagName = 'tagName',
  tagUserId = 'tagUserId',
  ownerGroup = 'ownerGroup',
  pmOwnerGroup = 'pmOwnerGroup',
  allReviewCount60Days = 'allReviewCount60Days',
  cobuReviewCount60Days = 'cobuReviewCount60Days',
  unassignedReviews60Days = 'unassignedReviews60Days',
  adoptionRate = 'adoptionRate',
  activeRate = 'activeRate',
  eventsLast60days = 'eventsLast60days',
  pmEventsLast30days = 'pmEventsLast30days',
  avgReviewRating = 'avgReviewRating',
  buildingImage = 'buildingImage',
  buildingGoLive = 'buildingGoLive'
}

export interface IOwnerGroup {
  name: string;
  uuid: string;
  isOwner: boolean;
  isPropertyManagementGroup: boolean;
  parent: string;
}

export interface IBuildingWithClient {
  name: string;
  buildingId: string;
  clientId: string;
  clientParentId: string;
}

export interface IOwnerGroupProperty {
  [AssetAccessors.buildingId]: string;
  [AssetAccessors.buildingImage]: string;
  [AssetAccessors.buildingName]: string;
  [AssetAccessors.isBuildingActive]: boolean;
  [AssetAccessors.allReviewCount60Days]?: string;
  [AssetAccessors.cobuReviewCount60Days]?: string;
  [AssetAccessors.unassignedReviews60Days]?: string;
  [AssetAccessors.adoptionRate]?: string;
  [AssetAccessors.activeRate]?: string;
  [AssetAccessors.eventsLast60days]?: string;
  [AssetAccessors.pmEventsLast30days]?: string;
  [AssetAccessors.avgReviewRating]?: string;
  [AssetAccessors.buildingGoLive]?: string;
  [AssetAccessors.tagName]?: string;
  [AssetAccessors.tagUserId]?: string;
}

export interface IOwnerGroupAsset extends IOwnerGroupProperty {
  [AssetAccessors.ownerGroup]?: IOwnerGroup;
  [AssetAccessors.pmOwnerGroup]?: IOwnerGroup;
}

export enum AssetGoogleReviewAccessor {
  buildingName = 'buildingName',
  buildingId = 'buildingId',
  avgReviewRating = 'avgReviewRating',
  startReviewOutreach = 'startReviewOutreach',
  totalGoogleReviews = 'totalGoogleReviews',
  cobuReviews = 'cobuReviews',
  cobuReviewStarRating = 'cobuReviewStarRating',
  nonCobuReviewStarRating = 'nonCobuReviewStarRating',
  allReviewsWithSnippet = 'allReviewsWithSnippet',
  cobuReviewsWithSnippet = 'cobuReviewsWithSnippet',
  nonCobuReviewsWithSnippet = 'nonCobuReviewsWithSnippet',
  pmOwnerGroup = 'pmOwnerGroup',
  isBuildingActive = 'isBuildingActive'
}

export interface IOwnerGroupGoogleRevew {
  [AssetGoogleReviewAccessor.buildingId]: string;
  [AssetGoogleReviewAccessor.buildingName]: string;
  [AssetGoogleReviewAccessor.isBuildingActive]: boolean;
  [AssetGoogleReviewAccessor.startReviewOutreach]: string;
  [AssetGoogleReviewAccessor.avgReviewRating]?: string;
  [AssetGoogleReviewAccessor.totalGoogleReviews]?: string;
  [AssetGoogleReviewAccessor.cobuReviews]?: boolean;
  [AssetGoogleReviewAccessor.cobuReviewStarRating]?: string;
  [AssetGoogleReviewAccessor.nonCobuReviewStarRating]?: string;
  [AssetGoogleReviewAccessor.allReviewsWithSnippet]?: string;
  [AssetGoogleReviewAccessor.cobuReviewsWithSnippet]?: string;
  [AssetGoogleReviewAccessor.nonCobuReviewsWithSnippet]?: string;
  [AssetGoogleReviewAccessor.pmOwnerGroup]?: IOwnerGroup;
}

export enum AssignmentTagAccessor {
  tagName = 'tagName',
  propertyName = 'propertyName',
  action = 'action'
}

export interface IAssignmentTag {
  uuid: string;
  [AssignmentTagAccessor.tagName]: string;
}

export interface IAssignmentTagProperty {
  uuid: string;
  [AssignmentTagAccessor.propertyName]: string;
  [AssignmentTagAccessor.tagName]: string;
  userUuid: string;
}

export enum OwnerManagementAccessor {
  ownerGroup = 'ownerGroup',
  propertyMngGroup = 'propertyMngGroup',
  propertyName = 'name'
}

export interface IOwnerGroupManagement extends IOwnerGroup {
  [OwnerManagementAccessor.ownerGroup]?: string;
  [OwnerManagementAccessor.propertyMngGroup]?: string;
}

export interface IManagedProperty {
  buildingId: string;
  clientParentId: string;
  name: string;
}
