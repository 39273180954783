import { ReactNode } from 'react';
import { Column } from 'react-table';
import React from 'react';
import { TableColumn } from './TableColumn';
import './ColumnGrowTable.css';

interface IColumnWithSibling {
  sibling: ReactNode;
}

interface IProps<T> {
  columns: (Column<any> & IColumnWithSibling)[];
  tableData: T;
  height?: number;
}

export const ColumnGrowTable = <T,>({
  columns,
  tableData,
  height
}: IProps<T>) => {
  const tables = columns.map((column) => {
    const { sibling, ...rest } = column;
    if (!tableData[column.accessor as keyof typeof tableData]) {
      return null;
    }

    return (
      <div className='table-column' key={column.accessor as string}>
        <TableColumn
          column={rest as Column<any>}
          columnData={tableData[column.accessor as keyof typeof tableData]}
          height={height}
          // sort={{ id: column.accessor as string, descending: false }}
        />
        {!!sibling && sibling}
      </div>
    );
  });

  return <div className='table-container'>{tables}</div>;
};
