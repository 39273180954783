import React from 'react';
import { useForm } from 'react-hook-form';
import { emailRegex } from '~/helpers/helpers';

import { RSVPRegisterFormType } from '~/types/auth';
import './RSVPRegisterForm.css';

interface IProps {
  onSubmit: (data: RSVPRegisterFormType) => void;
}
export const RSVPRegisterForm = (props: IProps) => {
  const { register, handleSubmit, errors } = useForm<RSVPRegisterFormType>();

  const onSubmit = (data: RSVPRegisterFormType) => {
    props.onSubmit(data);
  };

  return (
    <div>
      <form className='rsvp-register-form' onSubmit={handleSubmit(onSubmit)}>
        <div className='rsvp-register-row'>
          <div>
            <div className='form-field-error-box'>
              {errors.email ? errors.email.message : ''}
            </div>
            <input
              type='text'
              id='email'
              name='email'
              placeholder={'Email Address'}
              ref={register({
                required: 'This field is required',
                pattern: {
                  value: emailRegex(),
                  message: 'Please enter a valid email'
                }
              })}
              className={`field-long space ${errors.email && 'border-red'}`}
            />
          </div>
          <div>
            <div className='form-field-error-box'>
              {errors.password ? errors.password.message : ''}
            </div>
            <input
              type='password'
              id='password'
              name='password'
              placeholder='Password'
              ref={register({
                required: 'This field is required',
                minLength: {
                  value: 6,
                  message: 'Password must be at least 6 characters'
                }
              })}
              className={`field-long space ${errors.password} && 'border-red'}`}
            />
          </div>
        </div>
        <div className='rsvp-register-row'>
          <div className='separator-field-container'>
            <div>
              <div className='form-field-error-box'>
                {errors.firstName ? errors.firstName.message : ''}
              </div>
              <input
                type='text'
                id='firstName'
                name='firstName'
                placeholder={'First name'}
                ref={register({
                  required: 'This field is required'
                })}
                className={`field-short space ${
                  errors.firstName && 'border-red'
                }`}
              />
            </div>
            <div>
              <div className='form-field-error-box'>
                {errors.lastName ? errors.lastName.message : ''}
              </div>
              <input
                type='text'
                id='lastName'
                name='lastName'
                placeholder='Last name'
                ref={register({
                  required: 'This field is required'
                })}
                className={`field-short space ${
                  errors.lastName && 'border-red'
                }`}
              />
            </div>
            <div>
              <div className='form-field-error-box'>
                {errors.roomNumber ? errors.roomNumber.message : ''}
              </div>
              <input
                type='text'
                id='roomNumber'
                name='roomNumber'
                placeholder='Unit number'
                ref={register({
                  required: 'This field is required'
                })}
                className={`field-short space ${
                  errors.roomNumber && 'border-red'
                }`}
              />
            </div>
          </div>
        </div>
        <div className='rsvp-register-button-container'>
          <button
            type='submit'
            className='submit-button pointer register-button'
          >
            Continue
          </button>
        </div>
      </form>
    </div>
  );
};

export default RSVPRegisterForm;
