import { FormInputConfig } from '~/types/residentManagement';

export const yardiPMSFormConfig: FormInputConfig[] = [
  {
    label: 'Username',
    inputType: 'text',
    id: 'username',
    isRequired: true
  },
  {
    label: 'Password',
    inputType: 'text',
    id: 'password',
    isRequired: true
  },
  {
    label: 'Database',
    inputType: 'text',
    id: 'database',
    isRequired: true
  },
  {
    label: 'Server Name',
    inputType: 'text',
    id: 'serverName',
    isRequired: true
  },
  {
    label: 'Web Service URL',
    inputType: 'text',
    id: 'webServiceUrl',
    isRequired: true
  }
];
