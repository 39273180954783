import React, { useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import Breadcrumbs from '~/components/Breadcrumbs/Breadcrumbs';
import { FormField } from '~/components/FormField';
import Spinner from '~/components/Spinner/Spinner';
import useEnableHelloData from '~/hooks/building/useEnableHelloData';
import { FormInputConfig } from '~/types/residentManagement';
import { IEnableHelloDataForm } from './interfaces';
import { HelloDataDetails } from './HelloDataDetails';
import Spacer from '~/components/Spacer/Spacer';

export const EnableHelloData = () => {
  const { buildingId } = useParams<{ buildingId: string }>();
  const history = useHistory();
  const isMounted = useRef(false);

  const {
    isAddressPresent,
    loader,
    buildingConfig,
    onSubmitForm,
    buildingHelloData
  } = useEnableHelloData(buildingId);

  const {
    register,
    errors,
    control,
    watch,
    reset,
    formState: { isDirty, dirtyFields }
  } = useForm<IEnableHelloDataForm>({
    defaultValues: {
      enableHelloData: false
    }
  });

  useEffect(() => {
    if (buildingConfig) {
      const enableHelloData = buildingConfig?.config?.enableHelloData ?? false;

      reset({
        enableHelloData
      });
    }
  }, [buildingConfig]);

  const enableHelloData = watch('enableHelloData');

  useEffect(() => {
    if (buildingConfig) {
      if (isMounted.current) {
        onSubmitForm(enableHelloData);
      } else {
        isMounted.current = true;
      }
    }
  }, [enableHelloData, buildingConfig]);

  const navigateToAddAddress = () => {
    history.push(`/building/${buildingId}/edit/address`);
  };

  const enableHelloDataFormConfig: FormInputConfig[] = [
    {
      label: 'Enable Hello data',
      inputType: 'switch',
      id: 'enableHelloData',
      isRequired: false,
      isDisabled: isAddressPresent,
      styles: {
        contentStyles: {
          flexDirection: 'row',
          alignItems: 'center',
          display: 'flex',
          gap: '10px'
        }
      }
    }
  ];

  const generateField = (fieldConfig: FormInputConfig, className?: string) => {
    return (
      <div key={fieldConfig.id} className={className}>
        <div className='item'>
          {!!fieldConfig?.label && (
            <label
              style={fieldConfig?.styles?.lableStyles}
              className={`${fieldConfig?.styles?.lableClassName}`}
              htmlFor='title'
            >
              {fieldConfig.label}
            </label>
          )}
          <FormField
            fieldConfig={fieldConfig}
            errors={errors}
            register={register}
            control={control}
          />
        </div>
      </div>
    );
  };

  return (
    <div
      className='container'
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start'
      }}
    >
      <Breadcrumbs />
      <div className='float-spinner-parent'>
        {loader && <Spinner float />}
        {(isAddressPresent || buildingConfig?.config.enableHelloData) && (
          <form className='margin-top-24'>
            <div
              style={{ flexDirection: 'row', display: 'flex', gap: '0 30px' }}
            >
              <div>
                {enableHelloDataFormConfig.map((field) => generateField(field))}
              </div>
            </div>
          </form>
        )}
      </div>
      {!isAddressPresent && (
        <button
          className='btn-standard btn-secondary'
          onClick={navigateToAddAddress}
        >
          Enter building Address to sync Hello Data
        </button>
      )}

      {buildingHelloData ? (
        <>
          <Spacer height={20} />
          <HelloDataDetails buildingHelloData={buildingHelloData} />
        </>
      ) : !loader ? (
        <h3 className='color-warning'>
          No Hello-Data to display, please sync hello data by toggling above
          switch
        </h3>
      ) : null}
    </div>
  );
};
