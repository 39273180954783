import React, { useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import CobuConstants from '~/helpers/cobuConstants';
import {
  ResidentInputFieldConfig,
  CreateResidentFormType
} from '~/types/residentManagement';
import MultiSelect from '../MultiSelect/MultiSelect';
import { SelectedOptionType } from '~/types/form';
import { SelectType } from '~/enums/SelectType';
import Spacer from '../Spacer/Spacer';
import { toast } from 'react-toastify';
import { CreateSurveyBody } from '~/types/surveys';
import { useCobuSelector } from '~/hooks/useCobuSelector';

type IProps = {
  createSurvey: (r: CreateSurveyBody) => void;
  cancel: () => void;
  formConfig: ResidentInputFieldConfig[];
};

const CreateSurveyForm = ({ cancel, createSurvey, formConfig }: IProps) => {
  //@ts-ignore
  const buildings = useCobuSelector((state) => state.building.buildings);
  const [multiValue, setMultiValue] = useState<SelectedOptionType[]>([]);

  const { register, handleSubmit, errors } = useForm<any>({});

  const onSubmit: SubmitHandler<CreateSurveyBody> = (survey) => {
    if (multiValue.length === 0) {
      toast(CobuConstants.createSurvey.buildingNotSelectedWarning);
      return;
    }
    let building = multiValue[0].value;
    createSurvey({
      ...survey,
      building
    });
  };

  const getBuildingsForDropdown = () => {
    const buildingsForDropdown: SelectedOptionType[] = buildings.map(
      (building: any) => {
        return {
          value: building.uuid,
          label: building.name
        };
      }
    );
    return buildingsForDropdown;
  };

  const generateField = (fieldConfig: ResidentInputFieldConfig) => {
    let error = errors[`${fieldConfig.id}` as keyof CreateSurveyBody];
    return (
      <div key={fieldConfig.id}>
        <div className='item'>
          <label htmlFor='title'>{fieldConfig.label}</label>
          <input
            type='text'
            id={fieldConfig.id}
            name={fieldConfig.id}
            readOnly={fieldConfig.readOnly}
            ref={register({
              required: true,
              pattern: fieldConfig.pattern,
              maxLength: fieldConfig.maxLength,
              minLength: fieldConfig.minLength
            })}
            className={`${fieldConfig.readOnly && 'nonEditable'} ${
              error && 'border-red'
            }`}
          />
          {error && error.type === 'required' && (
            <span className='errorTitle' role='alert'>
              {CobuConstants.createResident.requiredError}
            </span>
          )}
          {error && fieldConfig.pattern && error.type === 'pattern' && (
            <span className='errorTitle' role='alert'>
              {fieldConfig.patternErrorMsg}
            </span>
          )}
          {error && fieldConfig.maxLengthError && error.type === 'maxLength' && (
            <span className='errorTitle' role='alert'>
              {fieldConfig.maxLengthError}
            </span>
          )}
          {error && fieldConfig.maxLengthError && error.type === 'minLength' && (
            <span className='errorTitle' role='alert'>
              {fieldConfig.minLengthError}
            </span>
          )}
        </div>
      </div>
    );
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='gridContainer'>
          <div>
            <label htmlFor='title'>
              {CobuConstants.createSurvey.buildingTitle}
            </label>
            <Spacer height={8} />
            <MultiSelect
              singleSelect
              options={getBuildingsForDropdown()}
              selectType={SelectType.Form}
              multiValue={multiValue ?? [{ label: '', value: '' }]}
              setMultiValue={setMultiValue}
              placeholder={CobuConstants.createSurvey.buildingPlaceholder}
              cobuPlusBuildings={[]}
            />
          </div>
          {formConfig.map((field) => generateField(field))}
        </div>
        <div className='formButtons'>
          <button className='btn-standard btn-secondary' onClick={cancel}>
            {CobuConstants.createResident.cancel}
          </button>
          <input
            className='btn-standard btn-primary margin-left-16 '
            type='submit'
          />
        </div>
      </form>
    </div>
  );
};

export default CreateSurveyForm;
