import { Widget } from '~/types/layout';
import { HorizontalList } from './HorizontalList';

const widgetsMap = new Map([['HORIZONTAL_LIST', HorizontalList]]);

export const getLayoutWidget = (widget: Widget) => {
  if (widgetsMap.has(widget.type)) {
    return widgetsMap.get(widget.type);
  } else {
    return null;
  }
};
